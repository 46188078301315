<template>
    <CheckTicketNsoftV2 v-if="isNSoft" />
    <CheckTicketInhouseV2 v-else />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import CheckTicketNsoftV2 from '@src/v2/app/modules/checkTicket/CheckTicketNsoftV2.vue';
import CheckTicketInhouseV2 from '@src/v2/app/modules/checkTicket/CheckTicketInhouseV2.vue';
import commonConfig from '@src/config/common';

export default defineComponent({
    name: 'CheckTicketV2',
    components: {
        CheckTicketNsoftV2,
        CheckTicketInhouseV2,
    },
    setup() {
        const isNSoft = computed(() => {
            return commonConfig.environment.isNSoft;
        });

        return {
            isNSoft,
        };
    },
});
</script>
