<template>
    <div
        :id="`event-${event.id}`"
        class="event-sport-row-container"
        :class="{ 'is-locked': event.isLocked, 'is-highlighted': isHighlighted, 'is-expanded': isSelected }"
    >
        <div :class="{ 'is-expanded': isSelected }" class="event-sport-row" @click="handleClick($event)">
            <div class="event-row__layout">
                <EventSummary
                    :isLive="event.isOngoing()"
                    :startDate="event.date"
                    :team1Name="event.team1Name"
                    :team2Name="event.team2Name"
                    :fullName="event.fullName"
                    :viewingOptions="{
                        liveBetting: event.isGoingToBeInLiveBetting,
                        liveSound: event.isGoingToHaveLiveAudio,
                    }"
                    :liveData="event.liveData"
                    :sportId="event.sportId"
                    :isInGrid="true"
                    :team1PreviousResult="event.team1PreviousResult"
                    :team2PreviousResult="event.team2PreviousResult"
                    :isCodeShown="isCodeShown"
                    :code="event.code"
                />
                <div class="event-sport-row___markets">
                    <div class="primary-sport-market__title">
                        {{ primaryMarket && primaryMarket.name }}
                    </div>
                    <div class="primary-sport-market__wrapper" :class="event.isLocked ? 'is-locked' : ''">
                        <template v-if="event.oddCount > 0">
                            <PrimaryMarket
                                v-if="!isSelected"
                                v-bind="primaryMarket || {}"
                                :marketFilters="marketFilters"
                                :eventId="event.id"
                                :eventCategory="event.eventCategory"
                                :selectedOdd="selectedOdd"
                                :isEventLocked="event.isLocked"
                                :oddValueDynamics="oddValueDynamics"
                                @oddClick="onOddClick"
                            />
                        </template>
                        <div v-else class="primary-sport-market__unavalibale">
                            {{ $t('no markets currently available') | upperCase }}
                        </div>
                        <button
                            v-if="displayMarketCount && !isSelected && additionalMarketCount"
                            :class="{ 'is-selected': hasSelectionInCollapsedMarkets }"
                            class="pick-sport__more-odds"
                        >
                            {{ additionalMarketCount ? `+${additionalMarketCount}` : '' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isSelected" class="event-sport-row__markets-container">
            <!--This is switcher for market filters (popular, all markets, etc.)-->
            <div class="overlay-control-element-container" style="width: 100%">
                <div class="overlay-control-element" @click="onBackButtonClicked">
                    <i class="icon icon-chevron_left event-sport-summary_icon" />
                    {{ displayName }}
                </div>
                <div class="overlay-control-element" @click="onBackButtonClicked">X</div>
            </div>
            <div v-if="event.isOngoing() && event.liveData" class="event-sport-overlay__score-helper">
                <div style="display: flex-column">
                    <div class="score-helper__wrapper" style="color: white">
                        <span
                            v-for="period in periods"
                            :key="period"
                            class="score-helper__item"
                            style="margin-right: 16px; width: 38px"
                        >
                            {{ $t(period) }}
                        </span>
                    </div>
                    <ScoreWidget
                        v-if="event.isOngoing()"
                        :liveData="event.liveData"
                        :sportId="event.sportId"
                        :isScoreCompact="false"
                    />
                </div>
            </div>
            <TypeSwitcher
                :types="marketFilters"
                :selected="trueSelectedMarketFilter"
                class="market-terminal-group__tabs"
                @valueChanged="onMarketClick"
                @onBack="onBackButtonClicked"
            />
            <MarketGroups
                :groupedMarkets="groupedMarkets"
                v-bind="marketGroupsCommonFields.props"
                v-on="marketGroupsCommonFields.listeners"
            />
            <AllMarkets
                :markets="marketsForSelectedGroup"
                :selectedGroup="realSelectedMarketGroupId"
                :eventId="event.id"
                :isOutright="isOutright"
                :eventOddsMap="eventOddsMap"
                :isEventLocked="event.isLocked"
                :selectedOdd="selectedOdd"
                :isOngoing="event.isOngoing()"
                :oddValueDynamics="oddValueDynamics"
                @oddClick="onOddClick"
            />
        </div>
        <MatchFooterRow v-if="event.matchFooter" :content="event.matchFooter" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { partition as _partition } from '@lodash';
import EventSummary from '@src/terminal/app/modules/shared/event/EventSummary.vue';
import { MarketFilter, EventTab } from '@app/store/modules/ui/sportOffer/sports/enums.ts';
import { MarketAttribute } from '@core/constants';
import { capitalize } from '@shared/filters';
import TypeSwitcher from '@src/terminal/app/modules/shared/components/TypeSwitcher.vue';
import { groupMarkets } from '@src/terminal/app/modules/eventGrid/marketGroupHelpers';
import { EventCategory } from '@core/models/offer/enums';
import PrimaryMarket from './PrimaryMarket.vue';
import MarketGroups from './MarketGroups.vue';
import AllMarkets from './AllMarkets.vue';
import MatchFooterRow from './MatchFooterRow.vue';
import { orderMarkets } from '../eventRowUtils';
import ScoreWidget from '../../shared/event/ScoreWidget.vue';

const ANIMATION_DURATION = 2000; // ms
// const SuperbetsMarketId = 999999;

export default {
    name: 'EventRow',
    props: {
        periods: {
            type: Array,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
        typeId: {
            type: [String, Number],
            required: true,
        },
        selectedMarketFilter: {
            type: String,
            default: '',
        },
        isSelected: {
            type: Boolean,
            required: false,
        },
        selectedOdds: {
            type: Set,
            required: false,
            default: () => new Set(),
        },
        hasSelectionInCollapsedMarkets: {
            type: Boolean,
            required: false,
            default: false,
        },
        selectedOdd: {
            type: String,
            required: false,
            default: '',
        },
        eventOddsMap: {
            type: Array,
            required: false,
        },
        primaryMarketAttribute: {
            type: String,
            default: MarketAttribute.main,
        },
        isHighlighted: {
            type: Boolean,
            required: false,
        },
        oddValueDynamics: {
            type: Object,
            required: false,
        },
        displayMarketCount: {
            type: Boolean,
            default: true,
        },
        isCodeShown: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            selectedMarketGroupId: null,
            marketGroupsSnapshot: [],
        };
    },
    watch: {
        marketGroups(newValue) {
            this.marketGroupsSnapshot = newValue;
        },
        isHighlighted(isHighlighted) {
            if (isHighlighted) {
                if (this.timeout) {
                    clearTimeout();
                }
                this.timeout = setTimeout(() => {
                    this.$emit('removeHighlight');
                }, ANIMATION_DURATION);
            }
        },
        isSelected(newValue) {
            if (newValue) {
                this.willExpand = true;
            } else {
                this.willCollapse = true;
            }
            this.emitRenderBeforeDestroy = true;
        },
    },
    mounted() {
        if (this.isSelected) {
            this.$emit('rowHeightChanged', this.$el.clientHeight);
        }
    },
    updated() {
        if (this.isSelected) {
            this.$nextTick(() => {
                this.$emit('rowHeightChanged', this.$el.clientHeight);
            });
        }

        if (this.willExpand) {
            delete this.willExpand;
            this.$emit('expand');
        } else if (this.willCollapse) {
            this.changeMarketGroup(null);
            delete this.willCollapse;
            this.$emit('collapse');
        }
    },
    computed: {
        ...mapGetters('data/sportOffer', ['getMarketGroupInfosForSportId']),
        isOutright() {
            return this.event.eventCategory === EventCategory.OUTRIGHT;
        },
        trueSelectedMarketFilter() {
            const currentFilter =
                this.marketFilters.find(({ type }) => type === this.selectedMarketFilter) || this.marketFilters[0];
            if (currentFilter.disabled) {
                const firstAvailableFilter = this.marketFilters.find(({ disabled }) => !disabled);
                return firstAvailableFilter ? firstAvailableFilter.type : null;
            }
            return currentFilter.type;
        },
        displayName() {
            if (this.event.eventCategory === EventCategory.OUTRIGHT) return this.event.fullName;
            return `${this.event.team1Name} - ${this.event.team2Name}`;
        },
        isSpecialTabActive() {
            return this.selectedEventTabWithFallback === EventTab.specialOffer;
        },
        marketGroupInfos() {
            return this.getMarketGroupInfosForSportId(this.event.sportId);
        },
        marketGroups() {
            const newMarketGroups = Array.from(this.groupedMarkets.values(), (group) => ({
                type: group.id,
                displayName: group.name,
            }));

            // If selected market group exists in new groups
            // set the new list of market groups
            if (this.groupedMarkets.has(this.selectedMarketGroupId) || !this.selectedMarketGroupId) {
                return newMarketGroups;
            }
            return this.marketGroupsSnapshot;
        },
        realSelectedMarketGroupId() {
            if (this.selectedMarketGroupId) {
                return this.selectedMarketGroupId;
            }
            return this.marketGroups.length ? this.marketGroups[0].type : null;
        },
        marketsForSelectedGroup() {
            return this.groupedMarkets.has(this.realSelectedMarketGroupId)
                ? this.groupedMarkets.get(this.realSelectedMarketGroupId).markets
                : [];
        },
        groupedMarkets() {
            return groupMarkets(this.marketGroupInfos, this.markets, this.$t('all').toString());
        },
        selectedEventTabWithFallback() {
            const currentFilter =
                this.eventTabs.find(({ type }) => type === this.selectedMarketFilter) || this.eventTabs[0];

            if (currentFilter.disabled) {
                const firstAvailableFilter = this.eventTabs.find(({ disabled }) => !disabled);
                return firstAvailableFilter ? firstAvailableFilter.type : null;
            }

            return currentFilter.type;
        },
        eventTabs() {
            const isAllMarketsDisabled = this.event.getOdds().length === 0;
            return [
                {
                    type: EventTab.allMarkets,
                    displayName: capitalize(this.$t('all markets')),
                    disabled: isAllMarketsDisabled,
                },
            ];
        },
        marketGroupsCommonFields() {
            return {
                props: {
                    eventId: this.event.id,
                    isEventLocked: this.event.isLocked,
                    selectedOdds: this.selectedOdds,
                    isOngoing: this.event.isOngoing(),
                    oddValueDynamics: this.oddValueDynamics,
                    areAdditionalMarketsLoaded: this.areAdditionalMarketsLoaded,
                    activeMarketsTab: this.selectedEventTabWithFallback || '',
                },
                listeners: {
                    oddClick: this.onOddClick,
                    // marketHeightChanged: this.emitHeightChange,
                    marketGroupChanged: this.changeMarketGroup,
                },
            };
        },
        markets() {
            let markets = this.event.getMarkets();

            const [superbets, other] = _partition(markets, (m) => m.isSuperbet);
            if (superbets.length) {
                // superbets are all seperated markets, but we are grouping them together
                const odds = superbets.map((s) => s.odds[0]);
                const id = superbets.map((s) => s.id)[0];
                const uniqueId = superbets.map((s) => s.uniqueId)[0];
                const order = Math.min(...odds.map((odd) => odd.marketOrder));
                const superbetsMarket = {
                    order,
                    odds,
                    id,
                    uniqueId,
                    name: this.$t('superbets'),
                    isSuperbet: true,
                    isLocked: false,
                };
                markets = [...other, superbetsMarket];
            }
            return orderMarkets(markets, this.primaryMarketAttribute, ['asc', 'order'], ['desc', 'asc']);
        },
        areAdditionalMarketsLoaded() {
            return this.markets.length === this.event.marketCount || this.markets.length > 1;
        },
        additionalMarketCount() {
            return Math.max(0, this.event.marketCount - 1);
        },
        primaryMarket() {
            return this.event.getPrematchMarket(this.primaryMarketAttribute);
        },
        marketFilters() {
            const noMarkets = this.event.getOdds().length === 0;
            return [
                {
                    type: MarketFilter.allMarkets,
                    displayName: capitalize(this.$t('all markets')),
                    disabled: noMarkets,
                },
            ];
        },
    },
    beforeDestroy() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        /*  (1) page height changes can make the row be destroyed because it's situated
                in a virtual scroller. We have to notify the parent that the render
                of this row is finished so he can initiate the scroll
                (We notify the parent that this row is finished rendering in 2 cases.
                The first one is when it expanded after it gets full markets.
                If this row is destroyed due to falling out of the rendered area of
                the virtual scroll the update after getting full markets is never called
                so we have to notify the parent that it's indeed finished rendering (or being destoyed))
            */
        if (this.emitRenderBeforeDestroy) {
            if (this.isSelected) {
                this.$emit('expand');
            } else {
                this.$emit('collapse');
            }
        }
    },
    methods: {
        changeMarketGroup(type) {
            this.selectedMarketGroupId = type;
            this.$emit('marketGroupChanged');
        },
        /* this logic is used to avoid using stopPropagation on click because of reasons explained here
                https://css-tricks.com/dangers-stopping-event-propagation/
            */
        handleClick(nativeEvent) {
            const target = nativeEvent.target;
            let clickedOnEvent = false;
            if (target === this.$el) {
                clickedOnEvent = true;
            } else {
                /*
                        class 'actionable' is a convention to put on html elements with their own click handlers.
                        These elements shouldn't activate their parent click handler
                    */
                clickedOnEvent = target.className.indexOf('actionable') === -1;
            }
            if (clickedOnEvent) {
                const offsetTop = this.$el.getBoundingClientRect().y;

                this.$emit('eventClick', {
                    offsetTop,
                    eventId: this.event.id,
                    typeId: this.typeId,
                });
            }
        },
        onMarketClick(type) {
            const offsetTop = this.$el.getBoundingClientRect().y;
            this.$emit('marketClick', type, {
                offsetTop,
            });
        },
        onOddClick(odd) {
            if (this.event.isLocked || odd.isLocked) {
                return;
            }

            this.$emit('oddClick', this.event.id, odd);
        },
        getMarketStickyScrollDistance() {
            const marketsStickyHeader = this.$el.querySelector('.market-group__tabs');
            const marketsContainer = this.$el.querySelector('.event-row__markets-container');
            if (!marketsStickyHeader || !marketsContainer) {
                return 0;
            }
            const verticalOffset =
                marketsContainer.getBoundingClientRect().y - marketsStickyHeader.getBoundingClientRect().y;
            return verticalOffset;
        },
        onBackButtonClicked() {
            // no offset is provided due to all markets are now overlay and do not change the scrollable component
            this.$emit('eventClick', {
                eventId: this.event.id,
                typeId: this.typeId,
            });
        },
    },
    components: {
        ScoreWidget,
        EventSummary,
        PrimaryMarket,
        AllMarkets,
        MatchFooterRow,
        TypeSwitcher,
        MarketGroups,
    },
};
</script>
