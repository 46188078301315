import config from '@config';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import type { ISportOfferState, SportOfferGetters } from './types';

function defaultState(): ISportOfferState {
    return {
        sports: {},
        categories: {},
        tournaments: {},
        markets: {},
        oddTypes: {},
        betGroupTree: [],
        events: {},
        activeEvents: {},
        oddValueDynamics: {},
        topTenEvents: [],
        subscriptions: {},
        isOfferStarting: false,
        isOfferReady: false,
        isStructFetched: false,
        shouldFetchOffer: true,
        specialTournaments: {},
        featuredTournaments: config.app.sportOffer.featuredEvents,
        superOfferConfig: {},
        liveEventsCount: 0,
        promotionalEvents: {},
        allChangesIncrementId: 0,
        areSubscriptionsSet: false,
        isOfferFetched: false,
        isSuperOfferFetched: false,
        areTopTenEventsFetched: false,
        expandedMarketIds: {},
        marketGroupInfos: {},
        betBuilderOddTimestamps: {},
    };
}

export default {
    mutations,
    actions,
    getters,
    namespaced: true,
    state: defaultState(),
};

export { ISportOfferState, SportOfferGetters };
