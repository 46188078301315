<template>
    <div>
        <VirtualKeyboard />
        <ModalContainer />
        <AppReadyChecker>
            <AppHealthChecker :checkedParts="checkedParts">
                <div id="app">
                    <!-- <router-view name="header"></router-view> -->

                    <div class="app-main">
                        <router-view v-if="useDesignV2" name="designV2"></router-view>
                        <router-view v-else name="designV1"></router-view>
                    </div>
                    <router-view v-if="isSportNavVisible" name="mobileBottomNav" />
                    <!--                    footer was removed for the terminal -->
                    <!--                    <router-view name="footer" />-->
                </div>
            </AppHealthChecker>
        </AppReadyChecker>
    </div>
</template>

<script>
import { debounce as _debounce } from '@lodash';
import { mapGetters, mapActions } from 'vuex';
import { MaintenanceType } from '@core/models/country/enums';
import ModalContainer from '@app/modals/ModalContainer.vue';
// analytics
import { saveUtmData } from '@app/analytics/utm';
import * as directives from '@app/directives';
import { isSportNavVisible } from '@app/layout';
// import { errorGuard } from '@app/utils';
import AppReadyChecker from '@shared/components/AppReadyChecker.vue';
import AppHealthChecker from '@shared/components/AppHealthChecker.vue';

export default {
    name: 'App',
    directives: {
        sticky: directives.sticky,
    },
    data() {
        return {
            checkedParts: [MaintenanceType.global],
        };
    },
    async created() {
        await this.initializeConfig();
        if (this.useDesignV2) {
            document.documentElement.setAttribute('data-theme', 'dark');
        }
        // await startScheduledJobs(this.$store);
        this.startOffer();
        this.onResize = _debounce(this.setEffectiveDeviceType, 500);
        window.addEventListener('resize', this.onResize);
        saveUtmData();
        const body = document.querySelector('body');
        const page = document.querySelector('html');
        this.$watch(
            ({ openSidebarId, modal }) => !!openSidebarId || !!modal,
            (hasOverlay) => {
                const bodyClass = 'modal-active';
                const htmlClass = 'modal-active';
                if (hasOverlay) {
                    body.classList.add(bodyClass);
                    page.classList.add(htmlClass);
                } else {
                    body.classList.remove(bodyClass);
                    page.classList.remove(htmlClass);
                }
            },
        );
        // await errorGuard({
        //     action: async () => {
        //         await this.initializeSession();
        //     },
        // });
    },
    computed: {
        isSportNavVisible() {
            return isSportNavVisible();
        },
        ...mapGetters('ui/modal', ['modal']),
        ...mapGetters('data/flags', ['useDesignV2']),
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        ...mapActions('data/country', ['initializeConfig']),
        ...mapActions('ui/deviceInfo', ['setEffectiveDeviceType']),
        ...mapActions('data/sportOffer', ['startOffer']),
    },
    watch: {
        useDesignV2(newValue) {
            if (newValue) {
                document.documentElement.setAttribute('data-theme', 'dark');
            } else {
                document.documentElement.setAttribute('data-theme', '');
            }
        },
    },
    components: {
        ModalContainer,
        AppReadyChecker,
        AppHealthChecker,
    },
};
</script>
