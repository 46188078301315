<template>
    <div class="lucky-loser-wrapper" :class="{ 'smaller-height': smallerHeight }">
        <div class="lucky-loser-notification">
            <i class="icon-lucky icon--md nav-sidebar-tree__icon icon-pariu-sansa" />
            <div class="text" v-html="notification" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'LuckyLoserNotification',
    props: {
        isLuckyLoser: {
            default: false,
            required: false,
        },
    },
    computed: {
        smallerHeight() {
            return this.notification.length < 80;
        },
        notification() {
            return this.isLuckyLoser
                ? this.$t('Progressive Lucky Loser is ACTIVE')
                : this.$t('ticketNotEligibleForLuckyLoser');
        },
    },
};
</script>
