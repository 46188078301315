import { fromEvent as observableFromEvent, Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import * as socketIo from 'socket.io-client';
import ErrorType from '@errors/network/ErrorType';
import { RequestError } from '@errors/network/RequestError';
import Logger from '@loggers/Logger';
import ResilientSocket from '@core/services/common/ResilientSocket';
import ConnectOpts = SocketIOClient.ConnectOpts;

export default class Socket extends ResilientSocket {
    private socket: SocketIOClient.Socket;
    private url: string;
    private connectOptions: ConnectOpts;
    private allTicketsChanges$: Observable<any>;
    private socketConnected$: Observable<any>;
    private socketReconnected$: Observable<any>;
    private socketDisconnected$: Observable<any>;
    private offlineKeptParams: {
        sessionId: null | string;
    } = {
        sessionId: null,
    };
    private socketBasicEventsSubscriptions: Subscription[] = [];

    constructor(
        url: string,
        connectOptions: ConnectOpts = {
            secure: false,
            transports: ['websocket'],
            upgrade: false,
            path: '/api/socket.io',
        },
    ) {
        super();
        this.url = url;
        this.connectOptions = connectOptions;
    }

    /**
     * @throws {RequestError}
     * @throws {Error}
     */
    public start(): void {
        try {
            this.socket = socketIo.connect(this.url, this.connectOptions);
            this.createStreams();
            this.subscribeToSocketBasicEvents();
        } catch (e) {
            Logger.error(e);
            // if we cant initialize we want the whole thing down
            this.stop();
            throw new RequestError(e, ErrorType.socketConnectionError);
        }
    }

    /**
     * @throws {RequestError}
     * @throws {Error}
     */
    public stop(): void {
        if (this.socket) {
            this.unsubscribeFromSocketBasicEvents();
            this.socketBasicEventsSubscriptions = [];
            try {
                this.socket.disconnect();
            } catch (e) {
                Logger.error(e);
                throw new RequestError(e, ErrorType.socketDisconnectError);
            }
        }
    }

    public getAllTicketsChanges$(sessionId: string): Observable<any> {
        this.offlineKeptParams.sessionId = sessionId;
        this._emit('subscribe/user/tickets', sessionId, (resp: any) => {
            if (!(resp && resp.success)) {
                throw new Error('Subscription to tickets failed');
            }
        });
        return this.allTicketsChanges$;
    }

    public getSocketReconnected$(): Observable<any> {
        return this.socketReconnected$;
    }

    private createStreams() {
        this.allTicketsChanges$ = observableFromEvent(this.socket, 'ticket_change').pipe(share());
        this.socketConnected$ = observableFromEvent(this.socket, 'connect').pipe(share());
        this.socketReconnected$ = observableFromEvent(this.socket, 'reconnect').pipe(share());
        this.socketDisconnected$ = observableFromEvent(this.socket, 'disconnect').pipe(share());
    }

    private subscribeToSocketBasicEvents() {
        this.subscribeToStream(this.socketConnected$, this.onSocketConnect);
        this.subscribeToStream(this.socketReconnected$, this.onSocketReconnect);
        this.subscribeToStream(this.socketDisconnected$, this.onSocketDisconnect);
    }

    private subscribeToStream(stream: Observable<any>, callback: any) {
        this.socketBasicEventsSubscriptions.push(stream.subscribe(callback));
    }

    private unsubscribeFromSocketBasicEvents() {
        this.socketBasicEventsSubscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    protected onSocketReconnect = () => {
        this.isConnected = true;
        this.emitSubscriptionToAllChanges();
    };

    private emitSubscriptionToAllChanges(): void {
        if (this.offlineKeptParams.sessionId) {
            this.getAllTicketsChanges$(this.offlineKeptParams.sessionId);
        }
    }

    private _emit(event: string, ...args: any[]) {
        this.socket.emit(event, ...args);
    }
}
