<template>
    <div class="betslip__header__controls">
        <button v-if="undoSelectionsCount" class="betslip__header__controls__button" @click="$emit('undoClear')">
            <i class="betslip__header__controls__button__icon sds-icon-time-arrow-counterclockwise" />
            <span class="betslip__header__controls__button__label">
                {{ undoSelectionsCount }}
            </span>
        </button>
        <button v-else-if="selectionsCount" class="betslip__header__controls__button" @click="$emit('clear')">
            <i class="betslip__header__controls__button__icon sds-icon-actions-delete" />
            <span class="betslip__header__controls__button__label">
                {{ selectionsCount }}
            </span>
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ToolbarV2',
    props: {
        undoSelectionsCount: {
            type: Number,
            required: true,
        },
        selectionsCount: {
            type: Number,
            required: true,
        },
    },
});
</script>
