<template functional>
    <span
        class="flag"
        :class="{ 'flag--lg': props.isLarge }"
        :style="`background: url(/static/img/${encodeURI(props.flag)})`"
    />
</template>

<script>
export default {
    name: 'FlagIcon',
    functional: true,
    props: {
        flag: {
            type: String,
            default: 'flags/default-flag.png',
        },
        isLarge: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
