<template>
    <div v-if="isEventLocked || isLocked" class="primary-market__unavalibale primary-market__unavalibale--locked">
        {{ $t('markets temporary locked').toUpperCase() }}
    </div>
    <div
        v-else-if="odds.length === 0 && eventCategory === null"
        class="primary-market__unavalibale primary-market__unavalibale--main"
    >
        {{ $t('main market not available, click to').toUpperCase() }}
        <strong>
            {{ $t('see more markets').toUpperCase() }}
        </strong>
    </div>
    <div v-else class="primary-sport-market">
        <template v-for="odd in odds">
            <SportOfferPick
                :key="odd.uniqueId || odd.id"
                v-bind="odd"
                :tooltipDescription="odd.description"
                :isEventLocked="isEventLocked"
                :valueChangeDynamics="oddValueDynamics && oddValueDynamics[odd.uniqueId]"
                :class="selectedOdd === `${odd.id}-${odd.specialBetValue}` ? 'is-selected' : ''"
                @click="$emit('oddClick', odd)"
            />
        </template>
    </div>
</template>

<script>
import SportOfferPick from '@src/terminal/app/modules/shared/event/SportOfferPick.vue';

export default {
    name: 'Markets',
    components: { SportOfferPick },
    props: {
        name: {
            type: String,
            required: false,
        },
        eventCategory: {
            type: String,
            required: false,
        },
        odds: {
            type: Array,
            default() {
                return [];
            },
        },
        eventId: {
            type: Number,
            required: true,
        },
        selectedOdd: {
            type: String,
            required: false,
        },
        isLocked: {
            type: Boolean,
            default: false,
        },
        isEventLocked: {
            type: Boolean,
            required: true,
        },
        oddValueDynamics: {
            type: Object,
            required: false,
        },
    },
};
</script>
