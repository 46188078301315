import { BetSlipErrorType } from '@models/shared/betSlip/betSlipEnums';

export default interface IBetSlipError {
    isSelectionAddable: boolean;
    type: BetSlipErrorType;
    params?: any;
    shouldRemove?: boolean;
    isInfo?: boolean;
}

export class BetSlipErrorFactory {
    public static createErrorsInSelections(): IBetSlipError {
        return {
            isSelectionAddable: true,
            type: BetSlipErrorType.errorsInSelection,
        };
    }

    public static createChooseTicketNegotiationError(): IBetSlipError {
        return {
            isSelectionAddable: false,
            type: BetSlipErrorType.chooseTicketNegotiation,
        };
    }

    public static createOddIsUnavailableError(): IBetSlipError {
        return {
            isSelectionAddable: true,
            type: BetSlipErrorType.oddIsUnavailable,
            shouldRemove: true,
        };
    }

    public static createEventCantPlaceBets(): IBetSlipError {
        return {
            isSelectionAddable: false,
            type: BetSlipErrorType.eventCantPlaceBets,
            shouldRemove: true,
        };
    }

    public static createSameMarketError(): IBetSlipError {
        return {
            isSelectionAddable: false,
            type: BetSlipErrorType.sameMarket,
        };
    }

    public static createCantCombineEventsError(): IBetSlipError {
        return {
            isSelectionAddable: false,
            type: BetSlipErrorType.cantCombineEvents,
        };
    }

    public static createCantCombineMarkets(): IBetSlipError {
        return {
            isSelectionAddable: false,
            type: BetSlipErrorType.cantCombineMarkets,
        };
    }

    public static createCantCombine(): IBetSlipError {
        return {
            isSelectionAddable: false,
            type: BetSlipErrorType.cantCombine,
        };
    }

    public static createMinSystemToPlayError(minTotal: number, fromNumber: number): IBetSlipError {
        return {
            isSelectionAddable: false,
            type: BetSlipErrorType.minSystemToPlay,
            params: {
                fromNumber,
                minNumber: minTotal,
            },
        };
    }
}
