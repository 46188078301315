<template>
    <div>
        <div class="betslip__system-box--terminal">
            <div v-for="datePart in dateComponents" :key="datePart.label" class="betslip-system__wrap--terminal">
                <div
                    style="text-align: center"
                    :class="selectedDateComponent(datePart.label)"
                    @click="onDateComponentClicked(datePart.label)"
                >
                    <div>
                        <div>
                            {{ datePart.label }}
                        </div>
                        <div>
                            {{ datePart.value }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="numberKey in fetchNumberKeys" :key="numberKey.value" class="betslip-system__wrap--terminal">
                <div class="betslip-input__modal--terminal" @click="onKeypadClick(numberKey)">
                    <div>
                        <span class="form-check-label__text">
                            {{ numberKey.value }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="btn-container--terminal">
                <button
                    v-if="modalData.button.enabled"
                    class="btn modal-btn--dual--terminal btn--block"
                    type="button"
                    @click="clearAndCloseModal"
                >
                    Clear
                </button>
                <button
                    v-if="modalData.button.enabled"
                    class="btn modal-btn--dual--terminal btn--block"
                    type="button"
                    @click="saveDate"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get as _get } from '@lodash';
import { getDate, getDaysInMonth, getMonth, getYear, isValid } from 'date-fns';

const DEFAULT_VALUE = new Date();
const MONTH = 'Month';
const DAY = 'Day';
const YEAR = 'Year';
const ARBITRARY_FLOOR_YEAR = 1900;

export default {
    name: 'DateTimeInputModal',
    data() {
        return {
            internalDateTimeValue: DEFAULT_VALUE,
            currentComponent: DAY,
            dateComponents: [],
        };
    },
    created() {
        this.createDateComponentsFromDate(this.selectedDate);
    },
    computed: {
        ...mapGetters('ui/results', ['selectedDate']),
        ...mapGetters('ui/modal', ['modalData']),
        textClass() {
            return _get(this, 'modalData.text.className') || '';
        },
        textContent() {
            return _get(this, 'modalData.text.content') || 'Something went wrong. Please try again';
        },
        buttonClass() {
            return _get(this, 'modalData.button.className') || 'btn btn__primary btn--block';
        },
        buttonLabel() {
            return _get(this, 'modalData.button.label') || 'ok';
        },
        fetchNumberKeys() {
            const keys = [];
            for (let i = 1; i < 10; i += 1) {
                keys.push({ value: i.toString() });
            }
            keys.push({ value: 0 });
            keys.push({ value: 'C' });
            return keys;
        },
    },
    methods: {
        ...mapActions('ui/modal', ['closeModal']),
        ...mapActions('ui/results', ['setSelectedDate']),
        onKeypadClick(numberKey) {
            if (numberKey.value === 'C') {
                this.createDateComponentsFromDate(this.selectedDate);
                this.internalDateTimeValue = this.selectedDate;
            } else {
                this.alterSelectedDateComponent(numberKey.value);
            }
        },
        alterSelectedDateComponent(value) {
            if (this.currentComponent) {
                const index = this.dateComponents.findIndex((c) => c.label === this.currentComponent);
                const currentComponent = this.dateComponents[index];
                if (currentComponent.value.toString().length < currentComponent.validLength) {
                    const newValue = currentComponent.value + value.toString();
                    this.spliceDateTimeValue(index, currentComponent, newValue);
                } else {
                    this.spliceDateTimeValue(index, currentComponent, value);
                }
            }
        },
        fetchDateComponentAndIndex(componentLabel) {
            const index = this.dateComponents.findIndex((c) => c.label === componentLabel);
            const component = this.dateComponents[index];
            return { index, component };
        },
        spliceDateTimeValue(index, currentComponent, newValue) {
            this.dateComponents.splice(index, 1, { ...currentComponent, value: parseFloat(newValue) });
        },
        onDateComponentClicked(componentLabel) {
            const previousComponentLabel = this.currentComponent;
            switch (previousComponentLabel) {
                case MONTH:
                    this.validateMonth();
                    this.validateDay();
                    break;
                case DAY:
                    this.validateDay();
                    break;
                default:
                    this.validateYear();
                    break;
            }
            this.currentComponent = componentLabel;
        },
        selectedDateComponent(componentLabel) {
            if (this.currentComponent === componentLabel) {
                return 'betslip-input_selected__modal--terminal';
            }
            return 'betslip-input__modal--terminal';
        },
        createDateComponentsFromDate(date) {
            this.dateComponents = [
                {
                    label: MONTH,
                    value: getMonth(date) + 1,
                    validLength: 2,
                },
                {
                    label: DAY,
                    value: getDate(date),
                    validLength: 2,
                },
                {
                    label: YEAR,
                    value: getYear(date),
                    validLength: 4,
                },
            ];
        },
        validateMonth() {
            const month = this.fetchDateComponentAndIndex(MONTH);
            const monthValue = month.component.value;
            if (monthValue > 12 || monthValue < 1) {
                this.spliceDateTimeValue(month.index, month.component, getMonth(this.selectedDate));
            }
        },
        validateDay() {
            const currentMonth = this.fetchDateComponentAndIndex(MONTH);
            const currentYear = this.fetchDateComponentAndIndex(YEAR);
            const testDate = new Date(currentYear.component.value, currentMonth.component.value);
            const numOfDaysInMonth = getDaysInMonth(testDate);
            const day = this.fetchDateComponentAndIndex(DAY);
            const dayValue = day.component.value;
            if (dayValue < 1 || dayValue > numOfDaysInMonth) {
                this.spliceDateTimeValue(day.index, day.component, getDate(this.selectedDate));
            }
        },
        validateYear() {
            const year = this.fetchDateComponentAndIndex(YEAR);
            const yearValue = year.component.value;
            if (yearValue < ARBITRARY_FLOOR_YEAR || yearValue > getYear(DEFAULT_VALUE)) {
                this.spliceDateTimeValue(year.index, year.component, getYear(this.selectedDate));
            }
        },
        saveDate() {
            this.validateYear();
            this.validateMonth();
            this.validateDay();
            const year = this.fetchDateComponentAndIndex(YEAR).component.value;
            const month = this.fetchDateComponentAndIndex(MONTH).component.value;
            const day = this.fetchDateComponentAndIndex(DAY).component.value;
            const submittedDate = new Date(year, month - 1, day);
            if (isValid(submittedDate)) {
                this.setSelectedDate(submittedDate);
            } else {
                this.setSelectedDate(this.selectedDate);
            }
            this.closeModal();
        },
        clearAndCloseModal() {
            this.createDateComponentsFromDate(this.selectedDate);
            this.internalDateTimeValue = this.selectedDate;
            this.closeModal();
        },
    },
};
</script>
