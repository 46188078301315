<template>
    <div class="nav-sidebar-sport">
        <div class="nav-sidebar__wrapper nav-sidebar__wrapper--search">
            <SearchInput
                :suggestionsFunction="searchFunction"
                :predefinedSuggestions="previousQueries"
                @submit="onSearchSubmit"
            />
        </div>
        <div v-if="featuredItems.length > 0" class="nav-sidebar__wrapper">
            <SidebarPromoItems
                :items="featuredItems"
                :selectedItemId="selectedFeaturedItem"
                @click="setSelectedFeaturedItem"
            />
        </div>
        <div class="nav-sidebar__wrapper">
            <SidebarTabs
                :isOuterSelected="!!selectedFeaturedItem"
                class="sidebar_static__top"
                @selectionChanged="onTabSelectionChange"
            />
            <SportsSidebarContainer v-if="isSports()" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { links, routeNames } from 'src/app/router';
import SearchInput from '@src/terminal/app/modules/sportOffer/sharedSubmodules/SearchInput.vue';
import { allTimeFilterSlug } from '@store/modules/ui/shared/timeFilter/utils';
import SidebarPromoItems from '@modules/shared/sidebar/SidebarPromoItems.vue';
import SidebarTabs from './components/SidebarTabs.vue';
import SportsSidebarContainer from '../sports/sidebar/SportsSidebarContainer.vue';

export default {
    name: 'SidebarContainer',
    created() {
        this.routes = {
            specials: { name: routeNames.special, params: { timeFilter: allTimeFilterSlug } },
            prematch: { path: links.landing },
            live: { path: links.live },
        };
    },
    beforeDestroy() {
        this.clearSelectedFeaturedItem();
    },
    computed: {
        ...mapGetters('ui/sportOffer/sports', ['selectedFeaturedItem']),
        ...mapGetters('ui/sportOffer/search', ['previousQueries', 'suggestions']),
        ...mapGetters('data/sportOffer', ['featuredItems']),
        // TODO: this is quick fix for live nav until better solution is found SSBT
        isLive() {
            if (!this.$route.params.timeFilter) {
                return false;
            }
            return this.$route.params.timeFilter.includes('live');
        },
    },
    methods: {
        ...mapActions('ui/sportOffer/sports', ['setSelectedFeaturedItem', 'clearSelectedFeaturedItem']),
        ...mapActions('ui/sportOffer/search', ['saveQuery']),
        onSearchSubmit(query) {
            this.saveQuery(query);
            this.$router.push({
                path: links.search,
                query: {
                    query,
                },
            });
        },
        isSports() {
            const pathname = this.$route.name;
            return pathname === routeNames.landing || pathname === routeNames.sportOffer;
        },
        searchFunction(query) {
            return this.suggestions(query);
        },
        onTabSelectionChange(link) {
            this.clearSelectedFeaturedItem();
            this.$router.push({ name: link });
        },
    },
    components: {
        SportsSidebarContainer,
        SidebarPromoItems,
        SidebarTabs,
        SearchInput,
    },
};
</script>
