<template functional>
    <div class="section-header--terminal">
        <i v-if="props.icon" class="icon section-header__icon--terminal" :class="props.icon" />
        <span class="section-header__title--terminal">
            <slot />
        </span>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.component('SectionHeader', {
    name: 'SectionHeader',
    functional: true,
    props: {
        icon: {
            type: String,
            required: false,
        },
    },
});
</script>
