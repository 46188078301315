<template functional>
    <button
        class="pick--terminal actionable"
        :disabled="!props.value || props.isEventLocked || props.isLocked"
        :class="
            data.class +
            (!props.value || props.isEventLocked || props.isLocked ? ' is-locked' : '') +
            (props.valueChangeDynamics && props.valueChangeDynamics.oddDirection === 1 ? ' up1' : '') +
            (props.valueChangeDynamics && props.valueChangeDynamics.oddDirection === 2 ? ' up2' : '') +
            (props.valueChangeDynamics && props.valueChangeDynamics.oddDirection === -1 ? ' down1' : '') +
            (props.valueChangeDynamics && props.valueChangeDynamics.oddDirection === -2 ? ' down2' : '') +
            (props.isSuperbet ? ' pick--superbet' : '')
        "
        @click="listeners.click && listeners.click(props.id)"
    >
        <span class="indicator actionable" />
        <span class="market actionable" :title="props.symbol">
            <template v-if="props.isSuperbet">
                <span v-for="part in props.marketName.split(';')" :key="part" class="superbet__segment">
                    {{ $options.methods.interpolatePart(part, props.specifiers) }}
                </span>
            </template>
            <template v-else>
                {{ props.isOutright ? props.name : props.symbol }}
                {{ props.specialBetValue && props.showSpecialBetValue ? `(${props.specialBetValue})` : '' }}
            </template>
        </span>
        <span v-if="props.value" class="odd actionable">
            <span v-if="props.isRefund" class="odd--refunded">
                {{ props.value | twoDecimals }}
            </span>
            <template v-if="props.isRefund">1</template>
            <template v-else>
                <span class="value actionable new">
                    {{ props.value | twoDecimals }}
                </span>
            </template>
        </span>
        <span v-else class="odd actionable">-</span>
    </button>
</template>

<script>
import liveBettingSpecifiersFormatter from '@core/services/offer/_private/LiveBettingSpecifiersFormatter';

export default {
    name: 'Odd',
    functional: true,
    inheritAttrs: false,
    props: {
        id: {
            type: Number,
            required: true,
        },
        value: {
            type: Number,
            required: false,
            default: null,
        },
        specialBetValue: {
            type: [String, Number],
            required: false,
            default: '',
        },
        symbol: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        isLocked: {
            type: Boolean,
            required: true,
        },
        marketName: {
            type: String,
            required: false,
            default: '',
        },
        marketId: {
            type: Number,
            default: null,
        },
        isEventLocked: {
            type: Boolean,
            required: false,
            default: false,
        },
        valueChangeDynamics: {
            type: Object,
            required: false,
            default: null,
        },
        isRefund: {
            type: Boolean,
            required: false,
            default: false,
        },
        tooltipDescription: {
            type: String,
            required: false,
            default: '',
        },
        isSuperbet: {
            type: Boolean,
            default: false,
        },
        isOutright: {
            type: Boolean,
            default: false,
        },
        specifiers: {
            type: Object,
            required: false,
        },
        showSpecialBetValue: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        interpolatePart(part, specifiers) {
            return liveBettingSpecifiersFormatter.format(part, specifiers);
        },
    },
};
</script>
