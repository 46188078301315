import axios, { AxiosError, AxiosRequestConfig, AxiosInterceptorManager, AxiosResponse } from 'axios';
import { REQUEST_TIMEOUT } from '@core/constants';
import ErrorType from '@errors/network/ErrorType';
import { RequestError } from '@errors/network/RequestError';

export type RestClient = {
    request<T = any>(config: AxiosRequestConfig): Promise<T>;
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    interceptors: {
        request: AxiosInterceptorManager<AxiosRequestConfig>;
        response: AxiosInterceptorManager<AxiosResponse>;
    };
};

export default class RestRequestFactory {
    static create(baseUrl?: string, withCredentials = false, timeout = REQUEST_TIMEOUT) {
        const requestConfig = {
            timeout,
            withCredentials,
        } as AxiosRequestConfig;
        if (baseUrl) {
            requestConfig.baseURL = baseUrl;
        }

        const request = axios.create(requestConfig) as RestClient;

        request.interceptors.response.use(
            (response: any) => response.data,
            (error: AxiosError) => {
                // we want all errors, even 300+ and 400+ errors

                if (error instanceof axios.Cancel) {
                    return;
                }

                if (error.response) {
                    const status: number = error.response.status;

                    if (status === 400) {
                        return Promise.reject(new RequestError(error, ErrorType.badRequest, error.response.data));
                    }

                    if (status === 401) {
                        return Promise.reject(new RequestError(error, ErrorType.authenticationNeeded));
                    }

                    if (status === 403) {
                        return Promise.reject(new RequestError(error, ErrorType.forbidden));
                    }

                    if (status === 404) {
                        return Promise.reject(new RequestError(error, ErrorType.resourceNotFound));
                    }

                    if (status >= 500 && status <= 511) {
                        return Promise.reject(new RequestError(error, ErrorType.serverError));
                    }

                    return Promise.reject(new RequestError(error, ErrorType.serverError));
                }

                if (error.request) {
                    if (error.code === 'ECONNABORTED') {
                        return Promise.reject(new RequestError(error, ErrorType.timeout));
                    }

                    return Promise.reject(new RequestError(error, ErrorType.networkError));
                }

                return Promise.reject(new RequestError(error, ErrorType.generic));
            },
        );

        return request;
    }
}
