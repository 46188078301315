<template>
    <div class="overlay-modal" />
</template>

<script>
export default {
    name: 'OverlayModal',
};
</script>

<style scoped></style>
