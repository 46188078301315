<template>
    <div class="selections-list">
        <!-- Inverting betslip selections so the new selections are more visible, possible filter -->
        <SelectionV2
            v-for="selection in selections.slice().reverse()"
            :key="selection.getOddUuid() || selection.getEventId()"
            :selection="selection"
            :error="selectionErrors[selection.getEventId()]"
            :oddValueDynamics="oddValueDynamics"
            :checkShowSbv="checkShowSbv"
            @removeClick="onRemoveClick"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { shouldShowSpecialBetValue } from '@core/models/offer/Odd';
import { useVuexStore } from '@src/v2/app/utils/vuexStore';
import SelectionV2 from '@src/v2/app/modules/sportOffer/betSlip/selection/SelectionV2.vue';
import SportSelection from '@core/models/betSlip/SportSelection';
import IBetSlipError from '@core/models/betSlip/validation/IBetSlipError';
import { OddDynamics } from '@store/modules/data/sportOffer/types';
import sportOfferStoreModule from '@store/modules/data/sportOffer/index';

export default defineComponent({
    components: { SelectionV2 },
    name: 'SimpleBetSlipV2',
    props: {
        selections: {
            type: Array as PropType<SportSelection[]>,
            required: true,
        },
        selectionErrors: {
            type: Object as PropType<Record<number, IBetSlipError>>,
            required: false,
            default: () => {},
        },
        oddValueDynamics: {
            type: Object as PropType<Record<number, Record<string, OddDynamics>>>,
            required: false,
        },
    },
    setup(_, { emit }) {
        const sportOfferStore = useVuexStore<typeof sportOfferStoreModule>('data/sportOffer');
        const markets = sportOfferStore.getter('markets');

        const onRemoveClick = (selection: SportSelection) => {
            emit('removeClick', selection);
        };

        const checkShowSbv = (marketId: number, isSpecial: boolean) => {
            return shouldShowSpecialBetValue(marketId, markets.value, isSpecial);
        };

        return {
            markets,
            checkShowSbv,
            onRemoveClick,
        };
    },
});
</script>
