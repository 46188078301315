<template>
    <div
        class="selection-odd"
        :disabled="!value || isLocked"
        :class="
            (!value || isLocked ? ' is-locked' : '') +
            (valueChangeDynamics && valueChangeDynamics.oddDirection === 1 ? ' up1' : '') +
            (valueChangeDynamics && valueChangeDynamics.oddDirection === 2 ? ' up2' : '') +
            (valueChangeDynamics && valueChangeDynamics.oddDirection === -1 ? ' down1' : '') +
            (valueChangeDynamics && valueChangeDynamics.oddDirection === -2 ? ' down2' : '') +
            (isSuperbet ? ' pick--superbet' : '')
        "
    >
        <span class="selection-odd__market-name">
            <div v-if="isSuperbet" class="selection-odd__market-name--combo">
                <span v-for="name in marketName.split(';')" :key="name" class="selection-odd__market-name--combo__name">
                    {{ name }}
                </span>
            </div>
            <template v-else>
                {{ isOutright ? name : symbol }}
                {{ specialBetValue && showSpecialBetValue ? `(${specialBetValue})` : '' }}
            </template>
        </span>
        <span v-if="value" class="selection-odd__value">
            {{ value | twoDecimals }}
        </span>
        <span v-else class="selection-odd__value">-</span>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { OddDynamics } from '@store/modules/data/sportOffer/types';

export default defineComponent({
    name: 'SelectionOddV2',
    props: {
        id: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        marketName: {
            type: String,
            required: false,
            default: '',
        },
        value: {
            type: Number,
            required: false,
            default: null,
        },
        symbol: {
            type: String,
            required: true,
        },
        isLocked: {
            type: Boolean,
            required: true,
        },
        specialBetValue: {
            type: [String, Number],
            required: false,
            default: '',
        },
        valueChangeDynamics: {
            type: Object as PropType<OddDynamics | null>,
            required: false,
            default: null,
        },
        isSuperbet: {
            type: Boolean,
            default: false,
        },
        showSpecialBetValue: {
            type: Boolean,
            default: false,
        },
        isOutright: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
