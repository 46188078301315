/* eslint-disable sort-keys */
import RestRequestFactory, { RestClient } from '@core/utils/network/RestRequestFactory';
import { OddMapper } from '@core/mappers/offer/OddMapper';
import Odd from '@models/offer/Odd';
import ServerConfig from '@core/models/country/ServerConfig';
import { OddDto } from '@superbet-group/offer.clients.lib';
import BetBuilderRequest from './BetBuilderRequest';

export default class RestService {
    private config: ServerConfig;
    private request: RestClient;
    private readonly oddMapper = new OddMapper();

    constructor(config: ServerConfig) {
        this.config = config;
    }

    public getBetBuilder(): void {
        const betBuilderUrl = this.config.offer.betBuilder.betBuilderServer;
        this.request = RestRequestFactory.create(betBuilderUrl);
    }

    public async getOdd(matchId: number, oddUuid: string, target: string, lang: string): Promise<Odd> {
        try {
            const response = await this.request.get<OddDto>('/v2/trackBetbuilderOddStatus', {
                params: BetBuilderRequest.createRequest(matchId, oddUuid, target, lang),
            });
            return this.oddMapper.createTargetObject(response, 0, false);
        } catch (e) {
            console.error(e, 'Get bet builder odd:');
            throw e;
        }
    }
}
