import { Event } from './Event';
import { sportName, tournamentName, periodNames } from '../util';

/**
 * EventCollection provides a specification for a subunit of Section. An event
 * collection should be used to group similar events (how the similarity is
 * defined depends on the use case). The only constraint is that all events
 * contained in the collection must belong to the same sport.
 */
export interface EventCollection {
    sportId: number;
    title: string;
    events: Event[];
    periodNames: string[];
    id?: number;
    showPeriods?: boolean;
}

export function createEventCollection(
    sportId: number,
    periodNames: string[] = [],
    title = '',
    tournamentId?: number,
): EventCollection {
    return {
        sportId,
        title,
        periodNames,
        events: [],
        id: tournamentId,
    } as EventCollection;
}

export function createTournamentEventCollection(sportId: number, tournamentId: number): EventCollection {
    const title = `${sportName(sportId)} - ${tournamentName(tournamentId)}`;
    return createEventCollection(sportId, periodNames(sportId), title, tournamentId);
}
