import IBetSlipOdd from '@core/models/base/IBetSlipOdd';

export default class SavedOdd {
    id: number;
    specialBetValue: string;

    constructor(odd: IBetSlipOdd) {
        this.id = odd.id;
        this.specialBetValue = odd.specialBetValue;
    }

    static createOdd(odd: IBetSlipOdd) {
        return new this(odd);
    }
}
