import { map } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';
import config from '@config';
import Socket from './Socket';

export default class SocketManager {
    private socket?: Socket;
    private changesObservable?: Observable<any>;
    private subscriptions: Subscription[] = [];

    public subscribeToTicketsChanges(sessionId: string, callback: (value: any) => void): Subscription {
        if (!this.changesObservable) {
            this.changesObservable = this.socket!.getAllTicketsChanges$(sessionId).pipe(
                map((value: string) => JSON.parse(value)),
            );
        }
        const changesSubscription = this.changesObservable.subscribe(callback);
        this.subscriptions.push(changesSubscription);
        return changesSubscription;
    }

    public subscribeToReconnectedStream(callback: () => void): Subscription {
        return this.socket!.getSocketReconnected$().subscribe(callback);
    }

    public unsubscribeFromTicketsChanges(subscription: Subscription) {
        subscription.unsubscribe();
        const index = this.subscriptions.indexOf(subscription);
        this.subscriptions.splice(index, 1);
        if (this.subscriptions.length === 0) {
            delete this.changesObservable;
        }
    }

    /**
     * @throws {RequestError}
     * @throws {Error}
     */
    public startStreaming(): void {
        if (!this.socket) {
            this.socket = new Socket(config.services.tickets.retrieval.host);
            this.socket.start();

            this.subscribeToReconnectedStream(() => {
                // todo trebamo zapamtiti tickete i nanovo se pretplatiti kad se socket reconnecta
            });
        }
    }

    /**
     * @throws {RequestError}
     * @throws {Error}
     */
    public stopStreaming(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions.length = 0;
        delete this.socket;
        delete this.changesObservable;
    }
}
