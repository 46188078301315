<template>
    <thead>
        <tr>
            <th colspan="1" class="p-l-1">
                {{ $t('game') }}
            </th>
            <th colspan="3">
                {{ $t('ticketId') }}
            </th>
            <th colspan="1">
                {{ $t('payin') }}
            </th>
            <th colspan="1">
                {{ $t('handlingFee') }}
            </th>
            <th colspan="1">
                {{ $t('stake') }}
            </th>
        </tr>
        <tr>
            <th colspan="1" class="p-l-1">
                {{ data.ticket.type | upperCase }}
            </th>
            <th colspan="3">
                {{ applyTicketIdMask(data.ticket.ticketId) }}
            </th>
            <th colspan="1">{{ numberFormat(data.ticket.payment.total) }} RON</th>
            <th colspan="1">{{ numberFormat(data.ticket.payment.handlingFee) }} RON</th>
            <th colspan="1">{{ numberFormat(data.ticket.payment.stake) }} RON</th>
        </tr>
        <tr class="is--dark text-uppercase">
            <th colspan="1" class="p-l-1">
                {{ $t('status') }}
            </th>
            <th colspan="2">
                {{ $t('code') }}
            </th>
            <th colspan="1">
                {{ $t('fix') }}
            </th>
            <th colspan="1">
                {{ $t('event') }}
            </th>
            <th colspan="1">
                {{ $t('date') }}
            </th>
            <th colspan="1">
                {{ $t('market') }}
            </th>
            <th colspan="1">
                {{ $t('bonus') }}
            </th>
            <th colspan="1">
                {{ $t('coefficient') }}
            </th>
        </tr>
    </thead>
</template>

<script>
import { localeNumberFormat } from '@src/terminal/core/services/gravity/helpers/math';

export default {
    name: 'TicketHeader',
    props: {
        data: Object,
    },
    methods: {
        numberFormat: localeNumberFormat,
        applyTicketIdMask(ticketId) {
            const ticketIdArray = ticketId.split('');
            const lastChar = ticketId.length - 1;
            ticketIdArray.splice(lastChar - 3, 3, '***');
            return ticketIdArray.join('');
        },
    },
};
</script>
