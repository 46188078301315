<template>
    <div class="autocomplete">
        <input
            :placeholder="placeholder"
            type="text"
            data-layout="normal"
            class="search__input--terminal"
            @focus="showVirtualKeyboard($event, { onConfirm, onChange, onHide })"
        />
        <i
            :class="[
                useDesignV2
                    ? 'search__input--terminal--icon sds-icon-navigation-search'
                    : 'icon--md icon-search search__input--terminal--icon',
            ]"
        ></i>
        <ul v-show="isLoading" class="autocomplete-results--terminal">
            <li class="search-results__item--terminal">
                {{ $t('loading') | capitalize }}
            </li>
        </ul>
        <ul v-show="noResults" :class="[useDesignV2 ? 'search__form--terminal' : 'search-results--terminal']">
            <li class="search-results__item--terminal">
                {{ $t('No result') | capitalize }}
            </li>
        </ul>
        <ul v-show="isOpen" :class="[useDesignV2 ? 'search__form--terminal' : 'search-results--terminal']">
            <li
                v-for="(result, i) in results"
                :key="result"
                class="search-results__item--terminal"
                :title="result"
                :class="{ 'is-active': i === arrowCounter }"
                @click="setResult(result)"
            >
                {{ result }}
            </li>
        </ul>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.component('AutoCompleteInput', {
    props: {
        value: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        minCharacters: {
            type: Number,
            default: 0,
        },
        maxCharacters: {
            type: Number,
            default: 32,
        },
        results: {
            type: Array,
            default: () => [],
        },
        isAsync: {
            type: Boolean,
            default: false,
        },
        areSuggestionsShown: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            arrowCounter: -1,
            isLoading: false,
        };
    },
    computed: {
        searchTerm: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
        isOpen: {
            get() {
                return this.areSuggestionsShown;
            },
            set(value) {
                this.$emit('suggestionsStateChange', value);
            },
        },
        noResults() {
            return this.value.length > 1 && this.results.length === 0;
        },
        ...mapGetters('data/flags', ['useDesignV2']),
    },
    watch: {
        results() {
            if (this.isAsync) {
                this.isLoading = false;
            }
        },
    },
    methods: {
        onConfirm(text) {
            this.searchTerm = text;
            this.$emit('itemClick');
            this.closeSuggestions();
        },
        onChange(value) {
            this.isOpen = true;
            if (value || value === '') {
                this.searchTerm = value;
            }
            if (value.trim().length >= this.minCharacters) {
                if (this.isAsync) {
                    this.isLoading = true;
                } else {
                    this.isOpen = true;
                }
            }
        },
        onHide() {
            this.isOpen = false;
        },
        closeSuggestions() {
            this.isOpen = false;
            this.arrowCounter = -1;
        },
        setResult(result) {
            this.searchTerm = result;
            this.isOpen = false;
            this.$emit('itemClick');
            this.hideVirtualKeyboard();
        },
    },
});
</script>
