import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import SuperOffer from '@core/models/offer/SuperOffer';
import { EventMapper } from '@core/mappers/offer/EventMapper';
import { PromotedDto } from '@superbet-group/offer.clients.lib';

const eventMapper: EventMapper = new EventMapper();

export class SuperOfferMapper extends AbstractMapper<PromotedDto, SuperOffer> {
    createTargetObject(dto: PromotedDto, key: number): SuperOffer {
        const superOffer = new SuperOffer();
        Object.entries(dto).forEach(([key, value]) => {
            superOffer[key] = eventMapper.map(value, true);
        });
        return superOffer;
    }
}
