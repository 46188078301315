import i18n from '@app/localization/i18n';
import Singleton from '@core/services/common/Singleton';
import router from '@app/router';
import Ticket from '@models/tickets/Ticket';
import { ITask } from '@src/terminal/core/services/gravity/_private/Task';
import SlaveShownTask from '@src/terminal/core/services/gravity/_private/SlaveShownTask';
import TicketsRebetTask from '@src/terminal/core/services/gravity/_private/TicketsRebetTask';
import UserBalanceChangedTask from '@src/terminal/core/services/gravity/_private/UserBalanceChangedTask';
import PeripheralsStateChangedTask from '@src/terminal/core/services/gravity/_private/PeripheralsStateChangedTask';
import TicketsCheckedTask from '@src/terminal/core/services/gravity/_private/TicketsCheckedTask';
import { prepareTicketForPrintV1 } from '@src/terminal/core/services/gravity/helpers/ticket';
import store from '@app/store';
import { instanceGuard } from '@core/utils/services';
import ServerConfig from '@core/models/country/ServerConfig';
import commonConfig from '@src/config/common';
import { format } from 'date-fns';
import * as Sentry from '@sentry/vue';
import { isEmpty as _isEmpty } from '@lodash';
import { isPrinterReady } from './_private/helpers';
import RetailService from '../retail/RetailService';
import SlaveSnoozeTask from './_private/SlaveSnoozeTask';
import TicketsCancelTask from './_private/TicketsCancelTask';
import BettingAvailableService from '../betting/BettingAvailableService';
import { NotReadySource } from '../betting/_private/enums';
import { IPlatform, INotification } from '../platform/IPlatform';
import { IGravityMessage, IGravityData } from './types';
import FeatureFlagService from '../flags/FeatureFlagService';

class GravityService implements IPlatform {
    private static instance: GravityService;

    private gateway: any;
    private slaveId: string;
    private deviceUuid: string;
    private gravityAuth: any;
    private readonly gravityTasks: Record<string, ITask>;
    private terminalBalance: number;
    private testConf: any = {};

    private constructor(private config: ServerConfig) {
        BettingAvailableService.getInstance().set(NotReadySource.Printer, {
            reason: 'default printer off',
            available: false,
        });
        BettingAvailableService.getInstance().set(NotReadySource.RetailConn, {
            reason: 'default connection to retail',
            available: false,
        });

        this.gravityTasks = {
            'Slave.Shown': new SlaveShownTask(),
            'Tickets.ReBet': new TicketsRebetTask(),
            'User.BalanceChanged': new UserBalanceChangedTask(),
            'Peripherals.StateChanged': new PeripheralsStateChangedTask(),
            'Tickets.Checked': new TicketsCheckedTask(),
            'Slave.Snooze': new SlaveSnoozeTask(),
            'Tickets.Cancel': new TicketsCancelTask(config),
        };

        try {
            if (this.isTest()) {
                this.testModeInit();
            } else {
                this.gatewayInit();
            }
        } catch (error) {
            console.error('SSBT::Gravity service failed to init', error);

            if (!this.isTest()) {
                Sentry.captureException(error);
            }
        }
    }

    private async testModeInit() {
        this.testConf = {
            romania: {
                deviceUuid: 'bb08d29a-aa93-4f8c-a1ee-f471d8d1a444',
                authUuid: 'b9650c86-fff5-4dd9-b3df-8982705e3f88',
                password: 'zC6CYUyL2Wv7NDpqJ7NsMmyiM3M6PfjUr-0yn0_1euI',
            },
        };
        console.log('SSBT::Gravity init skip, TEST MODE');
        // removing printer error when running locally
        BettingAvailableService.getInstance().unset(NotReadySource.Printer);

        this.setSlaveId('SuperbetPrematch');
        this.setDeviceUUID(this.testConf[commonConfig.country].deviceUuid);

        RetailService.createInstance(this.config, this.getSlaveId());
        try {
            await FeatureFlagService.getInstance().setNSoftCtx({ uuid: this.getDeviceUUID() });
            store.dispatch('data/flags/initFlags', null, { root: true });
        } catch (error) {
            console.error('FeatureFlagService failed to initialize', error);
        }
    }

    public requestBalanceStatusRequest(): void {
        throw new Error('Method not implemented.');
    }
    public getTerminalConfiguration() {
        throw new Error('Method not implemented.');
    }
    public getAuthToken(): string {
        throw new Error('Method not implemented.');
    }

    public static getInstance(): GravityService {
        return instanceGuard(GravityService.instance);
    }

    public static createInstance(config: ServerConfig) {
        if (!GravityService.instance) {
            GravityService.instance = new GravityService(config);
        }
        return GravityService.instance;
    }

    public refreshAuth(): void {
        throw new Error('Method not implemented.');
    }

    public async resetBetSlip() {
        await store.dispatch('ui/sportOffer/betSlip/setAutoUpdateChanges', true, { root: true });
        await store.dispatch('ui/sportOffer/betSlip/clear', null, { root: true });
    }

    private isTest() {
        return window.location.host.includes('localhost');
    }

    public setGravityAuth(auth: string): void {
        this.gravityAuth = auth;
    }

    public getGravityAuth(): string {
        if (!this.isTest()) {
            return this.gravityAuth;
        }
        const uuid = this.testConf[commonConfig.country].authUuid;
        const password = this.testConf[commonConfig.country].password;
        return `Basic ${window.btoa(`${uuid}:${password}`)}`;
    }

    public setDeviceUUID(value: string): void {
        this.deviceUuid = value;
        localStorage.setItem('deviceUuid', value);
    }

    public getDeviceUUID(): string {
        return this.deviceUuid;
    }

    public getTerminalBalance(): number {
        return this.terminalBalance;
    }

    public setTerminalBalance(amount: number) {
        this.terminalBalance = amount;
    }

    public gatewayInit() {
        console.log('SSBT::Gateway Init (0)');
        const currentRouteName = router.history.current.name;
        console.log('SSBT::Current Route Name >>', currentRouteName);

        this.setSlaveId('SuperbetPrematch');
        console.log('SSBT::Slave Id set to >>', this.slaveId);

        // tslint:disable-next-line:variable-name
        const SlaveGateway = window.gravity.gateway.slave;
        this.gateway = new SlaveGateway({
            slaveId: this.slaveId,
            allowedOrigins: null,
            debug: true,
            eventPropagation: {
                click: true,
                keydown: '*',
            },
            data: {
                settings: {
                    requires: ['gravity'],
                },
            },
            load: this.loadGravity,
        });
        this.subscribeToAll();
    }

    private loadGravity = (message: IGravityMessage<IGravityData>) => {
        const { uuid, password } = message.data.account;
        this.setGravityAuth(`Basic ${window.btoa(`${uuid}:${password}`)}`);
        const terminalBalanceAtLoad = message.data.terminal.balance;
        this.setTerminalBalance(terminalBalanceAtLoad);
        isPrinterReady(message.data.peripherals.printer);
        this.setDeviceUUID(message.data.device.deviceUuid);
        RetailService.createInstance(this.config, this.getSlaveId());
        FeatureFlagService.getInstance()
            .setNSoftCtx({ uuid: this.deviceUuid })
            .then(() => {
                console.log('FeatureFlagService initialized');
                store.dispatch('data/flags/initFlags', null, { root: true });
            })
            .catch((error) => console.error('FeatureFlagService failed to initialize', error));
        this.sendLoaded();
    };

    private subscribeToAll = () => {
        console.log('SSBT::Subscribing to Gravity Events');
        this.gateway.subscribe('*', async (message: any) => {
            console.log('SSBT::Processing event >>', message);
            if (!this.isTaskSupported(message.action)) return;
            this.gravityTasks[message.action].execute(message);
        });
    };

    private isTaskSupported = (taskName: string): boolean => !!this.gravityTasks[taskName];

    public areFoundsAvailable = (amount: number): boolean =>
        !this.isTest() ? this.getTerminalBalance() >= amount : true;

    public setSlaveId(value: string): void {
        this.slaveId = value;
    }

    public getSlaveId(): string {
        return this.slaveId;
    }

    // TODO: remove this code once the period for checking duplicate tickets ends
    private isDuplicateTicket = ({ id, isCopied }: Ticket | any) => {
        if (isCopied) return false;
        const getSafe = (itemName: string, alternative: any) => {
            try {
                return JSON.parse(localStorage.getItem(itemName) || '[]');
            } catch (error) {
                return alternative;
            }
        };

        const storedFingerprints = getSafe('fingerprints', []);
        const storedDate = localStorage.getItem('fingerprintsDate') || '';

        const isDuplicate = !_isEmpty(storedFingerprints) && storedFingerprints.includes(id);
        if (isDuplicate) {
            Sentry.captureMessage('Duplicate ticket fingerprint sent to print', {
                extra: {
                    country: commonConfig.country,
                    deviceUUID: this.getDeviceUUID(),
                    product: this.getSlaveId(),
                    ticketId: id,
                },
            });
        }
        localStorage.setItem('fingerprints', JSON.stringify([...storedFingerprints, id]));

        const formattedDate = format(new Date(), 'YYYY-MM-DD');
        if (formattedDate !== storedDate) {
            localStorage.setItem('fingerprints', JSON.stringify([id]));
            localStorage.setItem('fingerprintsDate', formattedDate);
        }

        return isDuplicate;
    };

    public async sendTicketPrint(ticket: Ticket | any): Promise<void> {
        try {
            const preparedTicket = await prepareTicketForPrintV1(ticket, this.config);

            console.log('SSBT::Prepared Ticket for Print >>', preparedTicket);
            // TODO: remove this code once the period for checking duplicate tickets ends
            try {
                if (this.isDuplicateTicket(ticket)) return;
            } catch (error) {
                Sentry.captureException(error, {
                    extra: {
                        errorType: 'isDuplicateTicket threw an error',
                        ticketId: ticket.id,
                    },
                });
            }

            if (this.isTest()) {
                store.dispatch('data/tickets/setTicketSubmitLoading', false);
                return preparedTicket;
            }

            await this.gateway.emitAsync({
                action: 'Peripherals.Print',
                enforceEvent: true,
                type: 'ticket',
                data: {
                    type: 'ticket',
                    action: '',
                    productId: 'SuperbetPrematch',
                    data: preparedTicket,
                },
            });

            const notificationTitle = i18n.t('Ticket printed successfully').toString();
            this.sendNotification({
                title: notificationTitle,
                text: notificationTitle,
                type: 'success',
            });
            store.dispatch('data/tickets/setTicketPrinted', true);
        } catch (err) {
            console.log('SSBT::Ticket print failed!', err);
            try {
                const notificationTitle = 'Ticket print failed!';
                this.sendNotification({
                    title: notificationTitle,
                    text: notificationTitle,
                    type: 'error',
                });
            } catch (err) {
                Sentry.captureException(err, {
                    extra: {
                        errorType: 'notification failed to send',
                    },
                });
            }
            Sentry.captureException(err, {
                level: Sentry.Severity.Fatal,
                extra: {
                    ticketId: ticket.id,
                },
            });
        }
        store.dispatch('data/tickets/setTicketSubmitLoading', false);
        setTimeout(() => store.dispatch('data/tickets/setTicketPrinted', false), 1500);
    }

    public sendNotification(message: INotification): void {
        const { title, text, type } = message;
        this.gateway.sendMessage({
            action: 'Notifications.Show',
            data: {
                title,
                type: type || 'info',
                description: text,
                timeout: 8000,
            },
        });
    }

    public postMessage = (obj: any, origin: any): void => {
        this.gateway.sendMessage(obj, origin);
    };

    private sendLoaded() {
        this.gateway.sendMessage({
            action: 'Slave.Loaded',
            data: {},
        });
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete GravityService.instance;
    }
}

export default GravityService as Singleton<GravityService>;
