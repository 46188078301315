<template>
    <div>
        <div class="number-display__wrapper">
            <p v-for="msg in errors" :key="msg" class="error--payout-ticket">
                {{ msg }}
            </p>
            <p v-for="point in info" :key="point" class="info--cancel-ticket">
                {{ point }}
            </p>
            <div v-if="!serialCardRequired" class="number-display" style="min-height: 60px; line-height: 30px">
                <span v-if="internalValue">{{ internalValue }}</span>
                <span v-else>{{ $t('input ticket control code') }}</span>
            </div>
            <div v-if="!!userCardSerialNumber" class="card-serial__wrap">
                <div class="card-serial">
                    {{ $t('card number') | upperCase }}
                </div>
                <div class="card-serial">
                    {{ userCardSerialNumber }}
                </div>
            </div>
        </div>
        <div class="betslip__system-box--terminal">
            <template v-if="!serialCardRequired">
                <div v-for="numberKey in fetchNumberKeys" :key="numberKey.value" class="betslip-system__wrap--terminal">
                    <div class="betslip-input__modal--terminal" @click="onKeypadClick(numberKey)">
                        <div>
                            <span class="form-check-label__text">
                                {{ numberKey.value }}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    v-for="numberKey in fetchAdditionalKeys"
                    :key="numberKey.value"
                    class="betslip-system__wrap--terminal"
                >
                    <div class="betslip-input__modal--terminal" @click="onKeypadClick(numberKey)">
                        <div>
                            <span class="form-check-label__text">
                                {{ numberKey.value }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="betslip-system__wrap--terminal">
                    <div class="betslip-input__modal--terminal" @click="onKeypadClick(delKey)">
                        <div>
                            <span class="form-check-label__text">
                                {{ delKey.value }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>
            <div class="betslip-system__wrap--terminal w-full">
                <button
                    :class="[
                        buttonClass,
                        {
                            'btn-payout-ticket--disabled': isCodeShort || submitDisabled,
                            'btn-loading-gradient': isLoading,
                        },
                    ]"
                    type="button"
                    @click="submit"
                >
                    {{ $t(buttonLabel) | capitalize }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get as _get, range as _range } from '@lodash';
import TicketService from '@core/services/tickets/TicketService';
import PlatformService from '@src/terminal/core/services/platform/PlatformService';
import commonConfig from '@src/config/common';
import { PayoutErrorType } from './_private/enums';

const DEFAULT_VALUE = '';

export default {
    name: 'TicketCancelModal',
    data() {
        return {
            delKey: { value: 'Del' },
            internalValue: DEFAULT_VALUE,
            errors: [],
            info: [],
            serialCardNumberMsgCounter: 0,
            serialCardRequired: false,
            submitDisabled: false,
            isLoading: false,
        };
    },
    created() {
        this.setUserCardSerialNumber('');
    },
    computed: {
        ...mapGetters('ui/modal', ['modalData']),
        ...mapGetters('data/country', ['userCardSerialNumber']),
        textClass() {
            return _get(this, 'modalData.text.className') || '';
        },
        textContent() {
            return _get(this, 'modalData.text.content') || 'Something went wrong. Please try again';
        },
        buttonClass() {
            return _get(this, 'modalData.button.className') || 'btn btn__primary btn--block';
        },
        buttonLabel() {
            return _get(this, 'modalData.button.label') || 'ok';
        },
        fetchNumberKeys() {
            return _range(9).map((i) => ({ value: `${i + 1}` }));
        },
        ticketId() {
            return _get(this, 'modalData.ticketId', 'INVALID_TICKET_ID');
        },
        isCodeShort() {
            return this.internalValue.toString().length < 4;
        },
        fetchAdditionalKeys() {
            return [{ value: 'C' }, { value: '0' }];
        },
    },
    methods: {
        ...mapActions('ui/modal', ['closeModal']),
        ...mapActions('data/country', ['setUserCardSerialNumber']),
        onKeypadClick(numberKey) {
            switch (numberKey.value) {
                case 'C':
                    this.internalValue = DEFAULT_VALUE;
                    break;
                case 'Del':
                    this.removeValueFromInternalValue();
                    break;
                default:
                    this.appendValueToInternalValue(numberKey.value);
                    break;
            }
        },
        appendValueToInternalValue(value) {
            const newValue = this.internalValue + this.validateValue(value);
            this.internalValue = newValue;
        },
        removeValueFromInternalValue() {
            const currentValue = this.internalValue;
            this.internalValue = currentValue.slice(0, -1);
        },
        validateValue(value) {
            if (this.hasFourDigits()) {
                return DEFAULT_VALUE;
            }
            return value;
        },
        hasFourDigits() {
            return this.internalValue.toString().length >= 4;
        },
        clearErrors() {
            this.errors = [];
        },
        pushError(error) {
            this.errors.push(error);
        },
        clearInfo() {
            this.info = [];
        },
        pushInfo(info) {
            this.info.push(info);
        },
        incrementSerialCardMsg() {
            this.serialCardNumberMsgCounter += 1;
        },
        resetSerialCardMsg() {
            this.serialCardNumberMsgCounter = 0;
        },
        sendNotification(msg, type = 'error') {
            PlatformService.getInstance().sendNotification({
                type,
                title: msg,
                text: msg,
            });
        },
        resolveError(message) {
            const strategies = {
                [PayoutErrorType.TICKET_NOT_FOUND]: () => {
                    const msg = this.$t('ticket not found').toString().toUpperCase();
                    this.sendNotification(msg);
                    this.setUserCardSerialNumber('');
                    this.closeModal();
                },
                [PayoutErrorType.WIN_AMOUNT_TOO_LARGE]: () => {
                    const msg = this.$t('to cash-out this ticket, please go over the counter');
                    this.sendNotification(msg, 'info');
                    this.pushInfo(msg);
                    this.setUserCardSerialNumber('');
                },
                [PayoutErrorType.INVALID_CARD_NUMBER]: () => {
                    this.serialCardRequired = true;
                    if (this.serialCardNumberMsgCounter === 0) {
                        this.pushInfo(this.$t('please scan the customer card').toString().toUpperCase());
                    } else {
                        this.pushError(this.$t('invalid customer card number').toString().toUpperCase());
                    }
                    this.setUserCardSerialNumber('');
                    this.incrementSerialCardMsg();
                },
                [PayoutErrorType.INVALID_CONTROL_CODE]: () => {
                    const msg = this.$t('wrong code').toString().toUpperCase();
                    this.pushError(msg);
                },
                [PayoutErrorType.ALREADY_PAID_OUT]: () => {
                    const msg = this.$t('already paid out').toString().toUpperCase();
                    this.sendNotification(msg);
                    this.closeModal();
                },
            };
            const defaultStrategy = () => {
                const msg = this.$t('Something went wrong. Please try again').toString().toLocaleUpperCase();
                this.sendNotification(msg);
            };
            return _get(strategies, message, defaultStrategy)();
        },
        async submit() {
            if (this.isCodeShort || this.submitDisabled) return;
            if (this.serialCardRequired && !this.userCardSerialNumber) return;
            this.clearErrors();
            this.clearInfo();
            const ticketId = this.ticketId;
            const controlCode = this.internalValue;

            if (!ticketId && !controlCode) return;

            this.isLoading = true;
            this.submitDisabled = true;
            this.serialCardRequired = false;
            const response = await TicketService.getInstance().payoutTicket(
                ticketId,
                controlCode,
                this.userCardSerialNumber,
            );
            this.submitDisabled = false;
            this.isLoading = false;

            const { error, message } = response;

            if (error) {
                this.resolveError(message);
                return;
            }
            const msg = this.$t('ticket paid out successfully').toString().toUpperCase();
            if (commonConfig.environment.isInHouse) {
                PlatformService.getInstance().requestBalanceStatusRequest();
            }
            this.sendNotification(msg, 'success');
            this.setUserCardSerialNumber('');
            this.closeModal();
        },
    },
};
</script>

<style lang="scss">
.payout-ticket-modal--terminal {
    width: 520px;
    max-width: 820px;
}

.btn--payout-ticket {
    height: 48px;
}

.error--payout-ticket {
    color: red;
    font-weight: 500;
    font-size: 1.1em;
}

.card-serial__wrap {
    display: flex;
    flex-direction: column;
}

.card-serial {
    color: white;
    font-weight: 500;
    font-size: 1.1em;
}

.info--cancel-ticket {
    color: white;
    font-weight: 500;
    font-size: 1.1em;
}

.btn-payout-ticket--disabled {
    opacity: 0.45;
}
</style>
