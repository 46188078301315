<template>
    <div
        v-if="isOutside"
        id="betslip"
        :class="{
            'is-purchasing': isTicketSubmitting,
            empty: isEmpty,
            undo: undoSelections,
            negotiation: !!ticketNegotiation,
        }"
        class="betslip--terminal"
    >
        <div v-if="isOutside" class="betslip-terminal__preview-message-area preview-message-area__in-preview">
            <Illustration large image="/static/img/illustrations/terminal/betslip__preview--dark.svg" />
            <div class="betslip-terminal__preview-message">
                {{ $t('your ticket is in preview mode') | capitalize }}
            </div>
        </div>
    </div>
    <div
        v-else
        :class="{
            'is-purchasing': isTicketSubmitting,
            empty: isEmpty,
            undo: undoSelections,
            negotiation: !!ticketNegotiation,
        }"
        class="betslip--terminal"
    >
        <transition name="animation__scale-up-ver-top-">
            <div
                v-if="!!ticketNegotiation || isTicketSubmitting || isRecreatingTicket"
                class="betslip__disable-helper--terminal"
            >
                <template v-if="isTicketSubmitting || isRecreatingTicket">
                    <Illustration
                        large
                        image="/static/img/illustrations/terminal/betslip__purchase-loading--dark.svg"
                        class="betslip__purchase-loading-ring--terminal"
                    />
                    <div v-if="isTicketSubmitting" class="betslip__purchase-loading-copy">
                        {{ $t('Placing bet...') }}
                    </div>
                    <div v-if="isRecreatingTicket" class="betslip__purchase-loading-copy">
                        {{ $t('Recreating ticket...') }}
                    </div>
                    <div v-if="isTicketNegotiating" class="betslip__purchase-loading-copy">
                        {{ $t('Negotiating...') }}
                    </div>
                    <div class="betslip__purchase-loading-copy" style="padding: 8px">
                        <h3>
                            {{ $t('do not navigate away from this screen until the ticket is printed') }}
                        </h3>
                    </div>
                </template>
            </div>
        </transition>
        <BetSlipHeader
            :isSimple="isSimple"
            :hasTypes="hasSystems"
            :selections="selections"
            :undoSelections="undoSelections"
            class="betslip__static__top"
            @typeClick="onTypeClick"
            @collapse="$emit('close')"
            @clear="clear"
            @undoClear="undoClear"
        />
        <div class="betslip-terminal-body">
            <div class="betslip__static__top">
                <SuperBonusBanner
                    v-if="showSuperBonusBanner"
                    :minSuperBonusOddValue="minSuperBonusOddValue"
                    :minNumberOfSelectionsForSuperBonus="minSuperBonusBreakpoint.numberOfSelections"
                    :minSuperBonusPercentage="minSuperBonusBreakpoint.percentage"
                    :superBonusPercentage="superBonusPercentage"
                />
                <Errors
                    v-if="changes"
                    :showCloseButton="false"
                    :errors="changesStrings()"
                    :areValidationErrors="false"
                    :shouldFadeOut="false"
                    @fadeOut="acceptAllChanges"
                />
                <Errors
                    v-if="selectionAddError"
                    :errors="[selectionAddError]"
                    :areValidationErrors="true"
                    :shouldFadeOut="true"
                    @fadeOut="removeSelectionAddError"
                />
                <Errors v-if="errors" :errors="errors" :areValidationErrors="true" :shouldFadeOut="false" />
                <Errors
                    v-if="submitErrors.length"
                    :errors="submitErrors"
                    :shouldFadeOut="false"
                    @fadeOut="removeError"
                />
            </div>
            <div class="betslip-body__side--terminal" :class="{ 'betslip-has-system': !isSimple }">
                <transition name="animation-list__slide-in-left-">
                    <SimpleBetSlip
                        v-if="!isEmpty && isSimple"
                        :selections="selections"
                        :selectionErrors="selectionErrors"
                        :oddValueDynamics="oddValueDynamics"
                        @removeClick="removeBet"
                    />
                    <SystemBetSlip
                        v-else-if="!isEmpty && hasSystems"
                        :systems="systems"
                        :fixedSelectionsCount="fixedSelectionsCount"
                        :isEmpty="isEmpty"
                        :selections="selections"
                        :selectionErrors="selectionErrors"
                        :oddValueDynamics="oddValueDynamics"
                        @systemClick="toggleSystemSelected"
                        @fixClick="toggleSelectionIsFixed"
                        @removeClick="removeBet"
                    />
                    <div v-if="isEmpty" class="empty-state-terminal__betslip responsive__element">
                        <Illustration image="/static/img/illustrations/terminal/betslip__empty-state--dark.svg" />
                        <div class="empty-state__betslip--content color-white--terminal">
                            {{ $t('Betslip is empty. Select events from offer and place your bet.') }}
                        </div>
                    </div>
                </transition>
            </div>
            <div class="betslip__details-wrapper--terminal">
                <LuckyLoserNotification
                    v-if="selections.length >= payout.minLuckyLoserCount && isSimple"
                    :isLuckyLoser="payout.isPariulSansa"
                />
                <div class="betslip__details--terminal">
                    <Stake
                        :stake="rawStake"
                        :isSimple="isSimple"
                        :totalCoefficient="totalCoefficient"
                        :totalNumberOfSelectedCombinations="totalNumberOfSelectedCombinations"
                        :betSlipNamespace="betSlipNamespace"
                        @change="setStake"
                        @stakePick="setStake"
                    />
                    <Tax
                        v-if="taxPercentage"
                        :stake="rawStake"
                        :stakeAfterTax="stakeAfterTax"
                        :taxPercentage="taxPercentage"
                    />
                </div>
                <div class="betslip--terminal__static-content">
                    <PayoutSummary :payout="payout" :isSystem="!isSimple" />
                    <BetSlipBonus v-if="hasBonusInfo" :bonusAmount="bonusAmount" :bonusPercentage="superBonusPercent" />
                </div>
                <div class="betslip--terminal__actions">
                    <CustomButton
                        :disabled="!!ticketNegotiation || isTicketSubmitting || isRecreatingTicket"
                        class="betslip__button--terminal"
                        :isLoading="isTicketSubmitting"
                        @click="onCreateClick"
                    >
                        {{ isOnline ? $t('bet now') : $t('accept changes and bet now') | upperCase }}
                    </CustomButton>
                </div>
            </div>
        </div>
        <TicketNegotiation
            v-if="ticketNegotiation"
            :ticketNegotiation="ticketNegotiation"
            :selections="selections"
            @accept="acceptTicketNegotiation"
            @decline="refuseTicketNegotiation"
            @error="ticketNegotiationError"
        />
    </div>
</template>

<script>
import i18n from '@app/localization/i18n';
import { isEmpty as _isEmpty } from '@lodash';
import { mapGetters, mapActions } from 'vuex';
import BetSlipHeader from '@src/terminal/app/modules/shared/betSlip/BetSlipTerminalHeader.vue';
import PayoutSummary from '@src/terminal/app/modules/shared/betSlip/PayoutSummary.vue';
import BetSlipBonus from '@src/terminal/app/modules/shared/betSlip/BetSlipBonus.vue';
import Stake from '@src/terminal/app/modules/shared/betSlip/Stake.vue';
import Tax from '@src/terminal/app/modules/shared/betSlip/Tax.vue';
import Errors from '@shared/betSlip/Errors.vue';
// import Changes from '@shared/betSlip/Changes';
import TicketNegotiation from '@src/terminal/app/modules/shared/betSlip/TicketNegotiation.vue';
import SimpleBetSlip from '@src/terminal/app/modules/shared/betSlip/SimpleBetSlip.vue';
import SystemBetSlip from '@src/terminal/app/modules/shared/betSlip/SystemBetSlip.vue';
import StoreUtil from '@app/store/StoreUtil';
import { errorGuard } from 'src/app/utils';
import Modals from '@store/modules/ui/shared/modal/modals';
import LuckyLoserNotification from '@src/terminal/app/modules/shared/betSlip/LuckyLoserNotification.vue';
import Illustration from '@shared/components/Illustration.vue';
import CustomButton from '@shared/components/Button.vue';
import SuperBonusBanner from '@src/terminal/app/modules/shared/betSlip/SuperBonusBanner.vue';
import { SuperBonusValidBetTypes } from '@superbet-group/betting.lib.payments';

export default {
    name: 'BetSlip',
    props: {
        betSlipNamespace: {
            type: String,
            required: true,
        },
        hasSystems: {
            type: Boolean,
            default: true,
        },
        isOutside: {
            type: Boolean,
            default: false,
        },
    },
    hasSystems: {
        type: Boolean,
        default: true,
    },
    isOutside: {
        type: Boolean,
        default: false,
    },

    created() {
        if (!this.rawStake) {
            this.setDefaultStake();
        }
    },
    beforeDestroy() {
        this.removeAllSubscriptions();
    },
    // Check src/app/store/persistence/sportBetSlipPersist.ts because when we reload page
    // store first fetches saved betslip's selections from local storage.  For each selection
    // we fetch event from backend and put them to our newly created betslip if exists
    computed: {
        ...mapGetters('data/country', {
            serverConfig: 'config',
            isLiveInMaintenance: 'isLiveInMaintenance',
            isSportInMaintenance: 'isSportInMaintenance',
        }),
        ...mapGetters('data/authentication', ['user', 'userBalanceWithBonus']),
        ...mapGetters('data/sportOffer', ['oddValueDynamics']),
        ...mapGetters('data/flags', ['showNewSuperbonusPromo']),
        ...StoreUtil.mapGettersDynamic('betSlipNamespace', [
            'isEmpty',
            'changes',
            'errors',
            'selectionAddError',
            'selectionErrors',
            'isLoading',
            'isOnline',
            'isOnlyXXSelected',
            'isSimple',
            'hasBonusInfo',
            'rawStake',
            'fixedSelectionsCount',
            'selections',
            'systems',
            'bonusAmount',
            'payout',
            'bonusPercentage',
            'undoSelections',
            'totalCoefficient',
            'totalNumberOfSelectedCombinations',
            'stakeAfterTax',
            'hasOngoingEvents',
            'hasPrematchEvents',
            'taxPercentage',
            'isSuperBonusEnabled',
            'superBonus',
            'superBonusPercentage',
        ]),
        ...mapGetters('data/tickets', [
            'isTicketSubmitting',
            'isTicketNegotiating',
            'submitErrors',
            'ticketNegotiation',
        ]),
        ...mapGetters('ui/ticketsStack/', ['isRecreatingTicket']),
        purchaseButtonClass() {
            return {
                btn_not_enough_balance: !this.hasEnoughMoneyForStake && this.isOnline,
            };
        },
        hasEnoughMoneyForStake() {
            if (!this.userBalanceWithBonus || !this.rawStake) {
                return false;
            }
            return this.rawStake <= this.userBalanceWithBonus;
        },
        superBonusPercent() {
            const { current } = this.superBonusPercentage;
            return current;
        },
        showSuperBonusBanner() {
            if (!this.showNewSuperbonusPromo) {
                return false;
            }

            const superBonusValidBetTypes = this.serverConfig.betSlip.bonusConfig.validBetTypes;
            const superBonusEnabledFromSystemTickets = superBonusValidBetTypes.includes(SuperBonusValidBetTypes.System);
            // TODO - Check how to optimize this check with number of combinations for bet type
            return (
                !this.isEmpty &&
                this.isSuperBonusEnabled &&
                (this.isSimple || this.isOnlyXXSelected || superBonusEnabledFromSystemTickets)
            );
        },
        minSuperBonusBreakpoint() {
            return this.serverConfig.betSlip.bonusConfig.bonusBreakpoints[0];
        },
        minSuperBonusOddValue() {
            return this.serverConfig.betSlip.bonusConfig.minOddValue;
        },
    },
    methods: {
        ...mapActions('data/country', ['fetchConfig']),
        ...StoreUtil.mapActionsDynamic('betSlipNamespace', [
            'clear',
            'setStake',
            'setType',
            'setPurchaseType',
            'toggleSystemSelected',
            'toggleSelectionIsFixed',
            'removeSelection',
            'acceptAllChanges',
            'declineChange',
            'removeError',
            'submitTicket',
            'setSelectionAddError',
            'removeBetSlipError',
            'undoClear',
            'removeAllSubscriptions',
            'setDefaultStake',
        ]),
        ...mapActions('data/tickets', [
            'removeError',
            'clearAllErrors',
            'acceptTicketNegotiation',
            'refuseTicketNegotiation',
            'ticketNegotiationError',
            'setTicketSubmitLoading',
        ]),
        ...mapActions('ui/modal', ['setModal']),
        removeBet(selection) {
            this.removeSelection({ selection });
        },
        onPurchaseTypeClick(type) {
            this.setPurchaseType(type);
        },
        onTypeClick(type) {
            this.setType(type);
        },
        onClearClick() {
            this.clear();
        },
        async onCreateClick() {
            await errorGuard({
                action: async () => {
                    this.setTicketSubmitLoading(true);
                    this.acceptAllChanges();
                    await this.submitTicket();
                    if (
                        _isEmpty(this.submitErrors) &&
                        _isEmpty(this.errors) &&
                        _isEmpty(this.selectionErrors) &&
                        !this.ticketNegotiation
                    ) {
                        this.$store.dispatch(`${this.betSlipNamespace}/clear`);
                    } else {
                        this.setTicketSubmitLoading(false);
                    }
                },
                onError: () => {
                    this.setTicketSubmitLoading(false);
                },
            });
        },
        onPreviewClick() {
            if (this.ticketNegotiation) {
                return;
            }

            this.setModal({
                code: Modals.betSlipPreview.code,
                data: {
                    betSlipNamespace: this.betSlipNamespace,
                    hasSystems: this.hasSystems,
                },
            });
        },
        async isInMaintenance() {
            await this.fetchConfig();
            if (this.isLiveInMaintenance && this.hasOngoingEvents) {
                return true;
            }

            return !!(this.isSportInMaintenance && this.hasPrematchEvents);
        },
        changesStrings() {
            if (this.changes.length > 0) {
                return [i18n.t('some odds modified, old ones cut')];
            }
            return [];
        },
        removeSelectionAddError() {
            this.setSelectionAddError(null);
        },
    },
    components: {
        LuckyLoserNotification,
        Stake,
        Tax,
        // Changes,
        BetSlipHeader,
        SimpleBetSlip,
        SystemBetSlip,
        PayoutSummary,
        BetSlipBonus,
        Errors,
        TicketNegotiation,
        Illustration,
        CustomButton,
        SuperBonusBanner,
    },
};
</script>

<style lang="scss">
.ba-button {
    background-color: rgb(75, 255, 51);
}

.nsoft-wallet-button {
    background-color: rgb(82, 22, 105);
}
</style>
