<template>
    <div class="betslip-payout__info__row">
        <span class="betslip-payout__info__row__description--superbonus">
            {{ $t('SuperBonus') }}
            <span v-if="bonusPercentage" class="betslip-payout__info__row__description--superbonus__badge">
                {{ bonusPercentage }}%
            </span>
        </span>
        <span class="betslip-payout__info__row__value">
            {{ bonusAmount | twoDecimals }}
            <span class="betslip-payout__info__row__value--currency">
                {{ $t('currencyAbbreviation') }}
            </span>
        </span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'BetSlipBonusV2',
    props: {
        bonusAmount: {
            type: Number,
            required: true,
        },
        bonusPercentage: {
            type: Number,
            required: true,
        },
    },
});
</script>
