export default {
    tickets: {},
    isTicketSubmitting: false,
    isTicketNegotiating: false,
    activeTicket: null,
    submitErrors: [],
    areTicketsLoading: false,
    ticketNegotiation: null,
    isTicketPrinted: false,
};
