<template>
    <div>
        <GroupSelector
            v-if="enoughGroupsExist"
            :groups="marketGroups"
            :selected="realSelectedMarketGroupId"
            @valueChanged="setMarketGroup"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ALL_MARKETS_GROUP_ID } from '@src/terminal/app/modules/eventGrid/marketGroupHelpers';
import GroupSelector from './GroupSelector.vue';

export default {
    name: 'MarketGroups',
    props: {
        groupedMarkets: {
            type: Map,
            required: true,
        },
        eventId: {
            type: Number,
            required: true,
        },
        selectedOdds: {
            type: Set,
            required: true,
        },
        isEventLocked: {
            type: Boolean,
            required: true,
        },
        oddValueDynamics: {
            type: Object,
            required: false,
        },
        isOngoing: {
            type: Boolean,
            required: false,
            default: false,
        },
        areAdditionalMarketsLoaded: {
            type: Boolean,
            required: true,
        },
        activeMarketsTab: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            selectedMarketGroupId: null,
            marketGroupsSnapshot: [],
        };
    },
    watch: {
        // Saving market group snapshot
        // if the selected market group
        // becomes unavailable
        marketGroups(newValue) {
            this.marketGroupsSnapshot = newValue;
        },
        activeMarketsTab(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.setMarketGroup(ALL_MARKETS_GROUP_ID);
            }
        },
    },
    computed: {
        ...mapGetters('data/country', {
            serverConfig: 'config',
        }),
        marketGroups() {
            const newMarketGroups = Array.from(this.groupedMarkets.values(), (group) => ({
                type: group.id,
                displayName: group.name,
            }));
            // If selected market group exists in new groups
            // set the new list of market groups
            if (this.groupedMarkets.has(this.selectedMarketGroupId) || !this.selectedMarketGroupId) {
                return newMarketGroups;
            }
            return this.marketGroupsSnapshot;
        },
        realSelectedMarketGroupId() {
            if (this.selectedMarketGroupId) {
                return this.selectedMarketGroupId;
            }
            return this.marketGroups.length ? this.marketGroups[0].type : null;
        },
        enoughGroupsExist() {
            // We only show groupings if there are is at least one real market group
            // (one group is always "All Markets" which doesn't count).
            return this.groupedMarkets.size > 1;
        },
    },
    methods: {
        setMarketGroup(type) {
            this.selectedMarketGroupId = type;
            this.$emit('marketGroupChanged', type);
        },
        emitOddClick(...args) {
            this.$emit('oddClick', ...args);
        },
        emitMarketHeightChange(...args) {
            this.$emit('marketHeightChanged', ...args);
        },
    },
    components: {
        GroupSelector,
    },
};
</script>
