<template functional>
    <component :is="props.tag" class="tooltip-wraper">
        <slot />
        <span :class="`tooltip tooltip--${props.position}`">
            {{ props.text }}
        </span>
    </component>
</template>

<script>
export default {
    name: 'Tooltip',
    functional: true,
    props: {
        text: {
            type: String,
            required: true,
        },
        tag: {
            type: String,
            default: 'span',
        },
        position: {
            type: String, // 'top', 'bottom'
            default: 'top',
        },
    },
};
</script>
