<template>
    <div class="verify-ticket__layout">
        <div class="verify-ticket__container">
            <div class="verify-ticket__header">
                <div class="verify-ticket__title">
                    {{ $t('check ticket') | upperCase }}
                </div>
                <Tooltip :text="$t('Enter the code and see if you won')" position="bottom-left">
                    <i class="icon--md icon-help_circle verify-ticket__icon" />
                </Tooltip>
            </div>
            <form @submit.prevent="checkTicket">
                <label for="tickedId" class="input__label">
                    {{ $t('check ticket') | capitalize }}
                </label>
                <input
                    id="tickedId"
                    v-model="ticketId"
                    type="text"
                    class="input__field"
                    :placeholder="$t('ticket ID') | capitalize"
                />
                <button class="btn btn__primary btn--block verify-ticket__button" type="submit">
                    <template v-if="!isLoading">
                        {{ $t('check ticket') | upperCase }}
                    </template>
                    <SpinnerBars v-else />
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Modals from '@store/modules/ui/shared/modal/modals';
import { errorGuard } from '@app/utils';
import Tooltip from '@shared/components/Tooltip.vue';
import SpinnerBars from '@shared/components/SpinnerBars.vue';

export default {
    name: 'CheckTicket',
    data() {
        return {
            ticketId: '',
            isLoading: false,
        };
    },
    methods: {
        ...mapActions('ui/modal', ['setModal']),
        ...mapActions('data/tickets', ['fetchTicket']),
        async checkTicket() {
            this.isLoading = true;
            await errorGuard({
                action: async () => {
                    const ticket = await this.fetchTicket(this.ticketId.trim());
                    this.setModal({
                        code: Modals.singleTicket.code,
                        data: {
                            ticket,
                        },
                    });
                },
            });
            this.isLoading = false;
        },
    },
    components: {
        Tooltip,
        SpinnerBars,
    },
};
</script>
