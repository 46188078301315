<template>
    <div class="betslip-tax--terminal">
        <span class="betslip-tax--terminal__amount">
            {{ $t('tax') | capitalize }}: {{ taxPercentage | twoDecimals }}% = {{ taxValue | twoDecimals }}
        </span>
        <span class="betslip-tax--terminal__final-stake">
            {{ $t('stake after tax') | capitalize }}: {{ stakeAfterTax | twoDecimals }}</span
        >
    </div>
</template>

<script>
import config from '@config';

export default {
    name: 'Tax',
    props: {
        stake: {
            type: Number,
            require: true,
        },
        stakeAfterTax: {
            type: Number,
            require: true,
        },
        taxPercentage: {
            type: Number,
            required: true,
        },
    },
    computed: {
        taxValue() {
            return this.stake - this.stakeAfterTax;
        },
    },
    created() {
        this.offlineTax = config.app.betSlip.offlineTax;
    },
};
</script>
