import StoreUtil from '@store/StoreUtil';
import { BetSlipPurchaseType, BetSlipType } from '@models/shared/betSlip/betSlipEnums';
import { sumBy as _sumBy } from '@lodash';
import { calculateStakePerCombination } from '@core/services/betSlip/CalculationHelper';
import Referral from '@core/models/betSlip/Referral';
import selectionGetters from './selections';
import systemGetters from './systems';
import summaryGetters from './summary';
import errorsGetters from './errors';
import changesGetters from './changes';
import IBetSlipState from '../types';

export default {
    ...selectionGetters,
    ...systemGetters,
    ...summaryGetters,
    ...errorsGetters,
    ...changesGetters,
    isEmpty(state: IBetSlipState): boolean {
        return state.selections.length === 0;
    },
    purchaseType: StoreUtil.createSimpleGetter('purchaseType'),
    type: StoreUtil.createSimpleGetter('type'),
    /**
     * Difference between stake and raw stake getter is in only one edge case.
     * If stake input field is empty, raw stake getter will return null and stake
     * getter will return 0.
     */
    totalNumberOfSelectedCombinations(state: IBetSlipState, getters: any) {
        return _sumBy(getters.selectedSystems, 'numberOfCombinations');
    },
    rawStake: StoreUtil.createSimpleGetter('stake'),
    stake(state: IBetSlipState): number {
        if (!state.stake) {
            return 0;
        }
        return state.stake;
    },
    stakePerCombination(state: IBetSlipState, getters: any) {
        return calculateStakePerCombination(getters.stake, getters.totalNumberOfSelectedCombinations);
    },
    isLoading: StoreUtil.createSimpleGetter('isLoading'),
    isOffline(state: IBetSlipState): boolean {
        return state.purchaseType === BetSlipPurchaseType.offline;
    },
    isOnline(state: IBetSlipState, getters: any): boolean {
        return !getters.isOffline;
    },
    isSimple(state: IBetSlipState): boolean {
        return state.type === BetSlipType.simple;
    },
    isSystem(state: IBetSlipState, getters: any): boolean {
        return !getters.isSimple;
    },
    hasBonusInfo(state: IBetSlipState, getters: any): boolean {
        return getters.isOffline;
    },
    isCodeShown(state: IBetSlipState): boolean {
        return BetSlipPurchaseType.offline === state.purchaseType;
    },
    referral(state: IBetSlipState): Referral | null {
        return state.referral;
    },
};
