<template>
    <div class="betslip--terminal-header">
        <BinarySwitcher
            v-if="hasTypes"
            class="betslip--terminal-header__binary-switcher"
            v-bind="switcherOptions"
            :value="value"
            @change="onTypeClick"
        />
        <div v-if="!hasTypes" class="betslip--terminal-header__title">
            {{ $t('betSlip') | upperCase }}
        </div>
        <ToolbarV2
            v-if="useDesignV2"
            :selectionsCount="selections.length"
            :undoSelectionsCount="undoSelections?.length || 0"
            @clear="$emit('clear')"
            @undoClear="$emit('undoClear')"
        />
        <Toolbar
            v-else
            :selectionsCount="selections.length"
            :undoSelections="undoSelections"
            :selectionNames="$t('events')"
            class="betslip__static__top"
            @clear="$emit('clear')"
            @undoClear="$emit('undoClear')"
            @close="$emit('close')"
        />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { useVuexStore } from '@src/v2/app/utils/vuexStore';
import Toolbar from '@src/terminal/app/modules/shared/betSlip/ToolbarTerminal.vue';
import ToolbarV2 from '@src/v2/app/modules/sportOffer/betSlip/ToolbarV2.vue';
import BinarySwitcher from '@shared/components/BinarySwitcher.vue';
import { BetSlipType } from '@core/models/shared/betSlip/betSlipEnums';
import { upperCase } from '@modules/shared/filters';
import dataFlagsModule from '@src/terminal/store/flags';
import i18n from '@src/app/localization/i18n';
import ISelection from '@core/models/betSlip/ISelection';

export default defineComponent({
    components: { BinarySwitcher, Toolbar, ToolbarV2 },
    name: 'BetSlipHeader',
    emits: ['clear', 'undoClear', 'close', 'typeClick'],
    props: {
        hasTypes: {
            type: Boolean,
            default: true,
        },
        isSimple: {
            type: Boolean,
            default: true,
        },
        selections: {
            type: Array as PropType<ISelection[]>,
            required: true,
        },
        undoSelections: {
            type: Array as PropType<ISelection[]>,
            required: false,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        const dataFlagsStore = useVuexStore<typeof dataFlagsModule>('data/flags');
        const useDesignV2 = dataFlagsStore.getter('useDesignV2');

        const switcherOptions = ref();

        if (props.hasTypes) {
            switcherOptions.value = {
                first: {
                    label: useDesignV2.value ? i18n.t('simple') : upperCase(`${i18n.t('simple')}`),
                    value: BetSlipType.simple,
                },
                second: {
                    label: useDesignV2.value ? i18n.t('system') : upperCase(`${i18n.t('system')}`),
                    value: BetSlipType.system,
                },
            };
        }

        const value = computed(() => {
            return props.isSimple ? switcherOptions.value.first.value : switcherOptions.value.second.value;
        });

        const onTypeClick = (type: BetSlipType) => {
            emit('typeClick', type);
        };

        return {
            useDesignV2,
            onTypeClick,
            switcherOptions,
            value,
        };
    },
});
</script>
