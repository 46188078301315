import WaitingRequestRegister from './WaitingRequestRegister';
import ResilientSocket from './ResilientSocket';
import ISocket from './models/ISocket';

export default abstract class ReliableSocket<T> extends ResilientSocket implements ISocket {
    protected isConnected = false;

    // track requests before socket is connected so we can emit all of them after socket connects
    protected waitingRequestRegister: WaitingRequestRegister<T> = new WaitingRequestRegister<T>();

    protected onSocketConnect = () => {
        super.onSocketConnect();
        this.waitingRequestRegister.emitAll(this);
    };

    protected abstract rawEmit: Function;

    protected onSocketDisconnect() {
        this.isConnected = false;
    }

    public emit = (event: string, request: T): void => {
        if (this.isConnected) {
            this.rawEmit(event, request);
        } else {
            this.waitingRequestRegister.add(event, request);
        }
    };
}
