import { isEmpty as _isEmpty, get as _get, find as _find, keyBy as _keyBy } from '@lodash';
import { isAfter } from 'date-fns';
import Odd from '@models/offer/Odd';
import Market from '@models/struct/Market';
import { EventCategory, OfferStatus, OfferType } from '@models/offer/enums';
import { MetaData } from '@models/offer/MetaData';
import Rule from '@models/shared/betSlip/Rule';
import { MarketAttribute, competitorDelimiter } from '@core/constants';
import ISportlikeEvent from '../base/ISportlikeEvent';
import MatchResult, { Score } from './MatchResult';

function isSameSpecialBetValue(odd: Odd, specialBetValue: string | undefined): boolean {
    return odd.specialBetValue === specialBetValue || (_isEmpty(odd.specialBetValue) && _isEmpty(specialBetValue));
}

function createMarket(odd: Odd): Market {
    return new Market({
        id: odd.marketId,
        originalName: odd.marketOriginalName,
        outrightMarketName: odd.marketName || '',
        uniqueId: odd.getUniqueMarketId(),
        name: odd.getUniqueMarketName(),
        order: odd.marketOrder,
        tags: odd.tags,
        isLocked: odd.isLocked,
        odds: [odd],
    });
}
export default class Match implements ISportlikeEvent {
    id: number;
    uuid: string;
    validStructEvent: boolean;
    offerId: number;
    fullName: string;
    sportId: number;
    sportName: string;
    tournamentId: number;
    tournamentName?: string;
    // todo remove - ovo necemo trebati kad dode dizajn
    preselectedOdds?: Odd[] = [];
    date: Date;
    utcDate?: string;
    oddCount?: number;
    marketCount?: number;
    matchTimestamp?: number;
    team1Id: number;

    team2Id: number;

    // shop code
    code: number;

    matchResults: {
        isGoingToNextRound: null | 1 | 2;
        winner: null | 1 | 2;
        finalScore: null | Score;
        totalPoints: null | number;
        score: MatchResult[];
        result: null | Score;
    };

    team1PreviousResult: string;

    team2PreviousResult: string;
    tournamentCategoryName?: string;

    tournamentName2?: string;
    // every superbet change (on every Match field) is +1
    incrementId: number;
    categoryId: number;
    categoryName: string;
    sportOrder: number;
    categoryOrder: number;
    tournamentOrder?: number;
    betRadarId: string | undefined;
    specialTournamentOrder?: number;
    isGoingToBeInLiveBetting?: boolean;

    _offerStatus?: Record<OfferType, OfferStatus>;
    _offerStatusLookup?: Record<OfferStatus, boolean>;

    get offerStatus(): Record<OfferType, OfferStatus> | undefined {
        return this._offerStatus;
    }

    set offerStatus(value: Record<OfferType, OfferStatus>) {
        this._offerStatus = value;
        this._offerStatusLookup = Object.values(value).reduce(
            (prev, curr) => ((prev[curr] = true), prev),
            {} as Record<OfferStatus, boolean>,
        );
    }

    rules: Rule[] = [];
    metaData?: MetaData | undefined;
    hasStream: boolean;

    matchFooter: string | null = null;

    tournamentFooter: string | null = null;
    private odds: Odd[] = [];

    // used when Match has finished
    oddResults: Odd[] = [];

    // fields for outrights
    enteringCodes?: string[];
    eventCategory: string | null;

    private _team1Name: string;

    private _team2Name: string;

    // event and odd
    validStruct?: boolean;

    get isOutright(): boolean {
        return this.eventCategory === EventCategory.OUTRIGHT;
    }

    get team1Name(): string {
        if (this._team1Name) {
            return this._team1Name;
        }
        if (!this.fullName) {
            return '';
        }

        if (this.isOutright) {
            return '';
        }

        const parts = this.fullName.split(competitorDelimiter);

        if (parts.length < 2) {
            // Logger.error(new Error(`Match name split error for ${this.id} and name ${this.fullName}`));
            return '';
        }

        return parts[0];
    }

    get team2Name(): string {
        if (this._team2Name) {
            return this._team2Name;
        }
        if (!this.fullName) {
            return '';
        }

        if (this.isOutright) {
            return '';
        }

        const parts = this.fullName.split(competitorDelimiter);

        if (parts.length < 2) {
            // Logger.error(new Error(`Match name split error for ${this.id} and name ${this.fullName}`));
            return '';
        }

        return parts[1];
    }

    public getMarkets(): Market[] {
        const markets: Map<string, Market> = new Map();

        this.getOdds().forEach((odd) => {
            const marketKey = odd.getUniqueMarketName();

            let market = markets.get(marketKey);
            if (!market) {
                market = createMarket(odd);
                markets.set(marketKey, market);
            } else {
                market.odds.push(odd);
            }
            market.isLocked = market.isLocked && odd.isLocked;
        });

        const result = [...markets.values()];
        result.forEach((market) => {
            market.isLocked = market.odds.every((odd) => odd.isLocked);
        });
        return result;
    }

    // deprecated. Use getPrematchMarket. It's faster
    get mainMarket(): Market | undefined {
        return this.getMarkets().find((m) => m.isMain);
    }

    getPrematchMarket(prematchTag: string = MarketAttribute.main) {
        // there is some duplication with getMarkets, but this method needs to be super fast
        const odds = this.getOdds();
        let market: Market | undefined;
        let isPrematchMarket;

        if (prematchTag === MarketAttribute.main) {
            isPrematchMarket = (odd: Odd) => odd.isMain();
        } else {
            isPrematchMarket = (odd: Odd) => odd.isMarketType(prematchTag);
        }

        for (let i = 0, n = odds.length; i < n; i += 1) {
            const odd = odds[i];
            if (!market) {
                if (isPrematchMarket(odd)) {
                    market = createMarket(odd);
                } else {
                    continue;
                }
            } else if (!isPrematchMarket(odd)) {
                break;
            } else {
                market.odds.push(odd);
            }

            market.isLocked = market.isLocked || odd.isLocked;
        }

        if (market) {
            market.isLocked = market.odds.every((odd) => odd.isLocked);
        }
        return market;
    }

    public containsFullOddList(): boolean {
        return _get(this, 'oddCount', 0) === this.getOdds().length;
    }

    public getOdds(): Odd[] {
        if (this.isFinished() && this.oddResults.length !== 0) {
            return this.oddResults;
        }

        return this.odds;
    }

    public getOdd(oddId: number, specialBetValue?: string, uuid?: string): Odd | undefined {
        if (uuid) {
            return this.getOddByUuid(uuid);
        }
        return this.getOddById(oddId, specialBetValue);
    }
    // todo test
    public getOddById(id: number, specialBetValue?: string): Odd | undefined {
        return _find(this.getOdds(), (odd) => odd.id === id && isSameSpecialBetValue(odd, specialBetValue));
    }

    public getOddByUuid(uuid: string): Odd | undefined {
        return _find(this.getOdds(), (odd) => odd.uuid === uuid);
    }

    getOddsMap(): Record<string, Odd> {
        return _keyBy(this.getOdds(), 'uniqueId');
    }

    public isStopped(): boolean {
        return !!this.offerStatus && this.offerStatus[this.getActiveOffer()] === OfferStatus.stop;
        return _get(this.offerStatus, this.getActiveOffer()) === OfferStatus.stop;
    }

    public isOngoing(): boolean {
        // // called many times, can be optimized
        // return _includes(
        //     [OfferStatus.active, OfferStatus.block],
        //     _get(this.offerStatus, OfferType.live)
        // );
        return (
            !!this.offerStatus &&
            (this.offerStatus[OfferType.live] === OfferStatus.active ||
                this.offerStatus[OfferType.live] === OfferStatus.block)
        );
    }

    public get isLocked(): boolean {
        return _get(this.offerStatus, this.getActiveOffer()) === OfferStatus.block;
    }

    public hasPreMatchBetting(): boolean {
        return _get(this.offerStatus, OfferType.preEvent) === OfferStatus.active && this.isInFuture();
    }

    public existsInLiveBetting(): boolean {
        return !!_get(this.offerStatus, OfferType.live);
    }

    public isInFuture(): boolean {
        return !!this.date && isAfter(this.date, new Date());
    }

    public isFinished(): boolean {
        return !this.hasActiveOffer();
    }

    public hasActiveOffer(): boolean {
        // // called many times, can be optimized, _find creates a list from object
        // return !!_find(this.offerStatus, (status) => {
        //     return status === OfferStatus.active || status === OfferStatus.block;
        // });
        return (
            !!this._offerStatusLookup &&
            (this._offerStatusLookup[OfferStatus.active] || this._offerStatusLookup[OfferStatus.block])
        );
    }

    get hasLive(): boolean {
        return this.existsInLiveBetting();
    }

    // unused currently. We'll have to fit it somewhere in the grid probably
    public getActiveOffer(): OfferType {
        // todo mario android ima ovako, ali imamo i metodu za provjeru ima li aktivnih offera
        // znaci li to da onda imamo uvijek jer ako nemamo onda getActiveOffer moze vratiti undefined?
        if (this.isOngoing()) {
            return OfferType.live;
        }

        return OfferType.preEvent;
    }

    public setOdds(odds: Odd[]) {
        this.odds = odds;
    }

    public setOddResults(odds: Odd[]) {
        this.oddResults = odds;
    }

    get minute(): number {
        return _get(this, 'metaData.minute', undefined);
    }

    get liveData() {
        if (!(this.metaData && this.metaData.periods)) {
            return null;
        }

        const minute = this.metaData.minute;
        const stoppageTime = this.metaData.stoppageTime ? this.metaData.stoppageTime.minutes + 1 : '';

        return {
            ...this.metaData,
            minute,
            stoppageTime,
            isStoppageTime: !!this.metaData.stoppageTime,
            isOvertime: !!this.metaData.stoppageTime, // TODO
        };
    }

    // todo test
    public hasOddsInRange(min: number, max: number): boolean {
        return !!this.getOdds().find((odd: Odd) => min <= odd.value && odd.value <= max);
    }

    get isGoingToHaveLiveAudio() {
        // don't know where this info is
        return false;
    }
}
