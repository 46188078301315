import RestRequestFactory, { RestClient } from '@core/utils/network/RestRequestFactory';
import Session from '@models/authentication/Session';
import ISubmittingTicketDto from '@core/mappers/tickets/dtos/ISubmittingTicketDto';
import ErrorType from '@core/errors/network/ErrorType';
import localConfig from '@config';
import { TicketSubmitError } from '@errors/tickets/TicketSubmitError';
import { NegotiationOdd, TicketNegotiation } from '@models/tickets/TicketNegotiation';
import ServerConfig from '@core/models/country/ServerConfig';
import { NotReadySource } from '@src/terminal/core/services/betting/_private/enums';
import CTRestRequestFactory from '@core/utils/network/CTRestRequestFactory';
import RetailService from '@src/terminal/core/services/retail/RetailService';
import i18n from '@src/app/localization/i18n';
import { redactStringAtPath } from '@src/terminal/core/services/gravity/helpers/utils';
import { OddChangeError } from '@core/errors/tickets/OddChangeError';
import { get as _get } from '@lodash';
import BettingAvailableService from '@src/terminal/core/services/betting/BettingAvailableService';
import PlatformService from '@src/terminal/core/services/platform/PlatformService';
import common from '@src/config/common';
import FeatureFlagService from '@src/terminal/core/services/flags/FeatureFlagService';
import { RequestError } from '@core/errors/network/RequestError';
import Logger from '@core/utils/loggers/Logger';
import GetTicketDto from '@core/mappers/tickets/dtos/GetTicketDto';

const CHECK_TICKET_INTERVAL = 20 * 1000;

export default class TicketsRestService {
    private readonly ticketFetchAPI: RestClient;
    private readonly ticketPaymentAPI: RestClient;
    private readonly submitTicketTimeout: number;

    private readonly paymentHost: string;
    private readonly paymentHostFastly: string;

    constructor(
        config: ServerConfig,
        restRequestFactories = {
            restRequestFactory: RestRequestFactory,
            ctRestRequestFactory: CTRestRequestFactory,
        },
    ) {
        const { paymentHost, paymentHostFastly } = config.tickets;
        this.paymentHost = paymentHost;
        this.paymentHostFastly = paymentHostFastly;

        const { cacheV3 } = config.tickets;

        this.ticketFetchAPI = restRequestFactories.restRequestFactory.create(`${cacheV3}`);
        this.ticketPaymentAPI = restRequestFactories.ctRestRequestFactory.create(
            paymentHost,
            false,
            localConfig.services.tickets.timeout,
        );
        this.submitTicketTimeout = config.tickets.ticketSubmitTimeout * 60 * 1000;
    }

    public getTickets(
        { sessionId, userId }: Session,
        params: {
            count?: number;
            status?: 'active' | 'finished';
        },
    ) {
        return this.ticketFetchAPI.get(`user/${userId}/tickets`, {
            headers: {
                sessionId,
            },
            params: {
                count: localConfig.app.ticketsPage.defaultPageSize,
                ...params,
            },
        });
    }

    public async getTicket(ticketId: string) {
        try {
            return await this.ticketFetchAPI.get(`/ticket/${ticketId}`);
        } catch (e) {
            if (e.type === ErrorType.resourceNotFound) {
                return null;
            }
            throw e;
        }
    }

    public async getTicketById(ticketId: string): Promise<GetTicketDto> {
        return await this.ticketPaymentAPI.get(`/betting/terminal/getTicket/${ticketId}`, {
            baseURL: this.paymentHostFastly,
        });
    }

    public async cancelTicket(ticketId: string, controlCode: string) {
        const fastlyWafEnabled = FeatureFlagService.getInstance().fastlyWafEnabled();
        const baseURL = fastlyWafEnabled ? this.paymentHostFastly : this.paymentHost;
        const endPoint = common.environment.isNSoft ? 'cancelTicket' : 'cancelTicketBetler';
        if (common.environment.isNSoft) {
            const data = {
                ticketId,
                deviceUuid: PlatformService.getInstance().getDeviceUUID(),
                codeTicketControl: controlCode,
            };
            return this.ticketPaymentAPI.post(`/betting/terminal/${endPoint}`, data, { baseURL });
        }
        const terminalConfiguration = PlatformService.getInstance().getTerminalConfiguration();
        const authToken = PlatformService.getInstance().getAuthToken();
        const data = {
            ticketId,
            terminalConfiguration: { ...terminalConfiguration, authToken },
            codeTicketControl: controlCode,
        };
        const config = { headers: { Authorization: authToken } };
        try {
            return this.ticketPaymentAPI.post(`/betting/tableTop/${endPoint}`, data, { ...config, baseURL });
        } catch (error) {
            if (error instanceof RequestError) {
                if (error.type === ErrorType.authenticationNeeded) {
                    PlatformService.getInstance().refreshAuth();
                }
            }
            throw error;
        }
    }

    public payoutTicket(ticketId: string, controlCode: string, cardSerialNumber?: string) {
        const terminalConfiguration = PlatformService.getInstance().getTerminalConfiguration();
        const authToken = PlatformService.getInstance().getAuthToken();
        const data = {
            ticketId,
            cardSerialNumber,
            terminalConfiguration: { ...terminalConfiguration, authToken },
            codeTicketControl: controlCode,
        };
        const config = { headers: { Authorization: authToken } };
        const endPoint = common.environment.isNSoft ? 'payoutTicket' : 'payoutTicketBetler';
        const fastlyWafEnabled = FeatureFlagService.getInstance().fastlyWafEnabled();
        const baseURL = fastlyWafEnabled ? this.paymentHostFastly : this.paymentHost;
        try {
            return this.ticketPaymentAPI.post(`/betting/tableTop/${endPoint}`, data, { ...config, baseURL });
        } catch (error) {
            if (error instanceof RequestError) {
                if (error.type === ErrorType.authenticationNeeded) {
                    PlatformService.getInstance().refreshAuth();
                }
            }
            throw error;
        }
    }

    public async requestReprintTickets(username: string, password: string, limit: number) {
        const deviceUuid = PlatformService.getInstance().getDeviceUUID();
        const fastlyWafEnabled = FeatureFlagService.getInstance().fastlyWafEnabled();
        const baseURL = fastlyWafEnabled ? this.paymentHostFastly : this.paymentHost;
        try {
            return this.ticketPaymentAPI.post(
                'betting/terminal/lastTickets',
                {
                    username,
                    password,
                    deviceUuid,
                    limit,
                },
                { baseURL },
            );
        } catch (error) {
            if (error instanceof RequestError) {
                if (error.type === ErrorType.authenticationNeeded) {
                    PlatformService.getInstance().refreshAuth();
                }
            }
            throw error;
        }
    }

    public async reprintTicketById(username: string, password: string, ticketId: string) {
        const deviceUuid = PlatformService.getInstance().getDeviceUUID();
        const fastlyWafEnabled = FeatureFlagService.getInstance().fastlyWafEnabled();
        const baseURL = fastlyWafEnabled ? this.paymentHostFastly : this.paymentHost;
        try {
            return this.ticketPaymentAPI.post(
                'betting/terminal/reprintTicketById',
                {
                    username,
                    password,
                    ticketId,
                    deviceUuid,
                },
                { baseURL },
            );
        } catch (error) {
            if (error instanceof RequestError) {
                if (error.type === ErrorType.authenticationNeeded) {
                    PlatformService.getInstance().refreshAuth();
                }
            }
            throw error;
        }
    }

    public async submitTicket(ticket: ISubmittingTicketDto) {
        const instance = BettingAvailableService.getInstance();
        let betting = instance.available();
        const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

        if (!betting) {
            const nextError = instance.get();
            if (nextError.includes(NotReadySource.RetailConn)) {
                const connectionError = new TicketSubmitError(nextError[1].reason);
                Logger.error(connectionError);
                await delay(3000);
                betting = instance.available();
            }

            if (!betting) {
                const error = new TicketSubmitError(i18n.t('genericTicketSubmitError').toString());
                const sentryError = new TicketSubmitError(i18n.t('Betting Unavailable after retry').toString());
                Logger.error(sentryError);
                throw error;
            }
        }
        const { total } = ticket;
        if (!PlatformService.getInstance().areFoundsAvailable(total)) {
            const errStr = i18n.t('insufficientFounds').toString();
            const message = {
                title: errStr,
                text: errStr,
                type: 'error',
            };
            PlatformService.getInstance().sendNotification(message);
            console.log('SSBT::Insufficient Funds >>', message);
            throw new TicketSubmitError(errStr);
        }

        let requestIdPromise;

        const fastlyWafEnabled = FeatureFlagService.getInstance().fastlyWafEnabled();
        const baseURL = fastlyWafEnabled ? this.paymentHostFastly : this.paymentHost;
        const endPoint = common.environment.isNSoft ? 'submitTicket' : 'submitTicketBetler';

        if (common.environment.isNSoft) {
            const submitTicketData = {
                ticket,
                amount: total,
                product: PlatformService.getInstance().getSlaveId(),
                deviceUUID: PlatformService.getInstance().getDeviceUUID(),
                authorization: PlatformService.getInstance().getGravityAuth(),
            };
            requestIdPromise = this.ticketPaymentAPI.post(`/betting/terminal-socket/${endPoint}`, submitTicketData, {
                baseURL,
            });
        } else {
            const submitTicketData = {
                ticket,
                product: PlatformService.getInstance().getSlaveId(),
                terminalConfiguration: PlatformService.getInstance().getTerminalConfiguration(),
            };
            requestIdPromise = this.ticketPaymentAPI.post(`/betting/tableTop-socket/${endPoint}`, submitTicketData, {
                headers: { Authorization: PlatformService.getInstance().getAuthToken() },
                baseURL,
            });
        }

        const response = await RetailService.getInstance().obtainTicketOutcome(
            this.submitTicketTimeout,
            CHECK_TICKET_INTERVAL,
            requestIdPromise,
        );
        if (common.environment.isInHouse) {
            PlatformService.getInstance().requestBalanceStatusRequest();
        }
        if (response.error) {
            console.log('SSBT::Retail Service Ticket Outcome ERROR >>', response.error);

            const errType = _get(response, 'errorSource.type', null);
            if (errType && errType.includes('acceptance_offer_compatibility_initial_coefficient_change')) {
                throw new OddChangeError(response.message);
            }

            throw new TicketSubmitError(response.message);
        }
        return response;
    }

    public async respondToTicketNegotiation(negotiation: TicketNegotiation, hasAccepted: boolean) {
        const rbaResponse = (negotiation: TicketNegotiation, hasAccepted: boolean) => ({
            ticketId: negotiation.ticketId,
            userAccepted: hasAccepted ? 1 : 0,
            win: negotiation.potentialWin,
            userAmount: negotiation.betSlipStake,
            bookieAmount: negotiation.bookieStake,
            odds: negotiation.odds.map((odd: NegotiationOdd) => ({
                matchId: odd.matchId,
                oddId: odd.oddId,
                oldOdd: odd.betSlipOdd,
                newOdd: odd.bookieOdd,
            })),
        });

        const receiverResponse = (negotiation: TicketNegotiation, hasAccepted: boolean) => ({
            originalResponse: negotiation.ticketData,
            userAccepted: hasAccepted ? 1 : 0,
        });

        const negotiationMappingStrategy = negotiation.ticketId ? rbaResponse : receiverResponse;

        const fastlyWafEnabled = FeatureFlagService.getInstance().fastlyWafEnabled();
        const baseURL = fastlyWafEnabled ? this.paymentHostFastly : this.paymentHost;
        const endPoint = common.environment.isNSoft ? 'submitTicket' : 'submitTicketBetler';

        let requestIdPromise;
        if (common.environment.isNSoft) {
            const submitTicketData = {
                ticket: { total: negotiation.bookieStake },
                amount: negotiation.bookieStake,
                product: PlatformService.getInstance().getSlaveId(),
                deviceUUID: PlatformService.getInstance().getDeviceUUID(),
                authorization: PlatformService.getInstance().getGravityAuth(),
                negotiation: negotiationMappingStrategy(negotiation, hasAccepted),
            };
            console.log('SSBT::Ticket Negotiation Response >>', redactStringAtPath(submitTicketData, 'authorization'));

            requestIdPromise = this.ticketPaymentAPI.post(`/betting/terminal-socket/${endPoint}`, submitTicketData, {
                baseURL,
            });
        } else {
            const negotiationData = {
                ticketId: negotiation.ticketId,
                userAccepted: hasAccepted ? 1 : 0,
                win: negotiation.potentialWin,
                userAmount: negotiation.betSlipStake,
                bookieAmount: negotiation.bookieStake,
                odds: negotiation.odds.map((odd: NegotiationOdd) => ({
                    matchId: odd.matchId,
                    oddId: odd.oddId,
                    oldOdd: odd.betSlipOdd,
                    newOdd: odd.bookieOdd,
                })),
            };

            const submitTicketData = {
                negotiation: negotiationData,
                ticket: { total: negotiation.bookieStake },
                product: PlatformService.getInstance().getSlaveId(),
                terminalConfiguration: PlatformService.getInstance().getTerminalConfiguration(),
            };

            console.log('SSBT::Ticket Negotiation Request >>', submitTicketData);
            requestIdPromise = this.ticketPaymentAPI.post(`/betting/tableTop-socket/${endPoint}`, submitTicketData, {
                headers: { Authorization: PlatformService.getInstance().getAuthToken() },
                baseURL,
            });
        }

        const response = await RetailService.getInstance().obtainTicketOutcome(
            this.submitTicketTimeout,
            CHECK_TICKET_INTERVAL,
            requestIdPromise,
        );

        if (common.environment.isInHouse) {
            PlatformService.getInstance().requestBalanceStatusRequest();
        }

        console.log('SSBT::Ticket Negotiation Retail Service Outcome >>>', response);

        // TODO: check if this error reporting works with .message
        if (response.error && response.message === '#TICKETNOTACCEPTED') {
            // ignore it
        } else if (response.error) {
            console.log('SSBT::Ticket Negotiation Retail Service Outcome ERROR >>', response.error);
            throw new TicketSubmitError(response.message);
        }
        return response;
    }
}
