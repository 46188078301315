<template>
    <div class="superbonus-banner" @click="toggleExpand">
        <div class="superbonus-banner__title">
            <img v-if="!superBonusPercentage.current" :src="superBonusIcon" />
            <div v-else :class="['superbonus-banner__title__percentage', { max: isMaxSuperBonusUnlocked }]">
                {{ superBonusPercentage.current }}%
            </div>
            <span>{{ title }}</span>
        </div>
        <div class="selection-item__divider" />
        <div class="superbonus-banner__body">
            <div v-html="notification" />
            <i class="superbonus-banner__body__info sds-icon-status-info" />
        </div>
        <div v-if="isExpanded" class="superbonus-banner__footer">
            <div class="label">
                {{ $t('close') }}
                <i class="icon icon-chevron_up" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { BonusPercentage } from '@superbet-group/betting.lib.payments';
import { defineComponent, PropType } from 'vue';
import superBonusIcon from '@superbet-group/web.lib.ui-tokens/output/assets/icons-multi-color/commerce-super-bonus-superbet-dark.svg';

export default defineComponent({
    name: 'SuperBonusBannerV2',
    props: {
        superBonusPercentage: {
            type: Object as PropType<BonusPercentage>,
            required: true,
        },
        minNumberOfSelectionsForSuperBonus: {
            type: Number,
            required: true,
        },
        minSuperBonusPercentage: {
            type: Number,
            required: true,
        },
        minSuperBonusOddValue: {
            type: Number,
            required: true,
        },
        isMaxSuperBonusUnlocked: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        return {
            superBonusIcon,
        };
    },
    data() {
        return {
            isExpanded: false,
        };
    },
    computed: {
        notification() {
            if (this.isExpanded) {
                if (this.superBonusPercentage.current) {
                    return this.$t('superbonusBanner.notification.expanded.bonusUnlocked');
                }
                return this.$t('superbonusBanner.notification.expanded.bonusLocked');
            }
            if (this.isMaxSuperBonusUnlocked) {
                return this.$t('superbonusBanner.notification.maximumBonus', {
                    maximumPercentage: this.superBonusPercentage.current,
                });
            }
            return this.$t('superbonusBanner.notification.addSelections', {
                selectionsNeededForNext: this.superBonusPercentage.selectionsNeededForNext,
                minSuperBonusOddValue: this.minSuperBonusOddValue,
                nextSuperBonusPercentage: this.superBonusPercentage.next,
            });
        },
        title() {
            if (this.superBonusPercentage.current) {
                return this.$t('superbonusBanner.title.bonusUnlocked').toString();
            }
            return this.$t('SuperBonus').toString();
        },
    },
    methods: {
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
        },
    },
});
</script>
