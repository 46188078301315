<template>
    <div class="event-grid">
        <VirtualScroller v-if="content.length" id="offer-scroller" :items="content" contentTag="div" buffer="1600">
            <template slot-scope="props">
                <CategoryHeader
                    v-if="showCategoryHeader && props.item.type === HeaderType.category"
                    :icon="`icon-sport-${props.item.id}`"
                >
                    {{ props.item.title }}
                </CategoryHeader>

                <div
                    v-if="props.item.type === HeaderType.section"
                    :id="props.item.key"
                    class="group-sport-header__wrapper"
                >
                    <SectionHeaderRow
                        v-if="showSectionHeader && props.item.subType === SectionSubType.normal"
                        :id="props.item.id.toString()"
                        :title="props.item.title"
                        :flagCode="props.item.flagCode"
                    />
                    <template v-for="(eventCollection, index) in props.item.eventCollections">
                        <EventCollectionHeader
                            :key="index"
                            :sportId="eventCollection.sportId"
                            :title="eventCollection.title"
                            :periods="eventCollection.periodNames"
                            :primaryMarketName="props.item.primaryMarketName"
                            :arePeriodsShown="
                                eventCollection.showPeriods !== undefined
                                    ? eventCollection.showPeriods
                                    : isOnSportLivePage
                            "
                        />
                        <EventRow
                            v-for="event in eventCollection.events"
                            :key="event.eventId"
                            :periods="eventCollection.periodNames"
                            :eventOddsMap="eventOddsMap"
                            :event="eventsMap[event.eventId]"
                            :typeId="event.typeId"
                            :isSelected="isSelected(event)"
                            :hasSelectionInCollapsedMarkets="
                                hasSelectionInCollapsedMarkets(
                                    event.eventId,
                                    eventCollection.primaryMarketAttribute || props.item.primaryMarketAttribute,
                                )
                            "
                            :selectedOdd="eventOddMap[event.eventId]"
                            :primaryMarketAttribute="
                                eventCollection.primaryMarketAttribute || props.item.primaryMarketAttribute
                            "
                            :selectedMarketFilter="selectedMarketFilter"
                            :oddValueDynamics="oddValueDynamics[event.eventId]"
                            :isCodeShown="isCodeShown"
                            @eventClick="onEventRowClick"
                            @oddClick="onOddClick"
                            @marketClick="onMarketClick"
                            @rowHeightChanged="setSelectedEventHeight"
                            @collapse="onRowCollapse"
                            @expand="onRowExpand"
                            @forceScroll="scrollToEventRow"
                        />
                    </template>
                    <SectionFooterRow v-if="props.item.footer" :content="props.item.footer" />
                </div>
            </template>
        </VirtualScroller>
        <div v-else-if="areEventsLoaded && !isLoading" class="empty-state__offer--terminal">
            <Illustration large image="/static/img/illustrations/offer__empty-state.svg" />
            <div class="empty-state__offer-copy">
                {{ $t('There are currently no bets available') }}
            </div>
        </div>
    </div>
</template>

<script>
import { get as _get, pick as _pick } from '@lodash';
import { sortBy } from '@core/utils/sort/sortBy';
import { mapActions, mapGetters } from 'vuex';
import { VirtualScroller } from 'vue-virtual-scroller';
import config from '@config';
import CategoryHeader from '@src/terminal/app/modules/shared/components/SectionHeader.vue';
import { isEventSolo } from '@app/layout';
import { promotionsPrefix } from '@core/constants';
import { screenInfo, setWindowScrollTop } from '@app/styles/scripts/uiHelpers';
import Illustration from '@shared/components/Illustration.vue';
import { HeaderType, SectionSubType } from './enums';
import EventRow from './components/EventRow.vue';
import SectionHeaderRow from './components/SectionHeaderRow.vue';
import SectionFooterRow from './components/SectionFooterRow.vue';
import EventCollectionHeader from './components/EventCollectionHeader.vue';
import GridDataPreparation, { sportGridState } from './GridDataPreparation';
import { FIRST_ROW_OFFSET, getElementHeights } from './constants';

function arraysDiffer(array1, array2, predicate) {
    return array1.length !== array2.length || array1.some((value, index) => predicate(value, array2[index]));
}

function expandedEventRowDiffers(expandedEvent1, expandedEvent2) {
    return (
        _get(expandedEvent1, 'id') !== _get(expandedEvent2, 'id') ||
        _get(expandedEvent1, 'typeId') !== _get(expandedEvent2, 'typeId')
    );
}

function eventsDiffer(events1, events2) {
    return arraysDiffer(events1, events2, (e1, e2) => e1.id !== e2.id);
}

function promotionGroupsDiffer(group1, group2) {
    return group1.id !== group2.id || eventsDiffer(group1.events, group2.events);
}

function promotionsDiffer(promotions1, promotions2) {
    return arraysDiffer(promotions1, promotions2, promotionGroupsDiffer);
}

function restructuringNeeded(oldValues, newValues) {
    return (
        oldValues.selectedEventHeight !== newValues.selectedEventHeight ||
        expandedEventRowDiffers(oldValues.expandedEventRow, newValues.expandedEventRow) ||
        eventsDiffer(oldValues.events, newValues.events) ||
        promotionsDiffer(newValues.promotions, oldValues.promotions) ||
        oldValues.screenWidth !== newValues.screenWidth ||
        oldValues.offsetTop !== newValues.offsetTop
    );
}

export default {
    name: 'EventGridContainer',
    props: {
        events: {
            type: Array,
            required: false,
            default: () => [],
        },
        promotions: {
            type: Array,
            required: false,
            default: () => [],
        },
        isOddSelected: {
            type: Function,
            required: false,
        },
        showCategoryHeader: {
            type: Boolean,
            required: true,
        },
        showSectionHeader: {
            type: Boolean,
            required: true,
        },
        renderDone: {
            type: Boolean,
            required: true,
        },
        sidebarChangedState: {
            type: Boolean,
            required: true,
        },
        areEventsLoaded: {
            type: Boolean,
            required: true,
        },
        filterChangeIndicator: {
            type: Number,
            required: true,
        },
        disableAutoScroll: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            HeaderType,
            SectionSubType,
            content: [],
        };
    },
    watch: {
        'expandedEventRow.id': function (newValue, oldValue) {
            if (newValue && oldValue) {
                // collapse + expand
                this.neededRowRerenders = 2;
            } else {
                // either only one expands or 1 collapses
                this.neededRowRerenders = 1;
            }
        },
        timeFilter() {
            // sometimes the time filter is changed but the offer-scroller is not ready
            // so we need to scroll to top when the offer-scroller is ready
            setTimeout(() => {
                const offerScroller = document.getElementById('offer-scroller');
                if (offerScroller) offerScroller.scrollTop = 0;
            }, 100);
        },
        /*
                For a snappier feel scroll to the event as soon the layout is changed.
            */
        sidebarChangedState() {
            this.scrollToEventRow();
        },
        user(value) {
            if (value) {
                const event = this.eventsMap[_get(this, 'expandedEventRow.id')];
                if (event && event.hasStream) {
                    this.$emit('showStream', event);
                }
            }
        },
    },
    mounted() {
        this.content = this.defineContent();
        /*
                When the page is immediately opened with the expanded id in the url there is no event row which
                emits that it's expanded. So the event grid should notify it's parent that it's rendered after
                it receives it's events
            */
        const unwatch = this.$watch('areEventsLoaded', async () => {
            unwatch();
            await this.$nextTick();
            this.$emit('eventGridRender');
        });

        this.$watch(
            // eslint-disable-next-line
            (vm) =>
                _pick(
                    vm,
                    'expandedEventRow',
                    'areEventsLoaded',
                    'events',
                    'promotions',
                    'filterChangeIndicator',
                    'selectedEventHeight',
                    'screenWidth',
                    'offsetTop',
                ),
            function (newValues, oldValues) {
                if (restructuringNeeded(oldValues, newValues)) {
                    this.content = this.defineContent();
                }
                /*  when the expanded event row changes we have to bring the newly expanded/collapsed
                        into focus by scrolling to it
                    */
                if (newValues.filterChangeIndicator !== oldValues.filterChangeIndicator) {
                    // when the user changes the filter don't scroll
                    this.scrollPosition = 0;
                    return;
                }

                if (
                    newValues.areEventsLoaded &&
                    (expandedEventRowDiffers(oldValues.expandedEventRow, newValues.expandedEventRow) ||
                        newValues.areEventsLoaded !== oldValues.areEventsLoaded) &&
                    !this.disableAutoScroll
                ) {
                    // better name?
                    const eventToRelativeScroll =
                        newValues.expandedEventRow && newValues.expandedEventRow.id
                            ? newValues.expandedEventRow
                            : oldValues.expandedEventRow;

                    this.scrollPosition = this.calculateNewPosition(eventToRelativeScroll);
                    if (this.scrollPosition) {
                        /*
                                renderDone is an indicator that that this components children and siblings
                                have rerendered. When they are we scroll
                            */
                        const unwatch2 = this.$watch(
                            function () {
                                return this.areEventsLoaded && this.renderDone;
                            },
                            (isReady) => {
                                if (isReady) {
                                    this.scrollToEventRow();
                                    unwatch2();
                                }
                            },
                        );
                    }
                }
            },
        );
    },
    computed: {
        ...mapGetters('navigation', ['isOnSportLivePage']),
        ...mapGetters('data/sportOffer', ['sports', 'tournaments', 'eventsMap', 'oddValueDynamics']),
        ...mapGetters('ui/sportOffer/betSlip', [
            'eventOddMap',
            'eventOddsMap',
            'hasSelectionInCollapsedMarkets',
            'isCodeShown',
        ]),
        ...mapGetters('ui/sportOffer', ['selectedEventHeight', 'expandedEventRow']),
        ...mapGetters('ui/sportOffer/sports', ['selectedMarketFilter', 'isLoading', 'timeFilter']),
        sortedEvents() {
            return sortBy(this.events, config.app.sportOffer.eventGridSort);
        },
        screenWidth: () => screenInfo.width,
        offsetTop() {
            return Math.max(sportGridState.offsetTop, this.firstRowOffset);
        },
        firstRowOffset() {
            const { withSidebar, normal } = getElementHeights();
            let offset = FIRST_ROW_OFFSET;

            const heights = this.selectedEvent ? withSidebar : normal;

            if (this.showSectionHeader) {
                offset += heights.sectionHeader;
            }
            offset += heights.eventCollectionHeader;

            return offset;
        },
    },
    methods: {
        ...mapActions('ui/sportOffer', ['setSelectedEventHeight']),
        ...mapActions('ui/sportOffer/sports', ['setMarketFilter']),
        onOddClick(eventId, odd) {
            this.$emit('oddClick', { eventId, odd });
        },
        onEventRowClick({ eventId, typeId, offsetTop }) {
            this.$emit('eventClick', { eventId, typeId });
            sportGridState.offsetTop = offsetTop;
        },
        onMarketClick(type, { offsetTop }) {
            this.setMarketFilter(type);
            sportGridState.offsetTop = offsetTop;
            this.scrollPosition = this.calculateNewPosition(this.expandedEventRow);
            this.scrollToEventRow();
        },
        isSelected({ eventId }) {
            return eventId === this.expandedEventRow.id;
        },
        getTournamentElementId(parentId, tournamentId) {
            return `tournament-${parentId}-${tournamentId}`;
        },
        isPromotional(parentId) {
            // todo import function
            return parentId && parentId.startsWith(promotionsPrefix);
        },
        // serves like a computed property for content
        defineContent() {
            if (!this.events.length && !this.promotions.length) {
                return [];
            }
            this.gridDataPreparator = new GridDataPreparation(this.expandedEventRow, {
                selectedEventHeight: this.selectedEventHeight,
                screenWidth: this.screenWidth,
                isGridSidebarActive: this.expandedEventRow.id,
                showCategoryHeader: this.showCategoryHeader,
            });
            if (isEventSolo() && this.expandedEventRow && this.expandedEventRow.id) {
                const event = this.events.find(({ id }) => this.expandedEventRow.id === id);
                return this.gridDataPreparator.getData([event]);
            }
            const sports = this.gridDataPreparator.getData(this.sortedEvents);
            const promotions = this.gridDataPreparator.getPromotionData(this.promotions);
            return promotions.concat(sports);
        },
        rowHasRerendered() {
            this.neededRowRerenders -= 1;
            if (this.neededRowRerenders === 0) {
                this.$emit('eventGridRender');
            }
        },
        onRowExpand() {
            this.rowHasRerendered();
        },
        onRowCollapse() {
            this.rowHasRerendered();
        },
        calculateNewPosition(selectedEvent) {
            if (!selectedEvent.id) {
                return 0;
            }
            const { withSidebar: heights } = getElementHeights();

            let parentId;
            if (this.isPromotional(selectedEvent.typeId)) {
                parentId = selectedEvent.typeId;
            } else {
                const event = this.eventsMap[selectedEvent.id];
                parentId = event.tournamentId;
            }
            // WARNING: must be == because torunaments have number ids
            // and promotions have string ids
            // eslint-disable-next-line eqeqeq
            const parentIndex = this.content.findIndex((c) => c.id == parentId);
            let heightSumBefore = 0;
            for (let i = 0; i < parentIndex; i += 1) {
                heightSumBefore += this.content[i].height;
            }

            const parent = this.content[parentIndex];
            // sometimes all items don't load at the same time
            // e.g. promotions
            if (!parent) {
                return 0;
            }
            heightSumBefore += heights.sectionHeader;
            for (let i = 0; i < parent.eventCollections.length; i += 1) {
                const events = parent.eventCollections[i].events;
                heightSumBefore += heights.eventCollectionHeader;
                for (let j = 0; j < events.length; j += 1) {
                    const event = events[j];
                    if (event.eventId !== selectedEvent.id) {
                        heightSumBefore += this.gridDataPreparator.getEventHeight(event.eventId, parent.id);
                        if (event.hasFooter) {
                            heightSumBefore += heights.eventFooter;
                        }
                    } else {
                        i = parent.eventCollections.length;
                        j = events.length;
                    }
                }
            }

            // we substract section and event collection heders beacuse they're sticky
            if (this.showSectionHeader) {
                heightSumBefore -= heights.sectionHeader;
            }
            heightSumBefore -= heights.eventCollectionHeader;

            const additionalOffset = 0;
            return (
                heightSumBefore + // total height off all elements that precede this one
                additionalOffset +
                (this.firstRowOffset - this.offsetTop)
            ); // amount to scroll element from it's topmost position
            // to the position before the click
        },
        scrollToEventRow() {
            if (this.disableAutoScroll) {
                return;
            }
            setWindowScrollTop(this.scrollPosition);
        },
    },
    components: {
        CategoryHeader,
        EventRow,
        SectionHeaderRow,
        EventCollectionHeader,
        SectionFooterRow,
        VirtualScroller,
        Illustration,
    },
};
</script>
