import ServerConfig from '@core/models/country/ServerConfig';
import ConfigMapper from '@core/mappers/country/DirectusConfigMapper';
import IDirectusConfigDto from '@core/mappers/country/dtos/IDirectusConfigDto';
import common from '@src/config/common';
import ConfigRestService from './_private/ConfigRestService';

class Mappers {
    configMapper: ConfigMapper = new ConfigMapper();
}

export class ConfigService {
    private configRestService: ConfigRestService = new ConfigRestService();
    private mappers: Mappers = new Mappers();

    /**
     * @throws {RequestError}
     * @throws {Error}
     */
    public getConfig = async (): Promise<ServerConfig> => {
        const configDto = await this.configRestService.getDirectusConfig();
        const mappedConfigs = this.mappers.configMapper.map(configDto as IDirectusConfigDto, false);
        const env = common.environment.variables;
        // TODO: remove once aws is the primary hosting solution
        mappedConfigs.tickets.paymentHost = env.paymentHost;
        mappedConfigs.tickets.paymentHostFastly = env.paymentHostFastly;

        mappedConfigs.tickets.paymentHostSocket = env.paymentHostSocket;

        mappedConfigs.tickets.socketPath = env.socketPath;

        mappedConfigs.tickets.virtualTournamentsEndpoint = env.virtualTournamentsEndpoint;
        mappedConfigs.tickets.allowancesUrl = env.allowancesUrl;
        mappedConfigs.tickets.countryKey = env.countryKey;
        mappedConfigs.offer.offerLang = common.offerLang;
        return mappedConfigs;
    };
}

export default new ConfigService();
