import { Subscription } from 'rxjs';
import Singleton from '@core/services/common/Singleton';
import Odd from '@models/offer/Odd';
import { instanceGuard } from '@utils/services';
import ServerConfig from '@core/models/country/ServerConfig';
import SSEManager, { BetBuilderOddMessage } from './_private/SSEManager';
import RestService from './_private/RestService';

// tslint:disable-next-line variable-name
class BetBuilderService {
    private static instance: BetBuilderService;
    private sseManager: SSEManager;
    // SB_RO target is the only one for now, we will change it to function or enum once the second value is introduced
    private readonly target = 'SB_RO';
    private lang: string;
    private restService: RestService;

    private constructor(config: ServerConfig) {
        this.sseManager = new SSEManager(config);
        this.restService = new RestService(config);
        this.restService.getBetBuilder();
        this.lang = config.offer.offerLang;
    }

    public static createInstance(config: ServerConfig): BetBuilderService {
        if (!BetBuilderService.instance) {
            BetBuilderService.instance = new BetBuilderService(config);
        }

        return BetBuilderService.instance;
    }
    public static getInstance(): BetBuilderService {
        return instanceGuard(BetBuilderService.instance);
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete (BetBuilderService as any).instance;
    }

    public async getOdd(matchId: number, oddUuid: string): Promise<Odd> {
        return await this.restService.getOdd(matchId, oddUuid, this.target, this.lang);
    }

    public subscribeToOdd(
        eventId: number,
        oddUuid: string,
        callback: (data: BetBuilderOddMessage) => void,
    ): Subscription {
        return this.sseManager.subscribeToOddChanges(eventId, oddUuid, this.target, this.lang, callback);
    }
}

export default BetBuilderService as Singleton<BetBuilderService>;
