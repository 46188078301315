<template>
    <TypeSwitcher :types="stakes" :selected="stake" class="betslip__body__details__stake__picker" @click="onClick" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import config from '@config';
import i18n from '@src/app/localization/i18n';
import TypeSwitcher from '@shared/components/TypeSwitcher.vue';
import PlatformService from '@src/terminal/core/services/platform/PlatformService';

export default defineComponent({
    components: { TypeSwitcher },
    emits: ['click'],
    name: 'StakePickerV2',
    props: {
        stake: {
            type: Number,
            required: true,
        },
    },
    setup(_, { emit }) {
        const stakes = computed(() => {
            const stakeTypes = config.app.betSlip.stakesV2.map((stake) => ({
                type: stake,
                displayName: `${stake} ${i18n.t('currencyAbbreviation')}`,
            }));

            // Max stake
            stakeTypes.push({
                type: 0,
                displayName: i18n.t('max').toString(),
            });

            return stakeTypes;
        });

        const onClick = (stake: number) => {
            if (!stake) {
                return emit('click', PlatformService.getInstance().getTerminalBalance());
            }
            emit('click', stake);
        };

        return {
            onClick,
            stakes,
        };
    },
});
</script>
