<template>
    <div style="display: flex">
        <div style="margin: 32px">
            <div v-if="!isAuthenticated" style="display: flex; flex-flow: column">
                <h1 style="color: white">
                    {{ $t('ticket reprint') | capitalize }}
                </h1>
                <div v-if="errors">
                    <span v-for="error in errors" :key="error" style="color: white">
                        {{ error }}
                    </span>
                </div>
                <input
                    id="username-field"
                    :placeholder="$t('username')"
                    type="text"
                    style="height: 50px; width: 250px"
                    @focus="
                        showVirtualKeyboard($event, {
                            onConfirm: editUsername,
                            onChange: editUsername,
                            onHide,
                        })
                    "
                />
                <input
                    id="password-field"
                    :placeholder="$t('your password')"
                    type="password"
                    style="height: 50px; width: 250px"
                    @focus="
                        showVirtualKeyboard($event, {
                            onConfirm: editPassword,
                            onChange: editPassword,
                            onHide,
                        })
                    "
                />
                <div style="display: flex; flex-flow: column; margin-top: 8px">
                    <button style="height: 50px; width: 250px" @click="requestTickets">
                        {{ $t('login') | capitalize }}
                    </button>
                    <button style="height: 50px; width: 250px" @click="onCancel">
                        {{ $t('cancel') | capitalize }}
                    </button>
                </div>
                <h4 style="color: white">APP_VERSION: {{ dockerImageTag }}</h4>
            </div>

            <div v-if="isAuthenticated">
                <div>
                    <button style="height: 50px; width: 250px" @click="onCancel">
                        {{ $t('back') | capitalize }}
                    </button>
                </div>
                <div style="overflow-y: scroll">
                    <div v-for="(obj, index) in tickets" :key="index" style="color: white">
                        <h2>{{ $t('ticket') | capitalize }} #{{ index + 1 }}</h2>
                        <p>{{ $t('ticket ID') | capitalize }}: {{ obj.ticketId }}</p>
                        <p>{{ $t('date') | capitalize }}: {{ parseDate(obj.dateReceived) }}</p>
                        <div v-for="(event, num) in obj.events" :key="event.eCodeEvent">
                            <p>
                                {{ $t('event name') | capitalize }} #{{ num + 1 }}:
                                {{ translateEventName(event.nameEvent) }}
                            </p>
                        </div>
                        <p>
                            {{ $t('totalStake') | capitalize }}:
                            {{ parseFloat(obj.sumTotal).toFixed(2) }}
                        </p>
                        <p>
                            {{ $t('potential win') | capitalize }}:
                            {{ obj.winEstimated.toFixed(2) }}
                            {{ $t('currencyAbbreviation') }}
                        </p>
                        <div>
                            <button style="height: 50px" @click="reprintTicket(username, password, obj.ticketId)">
                                {{ $t('print ticket') | capitalize }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PlatformService from '@src/terminal/core/services/platform/PlatformService';
import TicketService from '@core/services/tickets/TicketService';
import { routeNames } from '@app/router';
import config from '@config';
import common from '@src/config/common';
import eventLocale from '@utils/mappers/helpers';
import Logger from '@core/utils/loggers/Logger';

const DEFAULT_TIMEOUT = 2 * 60 * 1000;

export default {
    name: 'TicketReprintPage',
    data() {
        return {
            dockerImageTag: common.environment.dockerImageTag,
            cancelTask: null,
            isAuthenticated: false,
            tickets: [],
            username: null,
            password: null,
            errors: [],
        };
    },
    methods: {
        translateEventName(eventName) {
            return eventLocale(eventName, config.language);
        },
        async requestTickets() {
            this.errors = [];
            this.hideVirtualKeyboard();

            const username = document.getElementById('username-field').value;
            const password = document.getElementById('password-field').value;

            this.username = username;
            this.password = password;

            try {
                const tickets = await TicketService.getInstance().requestReprintTickets(username, password);
                this.isAuthenticated = true;
                this.tickets = tickets;
                this.startCancleTask();
            } catch (error) {
                Logger.error(error);
                if ([400, 401, 500].includes(error.response.status)) {
                    this.errors.push('Unauthorized');
                }
                this.isAuthenticated = false;
                this.username = null;
                this.password = null;
                this.tickets = [];
            }
        },
        async reprintTicket(username, password, ticketId) {
            const ticket = await TicketService.getInstance().reprintTicketById(username, password, ticketId);
            PlatformService.getInstance().sendTicketPrint(ticket);
            this.onCancel();
        },
        editUsername(value) {
            if (value && value !== 'undefined') {
                this.username = value;
            }
        },
        editPassword(value) {
            if (value && value !== 'undefined') {
                this.password = value;
            }
        },
        onHide() {},
        onCancel() {
            this.hideVirtualKeyboard();
            this.isAuthenticated = false;
            this.username = null;
            this.password = null;
            this.tickets = [];
            this.errors = [];
            if (this.cancelTask) {
                clearTimeout(this.cancelTask);
            }
            this.$router.push({ name: routeNames.landing });
        },
        startCancleTask() {
            if (!this.cancelTask) {
                this.cancelTask = setTimeout(() => {
                    clearTimeout(this.cancelTask);
                    this.onCancel();
                }, DEFAULT_TIMEOUT);
            } else {
                clearTimeout(this.cancelTask);
                this.cancelTask = null;
                this.startCancleTask();
            }
        },
        parseDate(dateStr) {
            const date = new Date(dateStr);
            const timestamp = Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
                date.getMilliseconds(),
            );
            return new Date(timestamp);
        },
    },
};
</script>
