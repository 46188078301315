import BettingAvailableService from '../../betting/BettingAvailableService';
import { NotReadySource } from '../../betting/_private/enums';

// eslint-disable-next-line import/prefer-default-export
export const isPrinterReady = (printer?: any): void => {
    const isPrinterOnline = printer.valid;
    if (isPrinterOnline) {
        BettingAvailableService.getInstance().unset(NotReadySource.Printer);
    } else {
        BettingAvailableService.getInstance().set(NotReadySource.Printer, {
            reason: printer.errorMessage,
            available: isPrinterOnline,
        });
    }
};
