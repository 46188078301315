import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import { ITicketItemDto } from '@core/mappers/tickets/dtos/ISubmittingTicketDto';
import DateUtils from '@utils/date/DateUtils';
import config from '@config';
import ISelection from '@core/models/betSlip/ISelection';
import { EventCategory } from '@core/models/offer/enums';

const apiDateFormat = config.app.format.apiDateFormat;

interface PropagatedAttributes {
    isSystem: boolean;
}

export default class BetSlipItemMapper extends AbstractMapper<ISelection, ITicketItemDto> {
    createTargetObject(selection: ISelection, propagatedAttributes: PropagatedAttributes) {
        const e = {} as Partial<ITicketItemDto>;
        const odd = selection.odd!;
        const event = selection.event;
        // OBA fields start
        // Mapping odd marketCode as enteringCode
        e.eventCode = selection.event.eventCategory === EventCategory.OUTRIGHT ? odd.marketCode : selection.event.code;
        e.eventUuid = event.uuid;
        // odd code on IBetslipOdd ??? symbol for now
        e.oddCode = odd.symbol;
        e.oddId = odd.id;
        e.oddUuid = odd.uuid;
        e.eventId = selection.event.id;
        e.eventName = selection.event.fullName;
        e.marketCode = selection.event.eventCategory === EventCategory.OUTRIGHT ? '0' : selection.event.code;
        e.marketId = selection.getMarketId() || undefined;
        e.marketName = odd.marketName;
        e.marketUuid = odd.marketUuid;
        // OBA fields end
        e.oddTypeId = odd.id;
        e.sbValue = odd.specialBetValue;
        e.matchId = event.id;
        e.value = this.prettyDecimal(odd.value);
        e.matchName = event.fullName;
        e.oddName = odd.symbol;
        e.oddFullName = odd.name;
        e.matchDate = DateUtils.toString(event.date, apiDateFormat);
        e.matchDateUtc = e.matchDate; // they're the same on eventDto and in the docs
        e.betGroupId = odd.marketId;
        e.selected = true; // hardcoded to true in score-alarm code too
        e.type = selection.getItemType();
        e.prefix = odd.prefix;
        e.rules = event.rules;
        e.fix = propagatedAttributes.isSystem ? selection.isFixed : false;
        e.teamnameone = event.team1Name;
        e.teamnametwo = event.team2Name;
        e.teamId1 = `${event.team1Id}`;
        e.teamId2 = `${event.team2Id}`;
        e.betRadarId = `${event.betRadarId}`;
        e.tournamentId = event.tournamentId;
        e.oddDescription = odd.description;
        e.sportId = event.sportId;
        e.source = {
            type: selection.getSourceType(),
        };
        e.live = event.isOngoing();
        return e as ITicketItemDto;
    }
}
