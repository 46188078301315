<template>
    <div class="nav-sidebar-promo">
        <SidebarPromoItem
            v-for="item in items"
            :key="item.id"
            :selectedItemId="selectedItemId"
            v-bind="item"
            @click="onClick"
        />
    </div>
</template>

<script>
import SidebarPromoItem from './SidebarPromoItem.vue';

export default {
    name: 'SidebarPromoItems',
    components: {
        SidebarPromoItem,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        selectedItemId: {
            type: Number,
            required: false,
        },
    },
    methods: {
        onClick(item) {
            this.$emit('click', item);
        },
    },
};
</script>
