type PeriodEntry = [number | string, number | string];

const emptyPeriod = ['-', '-'] as PeriodEntry;

export const processOther = (
    liveData: {
        periods: any[];
        team1Score: number;
        team2Score: number;
        serve?: number;
    },
    isServingSport = false,
) => {
    const data = {
        serve: liveData.serve,
        score: [] as PeriodEntry[],
    };
    const total = [liveData.team1Score, liveData.team2Score] as PeriodEntry;
    const last2Periods = liveData.periods
        .slice(-2)
        .map((period) => [period.team1Score, period.team2Score]) as PeriodEntry[];
    if (last2Periods.length < 2) {
        last2Periods.unshift(emptyPeriod);
    }
    last2Periods.reverse();
    if (isServingSport) {
        data.score = [...last2Periods, total];
    } else {
        data.score = [total, ...last2Periods];
    }
    return data;
};

export const processOtherFull = (liveData: {
    periods: any[];
    team1Score: number;
    team2Score: number;
    serve?: number;
}) => {
    const data = {
        serve: liveData.serve,
        score: [] as PeriodEntry[],
    };
    data.score = liveData.periods.map((period) => [period.team1Score, period.team2Score]) as PeriodEntry[];
    data.score.unshift([liveData.team1Score, liveData.team2Score]);
    return data;
};

export const processTennis = (liveData: any) => {
    let lastSet = liveData.periods[liveData.periods.length - 1];
    if (!lastSet) {
        lastSet = emptyPeriod;
    } else {
        lastSet = [lastSet.team1Score, lastSet.team2Score];
    }
    let gemScore = [0, 0];
    if (liveData.team1GemScore) {
        gemScore = [liveData.team1GemScore, liveData.team2GemScore];
    }

    const score = [gemScore, lastSet, [liveData.team1Score, liveData.team2Score]];

    return {
        score,
        serve: liveData.serve,
    };
};
