import { isEqual as _isEqual } from '@lodash';
import Vue from 'vue';
import Router, { RawLocation, Route } from 'vue-router';
import PageNotFound from '@pages/PageNotFound.vue';
import InfoPage from '@pages/InfoPage.vue';
import routes from '@countryRouter/routes';
import { updateTitle, updateMetaTags, getDefaultLayout } from '@app/router/utils';
import { links, routeNames, titles } from './constants';

const originalRouterPush = Router.prototype.push;

Router.prototype.push = async function push(loc: RawLocation): Promise<Route> {
    try {
        return await originalRouterPush.bind(this)(loc);
    } catch (e: any) {
        if (e?.name === 'NavigationDuplicated') {
            return e;
        }
        throw e;
    }
};

Vue.use(Router);

export { links, routeNames };

const router = new Router({
    mode: 'history',
    scrollBehavior(to, from) {
        if (from.name === to.name && _isEqual(from.params, to.params)) {
            return undefined;
        }
        return { x: 0, y: 0 };
    },
    routes: [
        ...routes,
        {
            path: links.info,
            name: routeNames.info,
            components: getDefaultLayout(InfoPage),
            meta: {
                title: titles.info,
            },
        },
        {
            path: '*',
            name: 'Not Found',
            components: getDefaultLayout(PageNotFound),
            meta: {
                title: titles.notFound,
            },
        },
    ],
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    updateTitle(to);
    updateMetaTags(to);
    next();
});

export default router;
