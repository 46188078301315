/**
 * Groups together all objects with the same value under a given key.
 * The keys in the resulting map preserve the order in which they are encountered.
 * The order of grouped values is determined by the order they occur in the array.
 *
 * @param array An array of objects to be grouped.
 * @param key The property whose values are used for grouping (grouping key).
 * @returns A Map object with keys (all values of the grouping property) pointing to corresponding groups.
 */
export default function groupBy<T extends { [key: string]: any }, K extends keyof T>(array: T[], key: K) {
    return array.reduce((grouped: Map<string, T[]>, current: T) => {
        const attrValue = current[key];
        if (!grouped.has(attrValue)) {
            grouped.set(attrValue, []);
        }
        grouped.get(attrValue)!.push(current);

        return grouped;
    }, new Map());
}
