<template>
    <div :class="{ selected: isSelected }" class="filters__container__button" @click="$emit('click', timeFilterId)">
        <i v-if="icon" :class="`sds-icon-${icon}`" />
        <span v-if="date">{{ date }}</span>
        <span v-else>{{ label }}</span>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { TimeFilter } from '@src/terminal/app/modules/shared/timeFilter/enums';
import {
    getFormattedDateFromTimeFilterV2,
    getLabelFromTimeFilter,
} from '@src/terminal/app/modules/shared/timeFilter/helpers';

export default defineComponent({
    name: 'TimeFilterItemV2',
    emits: ['click'],
    props: {
        timeFilterId: {
            type: String as PropType<TimeFilter>,
            required: true,
        },
        icon: {
            type: String,
        },
        isSelected: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        const date = computed(() => {
            return getFormattedDateFromTimeFilterV2(props.timeFilterId);
        });

        const label = computed(() => {
            return getLabelFromTimeFilter(props.timeFilterId);
        });

        return {
            date,
            label,
        };
    },
});
</script>
