<template>
    <div :class="['check-ticket-container', checkTicketClass]">
        <div v-if="data" v-sticky="stickyConfig" class="check-ticket">
            <div class="check-ticket__back btn-action" @click="onBack">
                <i class="sds-icon-navigation-chevron-left" />
                {{ $t('back') }}
            </div>
            <div class="check-ticket__search">
                <input
                    v-model="ticketIdQuery"
                    class="check-ticket__search__input"
                    type="text"
                    :placeholder="$t('ticket code')"
                    @focus="showVirtualKeyboard($event, { onConfirm, onChange, onHide: () => {} })"
                />
                <i class="sds-icon-commerce-ticket check-ticket__search__action" @click="emitTicketScan" />
            </div>
            <TicketOverviewV2 :ticket="data.ticket" />
            <div class="check-ticket__footer">
                <div v-if="data.ticket.bonus" class="check-ticket__footer__bonus">
                    <span class="check-ticket__footer__bonus__percentage"> {{ data.ticket.bonus.percentage }}% </span>
                    <span class="check-ticket__footer__bonus__label">
                        {{ $t('superbonusIncluded') }} ({{ data.ticket.bonus.value | twoDecimals }}
                        {{ $t('currencyAbbreviation') }})
                    </span>
                </div>
                <TicketPaymentV2 :ticket="data.ticket" />
                <div class="check-ticket__footer__actions">
                    <div v-if="isPayoutAvailableValue" class="btn-action payout" @click="payoutTicket(ticketIdQuery)">
                        {{ $t('payout ticket') }}
                    </div>
                    <div v-if="isCancelAvailableValue" class="btn-action" @click="cancelTicket(ticketIdQuery)">
                        {{ $t('cancel ticket') }}
                    </div>
                    <div v-if="isRebetAvailableValue" class="btn-action" @click="rebetTicket(ticketIdQuery, product)">
                        <i class="sds-icon-actions-copy" />
                        {{ $t('repeat ticket') }}
                    </div>
                    <div class="btn-action" @click="onBack">
                        <i class="sds-icon-navigation-chevron-left" />
                        {{ $t('back') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import TicketService from '@core/services/tickets/TicketService';
import TicketOverviewV2 from '@src/v2/app/modules/checkTicket/TicketOverviewV2.vue';
import i18n from '@app/localization/i18n';
import PlatformService from '@src/terminal/core/services/platform/PlatformService';
import { EventStatus, TicketStatus } from '@core/models/tickets/enums';
import TicketPaymentV2 from '@src/v2/app/modules/checkTicket/TicketPaymentV2.vue';
import { defineComponent, ref, watch, computed } from 'vue';
import countryModule from '@store/modules/data/country/countryIndex';
import Ticket, { EventCondensed, TicketData } from '@core/models/tickets/Ticket';
import ProductService from '@src/terminal/core/services/product/ProductService';
import { useRouter } from 'vue-router/composables';
import GetTicketDto from '@core/mappers/tickets/dtos/GetTicketDto';
import CheckTicketMapper from '@core/mappers/tickets/CheckTicketMapper';
import { useVuexStore } from '../../utils/vuexStore';

export default defineComponent({
    name: 'CheckTicketInhouseV2',
    components: {
        TicketOverviewV2,
        TicketPaymentV2,
    },
    setup() {
        const isPayoutAvailableValue = ref(false);
        const isCancelAvailableValue = ref(false);
        const isRebetAvailableValue = ref(false);
        const ticketIdQuery = ref('');
        const product = ref('');
        const data = ref<TicketData | null>(null);
        const route = useRouter();

        const stickyConfig = {
            scrollableContentSelector: '.check-ticket__body__selections',
            staticLowerContentSelector: '.check-ticket__footer',
            staticHigherContentSelector: '.check-ticket__search, .check-ticket__status, .ticket-status',
        };

        const countryStore = useVuexStore<typeof countryModule>('data/country');
        const checkedTicket = countryStore.getter('checkedTicket');

        const checkTicketClass = computed(() => {
            switch (data.value?.ticket.status) {
                case TicketStatus.payed:
                case TicketStatus.win:
                case TicketStatus.refund:
                    return 'check-ticket-container--win';
                case TicketStatus.active:
                    return 'check-ticket-container--active';
                default:
                    return 'check-ticket-container--lost';
            }
        });

        const onConfirm = (text: string) => {
            ticketIdQuery.value = text;
            (PlatformService as typeof ProductService).getInstance().scanTicket(ticketIdQuery.value);
        };

        const emitTicketScan = () => {
            (PlatformService as typeof ProductService).getInstance().scanTicket(ticketIdQuery.value);
        };

        const onChange = (value: string) => {
            ticketIdQuery.value = value;
        };

        const cancelTicket = (id: string) => {
            (PlatformService as typeof ProductService).getInstance().checkTicketCancel(id);
        };

        const payoutTicket = (id: string) => {
            (PlatformService as typeof ProductService).getInstance().checkPayoutTicket(id);
        };

        const rebetTicket = (id: string, product: string) => {
            checkTicketExit();
            (PlatformService as typeof ProductService).getInstance().checkTicketRecreateRequest(id, product);
        };

        const checkTicketExit = () => {
            data.value = null;
            ticketIdQuery.value = '';
            TicketService.getInstance().unsubscribeFromTicketById();
        };

        const onBack = () => {
            checkTicketExit();
            route.back();
        };

        const checkTicketSubmit = async () => {
            TicketService.getInstance().unsubscribeFromTicketById();
            data.value = null;
            const value = ticketIdQuery.value.trim().toUpperCase();
            if (!value) return;
            if (value === 'CLEAR') {
                checkTicketExit();
                return;
            }
            try {
                const mapper = new CheckTicketMapper();
                const prepareData = (ticket: GetTicketDto) => {
                    const ticketData = mapper.map(ticket);
                    parseData(ticketData);
                };
                const data = await TicketService.getInstance().getTicketById(value);
                prepareData(data);
                TicketService.getInstance().subscribeToTicketById(value, prepareData);
            } catch (err: any) {
                const title = i18n.t('ticket error').toString().toUpperCase();
                PlatformService.getInstance().sendNotification({
                    title,
                    text: err.message,
                    type: 'error',
                });
            }
        };

        const parseData = (ticket: Partial<Ticket>) => {
            product.value = 'SuperbetPrematch';
            const actions = {
                rebet: { active: isRebetAvailable(ticket) },
                cancel: { active: isCancelAvailable(ticket) },
                active: { active: false },
            };

            isPayoutAvailableValue.value = isPayoutAvailable(ticket);
            isCancelAvailableValue.value = isCancelAvailable(ticket);
            isRebetAvailableValue.value = isRebetAvailable(ticket);

            data.value = {
                ticket: ticket as Ticket,
                actions,
            };
        };

        const isRebetAvailable = (ticket: Partial<Ticket>) => {
            return ticket.events?.some((event) => event.status === EventStatus.active) || false;
        };

        const areSomeEventsStarted = (events: EventCondensed[]) => {
            return events.some((event) => {
                if (!event.date) return false;

                const now = new Date();
                const startDate = new Date(event.date);
                return startDate < now;
            });
        };

        const isCancelAvailable = (ticket: Partial<Ticket>) => {
            if (ticket.isTemplate) return false;
            const nonCancelableStatuses = [
                TicketStatus.win,
                TicketStatus.canceled,
                TicketStatus.lost,
                TicketStatus.payed,
                TicketStatus.refund,
            ];
            if (!ticket.status || nonCancelableStatuses.includes(ticket.status)) {
                return false;
            }
            if (!ticket.events || areSomeEventsStarted(ticket.events)) {
                return false;
            }
            if (ticket.status !== TicketStatus.active) return false;
            if (!ticket.dateReceived) return false;

            const now = new Date();
            const cancelLimit = new Date(ticket.dateReceived);
            const cancelTimeout = 60 * 15;
            cancelLimit.setSeconds(cancelLimit.getSeconds() + cancelTimeout);
            return now.getTime() < cancelLimit.getTime();
        };

        const isPayoutAvailable = (ticket: Partial<Ticket>) => {
            return ticket.status === TicketStatus.win;
        };

        watch(
            checkedTicket,
            (ticket) => {
                if (ticket) {
                    ticketIdQuery.value = ticket.id;
                    checkTicketSubmit();
                }
            },
            { immediate: true },
        );

        return {
            checkTicketClass,
            onBack,
            data,
            isPayoutAvailableValue,
            isCancelAvailableValue,
            isRebetAvailableValue,
            ticketIdQuery,
            product,
            onConfirm,
            emitTicketScan,
            onChange,
            cancelTicket,
            payoutTicket,
            rebetTicket,
            checkTicketExit,
            checkTicketSubmit,
            isRebetAvailable,
            isCancelAvailable,
            isPayoutAvailable,
            stickyConfig,
        };
    },
});
</script>

<style lang="scss">
.app-main {
    height: auto;
    min-height: initial;
    padding: 0;
    margin: 0;
}
</style>
