import Match from '@core/models/offer/Match';
import slugify from '@utils/url/slugify';
import { links } from '@app/router';
import { IState } from '@app/store';
import Sport from '@core/models/struct/Sport';

// eslint-disable-next-line
export interface ITeamsState {
    // semantic reasons, teamsIndex doesn't really use state
}

export default {
    namespaced: true,
    // todo pitati dinu zasto je isao preko gettera, a ne preko modula kao sport i filter
    // ...timeFilterModule,
    getters: {
        // ...timeFilterModule.getters,
        teamEvents(state: ITeamsState = {}, getters: any, rootState: IState, rootGetters: any) {
            const events = rootGetters['data/sportOffer/events'];
            const path = rootGetters['navigation/route'].path;
            const sports = rootGetters['data/sportOffer/sports'] as Record<number, Sport>;

            if (path.startsWith(links.teams)) {
                const { sportSlug, teamSlug } = rootGetters['navigation/route'].params;
                const sport = Object.values(sports).find((x) => slugify(x.name) === sportSlug);
                if (sport) {
                    const teamEvents = events.filter(
                        (event: Match) =>
                            event.sportId === sport.id &&
                            (slugify(event.team1Name) === teamSlug || slugify(event.team2Name) === teamSlug),
                    );
                    return teamEvents.filter((event: Match) =>
                        getters.timeFilters.every((func: Function) => func(event)),
                    );
                }
            }
            return [];
        },
    },
};
