<template>
    <transition name="fade" @after-leave="afterLeave(error)">
        <div class="betslip-error" style="font-size: 14px" role="alert">
            <div class="text">{{ error | capitalize }}.</div>
            <i v-if="showCloseButton" class="icon--sm icon-close" @click="afterLeave" />
        </div>
    </transition>
</template>

<script>
const ANIMATION_DURATION = 4000; // ms

export default {
    name: 'Error',
    props: {
        showCloseButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        error: {
            type: String,
            required: true,
        },
        shouldFadeOut: {
            type: Boolean,
            required: false,
            default: false,
        },
        duration: {
            type: Number,
            required: false,
            default: ANIMATION_DURATION,
        },
    },
    watch: {
        error(newValue) {
            if (this.shouldFadeOut && newValue) {
                this.fadeOut();
            }
        },
    },
    created() {
        if (this.shouldFadeOut) {
            this.fadeOut();
        }
    },
    beforeDestroy() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    },
    methods: {
        afterLeave() {
            this.$emit('fadeOut');
        },
        fadeOut() {
            if (this.timeout) {
                clearTimeout();
            }

            this.timeout = setTimeout(() => {
                this.$emit('fadeOut');
            }, this.duration);
        },
    },
};
</script>
