import { Store } from 'vuex';
import { IState } from '@app/store';
import ITicketsState from '@store/modules/data/tickets/types';
import flags from '@src/terminal/store/flags';
import competitions from '@src/app/competitions/store';
import { CompetitionsState } from '@src/app/competitions/store/types';
import sportOffer, { ISportOfferState, SportOfferGetters } from './sportOffer';
import { loadLiveEvents } from './tickets/subscriptions';
import country, { ICountryState } from './country/countryIndex';
import tickets from './tickets/index';

export interface IDataState {
    sportOffer: ISportOfferState;
    tickets: ITicketsState;
    country: ICountryState;
    competitions: CompetitionsState;
}

export interface DataGetters {
    sportOffer: SportOfferGetters;
}

export function subscriptions(store: Store<IState>) {
    loadLiveEvents(store);
}

export default {
    namespaced: true,

    modules: {
        country,
        flags,
        sportOffer,
        tickets,
        competitions,
    },
};

export const moduleNamespaces = {
    authentication: ['authentication'],
} as Record<string, string[]>;
