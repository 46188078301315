<template>
    <div class="not-found-page">
        <div>
            <h1 class="not-found-page__title">
                {{ $t('404 - page not found') | capitalize }}
            </h1>
            <h1 class="not-found-page__title">
                {{ $t('but you’re still a winner!') | capitalize }}
            </h1>
            <div class="notice">
                {{ $t('and your prize: an amazing sport fact you probably didn’t know.') }}
            </div>
            <div class="not-found-page__trivia">
                <div class="not-found-page__trivia-title">
                    <b>{{ $t('did you know') | capitalize }}</b>
                </div>
                <div class="notice">{{ $t('that ”Federer” can be typed entirely with the left hand') }}.</div>
            </div>
        </div>
        <Illustration image="/static/img/illustrations/page__not-found.svg" />
    </div>
</template>

<script>
import Illustration from '@shared/components/Illustration.vue';

export default {
    name: 'PageNotFound',
    components: {
        Illustration,
    },
};
</script>
