<template>
    <div class="ticket-payment">
        <table class="ticket-payment__info">
            <tr class="ticket-payment__info__label">
                <td>{{ $t('total quota') }}</td>
                <td>{{ $t('stake') }}</td>
                <td>{{ $t('payout') }}</td>
            </tr>
            <tr class="ticket-payment__info__value">
                <td>
                    {{ ticket.coefficient | twoDecimals }}
                </td>
                <td>
                    {{ ticket.payment.total | twoDecimals }}
                    <span class="ticket-payment__info__value__currency">{{ $t('currencyAbbreviation') }}</span>
                </td>
                <td>
                    {{ payout }}
                    <span class="ticket-payment__info__value__currency">{{ $t('currencyAbbreviation') }}</span>
                </td>
            </tr>
        </table>
    </div>
</template>

<script lang="ts">
import { TicketStatus } from '@core/models/tickets/enums';
import Ticket from '@core/models/tickets/Ticket';
import { twoDecimals } from '@shared/filters';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'TicketPaymentV2',
    props: {
        ticket: {
            type: Object as PropType<Ticket>,
            required: true,
        },
    },
    setup(props) {
        const payout = computed(() => {
            return twoDecimals(
                props.ticket.status === TicketStatus.active ? props.ticket.win.estimated : props.ticket.win.payoff,
            );
        });

        return {
            payout,
        };
    },
});
</script>
