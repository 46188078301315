<template>
    <div class="betslip-payout">
        <div v-if="isSummaryExpanded" class="betslip-payout__info">
            <div class="betslip-payout__info__row">
                <span class="betslip-payout__info__row__description">
                    {{ $t('total quota') }}
                </span>
                <span class="betslip-payout__info__row__value">
                    {{ totalCoefficient | twoDecimals }}
                    <span class="betslip-payout__info__row__value--currency">
                        {{ $t('currencyAbbreviation') }}
                    </span>
                </span>
            </div>
            <div class="betslip-payout__info__row">
                <span class="betslip-payout__info__row__description">
                    {{ $t('tax') }}
                </span>
                <span class="betslip-payout__info__row__value">
                    {{ taxPercentage | twoDecimals }} % = {{ taxValue | twoDecimals }}
                    <span class="betslip-payout__info__row__value--currency">
                        {{ $t('currencyAbbreviation') }}
                    </span>
                </span>
            </div>
            <div class="betslip-payout__info__row">
                <span class="betslip-payout__info__row__description">
                    {{ $t('stake after tax') }}
                </span>
                <span class="betslip-payout__info__row__value">
                    {{ stakeAfterTax | twoDecimals }}
                    <span class="betslip-payout__info__row__value--currency">
                        {{ $t('currencyAbbreviation') }}
                    </span>
                </span>
            </div>
            <template v-if="isSystem">
                <div class="betslip-payout__info__row">
                    <span class="betslip-payout__info__row__description">
                        {{ $t('min potential win') }}
                    </span>
                    <span class="betslip-payout__info__row__value">
                        {{ payout.minFinal | twoDecimals }}
                        <span class="betslip-payout__info__row__value--currency">
                            {{ $t('currencyAbbreviation') }}
                        </span>
                    </span>
                </div>
            </template>
            <BetSlipBonusV2 v-if="bonusAmount" :bonusAmount="bonusAmount" :bonusPercentage="bonusPercentage" />
        </div>
        <div class="betslip-payout__win" @click="onSummaryClick">
            <div class="betslip-payout__win__title">
                <span>{{ $t('potential win') }}</span>
                <i :class="payoutExpandClass" />
            </div>
            <div class="betslip-payout__win__potential" @click.stop.prevent="tapTicketReprint">
                <span>{{ payout.base | twoDecimals }}</span>
                <span class="betslip-payout__win__potential--currency">
                    {{ $t('currencyAbbreviation') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from 'vue';
import { Payout } from '@core/models/betSlip/Payout';
import BetSlipBonusV2 from '@src/v2/app/modules/sportOffer/betSlip/BetSlipBonusV2.vue';
import { useRouter } from 'vue-router/composables';
import { routeNames } from '@src/app/router';
import { useVuexStore } from '@src/v2/app/utils/vuexStore';
import betSlipModule from '@store/modules/ui/sportOffer/betSlip/betSlipIndex';

export default defineComponent({
    components: { BetSlipBonusV2 },
    name: 'PayoutSummaryV2',
    props: {
        bonusAmount: {
            type: Number,
            required: true,
        },
        bonusPercentage: {
            type: Number,
            required: true,
        },
        payout: {
            type: Object as PropType<Payout>,
            required: true,
        },
        totalCoefficient: {
            type: Number,
            required: true,
        },
        stake: {
            type: Number,
            required: true,
        },
        stakeAfterTax: {
            type: Number,
            required: true,
        },
        isSystem: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup(props) {
        const route = useRouter();
        const taps = ref(0);
        const tapsTimeout = ref();
        const isSummaryExpanded = ref(false);

        const sportBetslipStore = useVuexStore<typeof betSlipModule>('ui/sportOffer/betSlip');
        const taxPercentage = sportBetslipStore.getter('taxPercentage');

        const payoutExpandClass = computed(() => [
            'betslip-payout__win__expand',
            `sds-icon-navigation-chevron-${isSummaryExpanded.value ? 'down' : 'up'}`,
        ]);

        const taxValue = computed(() => {
            return props.stake - props.stakeAfterTax;
        });

        const onSummaryClick = () => {
            isSummaryExpanded.value = !isSummaryExpanded.value;
        };

        const tapTicketReprint = () => {
            if (tapsTimeout.value) {
                tapsTimeout.value = setTimeout(resetTicketReprintTaps, 1000 * 3);
            }
            if (taps.value >= 5) {
                resetTicketReprintTaps();
                route.push({ name: routeNames.ticketReprint });
            }
            taps.value += 1;
        };

        const resetTicketReprintTaps = () => {
            clearTimeout(tapsTimeout.value);
            tapsTimeout.value = null;
            taps.value = 0;
        };

        return {
            isSummaryExpanded,
            payoutExpandClass,
            onSummaryClick,
            tapTicketReprint,
            taxValue,
            taxPercentage,
        };
    },
});
</script>
