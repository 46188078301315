import Vue from 'vue';
import { xorBy as _xorBy, xor as _xor, get as _get, set as _set } from '@lodash';
import { ActionContext, Action, Commit, Store, MutationPayload, ActionPayload } from 'vuex';

import { logError } from '@app/utils/errors';
import AuthenticationError from '@errors/authentication/AuthenticationError';
import { IState } from '.';
import { RelativeRootGetters } from './types';

export default class StoreUtil {
    static asyncErrorGuard(f: Function): Action<any, any> {
        return async (context: ActionContext<any, any>, ...args: any[]) => {
            try {
                return await f(context, ...args);
            } catch (e) {
                if (!(e instanceof AuthenticationError)) {
                    logError(e);
                }
                e.isLogged = true;
                throw e;
            }
        };
    }

    static createSimpleMutatorAction =
        (mutation: string) =>
        ({ commit }: { commit: Commit }, ...args: any[]) =>
            commit(mutation, ...args);

    /// //// Lists START
    static createSimpleMutator =
        <T = any>(property: Extract<keyof T, string>) =>
        (state: any, value: any) =>
            _set(state, property, value);

    static createPushToListMutator = (property: string) => (state: any, value: any) => state[property].push(value);

    static createPushOrInsertToListMutator = (property: string, valueKey: string) => (state: any, payload: any) => {
        const index = payload.index;

        if (index !== null && index !== undefined) {
            state[property].splice(index, 0, payload[valueKey]);
        } else {
            state[property].push(payload[valueKey]);
        }
    };

    static createRemoveFromListMutator = (property: string) => (state: any, value: any) => {
        const index = state[property].indexOf(value);

        if (index === -1) {
            return;
        }

        state[property].splice(index, 1);
    };

    static createToggleListElementMutator = (property: string) => (state: any, value: any) =>
        (state[property] = _xor(state[property], [value]));

    static createClearListMutator = (property: string) => (state: any) => (state[property] = []);
    /// //// Lists END

    static createSimpleGetter =
        <T = any>(property: Extract<keyof T, string>, defaultValue?: any) =>
        (state: any) =>
            _get(state, property, defaultValue);

    static createToggleListElementMutatorBy = (property: string, key: string) => (state: any, value: any) =>
        (state[property] = _xorBy(state[property], [value], key));

    static createToggleMutator = (property: string) => (state: any, value?: any) => {
        if (value === undefined || value === _get(state, property)) {
            _set(state, property, !_get(state, property));
        } else {
            _set(state, property, value);
        }
    };

    static createSetMutator =
        (setPropertyName: string) =>
        (state: any, { key, value }: { key: string | number; value?: boolean }) => {
            if (typeof value === 'boolean') {
                Vue.set(state[setPropertyName], `${key}`, value);
            } else {
                Vue.set(state[setPropertyName], `${key}`, !state[setPropertyName][key]);
            }
        };

    /**
     * Use to map getters in a component when the namespace is a prop
     * @static
     * @param namespaceProp name of the prop that holds the namespace name
     * @param getters getter names. Same as in mapGetters from Vuex
     * @memberof StoreUtil
     */
    static mapGettersDynamic = (namespaceProp: string, getters: string[]): Record<string, () => any> =>
        getters.reduce((total: Record<string, () => any>, getter) => {
            total[getter] = function () {
                const namespace = _get(this, namespaceProp);
                return (this.$store as any).getters[`${namespace}/${getter}`];
            };
            return total;
        }, {});

    /**
     * Use to map actions in a component when the namespace is a prop
     * @static
     * @param namespaceProp name of the prop that holds the namespace name
     * @param actions actions names. Same as in mapActions from Vuex
     * @memberof StoreUtil
     */
    static mapActionsDynamic = (namespaceProp: string, actions: string[]): Record<string, () => any> =>
        actions.reduce((total: Record<string, () => any>, action) => {
            total[action] = function (...args) {
                const namespace = this[namespaceProp];
                return (this.$store as any).dispatch(`${namespace}/${action}`, ...args);
            };
            return total;
        }, {});

    /*
        Adds suffixes to mutation types.
        These suffixes can be used for filtering mutations in
        store subscribers.
        E.g. mutationType = 'PIN_EVENT'
        decorateMutationType(mutationType, ['PERSIST', 'LOG']) = 'PIN_EVENT__PERSIST_LOG'
    */
    static decorateMutationType = (mutationType: string, decorations: string[]) => {
        if (decorations.length === 0) {
            return mutationType;
        }
        const decorationsSuffix = decorations.map((dec) => dec.toUpperCase()).join('_');
        return `${mutationType}__${decorationsSuffix}`;
    };

    /* opposite of decorateMutationType */
    static getDecorations = (mutationType: string) => {
        const optionsStart = mutationType.indexOf('__');
        if (optionsStart) {
            return mutationType.slice(optionsStart + 2).split('_');
        }
        return [];
    };

    static createActionSubscriptionPromise(store: Store<any>, actionType: string) {
        return new Promise<void>((resolve) => {
            const unsubscribe = store.subscribeAction((action: ActionPayload) => {
                if (action.type === actionType) {
                    unsubscribe();
                    resolve();
                }
            });
        });
    }

    static createMutationSubscriptionPromise(store: Store<any>, actionType: string) {
        return new Promise<void>((resolve) => {
            const unsubscribe = store.subscribe((action: MutationPayload) => {
                if (action.type === actionType) {
                    unsubscribe();
                    resolve();
                }
            });
        });
    }
}

// a getter definition used when defining getters in a module

export type Getter<S, G, T> = (state: S, getters: G, rootState: IState, rootGetters: RelativeRootGetters) => T;

/* a type that returns typings for getters which other getter and actions
    receive */
export type ComputedGetters<T> = {
    [K in keyof T]: ReturnType<T[K]>;
};
