<template>
    <div class="event-row__expanded-markets--terminal">
        <template v-if="markets.length">
            <transition-group name="animation-list__market-group-">
                <div
                    v-for="market in markets"
                    :key="market.uniqueId"
                    class="event-row__expanded-market-group--terminal"
                >
                    <div :class="`market-${market.id}`">
                        <MarketHeader
                            :market="market"
                            :isOutright="isOutright"
                            :isExpanded="!!expandedMarketIds[market.uniqueId]"
                            @toggleDescription="toggleDescription(market)"
                            @toggleExpanded="toggleExpandedMarketsRow(market.uniqueId)"
                        />
                        <transition name="animation-list__market-single-">
                            <div
                                v-if="expandedMarketIds[market.uniqueId]"
                                :class="{ 'is-locked': isEventLocked || market.isLocked }"
                                class="event-row__expanded-markets-container"
                            >
                                <div
                                    v-for="(odds, groupName) in marketGroups(market.odds, market.id)"
                                    :key="groupName"
                                    class="event-row__expanded-market-type-group"
                                >
                                    <SportOfferPick
                                        v-for="odd in odds"
                                        :key="odd.uniqueId || odd.id"
                                        v-bind="odd"
                                        :eventId="eventId"
                                        :isTooltipShown="odd.isTooltipShown"
                                        :isAllMarkets="true"
                                        :tooltipDescription="odd.description"
                                        :isEventLocked="isEventLocked"
                                        :valueChangeDynamics="oddValueDynamics && oddValueDynamics[odd.uniqueId]"
                                        :specialBetValue="isOngoing ? '' : odd.specialBetValue"
                                        :class="isSelectedEvent(odd.uuid, odd.specialBetValue) ? 'is-selected' : ''"
                                        :specifiers="odd.specifiers"
                                        :isSuperbet="market.isSuperbet"
                                        :isOutright="isOutright"
                                        :showSpecialBetValue="getShowSpecialBetValue(market.id)"
                                        @click="$emit('oddClick', odd)"
                                    />
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </transition-group>
        </template>
        <div v-else class="primary-market__unavalibale px-3">
            {{ $t('no markets currently available') | upperCase }}
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { groupBy as _groupBy, remove as _remove } from '@lodash';
import oddComparator from '@utils/sortComparators/oddComparator';
import SportOfferPick from '@src/terminal/app/modules/shared/event/SportOfferPick.vue';
import { shouldShowSpecialBetValue } from '@core/models/offer/Odd';
import config from '@config';
import MarketHeader from './MarketHeader.vue';

export default {
    name: 'Markets',
    props: {
        eventOddsMap: {
            type: Array,
            required: false,
        },
        markets: {
            type: Array,
            required: true,
        },
        eventId: {
            type: Number,
            required: true,
        },
        isOutright: {
            type: Boolean,
            required: false,
            default: false,
        },
        selectedOdd: {
            type: String,
            required: false,
        },
        isEventLocked: {
            type: Boolean,
            required: true,
        },
        oddValueDynamics: {
            type: Object,
            required: false,
        },
        isOngoing: {
            type: Boolean,
            required: false,
            default: false,
        },
        selectedGroup: {
            type: [String, Number],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            expandedMarketDetails: [],
        };
    },
    // When leaving the all markets overlay we will collapse the selected markets
    beforeDestroy() {
        this.setExpandedMarketIds({});
    },
    computed: {
        getShowSpecialBetValue() {
            return (marketId) => shouldShowSpecialBetValue(marketId, this.marketsMap, false);
        },
        areAdditionalMarketsLoaded() {
            return this.markets.length >= 1;
        },
        isCollapsingEnabled() {
            return config.app.sportOffer.initiallyExpandedMarketsNumber > -1;
        },
        ...mapGetters('data/sportOffer', {
            marketsMap: 'markets',
            expandedMarketIds: 'expandedMarketIds',
        }),
    },
    watch: {
        markets() {
            const shouldExpandFirstFewMarkets = [this.areAdditionalMarketsLoaded, this.isCollapsingEnabled].every(
                Boolean,
            );
            if (shouldExpandFirstFewMarkets) {
                this.expandFirstFewMarkets();
            }
        },
    },
    methods: {
        ...mapActions('data/sportOffer', ['setExpandedMarketIds', 'toggleExpandedMarketId']),
        marketGroups(odds, marketId) {
            const alteredOdds = this.addIsTooltipShown(odds, marketId);
            const sortedOdds = alteredOdds.sort(oddComparator);
            return _groupBy(sortedOdds, 'specialBetValue');
        },
        isSelectedEvent(uuid, specialValue) {
            return this.eventOddsMap.find((event) => event.uuid === uuid && event.specialValue === specialValue);
        },
        addIsTooltipShown(odds, marketId) {
            return odds.map((odd) => ({
                isTooltipShown: this.isTooltipShown(marketId),
                isLocked: odd.isLocked,
                uniqueId: odd.uniqueId,
                ...odd,
            }));
        },
        toggleDescription(market) {
            // expand offer when clicked on info button
            if (!this.expandedMarketIds[market.uniqueId]) {
                this.toggleExpandedMarketsRow(market.uniqueId);
            }
            if (!this.expandedMarketDetails.includes(market.id)) {
                this.expandedMarketDetails.push(market.id);
            } else {
                this.expandedMarketDetails = _remove(this.expandedMarketDetails, (i) => i !== market.id);
            }
        },
        toggleExpandedMarketsRow(marketId) {
            this.toggleExpandedMarketId(marketId);
        },
        isTooltipShown(marketId) {
            return this.expandedMarketDetails.includes(marketId);
        },
        expandFirstFewMarkets() {
            const num = config.app.sportOffer.initiallyExpandedMarketsNumber;
            const firstFew = Math.min(num, this.markets.length);
            const marketIds = this.markets.slice(0, firstFew).reduce((acc, market) => {
                acc[market.uniqueId] = true;
                return acc;
            }, {});
            this.setExpandedMarketIds({ ...this.expandedMarketIds, ...marketIds });
        },
    },
    components: {
        SportOfferPick,
        MarketHeader,
    },
};
</script>
