import { EventCondensed } from '@core/models/tickets/Ticket';
import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import { EventStatus, EventType } from '@core/models/tickets/enums';
import { IEventDto } from './dtos/IActiveTicketDto';

// iCore ticket event mapper
export default class TicketEventMapper extends AbstractMapper<IEventDto, EventCondensed> {
    createTargetObject(origin: IEventDto) {
        const e = {} as EventCondensed;
        e.IsSpecialFromSportOffer = origin.IsSpecialFromSportOffer;
        e.tournamentName = origin.tournamentName;
        e.date = this.toDate(origin.dateEvent, { assumeUtc: true });
        e.name = this.extractLocalizedString(origin.nameEvent).replace('·', ' - ');

        e.odd = {
            coefficient: parseFloat(origin.oddCoefficientEntry) || 0,
            systemGroupName: origin.oddCoefficientEntry,
            name: this.extractLocalizedString(origin.oddName),
            // TODO where is it
            isFix: false,
            specialValue: origin.sbValue,
            oddId: origin.oddTypeId,
            sourceType: origin.sourceType,
            marketName: this.extractLocalizedString(origin.oddDescription)!,
        };
        e.market = {
            name: e.odd.marketName,
            marketId: origin.oddTypeId,
        };

        e.marketing = origin.marketing;
        e.eventId = origin.matchId;
        e.type = origin.eventType as EventType;
        e.inputCode = {
            event: origin.eCodeEvent,
            // not here
            market: '',
        };
        e.eventNo = origin.eventNo;
        e.status = origin.status as EventStatus;
        e.marketType = origin.marketType;

        return e;
    }
}
