<template>
    <div class="betslip-selection__list--terminal">
        <transition-group
            name="animation-list__slide-in-left-"
            tag="div"
            class="betslip-selection__list-wrapper--terminal"
        >
            <!-- Inverting betslip selections so the new selections are more visible, possible filter -->
            <div
                v-for="selection in selections.slice().reverse()"
                :key="selection.getOddUuid() || selection.getEventId()"
                class="betslip-selection__item--terminal"
            >
                <Selection
                    :selection="selection"
                    :error="selectionErrors[selection.getEventId()]"
                    :checkShowSbv="checkShowSbv"
                    :oddValueDynamics="oddValueDynamics"
                    @removeClick="onRemoveClick"
                />
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { shouldShowSpecialBetValue } from '@core/models/offer/Odd';
import Selection from './Selection.vue';

export default {
    name: 'SimpleBetSlip',
    props: {
        selections: {
            type: Array,
            required: true,
        },
        selectionErrors: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
        oddValueDynamics: {
            type: Object,
            required: false,
        },
    },
    computed: mapGetters('data/sportOffer', ['markets']),
    methods: {
        onRemoveClick(selection) {
            this.$emit('removeClick', selection);
        },
        checkShowSbv(marketId, isSpecial) {
            return shouldShowSpecialBetValue(marketId, this.markets, isSpecial);
        },
    },
    components: {
        Selection,
    },
};
</script>
