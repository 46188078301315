<template>
    <div class="offer__wrapper competition-container">
        <div :class="{ active: expandedEvent }" class="offer__container">
            <SectionHeader v-if="hasEvents" :icon="'icon-events'">
                <span>
                    {{ $t('events') | upperCase }}
                </span>
            </SectionHeader>
            <!-- 2x (SectionHeader + margin) This should maybe be dynamic-->
            <SportOfferEventGrid
                v-if="hasEvents"
                :events="filteredEvents"
                :showCategoryHeader="false"
                :showSectionHeader="false"
                :contentAbove="{ static: contentAboveHeight }"
                v-bind="scrollRelatedProps"
                @oddClick="onOddClick"
                @eventGridRender="$emit('eventGridRender')"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { tournamentName } from '@app/modules/eventGrid/util.ts';
import { getFlag } from '@src/data/flagMapping';
import { routeNames } from 'src/app/router';
import SectionHeader from '@shared/components/SectionHeader.vue';
import SportOfferEventGrid from '../../sharedSubmodules/SportOfferEventGrid.vue';

export default {
    name: 'CompetitionContainer',
    props: {
        scrollRelatedProps: {
            type: Object,
            required: true,
        },
    },
    created() {
        this.allOfferRoute = routeNames.landing;
    },
    computed: {
        ...mapGetters('data/sportOffer', ['isOfferFetched']),
        ...mapGetters('ui/sportOffer', ['expandedEvent']),
        ...mapGetters('ui/sportOffer/betSlip', ['selections']),
        ...mapGetters('ui/sportOffer/sports', ['filteredEvents']),
        ...mapGetters('navigation', ['route']),
        ...mapGetters('data/competitions', ['currentVirtualTournament']),
        availableTournamentIds() {
            return this.filteredEvents.map((event) => event.tournamentId);
        },
        competitionHeader() {
            if (this.currentVirtualTournament) {
                return this.currentVirtualTournament.name;
            }
            const tournamentId = this.availableTournamentIds[0];
            return tournamentId ? tournamentName(tournamentId) : null;
        },
        flag() {
            if (!this.filteredEvents.length) {
                return '';
            }
            const { categoryId, sportId } = this.filteredEvents[0];
            return getFlag(sportId, categoryId);
        },
        selectedDay() {
            return this.route.params.timeFilter || this.$t('today');
        },
        hasEvents() {
            return this.filteredEvents.length;
        },
        contentAboveHeight() {
            const height = 76;

            return height;
        },
    },
    methods: {
        ...mapActions('ui/modal', ['setModal']),
        ...mapActions('ui/sportOffer/betSlip', ['toggleAddRemoveSelection']),
        onOddClick({ eventId, odd }) {
            const { id: oddId, specialBetValue } = odd;

            this.toggleAddRemoveSelection({ eventId, oddId, specialBetValue });
        },
        onEventClick(eventId) {
            this.toggleExpandedEvent(eventId);
        },
    },
    components: {
        SportOfferEventGrid,
        SectionHeader,
    },
};
</script>
