<template>
    <div class="binary-switcher">
        <button :class="selectedClass(first)" class="btn binary-switcher__button" @click="onClick(first)">
            <span class="binary-switcher__button-text">
                {{ first.label }}
            </span>
        </button>
        <button :class="selectedClass(second)" class="btn binary-switcher__button" @click="onClick(second)">
            <span class="binary-switcher__button-text">
                {{ second.label }}
            </span>
        </button>
    </div>
</template>

<script>
const validateOption = (option = {}) => {
    const { label, value, disabled } = option;
    const isValid =
        (typeof label === 'string' || typeof label === 'number') &&
        value !== undefined &&
        (disabled === undefined || typeof disabled === 'boolean');
    return isValid;
};

export default {
    name: 'BinarySwitcher',
    props: {
        first: {
            type: Object,
            required: true,
            validator: validateOption,
        },
        second: {
            type: Object,
            required: true,
            validator: validateOption,
        },
        value: {
            type: [Object, String, Number, Boolean],
            default: null,
        },
    },
    methods: {
        onClick(option) {
            this.selected = option.value;
            this.$emit('change', option.value);
        },
        selectedClass(option) {
            return {
                'binary-switcher__button__selected': this.value === option.value,
            };
        },
    },
};
</script>
