<template>
    <MaintenanceChecker :checkedParts="checkedParts" class="w-full">
        <template slot="message">
            <div class="maintenance-mode__offer--terminal">
                <h2>{{ $t('Maintenance in progress') }}</h2>
            </div>
        </template>
        <div class="search__page flex w-full">
            <Sidebar />
            <OfferContainer class="main--terminal" :isFetcher="true">
                <SearchContainer />
            </OfferContainer>
            <div class="right-sidebar">
                <BetSlipV2 v-if="useDesignV2" />
                <BetSlipContainer v-else />
            </div>
        </div>
    </MaintenanceChecker>
</template>

<script lang="ts">
import SearchContainer from '@src/terminal/app/modules/sportOffer/search/SearchContainer.vue';
import { defineComponent } from 'vue';
import OfferContainer from '@src/terminal/app/modules/sportOffer/OfferContainer.vue';
import BetSlipContainer from '@src/terminal/app/modules/sportOffer/betslip/BetSlipContainer.vue';
import Sidebar from '@src/terminal/app/modules/sportOffer/search/sidebar/Sidebar.vue';
import { MaintenanceType } from '@core/models/country/enums';
import MaintenanceChecker from '@shared/components/MaintenanceChecker.vue';
import BetSlipV2 from '@src/v2/app/modules/sportOffer/betSlip/BetSlipV2.vue';
import { useVuexStore } from '@src/v2/app/utils/vuexStore';
import dataFlagsModule from '@src/terminal/store/flags';

export default defineComponent({
    name: 'SearchPage',
    setup() {
        const checkedParts = [MaintenanceType.sport];

        const dataFlagsStore = useVuexStore<typeof dataFlagsModule>('data/flags');
        const useDesignV2 = dataFlagsStore.getter('useDesignV2');

        return {
            checkedParts,
            useDesignV2,
        };
    },
    components: {
        SearchContainer,
        OfferContainer,
        BetSlipContainer,
        Sidebar,
        MaintenanceChecker,
        BetSlipV2,
    },
});
</script>
