import { Dispatch } from 'vuex';
// import config from '@config';

export default {
    namespaced: true,
    state: {},

    actions: {
        // TODO CLEANUP
        // eslint-disable-next-line
        showError({ dispatch }: { dispatch: Dispatch }, error: Error) {
            // if (!config.environment.isProduction) {
            // }
            // TODO: show error modal is not dispatched it will be replaced with gravity error notification
            // dispatch(
            //     'ui/modal/setModal',
            //     Modals.error.code,
            //     { root: true }
            // );
            // TODO just show visual error, something went wrong, try again later
        },
    },
};
