import { round } from '@lodash';
import i18n from '@src/app/localization/i18n';
import config from '@src/config';

export const twoDecimals = (value: number, shouldRound = true) => {
    if (shouldRound) {
        return i18n.n(value, 'decimal', config.numberLocale);
    }

    // rounding or not rounding only matters if the number has more decimal digits than wanted decimal places
    const hasMoreThanTwoDecimals = value - round(value, 2) !== 0;

    if (hasMoreThanTwoDecimals) {
        const unroundedNumber = Math.floor(value * 100) / 100;
        return i18n.n(unroundedNumber, 'decimal', config.numberLocale);
    }
    return i18n.n(value, 'decimal', config.numberLocale);
};

export const currency = (number: number, displaySymbol = false) => {
    if (displaySymbol) {
        if (config.locale === 'ro-RO') {
            return `${twoDecimals(number)} RON`;
        }

        return i18n.n(number, 'currency', config.locale);
    }

    return twoDecimals(number);
};
