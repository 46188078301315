import Singleton from '@core/services/common/Singleton';
import RestRequestFactory, { RestClient } from '@core/utils/network/RestRequestFactory';
import { instanceGuard } from '@core/utils/services';
import configService from '@core/services/configs/ConfigService';
import { VirtualTournament } from '../types';

class VirtualTournamentService {
    private request: RestClient;

    private static instance?: VirtualTournamentService;

    constructor() {
        this.request = RestRequestFactory.create('https://sports-offer.s3.eu-central-1.amazonaws.com');
    }

    async getVirtualTournaments(): Promise<VirtualTournament[]> {
        const virtualTournamentsEndpoint = (await configService.getConfig()).tickets.virtualTournamentsEndpoint || '';
        return await this.request.get(virtualTournamentsEndpoint);
    }

    public static getInstance(): VirtualTournamentService {
        return instanceGuard(VirtualTournamentService.instance);
    }

    public static createInstance() {
        if (!VirtualTournamentService.instance) {
            VirtualTournamentService.instance = new VirtualTournamentService();
        }

        return VirtualTournamentService.instance;
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }

        delete VirtualTournamentService.instance;
    }
}

export default VirtualTournamentService as Singleton<VirtualTournamentService>;
