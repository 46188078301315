import { UAParser } from 'ua-parser-js';

const uaParser = new UAParser();

const cachedMethods = ['getOS', 'getBrowser', 'getDevice'];
const cache = {
    getOS: undefined,
    getBrowser: undefined,
    getDevice: undefined,
    getDeviceType: undefined,
};

const cachedUAParser = new Proxy(uaParser, {
    get(obj: any, prop: 'getOS' | 'getBrowser' | 'getDevice' | 'getDeviceType') {
        if (prop in cachedMethods) {
            if (cache[prop] !== undefined) {
                return cache[prop];
            }
            return () => {
                const result = obj[prop]();
                cache[prop] = result;
                return result;
            };
        }
        return (<any>obj)[prop];
    },
});

const getOS = () => cachedUAParser.getOS();

export const getBrowser = () => cachedUAParser.getBrowser();

export const getDeviceType = () => getDevice().type;

export const getDevice = () => cachedUAParser.getDevice();

export const isSafari = () => getBrowser().name === 'Safari';

export const isMobileSafari = () => getBrowser().name === 'Mobile Safari';

export const isIOSChrome = () => getBrowser().name === 'Chrome' && getOS().name === 'iOS';

export const isMobile = () => getDeviceType() === 'mobile';

export const isDesktop = () => !isMobile();
