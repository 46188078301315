<template>
    <div>
        <slot v-if="isOfferReady" />
        <div v-if="isLoading || !isOfferReady" class="spinner-container--terminal">
            <SpinnerBars />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { errorGuard } from 'src/app/utils';
// import { routeNames } from 'src/app/router';
// import {
//     getDateToDayOfTheWeekSlug,
//     getLiveParam,
//     isToday,
//     isTomorrow,
// } from '@modules/shared/timeFilter/helpers';
// import slugify from '@core/utils/url/slugify';
import SpinnerBars from '@shared/components/SpinnerBars.vue';

export default {
    name: 'OfferContainer',
    props: {
        shouldFetchAll: {
            type: Boolean,
            default: true,
        },
        isFetcher: {
            type: Boolean,
            default: false,
        },
        isUrlRedirecter: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        isOfferReady(newVal, oldVal) {
            if (this.isFetcher && newVal && !oldVal) {
                if (!this.areSubscriptionsSet) {
                    this.subscribeToStructChanges();
                    this.subscribeToEventChanges();
                    this.subscribeToAllOutrightEventChanges();
                    this.setAreSubscriptionsSet(true);
                }

                if (!this.fetchedRequirements) {
                    this.fetchRequirements();
                }
            }
        },
        isOfferFetched() {
            // if (!this.isUrlRedirecter) {
            // }
            // This function is broken on current implementation as methods used don't exist anymore
            // and functionality is not clear. It is commented out to avoid errors.
            // const match = this.eventsMap[this.expandedEventId];
            // this.redirectToCorrectUrl(match, this.expandedEventId);
        },
    },
    created() {
        this.fetchRequirements();
    },
    computed: {
        ...mapGetters('data/sportOffer', [
            'isOfferReady',
            'isOfferFetched',
            'shouldFetchOffer',
            'areSubscriptionsSet',
            'eventsMap',
        ]),
        ...mapGetters('ui/sportOffer/sports', ['isLoading']),
        ...mapGetters('ui/sportOffer', ['expandedEventId']),
    },
    methods: {
        ...mapActions('data/sportOffer', [
            'fetchTopTenEvents',
            'fetchOutrights',
            'fetchSuperEvents',
            'subscribeToStructChanges',
            'subscribeToEventChanges',
            'subscribeToAllOutrightEventChanges',
            'subscribeToBetBuilderOddChange',
            'setShouldFetchOffer',
            'setAreSubscriptionsSet',
        ]),
        ...mapActions('ui/sportOffer/sports', ['loadOffer']),
        async fetchRequirements() {
            if (!this.isFetcher) {
                return;
            }

            await errorGuard({
                action: async () => {
                    if (this.shouldFetchAll && this.isOfferReady && this.shouldFetchOffer) {
                        await Promise.all([
                            this.loadOffer(),
                            this.fetchTopTenEvents(),
                            this.fetchSuperEvents(),
                            this.fetchOutrights(),
                        ]);
                        this.setShouldFetchOffer(false);
                        this.fetchedRequirements = true;
                    }
                },
            });
        },
        // redirectToCorrectUrl(match, eventId) {
        //     if (!match) {
        //         if (eventId) {
        //             this.removeQueryParamsFromUrl();
        //         }
        //         this.isRedirectionFinished = true;
        //         return;
        //     }

        //     const timeFilter = this.$route.params.timeFilter;

        //     if (match.isOngoing()) {
        //         const url = this.$router.resolve({
        //             name: routeNames.sportOffer,
        //             params: {
        //                 timeFilter: getLiveParam(),
        //             },
        //             query: {
        //                 ...this.$route.query
        //             }
        //         }).href;

        //         if (timeFilter !== slugify(this.$t('live'))) {
        //             // we need to note why are we doing it
        //             window.location = url;
        //         }
        //         return;
        //     }

        //     const day = getDateToDayOfTheWeekSlug(new Date(match.date));

        //     // assumes no time filter means today. That is the current behaviour
        //     if (day
        //         && timeFilter !== day
        //         && timeFilter !== undefined
        //         && !isToday(timeFilter)
        //         && !isTomorrow(timeFilter)
        //     ) {
        //         const url = this.$router.resolve({
        //             name: routeNames.sportOffer,
        //             params: {
        //                 ...this.$router.currentRoute.params,
        //                 timeFilter: day,
        //             },
        //             query: {
        //                 ...this.$route.query
        //             }
        //         }).href;

        //         window.location = url;
        //     }
        //     this.isRedirectionFinished = true;
        // },
    },
    components: {
        SpinnerBars,
    },
};
</script>
