export enum SelectionType {
    sport = 'sport',
    special = 'specials',
}

export enum BetSlipPurchaseType {
    online = 'online',
    offline = 'offline',
}

export enum BetSlipType {
    simple = 1,
    system = 2,
}

export enum SortOrder {
    asc = 'asc',
    desc = 'desc',
    unspecified = 'unspecified',
}

/**
 * Define all available rule types that can be sent in IEventDto mrls
 */
export enum RuleType {
    countRestrictions = 'special_count_restriction',
    prohibitionOfSameMarket = 'prohibition_of_same_class',
    prohibitWith = 'prohibit_with',
    checkMinStakeAmount = 'check_stake_amount',
    prohibitionOfSameMatch = 'prohibition_of_same_match',
    prohibitionOfSameEvent = 'prohibition_of_same_event',
}

/**
 *  6: M: match sport
 *   S: Specijal
 *   CLS: bet groupa (class) specijal
 *   CL: bet grupa sport
 *   E: u web slucaju isto match (event)
 *   ES: specijal
 *   T: mislim da je turnir
 */
export enum ProhibitionType {
    M = 'M',
    S = 'S',
    CLS = 'CLS',
    CL = 'CL',
    E = 'E',
    T = 'T',
    ES = 'ES',
}

export enum BetSlipErrorType {
    errorsInSelection = 'errorsInSelection',
    emptyBetSlip = 'emptyBetSlip',
    chooseTicketNegotiation = 'chooseTicketNegotiation',
    minimumBetSlipStakeError = 'minimumBetSlipStakeError',
    maximumBetSlipStakeError = 'maximumBetSlipStakeError',
    noSelectedSystemCombination = 'noSelectedSystemCombination',
    maxNumberOfSelections = 'maxNumberOfSelections',
    maxNumberOfFixedBalls = 'maxNumberOfFixedBalls',
    maxUnfixedEvents = 'maxUnfixedEvents',
    selectionIsLocked = 'selectionIsLocked',
    eventIsFinished = 'eventIsFinished',
    eventCantPlaceBets = 'eventCantPlaceBets',
    oddIsFinished = 'oddIsFinished',
    oddIsUnavailable = 'oddIsUnavailable',
    minimumStake = 'minimumStake',
    maximalStake = 'maximalStake',
    minimumStakeRaised = 'minimumStakeRaised',
    sameMarket = 'sameMarket',
    sameMatch = 'sameMatch',
    cantCombineEvents = 'cantCombineEvents',
    cantCombineMarkets = 'cantCombineMarkets',
    cantCombinePromotionalMarkets = 'cantCombinePromotionalMarkets',
    minSystemToPlay = 'minSystemToPlay',
    cantCombine = 'cantCombine',
    addMoreFromSameMarket = 'addMoreFromSameMarket',
    cantAddMoreFromThisMarket = 'cantAddMoreFromThisMarket',
    putMoreOdds = 'putMoreOdds',
    addMoreFromOtherMarket = 'addMoreFromOtherMarket',
    notEnoughSuperSix = 'notEnoughSuperSix',
    selectionIsUnavailable = 'selectionIsUnavailable',
    maxWinAmount = 'maxWinAmount',
    minWinAmount = 'minWinAmount',
    minStakePerCombination = 'minStakePerCombination',
    swapPicksPending = 'swapPicksPending',
    swapPicksSuccess = 'swapPicksSuccess',
    swapPicksFailed = 'swapPicksFailed',
    RetailEventStarted = 'RetailEventStarted',
}
