import { compose } from '@lodash/fp';
import {
    addDays,
    addYears,
    addMinutes,
    addSeconds,
    format as formatDate,
    isToday,
    isTomorrow,
    subDays,
    startOfDay,
    addHours,
    isBefore,
    differenceInCalendarDays,
    differenceInHours,
    min,
    max,
    differenceInMilliseconds,
    differenceInMinutes,
    isEqual,
} from 'date-fns';
import { fSetHours, fSetMinutes, fSetSeconds, fAddDays, fAddMinutes, fAddHours } from '@core/utils/functional/Date';
import config from '@config';
import IDuration from '../types/IDuration';

export default class DateUtils {
    static timeZoneOffset = new Date().getTimezoneOffset();

    // Add format option if it's used anywhere in the app
    // Replace with date-fns equivalent when it's out
    static toDate(string: string): Date {
        return new Date(string);
    }

    /**
     *
     * Be careful with this method as Javascript formats dates as local dates (even though
     * internal representation is UTC).
     *
     */
    static toString(date: Date, format = config.format.date, options = { UTC: true }): string {
        return formatDate(date, format);
    }

    static toStartOfDay(date: Date) {
        return startOfDay(date);
    }

    static addMinutes(minutes: number, date = new Date()): Date {
        return addMinutes(date, minutes);
    }

    static addDays(days: number, date = new Date()): Date {
        return addDays(date, days);
    }

    static addYears(years: number, date = new Date()): Date {
        return addYears(date, years);
    }

    static addHours(hours: number, date = new Date()): Date {
        return addHours(date, hours);
    }

    static addSeconds = addSeconds;

    static isBefore(date: Date, compareDate: Date): boolean {
        return isBefore(date, compareDate);
    }

    /**
     * @deprecated
     * @param first date
     * @param second date
     */
    static differenceInDays(first: Date, second: Date): number {
        return Math.abs(differenceInCalendarDays(first, second));
    }

    static differenceInCalendarDays(first: Date, second: Date): number {
        return Math.abs(differenceInCalendarDays(first, second));
    }

    static differenceInHours = differenceInHours;

    static differenceInMinutes = differenceInMinutes;

    static differenceInMilliseconds = differenceInMilliseconds;

    static subtractDays(days: number, startDate = new Date()): Date {
        return subDays(startDate, days);
    }

    static getDayOfWeek(date: Date): string {
        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

        return days[date.getDay()];
    }

    /**
     * 1. If input day is 1 (1 = monday) and fromDate is thursday it will return next week monday
     * 2. If input day is 1 (1 = monday) and fromDate is monday it will return that monday
     */
    static getNextNearestDay(day: number, fromDate = new Date()): Date {
        let days;

        if (fromDate.getDay() <= day) {
            days = day - fromDate.getDay();
        } else {
            days = 7 - (fromDate.getDay() - day);
        }

        return DateUtils.addDays(days, fromDate);
    }

    static isToday(date: Date) {
        return isToday(date);
    }

    static isTomorrow(date: Date) {
        return isTomorrow(date);
    }

    static isEqual(date1: Date, date2: Date) {
        return isEqual(date1, date2);
    }

    static isSameDate(firstDate: Date, secondDate: Date) {
        return (
            firstDate.getDate() === secondDate.getDate() &&
            firstDate.getMonth() === secondDate.getMonth() &&
            firstDate.getFullYear() === secondDate.getFullYear()
        );
    }

    /**
     * Method will take some date (local representation) and will show that date in UTC time.
     *
     * For example:
     *  input: 10.5.2018 01:00:00 +2000
     *  output: 09.5.2018 23:00:00 +2000
     *
     * See +2000 in output!  Although output shows date in UTC time, output is local representation of UTC time,
     * so real UTC date is now 09.5.2018 21:00:00 +0000
     *
     * Use this method *only* for formatting purposes!
     *
     */
    static inUtcTime(date: Date) {
        return new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds(),
        );
    }

    static getDatesArray(offset: number, currentDate: Date = new Date()): Date[] {
        const dateArray: Date[] = [];
        let date = subDays(currentDate, offset);
        date = fSetHours(date, 0);
        date = fSetMinutes(date, 0);
        date = fSetSeconds(date, 0);
        /* eslint-disable */
        let startDate = fSetHours(currentDate, 0);
        startDate = fSetMinutes(startDate, 0);
        startDate = fSetSeconds(startDate, 0);
        while (date.getTime() < currentDate.getTime()) {
            dateArray.push(date);
            date = addDays(date, 1);
        }
        /* eslint-enable */
        return dateArray;
    }

    static minDate(...dates: Date[]) {
        return min(...dates);
    }

    static maxDate(...dates: Date[]) {
        return max(...dates);
    }

    static isTimezonelessFormat(dateString: string) {
        const regex = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        return regex.test(dateString);
    }

    static fillToUTCDate(dateString: string) {
        let utcDateString = dateString;
        if (DateUtils.isTimezonelessFormat(dateString)) {
            const [date, time] = dateString.split(' ');
            utcDateString = `${date}T${time}Z`;
        } else {
            // TODO we need to handle this if we know it can occur
        }
        return utcDateString;
    }

    static isValidDate(d: any) {
        return d instanceof Date && Number.isFinite(d.getTime());
    }

    static isDateBetweenInclusive(start: Date, end: Date, current: Date): boolean {
        return start <= current && end >= current;
    }

    static addDuration(date: Date, duration: IDuration) {
        const { hours = 0, days = 0, minutes = 0 } = duration;
        return compose([fAddDays(days), fAddHours(hours), fAddMinutes(minutes)])(date);
    }
}
