<template>
    <div :class="['check-ticket-container', checkTicketClass]">
        <div v-if="data" v-sticky="stickyConfig" class="check-ticket">
            <TicketOverviewV2 :ticket="data.ticket" />
            <div class="check-ticket__footer">
                <div v-if="data.ticket.bonus" class="check-ticket__footer__bonus">
                    <span class="check-ticket__footer__bonus__percentage"> {{ data.ticket.bonus.percentage }}% </span>
                    <span class="check-ticket__footer__bonus__label">
                        {{ $t('superbonusIncluded') }} ({{ data.ticket.bonus.value | twoDecimals }}
                        {{ $t('currencyAbbreviation') }})
                    </span>
                </div>
                <TicketPaymentV2 :ticket="data.ticket" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import PlatformService from '@src/terminal/core/services/platform/PlatformService';
import TicketService from '@core/services/tickets/TicketService';
import { TicketStatus, EventStatus, MarketType } from '@models/tickets/enums';
import TicketOverviewV2 from '@src/v2/app/modules/checkTicket/TicketOverviewV2.vue';
import TicketPaymentV2 from '@src/v2/app/modules/checkTicket/TicketPaymentV2.vue';
import countryModule from '@store/modules/data/country/countryIndex';
import Ticket, { EventCondensed, TicketData } from '@core/models/tickets/Ticket';
import CheckTicketMapper from '@core/mappers/tickets/CheckTicketMapper';
import GetTicketDto from '@core/mappers/tickets/dtos/GetTicketDto';
import { useVuexStore } from '../../utils/vuexStore';

const uniqueId = () => `${Date.now().toString(36)}${Math.random().toString(36).slice(2)}`;

export default defineComponent({
    name: 'CheckTicketNsoftV2',
    components: {
        TicketOverviewV2,
        TicketPaymentV2,
    },
    setup() {
        const data = ref<TicketData | null>(null);
        const checkTicketInstance = ref(uniqueId());

        const stickyConfig = {
            scrollableContentSelector: '.check-ticket__body__selections',
            staticLowerContentSelector: '.check-ticket__footer',
            staticHigherContentSelector: '.check-ticket__status, .ticket-status',
        };

        const countryStore = useVuexStore<typeof countryModule>('data/country');
        const checkedTicket = countryStore.getter('checkedTicket');

        const checkTicketClass = computed(() => {
            switch (data.value?.ticket.status) {
                case TicketStatus.payed:
                case TicketStatus.win:
                case TicketStatus.refund:
                    return 'check-ticket-container--win';
                case TicketStatus.active:
                    return 'check-ticket-container--active';
                default:
                    return 'check-ticket-container--lost';
            }
        });

        const parseData = (originalId: string, ticket: Partial<Ticket>) => {
            console.log('parsing data for instance', ticket.ticketId, checkTicketInstance.value);
            const productId = 'SuperbetPrematch';
            const actions = {
                rebet: { active: isRebetAvailable(ticket) },
                cancel: { active: isCancelAvailable(ticket) },
                active: { active: false },
            };
            const slip = {
                productDisplayId: productId,
                codes: [
                    {
                        type: 'code39',
                        id: originalId,
                    },
                ],
            };
            const message = {
                msgSender: 'Slave',
                slaveId: productId,
                action: 'Tickets.UpdateTicketCheckState',
                data: {
                    ticket: { slip },
                    actions,
                },
            };
            console.log('SSBT::NSoft ticket check message obj', message);
            data.value = {
                ticket: ticket as Ticket,
                actions,
            };
            console.log('SSBT::Check ticket data', data.value);
            PlatformService.getInstance().postMessage(message, window.document.origin);
        };

        const isRebetAvailable = (ticket: Partial<Ticket>) => {
            return ticket.events?.some((event) => event.status === EventStatus.active) || false;
        };

        const areSomeEventsStarted = (events: EventCondensed[]) => {
            return events.some((event) => {
                if (!event.date) return false;

                const now = new Date();
                const startDate = new Date(event.date);
                return startDate < now;
            });
        };

        const isCancelAvailable = (ticket: Partial<Ticket>) => {
            if (ticket.isTemplate) return false;
            const nonCancelableStatuses = [
                TicketStatus.win,
                TicketStatus.canceled,
                TicketStatus.lost,
                TicketStatus.payed,
                TicketStatus.refund,
            ];
            if (!ticket.status || nonCancelableStatuses.includes(ticket.status)) {
                return false;
            }
            if (!ticket.events || areSomeEventsStarted(ticket.events)) {
                return false;
            }
            if (ticket.events.some((event) => event.marketType === MarketType.IN_PLAY)) {
                return false;
            }
            if (!ticket.dateReceived) return false;

            const now = new Date();
            const cancelLimit = new Date(ticket.dateReceived);
            const cancelTimeout = 60 * 15;
            cancelLimit.setSeconds(cancelLimit.getSeconds() + cancelTimeout);
            return now.getTime() < cancelLimit.getTime();
        };

        watch(checkedTicket, async ({ id }) => {
            if (id) {
                const mapper = new CheckTicketMapper();
                const prepareData = (ticket: GetTicketDto) => {
                    const ticketData = mapper.map(ticket);
                    parseData(id, ticketData);
                };
                const data = await TicketService.getInstance().getTicketById(id);
                prepareData(data);
                TicketService.getInstance().subscribeToTicketById(id, prepareData);
            }
        });

        return {
            data,
            checkTicketClass,
            stickyConfig,
        };
    },
});
</script>
