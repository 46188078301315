<template>
    <div class="betslip-changes__wrapper betslip-changes__wrapper--negotiation">
        <div class="betslip-changes__title">
            {{ $t('bookie changed the bet, do you accept?') | capitalize }}
        </div>
        <ul class="list--unstyled betslip-changes__event-list">
            <li v-for="(odd, index) in ticketNegotiation.odds" :key="index" class="betslip-changes__change-item">
                <div class="betslip-changes__details">
                    <span class="betslip-changes__event">
                        {{ getDisplayName(odd.matchId, odd.oddId) }}
                    </span>
                    <span class="betslip-changes__message">
                        <span class="betslip-changes__value old">
                            {{ odd.betSlipOdd | twoDecimals }}
                        </span>
                        <span class="betslip-changes__value new">
                            {{ odd.bookieOdd | twoDecimals }}
                        </span>
                    </span>
                </div>
            </li>
        </ul>
        <div v-if="shouldShownStake" class="betslip-changes__stake">
            <span class="betslip-changes__stake-label"> {{ $t('stake') | capitalize }}: </span>
            <span class="betslip-changes__stake-amounts">
                <span class="betslip-changes__stake-amount-old">
                    {{ ticketNegotiation.betSlipStake | twoDecimals }}
                </span>
                <i class="icon icon--sm icon-arrow_right" />
                <span class="betslip-changes__stake-amount-new">
                    {{ ticketNegotiation.bookieStake | twoDecimals }}
                </span>
                <span class="ticket-stack__item--currency">
                    {{ $t('currencyAbbreviation') }}
                </span>
            </span>
        </div>
        <div class="betslip__potential-gain">
            <div class="betslip__potential-gain-simple">
                <span class="betslip__potential-gain-label"> {{ $t('new potential win') | capitalize }}: </span>
                <span class="betslip__potential-gain-amount">
                    {{ ticketNegotiation.potentialWin | twoDecimals }}
                    <span class="ticket-stack__item--currency">
                        {{ $t('currencyAbbreviation') }}
                    </span>
                </span>
            </div>
        </div>
        <div class="betslip-changes__actions betslip-changes__actions--negotiation">
            <span class="betslip-changes__decisions">
                <button class="btn btn__secondary" @click="$emit('decline')">
                    {{ $t('decline') | upperCase }}
                </button>
                <button class="btn btn__primary" @click="$emit('accept')">
                    {{ $t('accept') | upperCase }}
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import Helpers from '@store/modules/ui/shared/betSlip/Helpers';
import { SelectionType } from '@models/shared/betSlip/betSlipEnums';
import { twoDecimals } from 'src/app/modules/shared/filters';

export default {
    name: 'TicketNegotiation',
    props: {
        ticketNegotiation: {
            type: Object,
            required: true,
        },
        selections: {
            type: Array,
            required: true,
        },
    },
    computed: {
        shouldShownStake() {
            return twoDecimals(this.ticketNegotiation.betSlipStake) !== twoDecimals(this.ticketNegotiation.bookieStake);
        },
    },
    created() {
        this.twoDecimals = twoDecimals;
    },
    methods: {
        getDisplayName(matchId, oddId) {
            const selection = Helpers.findSelectionByEventId(
                this.selections,
                parseInt(matchId, 10),
                SelectionType.sport,
            );
            if (!selection) {
                this.$emit('error', new Error(`${matchId} - ${oddId}`));
                return '';
            }
            return selection.negotiationName;
        },
    },
};
</script>
