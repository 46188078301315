<template>
    <EventGridContainerV2
        v-if="useDesignV2"
        :events="events"
        :promotions="promotions"
        :categoryConfig="categoryConfig"
        :renderDone="renderDone"
        :areEventsLoaded="areEventsLoaded"
        :filterChangeIndicator="filterChangeIndicator"
        :showSectionHeader="showSectionHeader"
        :sidebarChangedState="sidebarChangedState"
        v-bind="gridProps"
        @eventGridRender="$emit('eventGridRender')"
        v-on="commonEventHandlers"
    />
    <EventGridContainer
        v-else
        :events="events"
        :promotions="promotions"
        :categoryConfig="categoryConfig"
        :renderDone="renderDone"
        :areEventsLoaded="areEventsLoaded"
        :filterChangeIndicator="filterChangeIndicator"
        :showSectionHeader="showSectionHeader"
        :sidebarChangedState="sidebarChangedState"
        v-bind="gridProps"
        @eventGridRender="$emit('eventGridRender')"
        v-on="commonEventHandlers"
    />
</template>

<script>
import { isEmpty as _isEmpty } from '@lodash';
import { mapActions, mapGetters } from 'vuex';
import EventGridContainer from '@src/terminal/app/modules/eventGrid/EventGridContainer.vue';
import EventGridContainerV2 from '@src/v2/app/modules/eventGridV2/EventGridContainerV2.vue';
import { ExpandedEventInUrl } from '@app/enums';
import Helpers from '@store/modules/ui/shared/betSlip/Helpers';
import { SelectionType } from '@models/shared/betSlip/betSlipEnums';

export default {
    name: 'SportOfferEventGrid',
    props: {
        events: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        promotions: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        categoryConfig: {
            type: Object,
            required: false,
            default: null,
        },
        showCategoryHeader: {
            type: Boolean,
            default: true,
        },
        showSectionHeader: {
            type: Boolean,
            default: true,
        },
        renderDone: {
            type: Boolean,
            required: true,
        },
        areEventsLoaded: {
            type: Boolean,
            required: true,
        },
        filterChangeIndicator: {
            type: Number,
            required: true,
        },
        sidebarChangedState: {
            type: Boolean,
            required: true,
        },
        disableAutoScroll: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        expandedEventId(newValue, oldValue) {
            this.performActionOnToggleExpandedEvent(oldValue);

            const eventId = this.expandedEventId;
            const match = this.eventsMap[eventId];

            if (match) {
                if (match.containsFullOddList()) {
                    this.addEventFromUrlToBetSlip(match);
                } else {
                    const unwatch = this.$watch(
                        () => this.eventsMap[eventId].containsFullOddList(),
                        function () {
                            this.addEventFromUrlToBetSlip(this.eventsMap[eventId]);
                            unwatch();
                        },
                    );
                }
            }
        },
    },
    computed: {
        ...mapGetters('ui/sportOffer/betSlip', ['selections']),
        ...mapGetters('data/sportOffer', ['eventsMap']),
        // used for grouping events
        ...mapGetters('data/sportOffer', ['tournaments', 'markets', 'sports']),
        // used for state shared among all eventGrids
        ...mapGetters('ui/sportOffer', ['expandedEventId']),
        ...mapGetters('ui/sportOffer/sports', ['timeFilter']),
        ...mapGetters('data/flags', ['useDesignV2']),
        gridProps() {
            return {
                showCategoryHeader: this.showCategoryHeader,
                disableAutoScroll: this.disableAutoScroll,
            };
        },
        commonEventHandlers() {
            return {
                oddClick: (...args) => this.$emit('oddClick', ...args),
                eventClick: ({ eventId, typeId }) => {
                    this.toggleExpandedEvent({ eventId, typeId });
                },
            };
        },
    },
    methods: {
        ...mapActions('ui/sportOffer/betSlip', ['toggleAddRemoveSelection']),
        ...mapActions('ui/sportOffer', [
            'cancelEventSubscriptions',
            'setActiveStream',
            'toggleExpandedEvent',
            'toggleExpandedEventSubscription',
        ]),
        ...mapActions('ui/sportOffer/sports', ['setIsLoading']),
        performActionOnToggleExpandedEvent(oldEventId) {
            const eventId = this.expandedEventId;

            this.toggleExpandedEventSubscription({
                eventId,
                oldEventId,
            });
        },
        addEventFromUrlToBetSlip(match) {
            if (!match) {
                return;
            }

            const { [ExpandedEventInUrl.odd]: oddId, [ExpandedEventInUrl.sbValue]: specialBetValue = '' } =
                this.$route.query;

            if (oddId) {
                const selection = Helpers.findSelectionByEventId(this.selections, match.id, SelectionType.sport);

                if (
                    selection &&
                    selection.getOddId().toString() === oddId &&
                    _isEmpty(selection.getOddSpecialBetValue()) === _isEmpty(specialBetValue)
                ) {
                    return;
                }

                this.toggleAddRemoveSelection({
                    eventId: match.id,
                    oddId: parseInt(oddId, 10),
                    specialBetValue,
                });
            }
        },
        removeQueryParamsFromUrl() {
            this.$router.replace({
                path: this.$route.path,
            });
        },
    },
    beforeDestroy() {
        this.cancelEventSubscriptions();
    },
    components: {
        EventGridContainer,
        EventGridContainerV2,
    },
};
</script>
