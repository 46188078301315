import { Store } from 'vuex';
import { filter as _filter } from '@lodash';
import { IState } from '@app/store';
import Ticket from '@core/models/tickets/Ticket';
import { TicketStatus, TicketType, EventStatus, EventType } from '@core/models/tickets/enums';
import Match from '@core/models/offer/Match';

export function loadLiveEvents(store: Store<IState>) {
    const matchIds = new Set<string>();

    /*
        Loads events which aren't already loaded
    */
    const fetchEvents = () => {
        const eventsMap = store.getters['data/sportOffer/eventsMap'] as Record<string, Match>;

        const matchesToLoad: string[] = [];
        matchIds.forEach((id) => {
            if (!eventsMap[id]) {
                matchesToLoad.push(id);
            }
        });

        store.dispatch('data/sportOffer/fetchEventsByIds', matchesToLoad);
        matchIds.clear();
    };

    // fetches events if there are any after the offer service is ready
    const unsubscribeFromOfferStart = store.subscribeAction(({ type }) => {
        if (type === 'data/sportOffer/setIsOfferReady') {
            unsubscribeFromOfferStart();
            fetchEvents();
        }
    });

    /*
        Watches all tickets and from the active ones
        extracts active live events
        If the offer is ready (socket) dispatches an action
        to load them
    */
    const unsubscribeFromLoadingEvents = store.watch(
        (state) => state.data.tickets.tickets,
        (tickets) => {
            const activeSportTickets = _filter(
                tickets,
                (t: Ticket) => t.status === TicketStatus.active && t.type === TicketType.sport,
            );

            activeSportTickets.forEach((t) => {
                t.events.forEach((e) => {
                    if (e.status === EventStatus.active && e.type === EventType.live) {
                        matchIds.add(e.eventId);
                    }
                });
            });

            if (store.getters['data/sportOffer/isOfferReady']) {
                fetchEvents();
            }
        },
    );
    return unsubscribeFromLoadingEvents;
}
