import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import System from '@core/models/betSlip/System';
import IBetSlip from '@models/betSlip/IBetSlip';

export default class CombinationsMapper extends AbstractMapper<IBetSlip, any[]> {
    // new API
    // createTargetObject(origin: BetSlip) {
    //     const systems = origin.getSelectedSystems();
    //     return systems.map((system: System) =>
    //         `${system.getMinNumber()}\/${system.getFromNumber()}`);
    // }

    // old API
    createTargetObject(betSlip: IBetSlip) {
        const systems = betSlip.selectedSystems;
        return systems.map((system: System) => `${system.getMinNumber()}/${system.getFromNumber()}`);
    }
}

// todo new old API?
