// extendable keyboard layouts object
import i18n from '@app/localization/i18n';

export default {
    normal: {
        _meta: {
            tab: {
                key: '\t',
                text: 'Tab',
                width: 60,
                classes: 'control',
            },
            shiftl: {
                keySet: 'shifted',
                text: 'Shift',
                width: 100,
                classes: 'control',
            },
            shiftr: {
                keySet: 'shifted',
                text: 'Shift',
                width: 100,
                classes: 'control',
            },
            caps: {
                keySet: 'capsed',
                text: '⇪',
                width: 40,
                classes: 'lighter',
            },
            space: {
                key: ' ',
                text: 'Space',
                width: 120,
                classes: 'space-bracket',
            },
            backspace: {
                func: 'backspace',
                text: '',
                classes: 'control backspace lighter',
                width: 65,
            },
            accept: {
                func: 'accept',
                text: '→',
                classes: 'control featured arrow-right',
                width: 40,
            },
            next: {
                func: 'next',
                text: 'Next',
                classes: 'control featured',
            },
            close: {
                func: 'cancel',
                text: i18n.t('close'),
                classes: 'control featured',
                width: 60,
            },
        },
        default: [
            '1 2 3 4 5 6 7 8 9 0',
            'q w e r t y u i o p',
            'a s d f g h j k l @',
            '{caps} z x c v b n m {backspace}',
            '. {space} _ {accept}',
        ],
        shifted: [
            '! + # $ % ^ & * ( )',
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            '{caps} Z X C V B N M {backspace}',
            ', {space} - {accept}',
        ],
        capsed: [
            '! + # $ % ^ & * ( )',
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            '{caps} Z X C V B N M {backspace}',
            ', {space} - {accept}',
        ],
    },
    numeric: {
        _meta: {
            backspace: {
                func: 'backspace',
                text: '',
                classes: 'control',
            },
            accept: {
                func: 'accept',
                text: 'OK',
                classes: 'control featured',
                width: '66',
            },
            clear: {
                func: 'cancel',
                text: 'DEL',
                classes: 'control',
                width: '32',
            },
            zero: { key: '0' },
        },
        default: ['1 2 3', '4 5 6', '7 8 9', '. {zero} {backspace}', '{clear} {accept}'],
    },
};
