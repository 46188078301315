import Match from '@core/models/offer/Match';
import config from '@config';

if (!config.environment.isTest) {
    (window as any).befores = [];
}

export const mergeMatch = (matchBefore: Readonly<Match>, matchAfter: Match) => {
    // we receive event update data
    // locally we have the latest event data
    // only when we fetch event we get the full data and store it locally
    if (matchBefore.matchTimestamp === matchAfter.matchTimestamp) {
        // both local data and fetched data are full (all odds), return fetched data
        if (matchBefore.containsFullOddList() && matchAfter.containsFullOddList()) {
            return Object.freeze(matchAfter);
        }

        // local data is full and updated, return local data
        if (matchBefore.containsFullOddList()) {
            return matchBefore;
        }

        // fetched data is full, replace local with fetch data
        // local had only final market
        if (matchAfter.containsFullOddList()) {
            return Object.freeze(matchAfter);

            // neither data is full, update local data with fetched data, returned merged data
            // this case covers when there is update on final markets
        }
        const afterOdds = matchAfter.getOdds();
        const beforeOdds = matchBefore.getOdds().filter((o1) => !afterOdds.some((o2) => o1.id === o2.id));

        matchAfter.setOdds([...afterOdds, ...beforeOdds]);

        return Object.freeze(matchAfter);
    }

    if (matchAfter.matchTimestamp! > matchBefore.matchTimestamp!) {
        // legacy part from web, this only updates the final market if local data is full
        if (matchBefore.containsFullOddList() && !matchAfter.containsFullOddList()) {
            const afterOdds = matchAfter.getOdds();

            let beforeOdds = matchBefore.getOdds().filter((o1) => !afterOdds.some((o2) => o1.id === o2.id));

            // check if fetched data doesnt include final market
            // filter out final/main market if its no longer in offer (eg. it got disabled)
            if (!matchAfter.getPrematchMarket()) {
                beforeOdds = beforeOdds.filter((odd) => !odd.isMain());
            }

            // merge fetched and local data
            matchAfter.setOdds([...afterOdds, ...beforeOdds]);
        }

        return Object.freeze(matchAfter);
    }

    return matchBefore;
};

export const promoteSpecialTournamentToCategory = (match: Match, specialTournamentMap: any) => {
    if (specialTournamentMap[match.tournamentId]) {
        match.categoryId = specialTournamentMap[match.tournamentId].categoryId;
        match.categoryName = specialTournamentMap[match.tournamentId].name;
    }
    return match;
};
