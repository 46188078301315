<template>
    <Modal
        v-if="modal"
        :code="modal.code"
        :title="modal.title"
        :containerClass="modal.containerClass"
        :overlayClass="modal.overlayClass"
        :modalComponent="modalComponent"
        :setModal="setModal"
        :closeModal="onClose"
        :isIndependent="modal.isIndependent"
        @close="onClose"
    />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import availableModals from '@countryModals/availableModals';
import { availableModalsV2 } from '@src/app/modals/availableModals';
import Modal from './Modal.vue';

export default {
    name: 'ModalContainer',
    computed: {
        ...mapGetters('ui/modal', ['modal']),
        ...mapGetters('data/flags', ['useDesignV2']),
        modalComponent() {
            const modals = this.useDesignV2 ? availableModalsV2 : availableModals;

            const modal = modals[this.modal.code];

            if (!modal) {
                throw Error(`No such modal code: ${this.modal.code}! Check ModalContainer.vue`);
            }

            return modal;
        },
    },
    methods: {
        ...mapActions('ui/modal', ['setModal', 'closeModal', 'closeAllModals']),
        onClose() {
            const afterClose = this.modal.afterClose;
            if (this.modal.goBack) {
                this.closeModal();
            } else {
                this.closeAllModals();
            }

            if (afterClose) {
                afterClose(this.$store.dispatch);
            }
        },
    },
    components: {
        Modal,
    },
};
</script>
