<template>
    <div v-if="isOfferReady" class="filters">
        <TimeFilterContainerV2 class="filters--day" :filters="dayFilters" :isTodaySelected="isTodaySelected" />
        <TimeFilterContainerV2 v-if="isTodaySelected" class="filters--time" :filters="timeFilters" />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useVuexStore } from '@src/v2/app/utils/vuexStore';
import TimeFilterContainerV2 from '@src/v2/app/modules/sportOffer/sports/timeFilter/TimeFilterContainerV2.vue';
import { TimeFilter } from '@src/terminal/app/modules/shared/timeFilter/enums';
import { getTimeFilterFromSlug, getTimeFilterForWeekDay } from '@src/terminal/app/modules/shared/timeFilter/helpers';
import { DateTimeFilter } from '@src/v2/core/models/DateTimeFilter';
import sportOfferModule from '@store/modules/ui/sportOffer/sportOfferIndex';
import flagModule from '@src/terminal/store/flags';
import { useRoute } from 'vue-router/composables';

export default defineComponent({
    components: {
        TimeFilterContainerV2,
    },
    name: 'SportsTimeFilterContainerV2',
    setup() {
        const dayFilters = ref<DateTimeFilter>([
            { id: TimeFilter.all },
            { id: TimeFilter.today },
            { id: TimeFilter.tomorrow },
        ]);
        const timeFilters = ref<DateTimeFilter>([{ id: TimeFilter.allToday }]);

        const sportOfferStore = useVuexStore<typeof sportOfferModule>('data/sportOffer');
        const isOfferReady = sportOfferStore.getter('isOfferReady');

        const flagStore = useVuexStore<typeof flagModule>('data/flags');
        const isInPlayAvailable = flagStore.getter('isInPlayAvailable');

        const route = useRoute();

        if (isInPlayAvailable.value) {
            timeFilters.value.push(
                {
                    id: TimeFilter.prematch,
                },
                {
                    id: TimeFilter.live,
                    icon: 'time-live',
                },
            );
        }

        timeFilters.value.push(
            {
                id: TimeFilter.upcoming,
            },
            {
                id: TimeFilter.next3H,
            },
            {
                id: TimeFilter.next6H,
            },
            {
                id: TimeFilter.next12H,
            },
        );

        const todayDayIndex = new Date().getDay();
        for (let i = todayDayIndex + 2; i < todayDayIndex + 7; i += 1) {
            dayFilters.value.push({
                id: getTimeFilterForWeekDay((i + 7) % 7),
            });
        }

        const isTodaySelected = computed(() => {
            const slug = route.params.timeFilter;
            const currentFilter = slug ? getTimeFilterFromSlug(slug) : TimeFilter.today;

            return (
                currentFilter === TimeFilter.today || !!timeFilters.value.find((filter) => filter.id === currentFilter)
            );
        });

        watch(isInPlayAvailable, (enabled) => {
            if (enabled) {
                timeFilters.value.splice(
                    1,
                    0,
                    {
                        id: TimeFilter.prematch,
                    },
                    {
                        id: TimeFilter.live,
                        icon: 'time-live',
                    },
                );
            } else {
                timeFilters.value.splice(1, 2);
            }
        });

        return {
            isTodaySelected,
            dayFilters,
            timeFilters,
            isOfferReady,
        };
    },
});
</script>
