import { ITask } from '@src/terminal/core/services/gravity/_private/Task';
import store from '@app/store';
import router, { routeNames } from '@src/app/router';
import { get as _get } from '@lodash';

class TicketCheckTask implements ITask {
    execute = (message?: any): void => {
        const id = _get(message, 'data.ticket.slip.codes[0].id', null);
        store.dispatch('data/country/setCheckedTicket', { id, timeChecked: Date.now() }, { root: true });
        router.push({ name: routeNames.checkTicket });
    };
}

export default TicketCheckTask;
