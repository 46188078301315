<template functional>
    <div :title="props.content" class="group-sport-header__footer">
        {{ props.content }}
    </div>
</template>

<script>
export default {
    name: 'SectionFooterRow',
    functional: true,
    props: {
        content: {
            type: String,
            required: true,
        },
    },
};
</script>
