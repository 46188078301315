<template>
    <div>
        <TimeFilterItemV2
            v-for="(filter, index) in filters"
            :key="index"
            :timeFilterId="filter.id"
            :icon="filter.icon"
            :isSelected="isSelected(filter)"
            @click="onFilterClick"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import TimeFilterItemV2 from '@src/v2/app/modules/sportOffer/sports/timeFilter/TimeFilterItemV2.vue';
import { TimeFilter } from '@src/terminal/app/modules/shared/timeFilter/enums';
import { DateTimeFilter } from '@src/v2/core/models/DateTimeFilter';

export default defineComponent({
    components: { TimeFilterItemV2 },
    emits: ['click'],
    name: 'TimeFilterV2',
    props: {
        filters: {
            type: Array as PropType<DateTimeFilter>,
            required: true,
        },
        selectedFilter: {
            type: String as PropType<TimeFilter>,
            required: true,
        },
        isTodaySelected: Boolean,
    },
    setup(props, { emit }) {
        const isSelected = (filter: DateTimeFilter[number]) => {
            return (props.isTodaySelected && filter.id === TimeFilter.today) || filter.id === props.selectedFilter;
        };

        const onFilterClick = (filter: TimeFilter) => {
            emit('click', filter);
        };

        return {
            isSelected,
            onFilterClick,
        };
    },
});
</script>
