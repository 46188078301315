<template>
    <transition appear name="animation-slide-in-left" tag="div">
        <div
            ref="dragElement"
            @mousedown="onStartDrag"
            @mousemove="onDrag"
            @mouseup="onEndDrag"
            @touchstart="onStartDrag"
            @touchmove="onDrag"
            @touchend="onEndDrag"
            @click="openEvent"
        >
            <div :class="['selection-item', { error: error, dragging: isDragging, expanded: isEventExpanded }]">
                <div class="selection-item__header">
                    {{ getTeamName }} {{ event.team2Name ? `- ${event.team2Name}` : '' }}
                    <i v-if="error" class="selection-item__header--error sds-icon-status-danger-filled" />
                </div>
                <template v-if="event.isOngoing()">
                    <div class="selection-item__live-time">
                        {{ $t('live') }}
                        <div v-if="livePeriodHalf" class="selection-item__live-divider" />
                        {{ livePeriodHalf }}
                        <div v-if="livePeriodMinute" class="selection-item__live-divider" />
                        {{ livePeriodMinute }}
                    </div>
                    <div class="selection-item__live-progress" />
                </template>
                <template v-else>
                    <div class="selection-item__time">{{ eventStartTime }}</div>
                    <div class="selection-item__divider" />
                </template>
                <div class="selection-item__odd">
                    <div v-if="isFix" class="selection-item__odd__fix" @click.stop.prevent="$emit('fixClick')">
                        <slot />
                    </div>
                    <span v-if="!isOddSuperbet" class="selection-item__odd__market-name" :title="selection.name">
                        {{ selection.name }}
                    </span>
                    <SelectionOddV2
                        v-if="selection.odd"
                        :id="selection.getOddId() || 0"
                        :name="selection.odd.name"
                        :marketName="selection.marketName"
                        :isOutright="isOutright"
                        :value="selection.getOddValue() || 0"
                        :symbol="selection.getOddSymbol()"
                        :isLocked="selection.isLocked()"
                        :specialBetValue="selection.getOddSpecialBetValue()"
                        :valueChangeDynamics="valueChangeDynamics"
                        :isSuperbet="isOddSuperbet"
                        :showSpecialBetValue="checkSbv"
                    />
                    <div class="selection-item__odd__remove">
                        <i class="sds-icon-actions-delete" @click.stop.prevent="onRemoveClick" />
                    </div>
                </div>
            </div>
            <div v-if="error" class="selection-item__error_message">
                {{ errorMessage }}
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { isSuperbet } from '@models/struct/Market';
import BetSlipHelper from '@store/modules/ui/shared/betSlip/Helpers';
import { EventCategory } from '@core/models/offer/enums';
import { useVuexStore } from '@src/v2/app/utils/vuexStore';
import slugify from '@utils/url/slugify';
import { OFFER_PREFIX } from '@app/router/constants';
import { allTimeFilterSlug, liveTimeFilterSlug, todayTimeFilterSlug } from '@store/modules/ui/shared/timeFilter/utils';
import { getTimeFilterForWeekDay } from '@src/terminal/app/modules/shared/timeFilter/helpers';
import SelectionOddV2 from '@src/v2/app/modules/sportOffer/betSlip/selection/SelectionOddV2.vue';
import SportSelection from '@core/models/betSlip/SportSelection';
import IBetSlipError from '@core/models/betSlip/validation/IBetSlipError';
import { OddDynamics } from '@store/modules/data/sportOffer/types';
import sportOfferModule from '@store/modules/ui/sportOffer/sportOfferIndex';
import Odd from '@core/models/offer/Odd';
import Match from '@core/models/offer/Match';
import { useRouter } from 'vue-router/composables';
import i18n from '@src/app/localization/i18n';
import { formatDateTime } from '@shared/filters';

export default defineComponent({
    name: 'SelectionV2',
    emits: ['fixClick', 'removeClick'],
    props: {
        selection: {
            type: Object as PropType<SportSelection>,
            required: true,
        },
        error: {
            type: Object as PropType<IBetSlipError>,
            required: false,
        },
        oddValueDynamics: {
            type: Object as PropType<Record<number, Record<string, OddDynamics>>>,
            required: false,
        },
        checkShowSbv: {
            type: Function as PropType<(marketId: number, isSpecial: boolean) => boolean>,
            required: true,
        },
        isFix: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props, { emit }) {
        const lastOdd = ref(props.selection.odd as Odd);
        const dragElement = ref<HTMLElement>();
        const touchStartX = ref(0);
        const elementStartX = ref(0);
        const isDragging = ref(false);

        const sportOfferStore = useVuexStore<typeof sportOfferModule>('ui/sportOffer');
        const expandedEventId = sportOfferStore.getter('expandedEventId');
        const toggleExpandedEvent = sportOfferStore.action('toggleExpandedEvent');

        const router = useRouter();

        const valueChangeDynamics = computed(() => {
            if (props.oddValueDynamics) {
                if (props.oddValueDynamics[props.selection.getEventId()] && lastOdd.value) {
                    return props.oddValueDynamics[props.selection.getEventId()][lastOdd.value.uniqueId];
                }
            }
            return null;
        });

        const event = computed(() => {
            return props.selection.event as Match;
        });

        const isOutright = computed(() => {
            return event.value.eventCategory === EventCategory.OUTRIGHT;
        });

        const isOddSuperbet = computed(() => {
            return props.selection.odd
                ? isSuperbet(props.selection.odd.marketId, props.selection.odd.marketName)
                : false;
        });

        const checkSbv = computed(() => {
            if (!props.selection.getOdd()) {
                return false;
            }

            return props.checkShowSbv(props.selection.getOdd()!.marketId, false);
        });

        const isEventExpanded = computed(() => {
            return props.selection.event.id === expandedEventId.value;
        });

        const getTeamName = computed(() => {
            if (isOutright.value) {
                return event.value.fullName;
            }
            return event.value.team1Name;
        });

        const livePeriodHalf = computed(() => {
            return event.value.liveData?.isStoppageTime
                ? `+${event.value.liveData.stoppageTime}'`
                : event.value.liveData?.periodStatus;
        });

        const livePeriodMinute = computed(() => {
            return event.value.liveData?.minute ? `${event.value.liveData.minute}'` : '';
        });

        const eventStartTime = computed(() => {
            return formatDateTime(event.value.date, 'monthddhHHMM');
        });

        const openEvent = async () => {
            const eventId = event.value.id;
            if (eventId === expandedEventId.value) return;

            const typeId = event.value.tournamentId;
            const sportName = slugify(event.value.sportName);
            const categoryName = slugify(event.value.categoryName);
            const tournamentName = slugify(event.value.tournamentName || '');
            const datePath = getDateString(event.value);
            let slug = `${OFFER_PREFIX}/${sportName}/`;
            if (event.value?.categoryName) {
                slug += `${categoryName}/`;
            }
            if (event.value?.tournamentName) {
                slug += `${tournamentName}/`;
            }
            if (datePath) {
                slug += `${datePath}/`;
            }
            await router.push({
                path: slug,
            });
            toggleExpandedEvent({ eventId, typeId });
        };

        const getDateString = (event: Match) => {
            const currentDate = new Date();
            const eventDate = new Date(event.date);
            currentDate.setHours(0, 0, 0, 0);
            eventDate.setHours(0, 0, 0, 0);
            const diffTime = eventDate.getTime() - currentDate.getTime();
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (event.hasLive && event.liveData !== null) {
                return i18n.t(liveTimeFilterSlug);
            }
            if (diffDays === 0) {
                return todayTimeFilterSlug;
            }
            if (diffDays > 0 && diffDays <= 5) {
                return slugify(i18n.t(getTimeFilterForWeekDay(eventDate.getDay())).toString());
            }
            return allTimeFilterSlug;
        };

        const errorMessage = computed(() => {
            if (!props.error) return '';

            return i18n.t(BetSlipHelper.getBetSlipErrorMessageTemplate(props.error), props.error.params);
        });

        const onRemoveClick = () => {
            emit('removeClick', props.selection);
        };

        const onStartDrag = (event: TouchEvent | MouseEvent) => {
            isDragging.value = true;

            elementStartX.value = dragElement.value!.getBoundingClientRect().x;

            if (event instanceof TouchEvent) {
                touchStartX.value = event.touches[0].screenX;
            } else {
                touchStartX.value = event.screenX;
            }
        };

        const onDrag = (event: TouchEvent | MouseEvent) => {
            if (!isDragging.value) return;

            const currentX = event instanceof TouchEvent ? event.touches[0].screenX : event.screenX;

            const dragElementX = dragElement.value!.getBoundingClientRect().x;

            const diff = currentX - touchStartX.value;

            if (dragElementX + diff < elementStartX.value) {
                dragElement.value!.style.transform = 'none';
                return;
            }

            dragElement.value!.style.transform = `translateX(${diff}px)`;
        };

        const onEndDrag = (event: TouchEvent | MouseEvent) => {
            if (!isDragging.value) return;
            const currentX = event instanceof TouchEvent ? event.changedTouches[0].screenX : event.screenX;

            if (currentX - touchStartX.value > 210) {
                onRemoveClick();
            }

            dragElement.value!.style.transform = 'none';
            isDragging.value = false;
        };

        watch(
            () => props.selection.odd,
            (_, oldOdd) => {
                if (oldOdd) {
                    lastOdd.value = oldOdd as Odd;
                }
            },
        );

        return {
            onStartDrag,
            onDrag,
            onEndDrag,
            openEvent,
            isDragging,
            isEventExpanded,
            getTeamName,
            event,
            isOddSuperbet,
            onRemoveClick,
            errorMessage,
            isOutright,
            checkSbv,
            valueChangeDynamics,
            dragElement,
            livePeriodHalf,
            livePeriodMinute,
            eventStartTime,
        };
    },
    components: {
        SelectionOddV2,
    },
});
</script>
