import StoreUtil from '@store/StoreUtil';
import { ActionContext, GetterTree } from 'vuex';
import { keyBy as _keyBy } from '@lodash';
import { IState } from '@store/index';
import { SelectedTreeItem } from '@store/modules/ui/sportOffer/sports/types';
import { ItemType } from '@store/modules/ui/sportOffer/sports/enums';
import { logError } from '@src/app/utils/errors';
import { findVirtualTournamentByTournamentId } from '../utilities/virtualTournamentsUtilities';
import { VirtualTournament, TournamentId } from '../types';
import { CompetitionsState } from './types';
import VirtualTournamnetService from '../services/VirtualTournamentService';

function defaultState(): CompetitionsState {
    return {
        virtualTournaments: [],
    };
}

const mutations = {
    SET_VIRTUAL_TOURNAMENTS: 'SET_VIRTUAL_TOURNAMENTS',
    SET_DAILY_SPECIALS: 'SET_DAILY_SPECIALS',
};

interface Getters {
    currentVirtualTournament(
        state: CompetitionsState,
        getters: Getters,
        rootState: IState,
        rootGetters: any,
    ): VirtualTournament | undefined;
    tournamentToVirtualTournament(state: CompetitionsState, getters: Getters): Record<TournamentId, VirtualTournament>;
    virtualTournamentsMap(state: CompetitionsState): Record<string, VirtualTournament>;
}

const getters: GetterTree<CompetitionsState, IState> & Getters = {
    currentVirtualTournament(state, getters, rootState, rootGetters) {
        const selectedItem: SelectedTreeItem = rootGetters['ui/sportOffer/sports/selectedTreeItem'];
        if (selectedItem.type === ItemType.tournamentId) {
            return findVirtualTournamentByTournamentId(state.virtualTournaments, selectedItem.id);
        }
        return undefined;
    },
    tournamentToVirtualTournament(state) {
        const tournamentToVirtualTournament: Record<number | string, VirtualTournament> = {};
        state.virtualTournaments.forEach((virtualTournament) => {
            virtualTournament.childrenIds.forEach((tournamentId) => {
                tournamentToVirtualTournament[tournamentId] = virtualTournament;
            });
        });
        return tournamentToVirtualTournament;
    },
    virtualTournamentsMap(state) {
        return _keyBy(state.virtualTournaments, 'id');
    },
};

export default {
    namespaced: true,

    state: defaultState(),

    getters,

    mutations: {
        [mutations.SET_VIRTUAL_TOURNAMENTS]: StoreUtil.createSimpleMutator('virtualTournaments'),
    },

    actions: {
        async fetchVirtualTournaments(context: ActionContext<CompetitionsState, IState>): Promise<void> {
            try {
                const virtualTournaments = await VirtualTournamnetService.getInstance().getVirtualTournaments();
                context.commit(mutations.SET_VIRTUAL_TOURNAMENTS, virtualTournaments);
            } catch (e) {
                console.warn('Unable to load virtual tournaments', e);
                logError(new Error('Unable to load virtual tournaments endpoint'));
            }
        },
        async prepareCompetitions(context: ActionContext<CompetitionsState, IState>): Promise<void> {
            await Promise.all([context.dispatch('fetchVirtualTournaments')]);
        },
    },
};
