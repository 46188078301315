<template>
    <div class="betslip__bonus--terminal">
        <div class="betslip--terminal__bonus__percentage">
            <span class="betslip--terminal__bonus__percentage__label">{{ $t('potential bonus') | capitalize }}:</span>
            <span v-if="bonusPercentage" class="betslip--terminal__bonus__percentage__amount">
                {{ bonusPercentage | twoDecimals }} %
            </span>
        </div>
        <div class="betslip--terminal__bonus__amount">
            <span class="amount">{{ bonusAmount | currency }}</span>
            <span class="currency">{{ $t('currencyAbbreviation') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        bonusAmount: {
            type: Number,
            required: true,
        },
        bonusPercentage: {
            type: Number,
            required: true,
        },
    },
};
</script>
