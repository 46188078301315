export default {
    /**
     * Sets up a periodically executed task.
     *
     * @param fn The function to execute periodically.
     * @param period The interval between executions (milliseconds).
     * @returns The id of the scheduled task.
     */
    scheduleTask(fn: () => void, period: number): number {
        return window.setInterval(fn, period);
    },

    /**
     * Removes a previously scheduled task.
     *
     * @param id The id of the task to be removed.
     */
    removeScheduledTask(id: number) {
        window.clearInterval(id);
    },
};
