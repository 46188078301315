import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import Ticket, { Bonus, EventCondensed, Payment, PotentialBonuses, Win } from '@core/models/tickets/Ticket';
import { EventStatus, TicketStatus, TicketType } from '@core/models/tickets/enums';
import { competitorDelimiter, competitorDelimiter2 } from '@core/constants';
import * as Sentry from '@sentry/vue';
import GetTicketDto, { BonusesMap, BonusesPotentialEligibilityMap } from './dtos/GetTicketDto';

export default class CheckTicketMapper extends AbstractMapper<GetTicketDto, Partial<Ticket>> {
    private mapBonusesPotentialEligibility(bonusesPotentialEligibilityMap: BonusesPotentialEligibilityMap | undefined) {
        const defaultBonuses: PotentialBonuses = {
            LUCKY_LOSER: false,
            SUPER_BONUS: false,
        };
        if (!bonusesPotentialEligibilityMap) {
            return defaultBonuses;
        }

        return bonusesPotentialEligibilityMap.reduce(
            (acc, [bonus, value]) => ({ ...acc, [bonus]: value }),
            defaultBonuses,
        );
    }

    private mapBonuses(bonusesMap: BonusesMap) {
        const bonuses: {
            SUPER_BONUS?: Bonus;
            LUCKY_LOSER?: Bonus;
        } = {};
        return bonusesMap.reduce((acc, [bonusName, bonus]) => {
            acc[bonusName] = {
                percentage: bonus.percent,
                value: bonus.value,
            };
            return acc;
        }, bonuses);
    }

    private getPayoutField(status: TicketStatus) {
        switch (status) {
            case TicketStatus.active:
            case TicketStatus.canceled:
                return 'potentialPayout';
            case TicketStatus.win:
            case TicketStatus.refund:
            case TicketStatus.lost:
                return 'payout';
            case TicketStatus.payed:
                return 'actualPayout';
            default:
                return 'initialPotentialPayout';
        }
    }

    private mapTicketStatus(status: string, isPaidOut: boolean, isObsolete: boolean, ticketCode: string): TicketStatus {
        if (isPaidOut) {
            return TicketStatus.payed;
        }
        if (isObsolete) {
            return TicketStatus.obsolete;
        }
        switch (status) {
            case 'approved':
            case 'prepared':
            case 'active':
                return TicketStatus.active;
            case 'won':
                return TicketStatus.win;
            case 'refunded':
                return TicketStatus.refund;
            case 'lost':
                return TicketStatus.lost;
            case 'canceled':
                return TicketStatus.canceled;
            default: {
                const message = `SSBT: Unknown ticket status for ticket ${ticketCode}`;
                Sentry.captureMessage(message);
                throw new Error('Unknown ticket status');
            }
        }
    }

    private mapEventStatus(status: string, ticketCode: string): EventStatus {
        switch (status) {
            case 'active':
                return EventStatus.active;
            case 'refunded':
                return EventStatus.refund;
            case 'lost':
                return EventStatus.lost;
            case 'won':
                return EventStatus.win;
            default: {
                const message = `SSBT: Unknown event status for ticket ${ticketCode}`;

                Sentry.captureMessage(message);
                throw new Error(message);
            }
        }
    }

    createTargetObject(origin: GetTicketDto) {
        const ticket = {} as Partial<Ticket>;
        const ticketStatus = this.mapTicketStatus(
            origin.ticketData.status,
            origin.ticketData.isPaidOut,
            origin.ticketData.isObsolete,
            origin.ticketId,
        );

        const payoutField = this.getPayoutField(ticketStatus);
        ticket.ticketId = origin.ticketId;
        ticket.type = origin.ticketData.ticketType as TicketType;
        ticket.coefficient = origin.ticketData.sumCoefficient;
        ticket.status = ticketStatus;
        ticket.isTemplate = origin.ticketData.isTemplate;

        if (origin.ticketData.system?.groupOptionsList.length > 0) {
            ticket.system = {
                selected: origin.ticketData.system.groupOptionsList,
                count: origin.ticketData.system.groupCount,
                fixed: origin.ticketData.events.filter((event) => event.fix).length,
            };
        }
        ticket.dateReceived = this.toDate(origin.ticketData.dateReceived, { assumeUtc: true });
        ticket.datePayoff = this.toDate(origin.ticketData.datePayoff, { assumeUtc: true });
        ticket.payment = {
            stake: origin.ticketData.sumStake,
            total: origin.ticketData.sumTotal,
            handlingFee: origin.ticketData.handlingFee,
        } as Payment;

        ticket.win = {
            minPotential: origin.ticketData.potentialPayout.minPotentialWinning.total || 0,
            estimated: origin.ticketData.potentialPayout.potentialWinning.total || 0,
            tax: origin.ticketData[payoutField]?.potentialWinning.tax || 0,
            payoff: origin.ticketData[payoutField]?.potentialWinning.amount || 0,
            isLuckyLoser:
                this.mapBonusesPotentialEligibility(origin.ticketData[payoutField]?.bonusesPotentialEligibilityMap)
                    .LUCKY_LOSER || false,
        } as Win;

        if (origin.ticketData[payoutField]?.bonusesMap) {
            const bonuses = this.mapBonuses(origin.ticketData[payoutField].bonusesMap);
            if (bonuses.SUPER_BONUS) {
                ticket.bonus = {
                    value: bonuses.SUPER_BONUS.value,
                    percentage: bonuses.SUPER_BONUS.percentage,
                };
            }
        }

        ticket.events = origin.ticketData.events.map((e) => {
            let names = e.nameEvent.split(competitorDelimiter).map((name) => name.trim());

            if (names.length === 1 && e.nameEvent.includes(competitorDelimiter2)) {
                names = e.nameEvent.split(competitorDelimiter2).map((name) => name.trim());
            }
            return {
                IsSpecialFromSportOffer: e.IsSpecialFromSportOffer,
                date: this.toDate(e.dateEvent, { assumeUtc: true }),
                eventId: e.matchId,
                name: names,
                tournamentName: e.tournamentName,
                type: e.eventType,
                status: this.mapEventStatus(e.eventStatus, origin.ticketId),
                marketing: e.marketing,
                inputCode: e.inputCode,
                odd: {
                    id: e.oddTypeId,
                    uuid: e.oddUuid,
                    name: e.oddName,
                    description: e.oddDescription,
                    coefficient: e.oddCoefficientEntry,
                    sourceType: e.oddSourceType,
                    isFix: e.fix,
                    oddStatus: this.mapEventStatus(e.oddStatus, origin.ticketId),
                    specialValue: e.sbValue,
                },
                eventNo: e.eventNo,
                marketType: e.marketType,
            } as unknown as EventCondensed;
        });

        return ticket;
    }
}
