<template>
    <tfoot>
        <tr>
            <td colspan="1" class="p-l-1 p-y-1">
                {{ $t('ticketStatus') }}
            </td>
            <td colspan="1">
                {{ $t(data.ticket.status) | upperCase }}
            </td>
        </tr>
        <tr v-if="data.ticket.system && data.ticket.status !== 'lost'">
            <td colspan="1" class="p-l-1 p-y-1">
                {{ $t('minPotential') }}
            </td>
            <td colspan="1">
                {{ numberFormat(data.ticket.win.minPotential) + ' ' + $t('currencyAbbreviation') }}
            </td>
        </tr>
        <tr>
            <td colspan="1" class="p-l-1 p-y-1">
                {{ status }}
            </td>
            <td v-if="data.ticket.status === 'active'" colspan="1">
                {{ numberFormat(data.ticket.win.estimated) + ' ' + $t('currencyAbbreviation') }}
            </td>
            <td v-else colspan="1">
                {{ numberFormat(data.ticket.win.payoff) + ' ' + $t('currencyAbbreviation') }}
            </td>
        </tr>
        <tr v-if="isValidBonus()">
            <td colspan="1" class="p-l-1 p-y-1">
                {{ $t('SuperBonus') }}
            </td>
            <td colspan="1">
                {{ bonus }}
            </td>
        </tr>
    </tfoot>
</template>
<script>
import { localeNumberFormat } from '@src/terminal/core/services/gravity/helpers/math';

export default {
    name: 'TicketStatus',
    props: {
        data: Object,
    },
    computed: {
        bonus() {
            return this.data.ticket.bonus.percentage
                ? `${this.numberFormat(this.data.ticket.bonus.percentage)}% ${this.numberFormat(
                      this.data.ticket.bonus.value,
                  )}`
                : `${this.numberFormat(this.data.ticket.bonus.value)}`;
        },
        status() {
            return this.data.ticket.status === 'active' ? this.$t('potentialPayoff') : this.$t('actualPayoff');
        },
    },
    methods: {
        numberFormat: localeNumberFormat,
        isValidBonus() {
            return this.data.ticket.bonus && this.data.ticket.bonus.value !== 0;
        },
    },
};
</script>
