import { keyBy as _keyBy, isEmpty as _isEmpty } from '@lodash';
import Struct from '@core/models/struct/Struct';
import MalformedDataError from '@errors/network/MalformedDataError';
import Logger from '@loggers/Logger';
import { SportMapper } from '@core/mappers/struct/SportMapper';
import { OddTypeMapper } from '@core/mappers/struct/OddTypeMapper';
import { CategoryMapper } from '@core/mappers/struct/CategoryMapper';
import { TournamentMapper } from '@core/mappers/struct/TournamentMapper';
import ServerConfig from '@core/models/country/ServerConfig';
import { instanceGuard } from '@core/utils/services';
import { MarketGroupMapper } from '@core/mappers/struct/MarketGroupMapper';
import { RestService as OfferRestService, Phase } from '@superbet-group/offer.clients.lib';
import common from '@src/config/common';
import MarketGroupInfo from '@core/models/struct/MarketGroupInfo';
import Singleton from '../common/Singleton';
import SingletonError from '../common/errors/SingletonError';

class Mappers {
    sportMapper?: SportMapper = new SportMapper();
    tournamentMapper?: TournamentMapper = new TournamentMapper();
    categoryMapper?: CategoryMapper = new CategoryMapper();
    oddTypeMapper?: OddTypeMapper = new OddTypeMapper();
    marketGroupMapper?: MarketGroupMapper = new MarketGroupMapper();
}

class StructService {
    private mappers: Mappers;
    private restService: OfferRestService;
    private static instance?: StructService;

    private constructor(config: ServerConfig) {
        if (StructService.instance) {
            throw new SingletonError(this.constructor.name);
        }
        this.mappers = new Mappers();
        const hostServer = config.offer.hostServer;
        this.restService = new OfferRestService(hostServer, common.offerLang, 30000, 3);
    }

    public static getInstance(): StructService {
        return instanceGuard(StructService.instance)!;
    }

    public static createInstance(config: ServerConfig) {
        if (!StructService.instance) {
            StructService.instance = new StructService(config);
        }
        return StructService.instance;
    }

    private raiseAndLogMalformedDataError(msg: string) {
        const error = new MalformedDataError(msg);
        Logger.error(error);
        throw error;
    }

    public async getMarketGroupInfos(sportId: number, phase: Phase = Phase.prematch): Promise<MarketGroupInfo[]> {
        const marketGroups = await this.restService.getMarketGroupsBySportIdAndPhase(sportId, phase);
        return this.mappers.marketGroupMapper!.map(marketGroups, true);
    }

    /**
     * @throws {RequestError}
     * @throws {MalformedDataError}
     * @throws {Error}
     */
    public async getStruct(): Promise<Struct> {
        const data = await this.restService.getStruct();

        const { tournaments, sports, outcomes: oddTypes, categories, marketTree } = data;
        const hasEmptyValues: boolean = [tournaments, sports, oddTypes, categories, marketTree].some((value) =>
            _isEmpty(value),
        );

        if (hasEmptyValues) {
            this.raiseAndLogMalformedDataError('Struct has empty structures');
        }

        const struct = {
            tournaments: _keyBy(this.mappers.tournamentMapper!.map(tournaments, true), 'id'),
            sports: _keyBy(this.mappers.sportMapper!.map(sports, true), 'id'),
            markets: {},
            oddTypes: _keyBy(this.mappers.oddTypeMapper!.map(oddTypes, true), 'id'),
            categories: _keyBy(this.mappers.categoryMapper!.map(categories, true), 'id'),
        } as Struct;

        return struct;
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete StructService.instance;
    }
}

export default StructService as Singleton<StructService>;
