import { Route } from 'vue-router';
import slugify from '@utils/url/slugify';
import { getFlag } from '@src/data/flagMapping';
import { orderBy as _orderBy, get as _get } from '@lodash';

export interface INode {
    id: number | string;
    icon?: string;
    name: string;
    children: INode[];
    eventCount: number;
    order: number | string;
    flag?: string;
    specialTournamentOrder?: number;
}
export interface MatchForTree {
    sportId: number;
    sportName?: string;
    sportOrder: number;
    categoryId: number;
    categoryName?: string;
    tournamentId: number | string;
    tournamentName?: string;
    specialTournamentOrder?: number;
    tournamentOrder?: number;
    tournamentName2?: string;
}

type StructElement = { name: string };
interface IStruct {
    sports: Record<number, StructElement>;
    categories: Record<number, StructElement>;
    tournaments: Record<number, StructElement>;
}

export const createTreeNode = (data: any, branch: Record<number, INode>, parentId?: number): INode => {
    const node: INode = { ...data };
    if (parentId) {
        node.flag = getFlag(parentId, node.id);
    }
    branch[node.id] = node;
    return node;
};

/**
 * @param data New data to put in node
 * @param branch Branch to put Node on
 * @param parentId Need this only for flags
 */
export const createOrUpdateTreeNode = (data: any, branch: Record<number, INode>, parentId?: number): INode => {
    const node: INode = branch[data.id] || createTreeNode(data, branch, parentId);
    node.eventCount += 1;
    return node;
};

export const buildTreeFromMatches = function (matches: MatchForTree[], struct?: IStruct): Record<number, INode> {
    const tree: Record<number, INode> = {};
    for (let i = 0; i < matches.length; i += 1) {
        const event = matches[i];
        const sport = createOrUpdateTreeNode(
            {
                id: event.sportId,
                name: event.sportName || _get(struct, ['sports', event.sportId, 'name']),
                children: {},
                eventCount: 0,
                order: event.sportOrder,
            },
            tree,
        );
        const category = createOrUpdateTreeNode(
            {
                id: event.categoryId,
                name: event.categoryName || _get(struct, ['categories', event.categoryId, 'name']),
                children: {},
                eventCount: 0,
                order: event.categoryName,
            },
            sport.children,
            sport.id,
        );
        createOrUpdateTreeNode(
            {
                id: event.tournamentId,
                name: event.tournamentName || _get(struct, ['tournaments', event.tournamentId, 'name']),
                children: [],
                eventCount: 0,
                specialTournamentOrder: event.specialTournamentOrder,
                tournamentOrder: event.tournamentOrder,
                tournamentName2: event.tournamentName2,
            },
            category.children,
            category.id,
        );
    }
    return tree;
};

export const orderTree = function (tree: INode['children']) {
    const orderedTree = _orderBy(tree, 'order');

    for (let i = 0; i < orderedTree.length; i += 1) {
        const sport = orderedTree[i];
        sport.children = _orderBy(sport.children, 'order');
        sport.children.forEach((category: any) => {
            category.children = _orderBy(category.children, [
                'specialTournamentOrder',
                'tournamentOrder',
                'tournamentName2',
            ]);
        });
    }
    return orderedTree;
};

export const pushToBreadcrumbIds = function (parent: INode[], slug: string, breadcrumbIds: INode['id'][]) {
    if (!parent || !slug) {
        return null;
    }
    const treeItem = parent.find((item: any) => slugify(item.name) === slug);
    if (treeItem) {
        breadcrumbIds.push(treeItem.id);
    }
    return treeItem;
};

export const extractBreadcrumbsFromRoute = function (route: Route, tree: INode[]) {
    const breadcrumbIds = [] as INode['id'][];
    const { sportSlug, categorySlug, tournamentSlug } = route.params;
    const sportItem = pushToBreadcrumbIds(tree, sportSlug, breadcrumbIds);
    const categoryItem = pushToBreadcrumbIds(_get(sportItem, 'children')!, categorySlug, breadcrumbIds);

    pushToBreadcrumbIds(_get(categoryItem, 'children')!, tournamentSlug, breadcrumbIds);
    return breadcrumbIds;
};
