<template>
    <div class="ticket-selection">
        <div class="ticket-selection__name">
            <span v-if="selection.name.length" :class="eventStatusClass">{{ selection.name[0] }}</span>
            <template v-if="selection.name[1]">
                <span> {{ $t('vs') }} </span>
                <span :class="eventStatusClass">{{ selection.name[1] }}</span>
            </template>
            <span v-if="isSelectionLive" class="ticket-selection__name--live">{{ $t('live') }}</span>
            <span v-if="selection.odd.isFix" class="ticket-selection__name--fix">{{ $t('fix') }}</span>
        </div>
        <div class="ticket-selection__odd">
            <img class="ticket-selection__odd__status" :src="oddStatusIcon.primary" />
            <span class="ticket-selection__odd__name">{{ oddName }}</span>
        </div>
        <div v-for="(description, index) in oddDescriptions" :key="index" class="ticket-selection__market">
            <img class="ticket-selection__market__status" :src="oddStatusIcon.alt" />
            <span class="ticket-selection__market__name">{{ description }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { EventStatus, MarketType } from '@core/models/tickets/enums';
import { EventCondensed } from '@core/models/tickets/Ticket';
import inProgressIconPrimary from '@superbet-group/web.lib.ui-tokens/output/assets/icons-multi-color/status-in-progress-dark.svg';
import inProgressIconAlt from '@superbet-group/web.lib.ui-tokens/output/assets/icons-multi-color/status-in-progress-alt-dark.svg';
import wonIconPrimary from '@superbet-group/web.lib.ui-tokens/output/assets/icons-multi-color/status-checkmark-light.svg';
import wonIconAlt from '@superbet-group/web.lib.ui-tokens/output/assets/icons-multi-color/status-won-dark.svg';
import refundIconPrimary from '@superbet-group/web.lib.ui-tokens/output/assets/icons-multi-color/status-refund-superbet-dark.svg';
import lostIconPrimary from '@superbet-group/web.lib.ui-tokens/output/assets/icons-multi-color/status-close-light.svg';
import lostIconAlt from '@superbet-group/web.lib.ui-tokens/output/assets/icons-multi-color/status-lost-dark.svg';
import i18n from '@src/app/localization/i18n';

export default defineComponent({
    name: 'TicketSelectionV2',
    props: {
        selection: {
            required: true,
            type: Object as PropType<EventCondensed>,
        },
    },
    setup(props) {
        const isSelectionLive = computed(() => {
            return props.selection.marketType === MarketType.IN_PLAY;
        });

        const oddName = computed(() => {
            const isSuperbet = props.selection.odd.name.includes(';');

            return isSuperbet ? i18n.t('superbets') : props.selection.odd.name;
        });

        const eventStatusClass = computed(() => {
            switch (props.selection.status) {
                case EventStatus.win:
                    return 'ticket-selection__name--win';
                case EventStatus.lost:
                    return 'ticket-selection__name--lost';
                default:
                    return '';
            }
        });

        const oddStatusIcon = computed(() => {
            switch (props.selection.odd.oddStatus) {
                case 'win':
                    return {
                        primary: wonIconPrimary,
                        alt: wonIconAlt,
                    };
                case 'lost':
                    return {
                        primary: lostIconPrimary,
                        alt: lostIconAlt,
                    };
                case 'refund':
                    return {
                        primary: refundIconPrimary,
                        alt: refundIconPrimary,
                    };
                default:
                    return {
                        primary: inProgressIconPrimary,
                        alt: inProgressIconAlt,
                    };
            }
        });

        const oddDescriptions = computed(() => {
            return props.selection.odd.description?.split(';');
        });

        return {
            oddName,
            eventStatusClass,
            isSelectionLive,
            oddDescriptions,
            oddStatusIcon,
        };
    },
});
</script>
