import { PROMOTIONS_ID, PROMOTIONS_TITLE } from '@store/modules/ui/sportOffer/constants';
import { ScrollerItem } from './ScrollerItem';
import { HeaderType } from '../enums';
import { sportName } from '../util';
import { ElementHeights } from '../constants';

/**
 * The specification for all objects rendered as categories in the event grid.
 * (e.g. Football, Baseball, Promotions.)
 */
export type Category = ScrollerItem;

export function createCategory(id: number, title: string, heights: ElementHeights): Category {
    return {
        title,
        id: `${id}`,
        type: HeaderType.category,
        height: heights.categoryHeader,
    } as Category;
}

export function createSportCategory(sportId: number, heights: ElementHeights) {
    return createCategory(sportId, sportName(sportId), heights);
}

export function createPromotionsCategory(heights: ElementHeights) {
    return createCategory(PROMOTIONS_ID, PROMOTIONS_TITLE, heights);
}
