<template>
    <div class="event-row__expanded-market-header--terminal" @click.stop="$emit('toggleExpanded')">
        <span class="event-row__expanded-market-title--terminal">
            {{ isOutright ? market.outrightMarketName : market.name }}
        </span>
        <div class="event-row__expanded-market-actions">
            <i
                v-if="!useDesignV2"
                :title="$t('bets info') | capitalize"
                class="icon--sm event-row__expanded-market-icon icon-info_circle mr-2 event-sport-summary_icon"
                @click.stop="$emit('toggleDescription')"
            />
            <MarketIcon
                v-if="isCollapsingEnabled === false"
                :size="iconSize"
                :class="['event-row__expanded-market-icon', isExpanded ? 'icon-chevron_up' : 'icon-chevron_down']"
                @click.stop="$emit('toggleExpanded')"
            />
        </div>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useVuexStore } from '@src/v2/app/utils/vuexStore';
import flagModule from '@src/terminal/store/flags';
import MarketIcon from './MarketIcon.vue';

export default defineComponent({
    name: 'MarketHeader',
    components: {
        MarketIcon,
    },
    props: {
        market: {
            type: Object,
            required: true,
        },
        isOutright: {
            type: Boolean,
            default: false,
        },
        isCollapsingEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        isExpanded: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const flagStore = useVuexStore<typeof flagModule>('data/flags');
        const useDesignV2 = flagStore.getter('useDesignV2');

        const iconSize = computed(() => {
            return useDesignV2.value ? 'md' : 'xl';
        });

        return {
            iconSize,
            useDesignV2,
        };
    },
});
</script>
