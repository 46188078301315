import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import { MetaData, BookingStatus, Time } from '@core/models/offer/MetaData';
import { PeriodMapper } from '@core/mappers/offer/PeriodMapper';
import { MetaDataDto } from '@superbet-group/offer.clients.lib';

const periodMapper: PeriodMapper = new PeriodMapper();

export class MetaDataMapper extends AbstractMapper<MetaDataDto, MetaData> {
    // eslint-disable-next-line class-methods-use-this
    createTargetObject(dto: MetaDataDto): MetaData {
        const metaData: MetaData = {
            team1Score: dto.homeTeamScore ? parseInt(dto.homeTeamScore, 10) : undefined,
            team2Score: dto.awayTeamScore ? parseInt(dto.awayTeamScore, 10) : undefined,
            minute: dto.minutes ? Number(dto.minutes) : undefined,
            periodStatus: dto.periodStatus || '',
            matchStatusLabel: dto.matchStatusLabel,
            periods: dto.periods ? periodMapper.map(dto.periods, true) : undefined,
            bookingStatus: (dto.bookingStatus as BookingStatus) || undefined,
            team1GemScore: dto.homeTeamGameScore,
            team2GemScore: dto.awayTeamGameScore,
            serve: dto.teamServing,
            status: dto.status,
        };

        if (dto.stoppageTime) {
            metaData.stoppageTime = parseTimeString(dto.stoppageTime);
        }

        metaData.t1YCrd = dto.homeTeamYellowCards;
        metaData.t2YCrd = dto.awayTeamYellowCards;
        metaData.t1RCrd = dto.homeTeamRedCards;
        metaData.t2RCrd = dto.awayTeamRedCards;
        metaData.t1Crn = dto.homeTeamCorners;
        metaData.t2Crn = dto.awayTeamCorners;

        return metaData;
    }
}

// time is on format mm:ss
function parseTimeString(time: string): Time {
    const [minutes, seconds] = time.split(':').map((x) => parseInt(x, 10));

    return {
        minutes,
        seconds,
    };
}
