import { Commit } from 'vuex';
import { uniqBy as _uniqBy, orderBy as _orderBy } from '@lodash';
import config from '@config';
import Match from '@core/models/offer/Match';
import slugify from '@core/utils/url/slugify';
import { IState } from '@app/store/';
import { allTimeFilterSlug } from '../../shared/timeFilter/utils';
import { findAllIndices, lastLower } from './helpers';

const NUMBER_OF_SUGGESTIONS = config.app.search.numberOfSuggestions;

export const SAVE_QUERY = 'SAVE_QUERY';

export interface ISearchState {
    lastSearches: string[];
    preparedEvents: SearchableMatch[];
}

interface SearchableMatch {
    id: number;
    search: string;
    positions: [
        number, // team1NameIndex:
        number, // team2NameIndex
        number, // tournamentNameIndex
        number, // categoryNameIndex
    ];
}

interface SearchResult {
    item: SearchableMatch;
    matchedIndices: number[];
}

interface Getters {
    searchResults: SearchResult[];
    query: string;
    preparedEvents: SearchableMatch[];
    suggestions: (query: string) => string[];
}

function sortResults(results: SearchResult[]) {
    // sorts by number of matches and by the position of the search term in the search string
    return _orderBy(results, ['matchedIndices.length', 'matchedIndices[0]'], ['desc', 'asc']);
}

function searchMatches(searchArea: SearchableMatch[], query: string) {
    const searchTerm = query.trim().toLowerCase();
    const searchResults = [] as { item: SearchableMatch; matchedIndices: number[] }[];
    searchArea.forEach((item) => {
        const matchedIndices = findAllIndices(item.search, searchTerm);
        if (matchedIndices.length) {
            searchResults.push({ item, matchedIndices });
        }
    });
    return searchResults;
}

export default {
    namespaced: true,
    state: {
        lastSearches: [],
    },
    mutations: {
        [SAVE_QUERY](state: ISearchState, query: string) {
            if (state.lastSearches.indexOf(query) === -1) {
                state.lastSearches.push(query);
                if (state.lastSearches.length > NUMBER_OF_SUGGESTIONS) {
                    state.lastSearches.shift();
                }
            }
        },
    },
    getters: {
        previousQueries(state: ISearchState) {
            // TODO: this was disabled for purposes of SSBT
            // return state.lastSearches;
        },
        query(state: ISearchState, getters: Getters, rootState: any, rootGetters: any): string {
            return rootGetters['navigation/route'].query.query || '';
        },
        eventsByCompetitors(state: ISearchState, getters: Getters, rootState: any, rootGetters: any) {
            const allSearchResults = getters.searchResults;
            const competitorResults = [] as SearchResult[];
            allSearchResults.forEach((result) => {
                const bounds = [0, result.item.positions[2]];
                const appropriateIndices = result.matchedIndices.filter(
                    (index) => index >= bounds[0] && index < bounds[1],
                );
                if (appropriateIndices.length) {
                    competitorResults.push({
                        ...result,
                        matchedIndices: appropriateIndices,
                    });
                }
            });
            const eventsMap = rootGetters['data/sportOffer/eventsMap'] as Record<string, Match>;
            const matches = sortResults(competitorResults).map((res) => eventsMap[res.item.id]);
            return matches;
        },
        foundTournaments(state: ISearchState, getters: Getters, rootState: any, rootGetters: any) {
            const allSearchResults = getters.searchResults;
            const tournamentResults = [] as SearchResult[];
            allSearchResults.forEach((result) => {
                const bounds = [result.item.positions[2], result.item.positions[3]];
                const appropriateIndices = result.matchedIndices.filter(
                    (index) => index >= bounds[0] && index < bounds[1],
                );
                if (appropriateIndices.length) {
                    tournamentResults.push({
                        ...result,
                        matchedIndices: appropriateIndices,
                    });
                }
            });
            const eventsMap = rootGetters['data/sportOffer/eventsMap'] as Record<string, Match>;
            const matches = sortResults(tournamentResults).map((res) => eventsMap[res.item.id]);

            return _uniqBy(matches, 'tournamentId').map((match) => ({
                id: match.tournamentId,
                name: match.tournamentName,
                url: `${slugify(match.sportName)}/\
${slugify(match.categoryName)}/\
${slugify(match.tournamentName)}/\
${allTimeFilterSlug}`,
            }));
        },
        searchResults(state: ISearchState, getters: Getters): Getters['searchResults'] {
            const searchArea = getters.preparedEvents;
            const query = getters.query as string;
            const searchTerm = query.trim().toLowerCase();
            if (!searchTerm.length) {
                return [];
            }
            return searchMatches(searchArea, query);
        },
        preparedEvents(
            state: ISearchState,
            getters: Getters,
            rootState: IState,
            rootGetters: any,
        ): Getters['preparedEvents'] {
            const matches = rootGetters['data/sportOffer/events'] as Match[];
            const isInPlayAvailable = rootGetters['data/flags/isInPlayAvailable'] as boolean;
            /*
                concatenates searchable attributes into a single string and marks
                the their positions. This way the search can be done only once
                and cached for different attributes
            */
            const liveAndPreMatchSearch = (match: Match) => !match.isFinished();
            const preMatchSearchOnly = (match: Match) => !match.isFinished() && !match.hasLive;
            return matches.filter(isInPlayAvailable ? liveAndPreMatchSearch : preMatchSearchOnly).map((match) => {
                const searchableMatch = {
                    id: match.id,
                    search: '',
                    positions: [0, 0, 0, 0] as [number, number, number, number],
                };
                searchableMatch.search += `${match.team1Name} `;
                searchableMatch.positions[1] = searchableMatch.search.length;
                searchableMatch.search += `${match.team2Name} `;
                // outrights have no team1 and team2 name, so we use fullName
                if (!match.team1Name && !match.team2Name) {
                    searchableMatch.search += `${match.fullName} `;
                }
                searchableMatch.positions[2] = searchableMatch.search.length;
                searchableMatch.search += `${match.tournamentName} `;
                searchableMatch.positions[3] = searchableMatch.search.length;
                searchableMatch.search += `${match.categoryName} `;
                searchableMatch.search = searchableMatch.search.toLowerCase();
                return searchableMatch;
            });
        },
        suggestions(state: ISearchState, getters: Getters): Getters['suggestions'] {
            return (query: string) => {
                const searchResults = searchMatches(getters.preparedEvents, query);
                if (searchResults.length === 0) {
                    return [];
                }
                const sortedResults = sortResults(searchResults);
                const suggestions = [] as string[];
                // extracts matched string starting from left (event name)
                for (let i = 0; i < sortedResults[0].matchedIndices.length; i += 1) {
                    for (let j = 0; j < sortedResults.length; j += 1) {
                        const currentResult = sortedResults[j];
                        if (currentResult.matchedIndices[i] === undefined) {
                            return suggestions;
                        }
                        // finds the closest left index of an attribute where the the matched substring belongs
                        const leftBorder = lastLower(currentResult.item.positions, currentResult.matchedIndices[i])!;
                        // the index of the area where the matched substring is
                        const rightBorderIndex = currentResult.item.positions.indexOf(leftBorder) + 1;
                        // full attribute borders which was matched by the query
                        const borders = [
                            leftBorder,
                            currentResult.item.positions[rightBorderIndex]
                                ? currentResult.item.positions[rightBorderIndex]
                                : currentResult.item.search.length + 1,
                        ];
                        const matchedText = currentResult.item.search.slice(...borders);
                        if (suggestions.indexOf(matchedText) === -1) {
                            suggestions.push(matchedText);
                        }
                        if (suggestions.length === NUMBER_OF_SUGGESTIONS) {
                            return suggestions;
                        }
                    }
                }
                return suggestions;
            };
        },
    },
    actions: {
        saveQuery: ({ commit }: { commit: Commit }, query: string) => {
            const sanitizedQuery = query.toLowerCase().trim();
            commit(SAVE_QUERY, sanitizedQuery);
        },
    },
};
