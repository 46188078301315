import { countBy as _countBy } from '@lodash';
import Match from '@core/models/offer/Match';
import Sport from '@models/struct/Sport';

export const getEventsBySport = (
    events: Record<number, Match>,
    sports: Record<number, Sport>,
    selectedTreeItem: string,
    filters: Function[],
) => {
    const filteredEvents = Object.values(events).filter((event: Match) =>
        filters.every((func: Function) => func(event)),
    );

    const eventsBySport = _countBy(filteredEvents, (event: Match) => event.sportId);

    return Object.values(sports)
        .filter((sport: Sport) => sport.name === selectedTreeItem || eventsBySport[sport.id] > 0)
        .map((sport: Sport) => ({
            id: sport.id,
            name: sport.name,
            eventCount: eventsBySport[sport.id] || 0,
        }));
};

export const isActiveEvent = (e: Match) => !e.isFinished() && !e.isStopped();
