import Vue from 'vue';
import { sumBy as _sumBy } from 'lodash';
import Match from '@models/offer/Match';
import { IPromotionalEventGroup } from '@store/modules/data/sportOffer/types';
import store from '@store/index';
import { getFlag } from '@src/data/flagMapping';
import { HeaderType } from './enums';
import { ScrollerItem } from './gridUnits/ScrollerItem';
import { EventCollection, createTournamentEventCollection } from './gridUnits/EventCollection';
import { Event } from './gridUnits/Event';
import { Section, createTournamentSection, createPromotionSection } from './gridUnits/Section';
import { getElementHeightsV2, getElementHeights, HeightConfig } from './constants';
import { createSportCategory, createPromotionsCategory } from './gridUnits/Category';

interface UIOptions {
    isSidebarActive: boolean;
    isGridSidebarActive: boolean;
    selectedEventHeight?: number;
    showCategoryHeader?: boolean;
    arePinnedExpanded?: boolean;
}

export const sportGridState = Vue.observable({
    offsetTop: 0,
});

/**
 * This class will accept sorted events and will create structure that
 * event grid can consume.  As promotion-events don't have sport as header
 * you can pass flag isSportIncluded=false and you will get only tournaments.
 *
 */
export default class GridDataPreparation {
    private expandedEvent: {
        id: number;
        typeId: string;
    } | null;

    private heightConfig: HeightConfig;
    isDesignV2: boolean;

    public constructor(
        expandedEvent: {
            id: number;
            typeId: string;
        } | null,
        private uiOptions: UIOptions,
    ) {
        this.isDesignV2 = store.getters['data/flags/useDesignV2'];
        this.expandedEvent = expandedEvent;
        this.heightConfig = this.isDesignV2 ? getElementHeightsV2() : getElementHeights();
        this.uiOptions.showCategoryHeader =
            this.uiOptions.showCategoryHeader !== undefined ? this.uiOptions.showCategoryHeader : true;
    }

    get elementHeights() {
        if (this.expandedEvent && this.expandedEvent.id) {
            return this.heightConfig.withSidebar;
        }
        return this.heightConfig.normal;
    }

    /**
     *
     * Note: If events are sorted by tournament you can't have repeated tournament-headers
     * But if events are sorted by some other field that puts tournaments on random positions you can have
     * repeated tournament-headers.  For example, sorted by date and you have this list:
     *
     * Dinamo - Hajduk
     * Rijeka - Osijek
     * Ajax - PSV
     * Slaven B. - Gorica
     *
     * will return:
     *
     * 1. HNL [Dinamo - Hajduk, Rijeka - Osijeka]
     * Holland ere [Ajax - PSV]
     * 1. HNL [Slaven B. - Gorica]
     */
    public getData(matches: Match[]): ScrollerItem[] {
        let sportId: number;
        let categoryId: number;
        let tournament: Section;
        const items: ScrollerItem[] = [];
        matches.forEach((match: Match) => {
            if (match.categoryId !== categoryId) {
                categoryId = match.categoryId;
            }

            if (match.sportId !== sportId) {
                sportId = match.sportId;
                categoryId = match.categoryId;
                if (this.uiOptions.showCategoryHeader) {
                    items.push(createSportCategory(sportId, this.elementHeights));
                }
            }

            if (match.tournamentId.toString() !== (tournament && tournament.id)) {
                const matchPrimaryMarket = match.getPrematchMarket();
                tournament = createTournamentSection(
                    match.tournamentId,
                    sportId,
                    match.tournamentFooter,
                    matchPrimaryMarket ? matchPrimaryMarket.originalName : '',
                    match.sportName,
                );
                tournament.flagCode = getFlag(sportId, categoryId);
                items.push(tournament);
            }

            tournament.eventCollections[0].events.push({
                typeId: `${tournament.id}`,
                eventId: match.id,
                hasFooter: !!match.matchFooter,
            });
        });

        this.updateHeights(items);
        return items;
    }

    public getPromotionData(promotionGroups: IPromotionalEventGroup[]): ScrollerItem[] {
        if (promotionGroups.length === 0) {
            return [];
        }
        const items: ScrollerItem[] = [];
        // this gives us the category title (like a sport)
        items.push(createPromotionsCategory(this.elementHeights));
        promotionGroups.forEach((group) => {
            const tournaments: EventCollection[] = [];

            let currentTournamentId: number | null = null;
            let currentTournament: EventCollection;

            group.events.forEach((match: Match) => {
                if (match.tournamentId !== currentTournamentId) {
                    currentTournamentId = match.tournamentId;
                    currentTournament = createTournamentEventCollection(match.sportId, match.tournamentId);
                    tournaments.push(currentTournament);
                }

                currentTournament.events.push({ typeId: group.id, eventId: match.id, hasFooter: !!match.matchFooter });
            });

            items.push(
                createPromotionSection({
                    id: group.id,
                    primaryMarketAttribute: group.primaryMarketAttribute,
                    primaryMarketName: group.title,
                    title: group.title,
                    eventCollections: tournaments,
                    footer: group.footer,
                }),
            );
        });

        this.updateHeights(items);
        return items;
    }

    public getPromotionDataV2(promotionGroups: IPromotionalEventGroup[]): ScrollerItem[] {
        if (promotionGroups.length === 0) {
            return [];
        }

        const items: ScrollerItem[] = [];
        items.push(createPromotionsCategory(this.elementHeights));

        promotionGroups.forEach((group) => {
            const tournament = createTournamentEventCollection(0, 0);
            group.events.forEach((match: Match) => {
                tournament.events.push({ typeId: group.id, eventId: match.id, hasFooter: !!match.matchFooter });
            });
            items.push(
                createPromotionSection({
                    id: group.id,
                    primaryMarketAttribute: group.primaryMarketAttribute,
                    primaryMarketName: group.title,
                    title: group.title,
                    eventCollections: [tournament],
                    footer: group.footer,
                }),
            );
        });

        this.updateHeights(items);

        return items;
    }

    public updateHeights(items: ScrollerItem[]) {
        items.forEach((unit: ScrollerItem) => {
            if (unit.type === HeaderType.section) {
                this.updateHeight(unit as Section);
            }
        });
    }

    public updateHeight(section: Section) {
        // eslint-disable-next-line
        section.height = this.totalSectionHeight(section, !!section.footer);
    }

    public getEventHeight(eventId: number, type: string | number) {
        if (this.expandedEvent && eventId === this.expandedEvent.id && this.expandedEvent.typeId === type) {
            return this.uiOptions.selectedEventHeight as number;
        }
        return this.elementHeights.event;
    }

    public totalEventHeight(event: Event, type: string | number) {
        return this.getEventHeight(event.eventId, type) + +event.hasFooter * this.elementHeights.eventFooter;
    }

    public totalEventCollectionHeight(collection: EventCollection, sectionId: Section['id']) {
        const eventsHeight = _sumBy(collection.events, (e) => this.totalEventHeight(e, sectionId));
        return eventsHeight + this.elementHeights.eventCollectionHeader;
    }

    public totalSectionHeight(section: Section, hasFooter: boolean) {
        const contentHeight = _sumBy(section.eventCollections, (c) => this.totalEventCollectionHeight(c, section.id));
        return (
            this.elementHeights.sectionHeader +
            contentHeight +
            this.elementHeights.sectionPadding +
            this.elementHeights.sectionBottomMargin +
            +hasFooter * this.elementHeights.sectionFooter
        );
    }
}
