<template>
    <div
        class="illustration"
        :class="{ 'illustration--xl': xl, 'illustration--l': large }"
        :style="`background-image: url(${image})`"
    />
</template>

<script>
export default {
    name: 'Illustration',
    props: {
        image: {
            type: String,
            default: '',
        },
        xl: {
            type: Boolean,
            default: false,
        },
        large: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
