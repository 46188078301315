<template>
    <div class="modal__body">
        <div v-if="serialCardRequired" class="modal__body__card">
            <div class="modal__body__card__title">{{ $t('sbClubScan.title') }}</div>
            <div class="modal__body__card__info">{{ $t('sbClubScan.description') }}</div>
            <div v-for="msg in errors" :key="msg" class="modal__body__card__error">
                <i class="sds-icon-status-warning-filled" />
                {{ msg }}
            </div>
            <img class="modal__body__card__scan" src="/static/img/illustrations/v2/card_scan.svg" />
        </div>
        <template v-else>
            <div class="modal__body__input">
                <div v-if="!serialCardRequired" :class="['modal__body__input__ticket', { error: hasError }]">
                    <div class="modal__body__input__ticket__code">
                        <span class="modal__body__input__ticket__code__label">{{ $t('ticket code') }}</span>
                        <span class="modal__body__input__ticket__code__value">{{ internalValue }}</span>
                    </div>
                    <i v-if="hasError" class="modal__body__input__ticket--error sds-icon-status-danger-filled" />
                    <span>{{ $t('code') }}</span>
                    <i
                        class="modal__body__input__ticket__clear sds-icon-navigation-close-alt-filled"
                        @click="onKeypadClick({ value: 'C' })"
                    />
                </div>
                <p v-for="msg in errors" :key="msg" class="modal__body__input--error">
                    {{ msg }}
                </p>
                <p v-for="point in info" :key="point" class="modal__body__input--info">
                    {{ point }}
                </p>
            </div>
            <div v-if="!serialCardRequired" class="modal__body__numpad">
                <template v-for="numberKey in fetchNumberKeys">
                    <div :key="numberKey.value" class="modal__body__numpad__key" @click="onKeypadClick(numberKey)">
                        {{ numberKey.value }}
                    </div>
                </template>
                <template v-for="numberKey in fetchAdditionalKeys">
                    <div :key="numberKey.value" class="modal__body__numpad__key" @click="onKeypadClick(numberKey)">
                        {{ numberKey.value }}
                    </div>
                </template>
                <div class="modal__body__numpad__key" @click="onKeypadClick(delKey)">
                    <img src="/static/img/illustrations/v2/delete-button.svg" />
                </div>
            </div>
            <div class="modal__body__button" @click="closeModal">
                {{ $t('close') }}
            </div>
            <div
                :class="['modal__body__button', 'modal__body__button--submit', { disabled: isButtonDisabled }]"
                @click="submit"
            >
                <div v-if="isLoading" class="btn-loader" />
                <span v-else>{{ $t(buttonLabel) }}</span>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { get as _get, range as _range } from '@lodash';
import TicketService from '@core/services/tickets/TicketService';
import PlatformService from '@src/terminal/core/services/platform/PlatformService';
import commonConfig from '@src/config/common';
import { PayoutErrorType } from '@src/terminal/app/modals/_private/enums';
import { useVuexStore } from '@src/v2/app/utils/vuexStore';
import modalModule from '@store/modules/ui/shared/modal/modalIndex';
import countryModule from '@store/modules/data/country/countryIndex';
import i18n from '@src/app/localization/i18n';

const DEFAULT_VALUE = '';

export default defineComponent({
    name: 'PayoutTicketModalV2',
    setup() {
        const delKey = ref({ value: 'Del' });
        const internalValue = ref(DEFAULT_VALUE);
        const errors = ref<string[]>([]);
        const info = ref<string[]>([]);
        const serialCardNumberMsgCounter = ref(0);
        const serialCardRequired = ref(false);
        const submitDisabled = ref(false);
        const isLoading = ref(false);

        const modalStore = useVuexStore<typeof modalModule>('ui/modal');
        const modalData = modalStore.getter('modalData');
        const closeModal = modalStore.action('closeModal');

        const countryStore = useVuexStore<typeof countryModule>('data/country');
        const userCardSerialNumber = countryStore.getter('userCardSerialNumber');
        const setUserCardSerialNumber = countryStore.action('setUserCardSerialNumber');

        const hasError = computed(() => {
            return !!errors.value.length;
        });

        const buttonLabel = computed(() => {
            return _get(modalData.value, 'button.label') || 'ok';
        });

        const fetchNumberKeys = computed(() => {
            return _range(9).map((i) => ({ value: `${i + 1}` }));
        });

        const ticketId = computed(() => {
            return _get(modalData.value, 'ticketId', 'INVALID_TICKET_ID');
        });

        const isCodeShort = computed(() => {
            return internalValue.value.toString().length < 4;
        });

        const fetchAdditionalKeys = computed(() => {
            return [{ value: 'C' }, { value: '0' }];
        });

        const isButtonDisabled = computed(() => {
            return isCodeShort.value || submitDisabled.value || isLoading.value;
        });

        const onKeypadClick = (numberKey: { value: string }) => {
            if (hasError.value) clearErrors();
            if (info.value.length) clearInfo();

            switch (numberKey.value) {
                case 'C':
                    internalValue.value = DEFAULT_VALUE;
                    break;
                case 'Del':
                    removeValueFromInternalValue();
                    break;
                default:
                    appendValueToInternalValue(numberKey.value);
                    break;
            }
        };

        const appendValueToInternalValue = (value: string) => {
            const newValue = internalValue.value + validateValue(value);
            internalValue.value = newValue;
        };

        const removeValueFromInternalValue = () => {
            const currentValue = internalValue.value;
            internalValue.value = currentValue.slice(0, -1);
        };

        const validateValue = (value: string) => {
            if (hasFourDigits()) {
                return DEFAULT_VALUE;
            }
            return value;
        };

        const hasFourDigits = () => {
            return internalValue.value.toString().length >= 4;
        };

        const clearErrors = () => {
            errors.value = [];
        };

        const pushError = (error: string) => {
            errors.value.push(error);
        };

        const clearInfo = () => {
            info.value = [];
        };

        const pushInfo = (newInfo: string) => {
            info.value.push(newInfo);
        };

        const incrementSerialCardMsg = () => {
            serialCardNumberMsgCounter.value += 1;
        };

        const sendNotification = (msg: string, type = 'error') => {
            PlatformService.getInstance().sendNotification({
                type,
                title: msg,
                text: msg,
            });
        };

        const resolveError = (message: PayoutErrorType) => {
            const strategies = {
                [PayoutErrorType.TICKET_NOT_FOUND]: () => {
                    const msg = i18n.t('ticket not found').toString();
                    sendNotification(msg);
                    setUserCardSerialNumber('');
                    closeModal();
                },
                [PayoutErrorType.WIN_AMOUNT_TOO_LARGE]: () => {
                    const msg = i18n.t('to cash-out this ticket, please go over the counter').toString();
                    sendNotification(msg, 'info');
                    pushInfo(msg);
                    setUserCardSerialNumber('');
                },
                [PayoutErrorType.INVALID_CARD_NUMBER]: () => {
                    serialCardRequired.value = true;
                    if (serialCardNumberMsgCounter.value) {
                        pushError(i18n.t('invalid customer card number').toString());
                    }
                    setUserCardSerialNumber('');
                    incrementSerialCardMsg();
                },
                [PayoutErrorType.INVALID_CONTROL_CODE]: () => {
                    const msg = i18n.t('wrong code').toString();
                    pushError(msg);
                },
                [PayoutErrorType.ALREADY_PAID_OUT]: () => {
                    const msg = i18n.t('already paid out').toString();
                    sendNotification(msg);
                    closeModal();
                },
            };
            const defaultStrategy = () => {
                const msg = i18n.t('Something went wrong. Please try again').toString();
                sendNotification(msg);
            };
            return _get(strategies, message, defaultStrategy)();
        };

        const submit = async () => {
            if (isCodeShort.value || submitDisabled.value) return;
            if (serialCardRequired.value && !userCardSerialNumber.value) return;
            clearErrors();
            clearInfo();
            const id = ticketId.value;
            const controlCode = internalValue.value;

            if (!id && !controlCode) return;

            isLoading.value = true;
            submitDisabled.value = true;
            const response = await TicketService.getInstance().payoutTicket(
                id,
                controlCode,
                userCardSerialNumber.value,
            );
            serialCardRequired.value = false;
            submitDisabled.value = false;
            isLoading.value = false;

            const { error, message } = response;

            if (error) {
                resolveError(message);
                return;
            }
            const msg = i18n.t('ticket paid out successfully').toString().toUpperCase();
            if (commonConfig.environment.isInHouse) {
                PlatformService.getInstance().requestBalanceStatusRequest();
            }
            sendNotification(msg, 'success');
            setUserCardSerialNumber('');
            closeModal();
        };

        watch(userCardSerialNumber, (serialNumber) => {
            if (serialNumber) {
                submit();
            }
        });

        return {
            serialCardRequired,
            hasError,
            internalValue,
            onKeypadClick,
            userCardSerialNumber,
            errors,
            info,
            fetchNumberKeys,
            fetchAdditionalKeys,
            closeModal,
            submit,
            buttonLabel,
            delKey,
            setUserCardSerialNumber,
            serialCardNumberMsgCounter,
            isLoading,
            isButtonDisabled,
        };
    },
    created() {
        this.setUserCardSerialNumber('');
    },
});
</script>
