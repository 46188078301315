<template functional>
    <div class="group-sport-header">
        <div class="group-sport-header__details">
            <component
                :is="props.components.FlagIcon"
                v-if="props.flagCode"
                :flag="`flags/${props.flagCode}.png`"
                :isLarge="props.isLarge"
            />
            <span :title="props.title" class="group-sport-header__details-title">
                {{ props.title }}
            </span>
        </div>
    </div>
</template>

<script>
import FlagIcon from '@shared/components/FlagIcon.vue';

export default {
    functional: true,
    name: 'SectionHeaderRow',
    props: {
        id: {
            type: String,
            required: true,
        },
        flagCode: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        isLarge: {
            type: Boolean,
            default: false,
        },
        components: {
            type: Object,
            default() {
                return {
                    FlagIcon,
                };
            },
        },
    },
};
</script>
