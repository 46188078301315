import ISocket from './models/ISocket';
import IWaitingRequest from './models/IWaitingRequest';

export default class WaitingRequestRegister<T extends { toString: () => string }> {
    private waitingRequests: IWaitingRequest[] = [];

    public add(event: string, request: T) {
        this.waitingRequests.push(new IWaitingRequest(event, request));
    }

    public remove(event: string, request: T) {
        this.waitingRequests.filter(
            (waitingRequest) =>
                waitingRequest.event === event && waitingRequest.request.toString() === request.toString(),
        );
    }

    public isWaiting(event: string, request: T): boolean {
        return !!this.waitingRequests.find(
            (waitingRequest) =>
                waitingRequest.event === event && waitingRequest.request.toString() === request.toString(),
        );
    }

    public emitAll(socket: ISocket) {
        this.waitingRequests.forEach((waitingRequest) => socket.emit(waitingRequest.event, waitingRequest.request));
        this.clear();
    }

    public clear() {
        this.waitingRequests = [];
    }
}
