<template>
    <div class="keypad">
        <span class="keypad__number">
            <span
                v-for="action in keypadActions"
                :key="action"
                :class="`keypad__number__value--${action}`"
                @click="onClick(action)"
            >
                <template v-if="action === KeypadActions.DECIMAL">{{ decimalSeparator }}</template>
                <template v-else>
                    {{ action }}
                </template>
            </span>
        </span>
        <div class="keypad__sidebar">
            <div class="keypad__sidebar__confirm" @click="$emit('onInputClick')">{{ $t('done') }}</div>
            <span class="keypad__sidebar__delete" @click="onClick(KeypadActions.DELETE)">
                <img src="/static/img/illustrations/v2/delete-button.svg" />
            </span>
        </div>
        <div class="keypad__close" @click="onClose">{{ $t('close') }}</div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';

enum KeypadActions {
    CLEAR = 'c',
    DECIMAL = 'decimal',
    DELETE = 'delete',
}

export default defineComponent({
    name: 'NumberKeypadV2',
    emits: ['onInputClick', 'onChange', 'onClose'],
    props: {
        decimalSeparator: {
            type: String,
            required: true,
        },
        initialStake: {
            type: Number,
            required: true,
        },
        inputStake: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const stakeFormat = /^\d+\.?\d{0,2}$/;
        const newStake = ref('');

        const keypadActions = computed(() => {
            const actions = new Array(10).fill(0).map((_, i) => `${i}`);

            actions.splice(0, 0, KeypadActions.CLEAR);
            actions.splice(2, 0, KeypadActions.DECIMAL);

            return actions.reverse();
        });

        const onClick = (value: KeypadActions | string) => {
            switch (value) {
                case KeypadActions.CLEAR:
                    newStake.value = '';
                    break;
                case KeypadActions.DECIMAL:
                    if (!newStake.value) newStake.value = props.inputStake;
                    if (!stakeFormat.test(`${newStake.value}.`)) return;

                    newStake.value += '.';
                    break;
                case KeypadActions.DELETE:
                    if (!newStake.value) newStake.value = props.inputStake;
                    newStake.value = newStake.value.slice(0, -1);
                    break;
                default:
                    if (!stakeFormat.test(newStake.value + value)) return;
                    newStake.value += value;
                    break;
            }

            emit('onChange', newStake.value);
        };

        const onClose = () => {
            emit('onClose', props.initialStake);
            emit('onInputClick');
        };

        watch(
            () => props.inputStake,
            (value) => {
                newStake.value = value;
            },
        );

        return {
            KeypadActions,
            onClick,
            onClose,
            keypadActions,
        };
    },
});
</script>
