import StoreUtil from '@store/StoreUtil';
import Modals from '@store/modules/ui/shared/modal/modals';
import { Commit, Dispatch } from 'vuex';

export interface IModal {
    code: string;
    containerClass?: string;
    overlayClass?: string;
    title?: string;
    // used when default close actions on the modal don't close it. instead they go back on the modal stack
    goBack?: boolean;
    isUnique?: boolean;
    data?: any;
    isIndependent?: boolean;
}

export interface IModalState {
    modalStack: IModal[];
}

const PUSH_MODAL = 'PUSH_MODAL';
const CLEAR_MODAL_STACK = 'CLEAR_MODAL_STACK';
const REMOVE_LAST_N_ENTRIES = 'REMOVE_LAST_N_ENTRIES';
const CLEAR_SPECIFIC_MODAL = 'CLEAR_SPECIFIC_MODAL';

function defaultState(): IModalState {
    return {
        modalStack: [],
    };
}

export default {
    namespaced: true,

    state: defaultState(),

    mutations: {
        [PUSH_MODAL]: (state: IModalState, modal: string | IModal) => {
            if (!modal) {
                return;
            }
            const code = (modal as any).code || modal;
            if (!Modals[(modal as any).code || modal]) {
                throw Error(`No modal for code: ${code}`);
            }
            let modalStackEntry;
            if (typeof modal === 'string') {
                modalStackEntry = Modals[modal];
            } else {
                modalStackEntry = {
                    ...Modals[modal.code],
                    ...modal,
                };

                if (modal.isUnique) {
                    state.modalStack = state.modalStack.filter((existingModal: IModal) => {
                        const code = (modal as IModal).code || modal;
                        const existingCode = existingModal.code;
                        return code !== existingCode;
                    });
                }
            }
            state.modalStack.push(modalStackEntry);
        },
        [CLEAR_MODAL_STACK]: (state: IModalState) => {
            state.modalStack = [];
        },
        [CLEAR_SPECIFIC_MODAL]: (state: IModalState, modalCode: string) => {
            state.modalStack = state.modalStack.filter((modal: IModal) => modal.code !== modalCode);
        },
        [REMOVE_LAST_N_ENTRIES]: (state: IModalState, entryAmount: number) => {
            state.modalStack.splice(-entryAmount);
        },
    },

    getters: {
        modal: (state: IModalState) => state.modalStack[state.modalStack.length - 1],
        modalData: (state: IModalState, getters: any) => {
            if (getters.modal) {
                return getters.modal.data || {};
            }
            return {};
        },
    },

    actions: {
        setModal: ({ commit }: { commit: Commit }, modal: string | IModal) => {
            commit(CLEAR_MODAL_STACK);
            commit(PUSH_MODAL, modal);
        },
        pushModal: StoreUtil.createSimpleMutatorAction(PUSH_MODAL),
        closeModal({ dispatch }: { dispatch: Dispatch }) {
            dispatch('closeModals', 1);
        },
        closeSpecificModal({ commit }: { commit: Commit }, modalCode: string) {
            commit(CLEAR_SPECIFIC_MODAL, modalCode);
        },
        closeAllModals({ dispatch }: { dispatch: Dispatch }) {
            dispatch('closeModals', -1);
        },
        closeModals({ commit }: { commit: Commit }, amountToClose = -1) {
            // -1 Means all;
            if (amountToClose <= -1) {
                commit(CLEAR_MODAL_STACK);
            } else {
                commit(REMOVE_LAST_N_ENTRIES, amountToClose);
            }
        },
    },
};
