<template>
    <div>
        <div class="check-ticket__status">
            <span class="check-ticket__status__title">
                {{ $t(ticketStatusLabels.title) }}
            </span>
            <i18n :path="ticketStatusLabels.descrtiption" tag="span" class="check-ticket__status__description">
                <template #amount>
                    <span class="check-ticket__status__description--amount">
                        {{ payout }} {{ $t('currencyAbbreviation') }}!!!
                    </span>
                </template>
            </i18n>
        </div>
        <div class="check-ticket__body">
            <TicketStatusV2 :ticket="ticket" />
            <div class="check-ticket__body__selections">
                <TicketSelectionV2 v-for="(selection, index) in ticket.events" :key="index" :selection="selection" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Ticket from '@core/models/tickets/Ticket';
import { TicketStatus } from '@core/models/tickets/enums';
import { computed, defineComponent, PropType } from 'vue';
import TicketStatusV2 from '@src/v2/app/modules/checkTicket/TicketStatusV2.vue';
import TicketSelectionV2 from '@src/v2/app/modules/checkTicket/TicketSelectionV2.vue';
import { twoDecimals } from '@shared/filters';

export default defineComponent({
    name: 'TicketOverviewV2',
    components: {
        TicketSelectionV2,
        TicketStatusV2,
    },
    props: {
        ticket: {
            required: true,
            type: Object as PropType<Ticket>,
        },
    },
    setup(props) {
        const payout = computed(() => {
            return twoDecimals(
                props.ticket.status === TicketStatus.active ? props.ticket.win.estimated : props.ticket.win.payoff,
            );
        });

        const ticketStatusLabels = computed(() => {
            switch (props.ticket.status) {
                case TicketStatus.win:
                    return {
                        title: 'ticketWon.title',
                        descrtiption: 'ticketWon.description',
                    };
                case TicketStatus.payed:
                    return {
                        title: 'ticketPayed.title',
                        descrtiption: '',
                    };
                case TicketStatus.refund:
                    return {
                        title: 'ticketRefund.title',
                        descrtiption: '',
                    };
                case TicketStatus.obsolete:
                    return {
                        title: 'ticketObsolete.title',
                        descrtiption: '',
                    };
                case TicketStatus.lost:
                    return {
                        title: 'ticketLost.title',
                        descrtiption: 'ticketLost.description',
                    };
                case TicketStatus.canceled:
                    return {
                        title: 'ticketCancelled.title',
                        descrtiption: '',
                    };
                default:
                    return {
                        title: 'ticketActive.title',
                        descrtiption: '',
                    };
            }
        });

        return {
            payout,
            ticketStatusLabels,
        };
    },
});
</script>
