import FeatureFlagService, { flags } from '@src/terminal/core/services/flags/FeatureFlagService';
import StoreUtil from '@store/StoreUtil';
import { Commit } from 'vuex';

export interface IFlagModuleState {
    isInPlayAvailable: boolean;
    useAllowancesV2: boolean;
    fastlyWafEnabled: boolean;
    usePlatformV2: boolean;
    inactivityTimerValue: number;
    showNewSuperbonusPromo: boolean;
    useDesignV2: boolean;
}

function defaultState(): IFlagModuleState {
    return {
        // ssbt.product.in-play
        isInPlayAvailable: flags.inPlay.defaultValue,
        // allowances v2
        useAllowancesV2: flags.useAllowancesV2.defaultValue,
        fastlyWafEnabled: flags.fastlyWafEnabled.defaultValue,
        usePlatformV2: flags.platformV2.defaultValue,
        inactivityTimerValue: flags.inactivityTimerValue.defaultValue,
        showNewSuperbonusPromo: flags.showNewSuperbonusPromo.defaultValue,
        useDesignV2: flags.designV2.defaultValue,
    };
}

export const Mutations = {
    SET_IS_IN_PLAY_AVAILABLE: 'SET_IS_IN_PLAY_AVAILABLE',
    SET_IS_ALLOWANCES_V2_ENABLED: 'SET_IS_ALLOWANCES_V2_ENABLED',
    SET_IS_FASTLY_WAF_ENABLED: 'SET_IS_FASTLY_WAF_ENABLED',
    SET_IS_PLATFORM_V2: 'SET_IS_PLATFORM_V2',
    SET_INACTIVITY_TIMER_VALUE: 'SET_INACTIVITY_TIMER_VALUE',
    SET_SHOW_NEW_SUPERBONUS_PROMO: 'SET_SHOW_NEW_SUPERBONUS_PROMO',
    SET_IS_DESIGN_V2: 'SET_IS_DESIGN_V2',
};

const mutations = {
    [Mutations.SET_IS_IN_PLAY_AVAILABLE]: StoreUtil.createSimpleMutator('isInPlayAvailable'),
    [Mutations.SET_IS_ALLOWANCES_V2_ENABLED]: StoreUtil.createSimpleMutator('isAllowancesV2Enabled'),
    [Mutations.SET_IS_FASTLY_WAF_ENABLED]: StoreUtil.createSimpleMutator('fastlyWafEnabled'),
    [Mutations.SET_IS_PLATFORM_V2]: StoreUtil.createSimpleMutator('usePlatformV2'),
    [Mutations.SET_INACTIVITY_TIMER_VALUE]: StoreUtil.createSimpleMutator('inactivityTimerValue'),
    [Mutations.SET_SHOW_NEW_SUPERBONUS_PROMO]: StoreUtil.createSimpleMutator('showNewSuperbonusPromo'),
    [Mutations.SET_IS_DESIGN_V2]: StoreUtil.createSimpleMutator('useDesignV2'),
};

const actions = {
    initFlags: ({ commit }: { commit: Commit }): void => {
        commit(Mutations.SET_IS_IN_PLAY_AVAILABLE, FeatureFlagService.getInstance().isInPlayAvailable());
        commit(Mutations.SET_IS_ALLOWANCES_V2_ENABLED, FeatureFlagService.getInstance().useAllowancesV2());
        commit(Mutations.SET_IS_FASTLY_WAF_ENABLED, FeatureFlagService.getInstance().fastlyWafEnabled());
        commit(Mutations.SET_IS_PLATFORM_V2, FeatureFlagService.getInstance().usePlatformV2());
        commit(Mutations.SET_SHOW_NEW_SUPERBONUS_PROMO, FeatureFlagService.getInstance().showNewSuperbonusPromo());
        commit(Mutations.SET_IS_DESIGN_V2, FeatureFlagService.getInstance().useDesignV2());

        // subscriptions
        FeatureFlagService.getInstance().subscribeToFlagChange(flags.inPlay, (value) => {
            console.log(`SSBT:Flag ${flags.inPlay.name} changed to ${value}`);
            commit(Mutations.SET_IS_IN_PLAY_AVAILABLE, value);
        });
        FeatureFlagService.getInstance().subscribeToFlagChange(flags.useAllowancesV2, (value) => {
            console.log(`SSBT:Flag ${flags.useAllowancesV2.name} changed to ${value}`);
            commit(Mutations.SET_IS_ALLOWANCES_V2_ENABLED, value);
        });
        FeatureFlagService.getInstance().subscribeToFlagChange(flags.fastlyWafEnabled, (value) => {
            console.log(`SSBT:Flag ${flags.fastlyWafEnabled.name} changed to ${value}`);
            commit(Mutations.SET_IS_FASTLY_WAF_ENABLED, value);
        });

        FeatureFlagService.getInstance().subscribeToFlagChange(flags.platformV2, (value) => {
            console.log(`SSBT:Flag ${flags.platformV2.name} changed to ${value}`);
            commit(Mutations.SET_IS_PLATFORM_V2, value);
        });

        FeatureFlagService.getInstance().subscribeToFlagChange(flags.inactivityTimerValue, (value) => {
            console.log(`SSBT:Flag ${flags.inactivityTimerValue.name} changed to ${value}`);
            commit(Mutations.SET_INACTIVITY_TIMER_VALUE, value);
        });

        FeatureFlagService.getInstance().subscribeToFlagChange(flags.showNewSuperbonusPromo, (value) => {
            console.log(`SSBT:Flag ${flags.showNewSuperbonusPromo.name} changed to ${value}`);
            commit(Mutations.SET_SHOW_NEW_SUPERBONUS_PROMO, value);
        });

        FeatureFlagService.getInstance().subscribeToFlagChange(flags.designV2, (value) => {
            console.log(`SSBT:Flag ${flags.designV2.name} changed to ${value}`);
            commit(Mutations.SET_IS_DESIGN_V2, value);
        });

        console.log('SSBT:Flags initialized');
    },
};

const getters = {
    isInPlayAvailable: StoreUtil.createSimpleGetter('isInPlayAvailable', flags.inPlay.defaultValue),
    useAllowancesV2: StoreUtil.createSimpleGetter('useAllowancesV2', flags.useAllowancesV2.defaultValue),
    fastlyWafEnabled: StoreUtil.createSimpleGetter('fastlyWafEnabled', flags.fastlyWafEnabled.defaultValue),
    usePlatformV2: StoreUtil.createSimpleGetter('usePlatformV2', flags.platformV2.defaultValue),
    inactivityTimerValue: StoreUtil.createSimpleGetter('inactivityTimerValue', flags.inactivityTimerValue.defaultValue),
    showNewSuperbonusPromo: StoreUtil.createSimpleGetter(
        'showNewSuperbonusPromo',
        flags.showNewSuperbonusPromo.defaultValue,
    ),
    useDesignV2: StoreUtil.createSimpleGetter('useDesignV2', flags.designV2.defaultValue),
};

export default {
    namespaced: true,
    state: defaultState(),
    mutations,
    actions,
    getters,
};
