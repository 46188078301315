import Odd from '@core/models/offer/Odd';
import { SelectionType } from '@core/models/shared/betSlip/betSlipEnums';
import Rule from '@models/shared/betSlip/Rule';
import ISerializedSelection from '@core/models/betSlip/serialization/ISerializedSelection';
import ISelection from '@core/models/betSlip/ISelection';
import SavedOdd from '@core/models/base/SavedOdd';
import ISportlikeEvent from '../base/ISportlikeEvent';
import IBetSlipOdd from '../base/IBetSlipOdd';
import Match from '../offer/Match';
import { OddSourceType, OddSourceTypeValues } from '../tickets/enums';

export interface OddSource {
    type?: OddSourceTypeValues;
    screen?: number;
}
export interface CreateSelectionOptions {
    event?: ISportlikeEvent;
    odd?: Odd;
    itemType?: SelectionType;
    multiNo?: number;
    groupNo?: number;
    isShowFix?: boolean;
    swapPickUpdated?: boolean;
    hasStarted?: boolean;
    hasFinished?: boolean;
    copyingFailed?: boolean;
    uuid?: string;
    source?: OddSource;
}

export default class SportSelection implements ISelection {
    event: ISportlikeEvent;
    // Event can come from offer without odd that is already selected!
    odd: IBetSlipOdd | null;
    savedOdd?: SavedOdd;
    isFixed = false;
    isShowFix = false;
    type: SelectionType = SelectionType.sport;
    source?: OddSource; // move to odd object once we refactor it. It shouldn't depend on Odd from offer

    public toggleFixState(): void {
        this.isFixed = !this.isFixed;
    }

    public getEventId(): number {
        return this.event.id;
    }

    public getMarketId(): number | null {
        return this.odd ? this.odd.marketId : null;
    }
    public getOddUuid(): string | null {
        return this.odd ? this.odd.uuid : null;
    }
    public getTags(): string {
        return this.odd?.tags || '';
    }
    isBetBuilderSource() {
        return this.source?.type === OddSourceType.BET_BUILDER;
    }

    getSourceType() {
        return this.source?.type || 0;
    }

    public getPrefix(): string {
        return this.odd ? this.odd.prefix : '';
    }

    public getOddDescription(): string {
        return this.odd ? this.odd.description : '';
    }

    public getRules(): Rule[] {
        return this.event.rules;
    }

    public getOddSymbol(): string {
        return this.odd ? this.odd.symbol : '';
    }

    public getOddValue(): number | null {
        return this.odd ? this.odd.value : null;
    }

    public getOddId(): number | null {
        return this.odd ? this.odd.id : null;
    }

    public getOddUniqueId(): string | null {
        return this.odd ? (this.odd as Odd).uniqueId : null;
    }

    getTournamentId() {
        return this.event.tournamentId;
    }

    getEventDate(): Date {
        return this.event.date;
    }

    getEventFullName(): string {
        return this.event.fullName;
    }

    public getOddSpecialBetValue(): string {
        return this.odd ? this.odd.specialBetValue : '';
    }

    public isEventFinished(): boolean {
        return this.event.isFinished();
    }

    public isOngoing(): boolean {
        return this.event.isOngoing();
    }

    public isSportType(): boolean {
        return this.getItemType() === SelectionType.sport;
    }

    public isBonusable(): boolean {
        return !!this.odd && this.odd.isBonusable;
    }

    public isPromotional(): boolean {
        return !!this.odd && this.odd.isInPromotion;
    }

    hasOdd(odd: IBetSlipOdd | null): boolean {
        if (this.odd === odd) {
            return true;
        }
        if (!this.odd || !odd) {
            return false;
        }

        return this.odd.id === odd.id && this.odd.specialBetValue === odd.specialBetValue;
    }

    // TODO can be a getter but not necessary
    public isLocked(): boolean {
        return this.odd ? this.odd.isLocked || this.event.isLocked : false;
    }

    public hasEvent(eventId: number, type = SelectionType.sport): boolean {
        return this.getEventId() === eventId && this.getItemType() === type;
    }

    public exists(): boolean {
        const isSportEventStillAvailable =
            this.getItemType() === SelectionType.sport && this.odd && !this.odd.isFinished;
        return !!isSportEventStillAvailable;
    }

    public isEqual(compareItem: ISelection): boolean {
        return this.hasEvent(compareItem.getEventId(), compareItem.getItemType()) && this.hasOdd(compareItem.odd);
    }

    public serialize(): ISerializedSelection {
        return {
            eventId: this.getEventId(),
            oddId: this.getOddId(),
            isFixed: this.isFixed,
            isShowFix: this.isShowFix,
            itemType: this.getItemType(),
            specialBetValue: this.getOddSpecialBetValue(),
            oddValue: this.getOddValue(),
            offerId: this.getOfferId(),
        };
    }

    // TODO move to getters
    getOfferId() {
        return this.event.offerId;
    }

    // TODO remove
    updateValue(item: any) {
        this.odd = item.odd;
    }

    getItemType() {
        return this.type;
    }

    // TODO don't like
    getEvent() {
        return this.event;
    }

    // TODO don't like
    getOdd() {
        return this.odd;
    }

    // TODO don't like
    setOddValue(value: number) {
        if (this.odd) {
            this.odd.value = value;
        }
    }

    isAvailableForBetting() {
        return true;
    }

    getBettingPeriod() {
        return {
            betStart: new Date(), // or further in the past, but it won't make a difference
            betEnd: this.event.date,
        };
    }

    get negotiationName() {
        return this.event.fullName;
    }

    // proxies to event properties
    get isGoingToHaveLiveAudio() {
        if (this.isSportType()) {
            return (<Match>this.event).isGoingToHaveLiveAudio || false;
        }
        return false;
    }

    get marketName() {
        return this.odd ? this.odd.marketName : '';
    }

    get name() {
        return this.odd ? this.odd.marketName : '';
    }

    get isGoingToBeInLiveBetting() {
        if (this.isSportType()) {
            return (<Match>this.event).isGoingToBeInLiveBetting || false;
        }
        return false;
    }

    public static createItem(event: ISportlikeEvent, odd: IBetSlipOdd | null, options: CreateSelectionOptions = {}) {
        let item = new this();

        item.event = event;
        item.odd = odd;
        item = Object.assign(item, options);
        item.source = options.source;
        return item;
    }
}
