<template>
    <div class="spinner-bars" :class="colorClass">
        <div class="spinner-bars__el a" />
        <div class="spinner-bars__el b" />
        <div class="spinner-bars__el c" />
    </div>
</template>

<script>
export default {
    name: 'SpinnerBars',
    props: {
        colorClass: {
            type: String,
            default: '',
        },
    },
};
</script>
