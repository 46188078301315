import {
    validateBetSlip,
    validateSelections,
    BetSlip,
    Selection,
    BetSlipRestrictionsConfig,
    BetSlipError,
    SelectionError,
} from '@superbet-group/betting.lib.validation';
import IValidationService from '@core/services/betSlip/validation/IValidationService';
import IBetSlip, { BetSlipData } from '@models/betSlip/IBetSlip';
import IValidationResult from '@models/betSlip/validation/IValidationResult';
import ServerConfig from '@core/models/country/ServerConfig';
import { instanceGuard } from '@core/utils/services';
import SingletonError from '@core/services/common/errors/SingletonError';
import Singleton from '@core/services/common/Singleton';
import ISelection from '@models/betSlip/ISelection';
import { Payout } from '@models/betSlip/Payout';
import { TicketType } from '@superbet-group/betting.lib.payments';
import config from '@config';
import { BetSlipPurchaseType, BetSlipType } from '@models/shared/betSlip/betSlipEnums';
import MaintenanceService from '@core/services/maintenance/MaintenanceService';
import { TaxService } from '@core/services/betSlip/TaxService';

class SportValidationService implements IValidationService {
    private static instance: SportValidationService;

    public static getInstance(): SportValidationService {
        return instanceGuard(SportValidationService.instance);
    }

    public static createInstance(config: ServerConfig) {
        if (!SportValidationService.instance) {
            SportValidationService.instance = new SportValidationService(config);
        }
        return SportValidationService.instance;
    }

    private constructor(private config: ServerConfig) {
        if (SportValidationService.instance) {
            throw new SingletonError(this.constructor.name);
        }
    }

    public validate(betSlip: IBetSlip, isSubmit = false, isInPlayAvailable = false): IValidationResult {
        return {
            betSlipError: this.validateSportBetSlip(betSlip, isSubmit)[0] || null,
            selectionErrors: this.validateSportSelections(
                betSlip.type,
                betSlip.selections,
                betSlip.stake,
                isSubmit,
                isInPlayAvailable,
            ),
        };
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete SportValidationService.instance;
    }

    private validateSportBetSlip(betSlip: BetSlipWeb, isSubmit: boolean): BetSlipError[] {
        const enabledWinLowerThanStakeCheck =
            !config.countrySpecific.betSlip.validationsConfig.isEnabledWinLowerThanStake;

        const betslipConfig: BetSlipRestrictionsConfig = {
            enabledWinLowerThanStakeCheck,
            maxStake: this.config.betSlip.stakeMax,
            minStakeForSimple: config.app.betSlip.minStakeForSimple,
            minStakeForSystem: config.app.betSlip.minStakeForSystem,
            minStakePerCombination: this.config.betSlip.minStakePerCombination,
            maxSelectedNumbers: this.config.betSlip.maxSelectedNumbers,
            maxWin: this.config.betSlip.maxWin,
            enabledEmptyBetSlip: true,
            enabledNoSelectedSystemCombination: true,
            enabledSubmitWithMaxWinCheck: !config.countrySpecific.betSlip.allowSubmitWithMaxWin,
        };

        const rounding = {
            toTwoDecimals: (value: number) => value.toString(),
        };

        return validateBetSlip(mapBetSlip(betSlip), betslipConfig, rounding, isSubmit);
    }

    private validateSportSelections(
        betSlipType: BetSlipType,
        selections: ISelection[],
        stake: number,
        isSubmit: boolean,
        isInPlayAvailable: boolean,
    ): Record<string, SelectionError> {
        const config = {
            enabledRetailEventStarted: !isInPlayAvailable,
            enabledEventMaintenance: true,
            enabledEventIsFinished: true,
            enabledOddIsFinished: true,
            enabledSelectionIsLocked: true,
            enabledSelectionIsUnavailableForBetting: true,
        };

        const selectionErrors = validateSelections({
            stake,
            isSubmit,
            config,
            selections: mapSelections(selections),
            getNetStake: (stake: number) =>
                TaxService.getInstance().getStakeAfterDeductions(stake, BetSlipPurchaseType.offline),
            maintenanceFlags: {
                isPrematchInMaintenance: MaintenanceService.getInstance().isLiveInMaintenance(),
                isLiveInMaintenance: MaintenanceService.getInstance().isSportInMaintenance(),
            },
            minStake: this.getMinStake(betSlipType),
        });
        return mapSelectionErrors(selectionErrors);
    }

    private getMinStake(betSlipType: BetSlipType) {
        if (betSlipType === BetSlipType.simple) {
            return config.app.betSlip.minStakeForSimple;
        }
        return config.app.betSlip.minStakeForSystem;
    }
}

export default SportValidationService as Singleton<SportValidationService>;

interface BetSlipWeb extends BetSlipData {
    payout: Payout;
}

function mapBetSlip(betSlip: BetSlipWeb): BetSlip {
    return {
        stake: betSlip.stake,
        stakePerCombination: betSlip.stakePerCombination,
        type: betSlip.type, // should we remap this
        purchaseType: TicketType.retail,
        selectedSystems: betSlip.selectedSystems.map((system) => ({
            minNumber: system.getMinNumber(),
            fromNumber: system.getFromNumber(),
        })),
        payout: {
            base: betSlip.payout.base,
            final: betSlip.payout.final,
            hasBeenLimited: betSlip.payout.hasBeenLimited,
        },
        selections: mapSelections(betSlip.selections),
    };
}

function mapSelections(selections: ISelection[]): Selection[] {
    return selections.map((selection) => ({
        uuid: `${selection.getEventId()}`,
        eventId: selection.getEventId(),
        marketId: selection.getMarketId() || undefined,
        tournamentId: selection.getTournamentId(),
        isEventFinished: selection.isEventFinished(),
        isOngoing: selection.isOngoing(),
        isLocked: selection.isLocked(),
        exists: selection.exists(),
        date: selection.event.date,
        odd: selection.odd,
        tags: selection.getTags(),
        rules: selection.getRules() as Selection['rules'],
        isAvailableForBetting: selection.isAvailableForBetting(),
        bettingPeriod: selection.getBettingPeriod(),
    }));
}

function mapSelectionErrors(selectionErrors: SelectionError[]) {
    const result: Record<string, SelectionError> = {};
    selectionErrors.forEach((error) => {
        if (!result[error.uuid]) {
            result[error.uuid] = error;
        }
    });

    return result;
}
