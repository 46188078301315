export enum TimeFilter {
    live = 'live',
    upcoming = 'upcoming',
    today = 'today',
    allToday = 'allToday',
    prematch = 'prematch',
    tomorrow = 'tomorrow',
    next3H = 'next3H',
    next6H = 'next6H',
    next12H = 'next12H',
    sunday = 'sunday',
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    all = 'all',
}
