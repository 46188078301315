<template>
    <div class="search-results__page">
        <Content class="search-results__container" :sectionIds="sectionIds" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { errorGuard } from 'src/app/utils';
import Content from './content';
import sectionIds from './sectionIds';

export default {
    name: 'SearchContainer',
    data() {
        return {
            sectionIds,
        };
    },
    async created() {
        await errorGuard({
            action: () => this.fetchAll(),
        });
    },
    methods: {
        ...mapActions('data/sportOffer', ['fetchAll']),
    },
    components: {
        /* eslint-disable vue/no-unused-components */
        Content,
        /* eslint-enable vue/no-unused-components */
    },
};
</script>
