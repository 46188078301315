import { find as _find } from '@lodash';
import { BetSlipError, SelectionError, SelectionErrorType } from '@superbet-group/betting.lib.validation';
import IBetSlipError from '@models/betSlip/validation/IBetSlipError';

export default interface IValidationResult {
    betSlipError: IBetSlipError | BetSlipError | null;
    selectionErrors: Record<string, SelectionError>;
}

export const validationResultService = {
    getFirstErrorWithAddSelectionRejection(validationResult: IValidationResult, eventId: string) {
        if (validationResult.betSlipError && !validationResult.betSlipError.isSelectionAddable) {
            return validationResult.betSlipError;
        }

        const mySelectionError: SelectionError = validationResult.selectionErrors[eventId];

        if (
            mySelectionError &&
            (
                [
                    SelectionErrorType.EventIsFinished,
                    SelectionErrorType.OddIsFinished,
                    SelectionErrorType.SelectionIsLocked,
                ] as SelectionErrorType[]
            ).includes(mySelectionError.type)
        ) {
            return mySelectionError;
        }

        return _find(validationResult.selectionErrors, (e) => !e.isSelectionAddable);
    },
};
