<template>
    <div class="ticket-status">
        <img :src="ticketStatusIcon" class="ticket-status__indicator" />
        <template v-if="ticket.system">
            <span class="ticket-status__separator" />
            <span class="ticket-status__system">{{ $t('system') }}</span>
        </template>
        <template v-else>
            <span class="ticket-status__separator" />
            <span class="ticket-status__system">{{ $t('simple') }}</span>
        </template>
        <template v-if="hasLive">
            <span class="ticket-status__separator" />
            <span class="ticket-status__live">{{ $t('live') }}</span>
        </template>
        <template v-if="wonSelectionsNum">
            <span class="ticket-status__separator" />
            <span class="ticket-status__selections won">{{ wonSelectionsNum }}</span>
        </template>
        <template v-if="lostSelectionsNum">
            <span class="ticket-status__separator" />
            <span class="ticket-status__selections lost">{{ lostSelectionsNum }}</span>
        </template>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import Ticket from '@core/models/tickets/Ticket';
import { EventStatus, MarketType, TicketStatus } from '@core/models/tickets/enums';

export default defineComponent({
    name: 'TicketStatusV2',
    props: {
        ticket: {
            required: true,
            type: Object as PropType<Ticket>,
        },
    },
    setup(props) {
        const hasLive = computed(() => {
            return props.ticket.events.some((event) => event.marketType === MarketType.IN_PLAY);
        });

        const wonSelectionsNum = computed(() => {
            return props.ticket.events.filter((event) => event.status === EventStatus.win).length;
        });

        const lostSelectionsNum = computed(() => {
            return props.ticket.events.filter((event) => event.status === EventStatus.lost).length;
        });

        const ticketStatusIcon = computed(() => {
            switch (props.ticket.status) {
                case TicketStatus.win:
                case TicketStatus.payed:
                case TicketStatus.refund:
                    return '/static/img/illustrations/v2/ticket-won.svg';
                case TicketStatus.lost:
                case TicketStatus.canceled:
                case TicketStatus.obsolete:
                    return '/static/img/illustrations/v2/ticket-lost.svg';
                default:
                    return '/static/img/illustrations/v2/ticket-active.svg';
            }
        });

        return {
            ticketStatusIcon,
            hasLive,
            wonSelectionsNum,
            lostSelectionsNum,
        };
    },
});
</script>
