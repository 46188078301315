<template>
    <div>
        <slot />
        <div class="button-group">
            <button class="btn btn__secondary" @click="$emit('cancel')">
                {{ $t(cancelLabel) | capitalize }}
            </button>
            <button class="btn btn__primary" @click="$emit('continue')">
                {{ $t(continueLabel) | capitalize }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DialogComponent',
    props: {
        cancelLabel: {
            type: String,
            required: false,
            default: 'cancel',
        },
        continueLabel: {
            type: String,
            required: false,
            default: 'continue',
        },
    },
};
</script>
