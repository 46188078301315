/* eslint-disable import/no-named-default */
import { ITask } from '@src/terminal/core/services/gravity/_private/Task';
import store from '@app/store';
import ServerConfig from '@core/models/country/ServerConfig';
import Modals from '@store/modules/ui/shared/modal/modals';
import PlatformService from '../../platform/PlatformService';

class TicketsCancelTask implements ITask {
    private readonly config: ServerConfig;

    constructor(config: ServerConfig) {
        this.config = config;
    }

    execute = async (message?: any): Promise<void> => {
        try {
            const ticketId = message.data.ticket.slip.codes[0].id;
            const root = { root: true };
            const config = {
                code: Modals.cancelTicket.code,
                containerClass: 'cancle-ticket-modal--terminal',
                data: {
                    ticketId,
                    button: {
                        lable: 'confirm',
                        enabled: true,
                        className: 'btn modal-btn--terminal btn--block btn--cancel-ticket',
                    },
                },
            };
            store.dispatch('ui/modal/setModal', config, root);
        } catch (err: any) {
            const title = err.message || 'Error occurred';
            PlatformService.getInstance().sendNotification({
                title,
                text: title,
                type: 'error',
            });
        }
    };
}

export default TicketsCancelTask;
