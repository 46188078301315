<template>
    <div class="modal__overlay">
        <div class="modal__container warning-modal warning-limit">
            <div class="modal__header">
                <div class="modal__title">
                    {{ $i18n.t('Incompatible selections') }}
                </div>
            </div>
            <div class="warning-body">
                <div class="warning-image-wrapper">
                    <img src="/static/img/illustrations/terminal/oddBlocked.svg" alt="image" width="368" height="126" />
                </div>
                <span class="full-width warning-text" v-html="getSuperOddMessage()" />
                <div class="warning-two-buttons">
                    <div class="warning-button-container">
                        <button class="warning-button btn left-btn" @click="closeModal()">
                            {{ $i18n.t('cancel') }}
                        </button>
                    </div>
                    <div class="warning-button-container" @click="replaceOdds()">
                        <button class="warning-button btn right-btn">
                            {{ $i18n.t('replace') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import ISelection from '@models/betSlip/ISelection';

export default {
    name: 'BoostedOddConflict',
    computed: {
        ...mapGetters('ui/modal', ['modalData']),
        isNewOddBoosted() {
            return this.modalData.data.isNewOddBoosted;
        },
        newSelectionName(): string {
            return this.getSelectionName(this.modalData.data.newSelection);
        },
        removedSelections() {
            return this.modalData.data.selections;
        },
        removedSelectionCount() {
            return this.modalData.data.selections.length;
        },
        firstRemovedSelectionName() {
            if (!this.removedSelections.length) {
                return 0;
            }

            return this.getSelectionName(this.removedSelections[0]);
        },
    },
    methods: {
        ...mapActions('ui/modal', ['closeModal']),

        replaceOdds() {
            this.$emit('close');
        },
        getSelectionName(selection: ISelection): string {
            return [selection.event.team1Name, selection.event.team2Name].join(' - ');
        },
        getSuperOddMessage() {
            const isNewOddMessage = this.$i18n.t(
                'SuperOdd cannot be combined with other selections. Want to replace {removedSelectionCount} selections with <strong>{newSelectionName} SuperOdd</strong>',
                {
                    removedSelectionCount: this.removedSelectionCount,
                    newSelectionName: this.newSelectionName,
                },
            );
            const isOldOddMessage = this.$i18n.t(
                'SuperOdd cannot be combined with other selections. Want to replace <strong>{firstRemovedSelectionName} SuperOdd </strong>with {newSelectionName}',
                {
                    firstRemovedSelectionName: this.firstRemovedSelectionName,
                    newSelectionName: this.newSelectionName,
                },
            );
            return this.isNewOddBoosted ? isNewOddMessage : isOldOddMessage;
        },
    },
};
</script>

<style scoped></style>
