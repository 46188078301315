import { orderBy as _orderBy, partition as _partition } from '@lodash';
import { MarketAttribute } from '@core/constants';
import Match from '@core/models/offer/Match';
import Market from '@core/models/offer/Market';

export type MarketForArrange = Pick<
    Market,
    'id' | 'order' | 'odds' | 'uniqueId' | 'name' | 'isSuperbet' | 'isLocked' | 'isMain' | 'containsAttribute'
>;
type MatchMarket = ReturnType<Match['getMarkets']>[0];

export function orderMarkets(
    markets: MatchMarket[],
    primaryMarketAttribute: string,
    primaryOrderCriteria: (market: MatchMarket) => boolean,
) {
    let primary = [];
    let secondary = [];
    const checkFunction =
        primaryMarketAttribute === MarketAttribute.main
            ? (market: MatchMarket) => market.isMain
            : (market: MatchMarket) => {
                  try {
                      return market.containsAttribute(primaryMarketAttribute);
                  } catch (err) {
                      return false;
                  }
              };
    [primary, secondary] = _partition(markets, checkFunction);

    return primary.concat(_orderBy(secondary, primaryOrderCriteria, ['desc']));
}
