/* eslint-disable */
import { PluginFunction } from 'vue';
interface IVirtualKeyboardActions {
    onConfirm: Function;
    onChange: Function;
    onHide: Function;
}

export default {
    install(vue: PluginFunction<{}>) {
        vue.prototype.showVirtualKeyboard = (e: any, virtualKeyboardActions: IVirtualKeyboardActions) => {
            if (window.SSBT && window.SSBT.VirtualKeyboard) {
                window.SSBT.VirtualKeyboard.show(e, virtualKeyboardActions);
            }
        };
        vue.prototype.hideVirtualKeyboard = () => {
            if (window.SSBT && window.SSBT.VirtualKeyboard) {
                window.SSBT.VirtualKeyboard.hide();
            }
        };
    },
};
