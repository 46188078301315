import { Payout } from '@models/betSlip/Payout';
import CalculationHelper from '@core/services/betSlip/CalculationHelper';
import TaxService from '@core/services/betSlip/TaxService';

import ISelection from '@models/betSlip/ISelection';
import { PayoutService } from '@core/services/betSlip/PayoutService';
import { SuperBonusValidBetTypes } from '@superbet-group/betting.lib.payments';
import IBetSlipState from '../types';

export default {
    payout(state: IBetSlipState): Payout {
        return state.payout;
    },
    stakeAfterTax(state: IBetSlipState, getters: any): number {
        const taxService = TaxService.getInstance();
        return CalculationHelper.round(taxService.getStakeAfterDeductions(getters.stake, getters.purchaseType), 2);
    },
    bonusAmount(state: IBetSlipState, getters: any): number {
        return getters.payout.bonus;
    },
    bonusPercentage(state: IBetSlipState, getters: any): number {
        if (getters.isSimple || getters.isOnlyXXSelected) {
            return PayoutService.getInstance().getSuperBonus(getters.selectionsWithOdd, SuperBonusValidBetTypes.Simple)
                .current;
        }

        return 0;
    },
    totalCoefficient(state: IBetSlipState, getters: any): number {
        let total = 1;
        getters.selectionsWithOdd.forEach((s: ISelection) => {
            total *= s.getOddValue()!;
        });
        return total;
    },
    taxPercentage(state: IBetSlipState) {
        return TaxService.getInstance().getStakeTaxByPurchaseType(state.purchaseType);
    },
    isSuperBonusEnabled() {
        return PayoutService.getInstance().isSuperBonusEnabled;
    },
    superBonus(state: any, getters: any) {
        const betType =
            getters.isSimple || getters.isOnlyXXSelected
                ? SuperBonusValidBetTypes.Simple
                : SuperBonusValidBetTypes.System;
        return PayoutService.getInstance().getSuperBonusEligibility({
            purchaseType: state.purchaseType,
            stake: getters.stake || 0,
            selections: getters.selectionsWithOdd,
            betType,
        });
    },
    superBonusPercentage(state: any, getters: any) {
        const betType =
            getters.isSimple || getters.isOnlyXXSelected
                ? SuperBonusValidBetTypes.Simple
                : SuperBonusValidBetTypes.System;
        return PayoutService.getInstance().getSuperBonus(getters.selectionsWithOdd, betType);
    },
};
