<template>
    <button :class="{ btn: true, 'is-loading': isLoading }" :disabled="disabled || isLoading" v-on="$listeners">
        <SpinnerBars v-if="isLoading" />
        <slot v-else />
    </button>
</template>

<script>
import SpinnerBars from '@shared/components/SpinnerBars.vue';

export default {
    name: 'CustomButton',
    components: {
        SpinnerBars,
    },
    props: {
        isLoading: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
