<template>
    <div>
        <!-- <form @submit.prevent="checkTicketMock()">
            <input type="text" v-model="ticketIdMock"/>
            <input type="submit"/>
        </form> -->
        <ticket-overview v-if="data" :ticketData="data" />
    </div>
</template>

<script>
import PlatformService from '@src/terminal/core/services/platform/PlatformService';
import TicketService from '@core/services/tickets/TicketService';
import { mapGetters } from 'vuex';
import { TicketStatus, EventType } from '@models/tickets/enums';
import CheckTicketMapper from '@core/mappers/tickets/CheckTicketMapper';
import TicketOverview from './TicketOverview.vue';

const uniqueId = () => `${Date.now().toString(36)}${Math.random().toString(36).slice(2)}`;

export default {
    name: 'CheckTicketNsoft',
    components: {
        TicketOverview,
    },
    data() {
        return {
            data: null,
            ticketIdMock: '',
            checkTicketInstance: uniqueId(),
        };
    },
    watch: {
        async checkedTicket({ id }) {
            if (id) {
                const mapper = new CheckTicketMapper();
                const prepareData = (ticket) => {
                    const ticketData = mapper.map(ticket);
                    this.parseData(id, ticketData);
                };
                const data = await TicketService.getInstance().getTicketById(id);
                prepareData(data);
                TicketService.getInstance().subscribeToTicketById(id, prepareData);
            }
        },
    },
    computed: {
        ...mapGetters('data/country', ['checkedTicket']),
    },
    methods: {
        // for manually testing the ticket, just uncomment the html on the top
        // ...mapActions('data/country', ['setCheckedTicket']),
        // checkTicketMock() {
        //     this.setCheckedTicket({ id: this.ticketIdMock, checkedTime: Date.now() });
        // },
        parseData(originalId, ticket) {
            console.log('parsing data for instance', ticket.ticketId, this.checkTicketInstance);
            const productId = 'SuperbetPrematch';
            const actions = {
                rebet: { active: this.isRebetAvailable(ticket) },
                cancel: { active: this.isCancelAvailable(ticket) },
            };
            const slip = {
                productDisplayId: productId,
                codes: [
                    {
                        type: 'code39',
                        id: originalId,
                    },
                ],
            };
            const message = {
                msgSender: 'Slave',
                slaveId: productId,
                action: 'Tickets.UpdateTicketCheckState',
                data: {
                    ticket: { slip },
                    actions,
                },
            };
            console.log('SSBT::NSoft ticket check message obj', message);
            this.data = {
                ticket,
                actions,
            };
            console.log('SSBT::Check ticket data', this.data);
            PlatformService.getInstance().postMessage(message, window.document.origin);
        },
        isRebetAvailable(ticket) {
            return ticket.events.some((event) => event.status === TicketStatus.active);
        },
        areSomeEventsStarted(events) {
            return events.some((event) => {
                const now = new Date();
                const startDate = new Date(event.date);
                return startDate < now;
            });
        },
        isCancelAvailable(ticket) {
            if (ticket.isTemplate) return false;
            const nonCancelableStatuses = [
                TicketStatus.win,
                TicketStatus.canceled,
                TicketStatus.lost,
                TicketStatus.payed,
                TicketStatus.refund,
            ];
            if (nonCancelableStatuses.includes(ticket.status)) {
                return false;
            }
            if (this.areSomeEventsStarted(ticket.events)) {
                return false;
            }
            if (ticket.events.some((event) => event.type === EventType.live)) {
                return false;
            }
            const now = new Date();
            const cancelLimit = new Date(ticket.dateReceived);
            const cancelTimeout = 60 * 15;
            cancelLimit.setSeconds(cancelLimit.getSeconds() + cancelTimeout);
            return now.getTime() < cancelLimit.getTime();
        },
    },
};
</script>
