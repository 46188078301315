<template>
    <div class="event-sport-summary" :class="team1Name === '' && team2Name === '' ? 'outrights-title' : ''">
        <div class="event-sport-summary__competitors-and-scores">
            <div class="event-sport-summary__competitors-wrapper">
                <span v-if="team1Name" class="event-sport-summary__competitors-team1" :title="team1Name">
                    <span v-if="isLive && liveData" class="event-summary-match-indicator-box">
                        <span class="event-summary__match-indicator-period-half">{{ periodHalf }}</span>
                    </span>
                    <span
                        v-if="team1PreviousResult"
                        :title="$t('previuos match result') | capitalize"
                        class="event-sport-summary__competitors-past-result"
                    >
                        {{ team1PreviousResult }}
                    </span>
                    {{ team1Name }}
                </span>
                <span v-else-if="fullName" class="event-sport-summary__competitors-team1" :title="fullName">
                    {{ fullName }}
                </span>
                <span v-if="team2Name" class="event-sport-summary__competitors-team2" :title="team2Name">
                    <span v-if="isLive && liveData" class="event-summary-match-indicator-box">
                        <span class="event-summary__match-indicator-period-minute">{{ periodMinute }}</span>
                    </span>
                    <span
                        v-if="team2PreviousResult"
                        :title="$t('previuos match result') | capitalize"
                        class="event-sport-summary__competitors-past-result"
                    >
                        {{ team2PreviousResult }}
                    </span>
                    {{ team2Name }}
                </span>
                <div v-if="!isLive" class="event-sport-summary__prematch-date-wrapper">
                    <i class="sds-icon-time-history event-sport-summary__prematch-date-icon"></i>
                    <span class="event-sport-summary__prematch-date">
                        {{ startDate | formatDateTime(dateFormat(startDate)) }} {{ startDate | timeOnly }}
                    </span>
                </div>
            </div>
            <div v-if="hasScore" class="event-summary__score-wrapper">
                <ScoreWidget
                    v-if="isLive && liveData"
                    :liveData="liveData"
                    :sportId="sportId"
                    :isScoreCompact="isScoreCompact"
                />
                <div v-else-if="result" class="event-summary__score--simple">
                    <div>{{ result.team1Score }}</div>
                    <div>{{ result.team2Score }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { capitalize } from '@modules/shared/filters';
import ScoreWidget from '@src/terminal/app/modules/shared/event/ScoreWidget.vue';
import config from '@config';
import DateUtils from '@core/utils/date/DateUtils';

export default {
    components: {
        ScoreWidget,
    },
    props: {
        team1Name: {
            type: String,
            required: true,
        },
        team2Name: {
            type: String,
            required: true,
        },
        fullName: {
            type: String,
            required: true,
        },
        team1PreviousResult: {
            type: String,
            required: false,
            default: '',
        },
        team2PreviousResult: {
            type: String,
            required: false,
            default: '',
        },
        viewingOptions: {
            type: Object,
            required: false,
            default: null,
        },
        isLive: {
            type: Boolean,
            required: true,
        },
        startDate: {
            type: Date,
            required: true,
        },
        liveData: {
            type: Object,
            required: false,
            default: null,
        },
        result: {
            type: Object,
            default: null,
        },
        sportId: {
            type: Number,
            required: false,
        },
        isScoreCompact: {
            type: Boolean,
            default: true,
        },
        isInGrid: {
            type: Boolean,
            required: false,
            default: false,
        },
        isCodeShown: {
            type: Boolean,
            required: false,
            default: false,
        },
        code: {
            type: [Number, String],
            required: false,
            default: '',
        },
        cap: {
            type: Function,
            required: false,
            default: capitalize,
        },
        dateFormat: {
            type: Function,
            required: false,
            default: (date) => {
                const now = new Date();
                const isInFuture =
                    DateUtils.differenceInCalendarDays(now, date) > config.app.sportOffer.gridDateFutureThreshold;
                if (isInFuture) {
                    return 'ddmm';
                }
                return 'w';
            },
        },
    },
    computed: {
        periodHalf() {
            return this.liveData.isStoppageTime ? `+${this.liveData.stoppageTime}'` : this.liveData.periodStatus;
        },
        periodMinute() {
            return this.liveData.minute ? `${this.liveData.minute}'` : '';
        },
        hasScore() {
            return (this.isLive && this.liveData) || this.result;
        },
    },
};
</script>
