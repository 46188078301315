import { orderBy as _orderBy } from '@lodash';
import StoreUtil from '@app/store/StoreUtil';
import ITicketsState from './types';

export default {
    tickets: (state: ITicketsState) => _orderBy(Object.values(state.tickets), ['dateReceived'], ['desc']),
    ticketsMap: StoreUtil.createSimpleGetter('tickets'),
    getTicket: (state: ITicketsState) => (id: string) => state.tickets[id],
    isTicketSubmitting: StoreUtil.createSimpleGetter('isTicketSubmitting'),
    isTicketNegotiating: StoreUtil.createSimpleGetter('isTicketNegotiating'),
    isTicketPrinted: StoreUtil.createSimpleGetter('isTicketPrinted'),
    submitErrors: StoreUtil.createSimpleGetter('submitErrors'),
    ticketNegotiation: StoreUtil.createSimpleGetter('ticketNegotiation'),
};
