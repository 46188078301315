<template>
    <ul id="tree-container" class="nav-sidebar-tree--terminal sidebar__responsive__element">
        <TreeItem
            v-for="item in items"
            :key="item.id"
            :item="item"
            :activeIds="activeIds"
            :depth="1"
            :isActive="activeIds[0] === item.id"
            :isCurrentSelection="activeIds[0] === item.id && activeIds.length === 1"
            :showItemCount="showItemCount"
            :showToplevelFlag="showToplevelFlag"
            :showSubitemFlag="showSubitemFlag"
            @click="onClick"
        />
    </ul>
</template>

<script>
import { isEqual as _isEqual } from '@lodash';
import Vue from 'vue';
import TreeItem from './TreeItem.vue';

export default Vue.component('Tree', {
    name: 'Tree',
    components: {
        TreeItem,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        activeIds: {
            type: Array,
            required: true,
        },
        isClosable: {
            type: Boolean,
            required: false,
            default: true,
        },
        showItemCount: {
            type: Boolean,
            required: false,
            default: true,
        },
        showToplevelFlag: {
            type: Boolean,
            required: false,
            default: false,
        },
        showSubitemFlag: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    methods: {
        onClick(itemsArray, isLeaf) {
            if (
                this.isClosable &&
                _isEqual(
                    this.activeIds,
                    itemsArray.map((item) => item.id),
                ) &&
                !isLeaf
            ) {
                itemsArray.pop();
            }
            this.$emit('itemClick', itemsArray);
        },
    },
});
</script>
