<template>
    <div class="betslip">
        <BetSlipHeader
            v-if="!isEmpty || undoSelections"
            :isSimple="isSimple"
            :hasTypes="true"
            :selections="selections"
            :undoSelections="undoSelections"
            @typeClick="onTypeClick"
            @clear="onClearClick"
            @undoClear="undoClear"
        />
        <template v-if="isEmpty">
            <BetslipSuperBonusOverlay v-if="showSuperbonusPromoOverlay" @onClose="onSuperBonusOverlayClose" />
            <div v-else class="betslip--empty">
                <Illustration image="/static/img/illustrations/v2/betslip-empty.svg" />
                <div class="betslip--empty__label">
                    {{ $t('Betslip is empty. Select events from offer and place your bet.') }}
                </div>
            </div>
        </template>
        <transition v-if="isTicketSubmitting || isRecreatingTicket || isTicketPrinted" name="animation-ease">
            <div
                :class="[
                    'betslip--disabled',
                    { loading: isTicketSubmitting || isRecreatingTicket },
                    { printed: isTicketPrinted },
                    { error: hasErrors },
                ]"
            />
        </transition>
        <template v-if="!isEmpty">
            <div class="betslip__body">
                <SystemsSelect
                    v-if="isSystemExpand && !isDeleteConfirmExpanded"
                    :systems="systems"
                    :fixedSelectionsCount="fixedSelectionsCount"
                    @systemToggle="onSystemToggle"
                />
                <div v-if="isDeleteConfirmExpanded" class="betslip__body__delete">
                    <div class="betslip__body__delete__actions">
                        <div class="betslip__body__delete__actions--cancel" @click="onDeleteCancel">
                            {{ $t('cancel') }}
                        </div>
                        <div class="betslip__body__delete__actions--confirm" @click="onDeleteConfirm">
                            {{ $t('confirm') }} ({{ selections.length }})
                        </div>
                    </div>
                    <div class="betslip__body__delete--label">{{ $t('Clear betslip') }}?</div>
                </div>
                <template v-else>
                    <SuperBonusBannerV2
                        v-if="showSuperBonusBanner"
                        :minSuperBonusOddValue="minSuperBonusOddValue"
                        :minNumberOfSelectionsForSuperBonus="minSuperBonusBreakpoint.numberOfSelections"
                        :minSuperBonusPercentage="minSuperBonusBreakpoint.percentage"
                        :isMaxSuperBonusUnlocked="isMaxSuperBonusUnlocked"
                        :superBonusPercentage="superBonusPercentage"
                    />
                    <div class="betslip__body__selections">
                        <SimpleBetSlipV2
                            v-if="isSimple"
                            :selections="selections"
                            :selectionErrors="selectionErrors"
                            :oddValueDynamics="oddValueDynamics"
                            @removeClick="removeBet"
                        />
                        <SystemBetSlipV2
                            v-else
                            :systems="systems"
                            :fixedSelectionsCount="fixedSelectionsCount"
                            :selections="selections"
                            :selectionErrors="selectionErrors"
                            :oddValueDynamics="oddValueDynamics"
                            @systemClick="toggleSystemSelected"
                            @systemToggle="onSystemToggle"
                            @fixClick="toggleSelectionIsFixed"
                            @removeClick="removeBet"
                        />
                    </div>
                </template>
                <div v-if="!isSystemExpand" class="betslip__body__details">
                    <TicketNegotiationV2
                        v-if="!!ticketNegotiation"
                        :ticketNegotiation="ticketNegotiation"
                        @accept="acceptTicketNegotiation"
                        @decline="refuseTicketNegotiation"
                    />
                    <template v-else>
                        <div v-if="hasErrors" class="betslip__body__details--errors">
                            <Errors
                                v-if="changes.length"
                                :showCloseButton="false"
                                :errors="changesStrings"
                                :areValidationErrors="false"
                                :shouldFadeOut="false"
                                @fadeOut="acceptAllChanges"
                            />
                            <Errors
                                v-if="selectionAddError"
                                :showCloseButton="false"
                                :errors="[selectionAddError]"
                                :areValidationErrors="true"
                                :shouldFadeOut="true"
                                :duration="ERROR_FADEOUT_TIME"
                                @fadeOut="removeSelectionAddError"
                            />
                            <Errors
                                v-if="errors.length"
                                :showCloseButton="false"
                                :errors="errors"
                                :areValidationErrors="true"
                                :shouldFadeOut="false"
                            />
                            <Errors
                                v-if="submitErrors.length"
                                :showCloseButton="false"
                                :errors="submitErrors"
                                :shouldFadeOut="false"
                                @fadeOut="removeError"
                            />
                        </div>
                        <LuckyLoserNotification v-if="showLuckyLoser" :isLuckyLoser="payout.isPariulSansa" />
                        <div v-if="!isSimple" class="betslip__body__details__system" @click="onSystemToggle">
                            {{ systemsPrint || $t('chooseSystems') }}
                        </div>
                        <StakeV2
                            :initialStake="initialStake"
                            :stake="rawStake"
                            :isSimple="isSimple"
                            :totalNumberOfSelectedCombinations="totalNumberOfSelectedCombinations"
                            :isInputExpanded="isInputExpanded"
                            @setInitialStake="setInitialStake"
                            @onInputClick="onInputClick"
                            @change="setStake"
                        />
                        <template v-if="!isInputExpanded">
                            <PayoutSummaryV2
                                :totalCoefficient="totalCoefficient"
                                :stake="rawStake"
                                :stakeAfterTax="stakeAfterTax"
                                :bonusAmount="bonusAmount"
                                :bonusPercentage="superBonusPercent"
                                :payout="payout"
                                :isSystem="!isSimple"
                            />
                            <CustomButton
                                :disabled="isTicketSubmitting || isRecreatingTicket"
                                :class="['betslip__body__details__submit', { superbonus: isMaxSuperBonusUnlocked }]"
                                @click="onCreateClick"
                            >
                                {{ $t('bet now') }}
                            </CustomButton>
                        </template>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import i18n from '@app/localization/i18n';
import { isEmpty as _isEmpty } from '@lodash';
import { useVuexStore } from '@src/v2/app/utils/vuexStore';
import BetSlipHeader from '@src/terminal/app/modules/shared/betSlip/BetSlipTerminalHeader.vue';
import PayoutSummaryV2 from '@src/v2/app/modules/sportOffer/betSlip/PayoutSummaryV2.vue';
import StakeV2 from '@src/v2/app/modules/sportOffer/betSlip/stake/StakeV2.vue';
import Errors from '@shared/betSlip/Errors.vue';
import TicketNegotiationV2 from '@src/v2/app/modules/sportOffer/betSlip/TicketNegotiationV2.vue';
import { errorGuard } from 'src/app/utils';
import LuckyLoserNotification from '@src/terminal/app/modules/shared/betSlip/LuckyLoserNotification.vue';
import Illustration from '@shared/components/Illustration.vue';
import CustomButton from '@shared/components/Button.vue';
import SimpleBetSlipV2 from '@src/v2/app/modules/sportOffer/betSlip/SimpleBetSlipV2.vue';
import SystemBetSlipV2 from '@src/v2/app/modules/sportOffer/betSlip/SystemBetSlipV2.vue';
import SystemsSelect from '@src/v2/app/modules/sportOffer/betSlip/SystemsSelect.vue';
import sportOfferModule from '@store/modules/data/sportOffer';
import betSlipModule from '@store/modules/ui/sportOffer/betSlip/betSlipIndex';
import ticketsModule from '@store/modules/data/tickets';
import ticketsStackModule from '@store/modules/ui/shared/ticketsStack/ticketsStackIndex';
import SportSelection from '@core/models/betSlip/SportSelection';
import { BetSlipType } from '@models/shared/betSlip/betSlipEnums';
import dataCountryModule from '@store/modules/data/country/countryIndex';
import SuperBonusBannerV2 from '@src/v2/app/modules/sportOffer/betSlip/SuperBonusBannerV2.vue';
import { SuperBonusValidBetTypes } from '@superbet-group/betting.lib.payments';
import BetslipSuperBonusOverlay from '@src/v2/app/modules/sportOffer/betSlip/BetslipSuperBonusOverlay.vue';
import { ERROR_FADEOUT_TIME } from '@core/constants';

export default defineComponent({
    name: 'BetSlipV2',
    created() {
        if (!this.rawStake) {
            this.setDefaultStake();
        }
    },
    beforeDestroy() {
        this.removeAllSubscriptions();
        this.clearAllErrors();
    },
    setup() {
        const initialStake = ref(0);
        const isInputExpanded = ref(false);
        const isDeleteConfirmExpanded = ref(false);
        const isSystemExpand = ref(false);
        const showSuperbonusPromoOverlay = ref(true);

        // Check src/app/store/persistence/sportBetSlipPersist.ts because when we reload page
        // store first fetches saved betslip's selections from local storage.  For each selection
        // we fetch event from backend and put them to our newly created betslip if exists
        const sportOfferStore = useVuexStore<typeof sportOfferModule>('data/sportOffer');
        const oddValueDynamics = sportOfferStore.getter('oddValueDynamics');

        const sportBetslipStore = useVuexStore<typeof betSlipModule>('ui/sportOffer/betSlip');
        const isEmpty = sportBetslipStore.getter('isEmpty');
        const changes = sportBetslipStore.getter('changes');
        const errors = sportBetslipStore.getter('errors');
        const selectionAddError = sportBetslipStore.getter('selectionAddError');
        const selectionErrors = sportBetslipStore.getter('selectionErrors');
        const isSimple = sportBetslipStore.getter('isSimple');
        const rawStake = sportBetslipStore.getter('rawStake');
        const fixedSelectionsCount = sportBetslipStore.getter('fixedSelectionsCount');
        const selections = sportBetslipStore.getter('selections');
        const systems = sportBetslipStore.getter('systems');
        const bonusAmount = sportBetslipStore.getter('bonusAmount');
        const payout = sportBetslipStore.getter('payout');
        const undoSelections = sportBetslipStore.getter('undoSelections');
        const totalCoefficient = sportBetslipStore.getter('totalCoefficient');
        const totalNumberOfSelectedCombinations = sportBetslipStore.getter('totalNumberOfSelectedCombinations');
        const stakeAfterTax = sportBetslipStore.getter('stakeAfterTax');
        const superBonusPercentage = sportBetslipStore.getter('superBonusPercentage');
        const isSuperBonusEnabled = sportBetslipStore.getter('isSuperBonusEnabled');
        const isOnlyXXSelected = sportBetslipStore.getter('isOnlyXXSelected');
        const clear = sportBetslipStore.action('clear');
        const setStake = sportBetslipStore.action('setStake');
        const setType = sportBetslipStore.action('setType');
        const toggleSystemSelected = sportBetslipStore.action('toggleSystemSelected');
        const toggleSelectionIsFixed = sportBetslipStore.action('toggleSelectionIsFixed');
        const removeSelection = sportBetslipStore.action('removeSelection');
        const acceptAllChanges = sportBetslipStore.action('acceptAllChanges');
        const submitTicket = sportBetslipStore.action('submitTicket');
        const setSelectionAddError = sportBetslipStore.action('setSelectionAddError');
        const undoClear = sportBetslipStore.action('undoClear');
        const removeAllSubscriptions = sportBetslipStore.action('removeAllSubscriptions');
        const setDefaultStake = sportBetslipStore.action('setDefaultStake');

        const dataTicketsStore = useVuexStore<typeof ticketsModule>('data/tickets');
        const isTicketSubmitting = dataTicketsStore.getter('isTicketSubmitting');
        const isTicketPrinted = dataTicketsStore.getter('isTicketPrinted');
        const submitErrors = dataTicketsStore.getter('submitErrors');
        const ticketNegotiation = dataTicketsStore.getter('ticketNegotiation');
        const removeError = dataTicketsStore.action('removeError');
        const clearAllErrors = dataTicketsStore.action('clearAllErrors');
        const acceptTicketNegotiation = dataTicketsStore.action('acceptTicketNegotiation');
        const refuseTicketNegotiation = dataTicketsStore.action('refuseTicketNegotiation');
        const setTicketSubmitLoading = dataTicketsStore.action('setTicketSubmitLoading');

        const ticketsStackStore = useVuexStore<typeof ticketsStackModule>('ui/ticketsStack');
        const isRecreatingTicket = ticketsStackStore.getter('isRecreatingTicket');

        const dataCountryStore = useVuexStore<typeof dataCountryModule>('data/country');
        const serverConfig = dataCountryStore.getter('config');

        const superBonusPercent = computed(() => {
            const { current } = superBonusPercentage.value;
            return current;
        });

        const hasErrors = computed(() => {
            return (
                !!changes.value.length ||
                !!selectionAddError.value ||
                !!errors.value.length ||
                !!submitErrors.value.length
            );
        });

        const systemsPrint = computed(() => {
            const selectedSystems = systems.value.filter((system) => system.isSelected);
            if (selectedSystems && selectedSystems[0]) {
                const first = selectedSystems[0].toString();
                const affix = first.substr(first.indexOf('/'), first.length - 1);
                const prettySystems = selectedSystems.map((system) =>
                    system.toString().substr(0, system.toString().indexOf('/')),
                );
                if (fixedSelectionsCount.value <= 0) {
                    return `${prettySystems}${affix}`;
                }
                return `${fixedSelectionsCount.value}F ${prettySystems}${affix}`;
            }
            return null;
        });

        const changesStrings = computed(() => {
            if (changes.value.length > 0) {
                return [i18n.t('some odds modified, old ones cut')];
            }
            return [];
        });

        const showLuckyLoser = computed(() => {
            if (!payout.value.minLuckyLoserCount) return false;

            return selections.value.length >= payout.value.minLuckyLoserCount && isSimple;
        });

        const showSuperBonusBanner = computed(() => {
            const superBonusValidBetTypes = serverConfig.value.betSlip.bonusConfig.validBetTypes;
            const superBonusEnabledFromSystemTickets = superBonusValidBetTypes.includes(SuperBonusValidBetTypes.System);
            // TODO - Check how to optimize this check with number of combinations for bet type
            return (
                !isEmpty.value &&
                isSuperBonusEnabled.value &&
                (isSimple.value || isOnlyXXSelected.value || superBonusEnabledFromSystemTickets)
            );
        });

        const minSuperBonusBreakpoint = computed(() => {
            return serverConfig.value.betSlip.bonusConfig.bonusBreakpoints[0];
        });

        const minSuperBonusOddValue = computed(() => {
            return serverConfig.value.betSlip.bonusConfig.minOddValue;
        });

        const isMaxSuperBonusUnlocked = computed(() => {
            return (
                showSuperBonusBanner.value &&
                superBonusPercentage.value.current === superBonusPercentage.value.maximumPercentage
            );
        });

        const removeBet = (selection: SportSelection) => {
            removeSelection({ selection });
        };

        const onInputClick = () => {
            initialStake.value = rawStake.value;
            isInputExpanded.value = !isInputExpanded.value;
        };

        const onSystemToggle = () => {
            isSystemExpand.value = !isSystemExpand.value;
        };

        const onTypeClick = (type: BetSlipType) => {
            setType(type);
        };

        const onDeleteCancel = () => {
            isDeleteConfirmExpanded.value = false;
        };

        const onClearClick = () => {
            isDeleteConfirmExpanded.value = true;
        };

        const onSuperBonusOverlayClose = () => {
            showSuperbonusPromoOverlay.value = false;
        };

        const onDeleteConfirm = () => {
            initialStake.value = 0;
            isInputExpanded.value = false;
            isDeleteConfirmExpanded.value = false;
            isSystemExpand.value = false;
            clear();
        };

        const onCreateClick = async () => {
            await errorGuard({
                action: async () => {
                    setTicketSubmitLoading(true);
                    acceptAllChanges();
                    await submitTicket();
                    if (
                        _isEmpty(submitErrors.value) &&
                        _isEmpty(errors.value) &&
                        _isEmpty(selectionErrors.value) &&
                        !ticketNegotiation.value
                    ) {
                        clear();
                    } else {
                        setTicketSubmitLoading(false);
                    }
                },
                onError: () => {
                    setTicketSubmitLoading(false);
                },
            });
        };

        const removeSelectionAddError = () => {
            setSelectionAddError(null);
        };

        const setInitialStake = (stake: number) => {
            initialStake.value = stake;
        };

        watch(isEmpty, (newValue) => {
            if (!newValue && showSuperbonusPromoOverlay.value) {
                onSuperBonusOverlayClose();
            }
        });

        return {
            isEmpty,
            undoSelections,
            isSimple,
            selections,
            onTypeClick,
            onClearClick,
            undoClear,
            isTicketSubmitting,
            isRecreatingTicket,
            isTicketPrinted,
            hasErrors,
            isSystemExpand,
            isDeleteConfirmExpanded,
            systems,
            fixedSelectionsCount,
            onSystemToggle,
            onDeleteCancel,
            onDeleteConfirm,
            selectionErrors,
            oddValueDynamics,
            removeBet,
            toggleSystemSelected,
            toggleSelectionIsFixed,
            ticketNegotiation,
            acceptTicketNegotiation,
            refuseTicketNegotiation,
            changes,
            changesStrings,
            acceptAllChanges,
            selectionAddError,
            removeSelectionAddError,
            errors,
            submitErrors,
            removeError,
            payout,
            systemsPrint,
            initialStake,
            totalNumberOfSelectedCombinations,
            isInputExpanded,
            setInitialStake,
            onInputClick,
            setStake,
            totalCoefficient,
            stakeAfterTax,
            bonusAmount,
            superBonusPercent,
            onCreateClick,
            rawStake,
            setDefaultStake,
            removeAllSubscriptions,
            clearAllErrors,
            showLuckyLoser,
            showSuperBonusBanner,
            minSuperBonusBreakpoint,
            minSuperBonusOddValue,
            superBonusPercentage,
            isMaxSuperBonusUnlocked,
            showSuperbonusPromoOverlay,
            onSuperBonusOverlayClose,
            ERROR_FADEOUT_TIME,
        };
    },
    components: {
        LuckyLoserNotification,
        StakeV2,
        BetSlipHeader,
        SimpleBetSlipV2,
        SystemBetSlipV2,
        PayoutSummaryV2,
        Errors,
        TicketNegotiationV2,
        Illustration,
        CustomButton,
        SystemsSelect,
        SuperBonusBannerV2,
        BetslipSuperBonusOverlay,
    },
});
</script>
