import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import Ticket from '@core/models/tickets/Ticket';
import IBetSlip from '@models/betSlip/IBetSlip';
import TaxService from '@core/services/betSlip/TaxService';
import BetSlipSelectionToTicketEventMapper from './BetSlipSelectionToTicketEventMapper';

const selectionToTicketItemMapper = new BetSlipSelectionToTicketEventMapper();

/* used to map the betslip into a ticket used for preview */

export default class BetSlipToTicketMapper extends AbstractMapper<IBetSlip, Partial<Ticket>> {
    createTargetObject(betSlip: IBetSlip, predefinedData: Partial<Ticket> = {}) {
        const taxService = TaxService.getInstance();
        const e = {
            ...predefinedData,
        } as Partial<Ticket>;
        e.payment = {
            stake: betSlip.stakeAfterTax!,
            handlingFee: betSlip.stake - betSlip.stakeAfterTax!,
            branchId: '',
            total: betSlip.stake,
            // TODO check
            tax: taxService.getStakeTaxByPurchaseType(betSlip.purchaseType),
        };
        const payout = betSlip.payout!;
        e.win = {
            branchId: '',
            minPotential: payout.minFinal,
            estimated: payout.base,
            payoff: payout.final,
            // TODO check
            tax: payout.tax,
        };
        e.events = betSlip.selections.map((s) => selectionToTicketItemMapper.createTargetObject(s));
        return e;
    }
}
