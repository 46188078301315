<template>
    <SelectionV2
        :selection="selection"
        :error="error"
        :oddValueDynamics="oddValueDynamics"
        :checkShowSbv="checkShowSbv"
        :isFix="true"
        @removeClick="onRemoveClick"
        @fixClick="onFixClick"
    >
        <input
            :id="getId(selection).toString()"
            :value="selection.isFixed"
            :checked="selection.isFixed"
            type="radio"
            class="fix-switch"
        />
        <label :for="getId(selection).toString()" class="fix-label">
            <span>{{ $t('fix') }}</span>
        </label>
    </SelectionV2>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import SelectionV2 from '@src/v2/app/modules/sportOffer/betSlip/selection/SelectionV2.vue';
import SportSelection from '@core/models/betSlip/SportSelection';
import IBetSlipError from '@core/models/betSlip/validation/IBetSlipError';
import { OddDynamics } from '@store/modules/data/sportOffer/types';

export default defineComponent({
    components: { SelectionV2 },
    name: 'SystemBetSlipItemV2',
    emits: ['fixClick', 'removeClick'],
    props: {
        selection: {
            type: Object as PropType<SportSelection>,
            required: true,
        },
        error: {
            type: Object as PropType<IBetSlipError>,
            required: false,
        },
        oddValueDynamics: {
            type: Object as PropType<Record<number, Record<string, OddDynamics>>>,
            required: false,
        },
        checkShowSbv: {
            type: Function as PropType<(marketId: number, isSpecial: boolean) => boolean>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const getId = (selection: SportSelection) => {
            return selection.getOddUuid() || selection.getEventId();
        };

        const onFixClick = () => {
            emit('fixClick', props.selection);
        };

        const onRemoveClick = (selection: SportSelection) => {
            emit('removeClick', selection);
        };

        return {
            getId,
            onFixClick,
            onRemoveClick,
        };
    },
});
</script>
