<template>
    <div class="details-sport-header">
        <div class="details-sport-header__event-collection">
            <span v-if="title" class="details-sport-header__event-collection-wrapper">
                <i :class="`icon-sport-${sportId}`" class="icon icon--xs" />
                <span :title="title | upperCase" class="details-sport-header__event-collection-title">
                    {{ title | upperCase }}
                </span>
            </span>
        </div>
        <div class="score-helper__wrapper" :class="{ hidden: !arePeriodsShown }">
            <span v-for="period in periods" :key="period" class="score-helper__item">
                {{ $t(period) }}
            </span>
        </div>
        <div class="market-helper__wrapper--terminal">
            {{ primaryMarketName | upperCase }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventCollectionHeader',
    props: {
        title: {
            type: String,
            required: true,
        },
        periods: {
            type: Array,
            required: true,
        },
        sportId: {
            type: Number,
            required: true,
        },
        primaryMarketName: {
            type: String,
            required: true,
        },
        arePeriodsShown: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
};
</script>
