import { ActionContext, Commit, Dispatch } from 'vuex';
import { Subscription } from 'rxjs';
import Ticket from '@models/tickets/Ticket';
import Session from '@models/authentication/Session';
import StoreUtil from '@app/store/StoreUtil';
import Modals from '@store/modules/ui/shared/modal/modals';
import { IState } from '@app/store';
import { TicketSubmitError } from '@errors/tickets/TicketSubmitError';
import { TicketNegotiation } from '@models/tickets/TicketNegotiation';
import TicketService, { GetTicketOptions, SortAttribute } from '@core/services/tickets/TicketService';
import { OddChangeError } from '@core/errors/tickets/OddChangeError';
import PlatformService from '@src/terminal/core/services/platform/PlatformService';
import { RequestError } from '@core/errors/network/RequestError';
import ErrorType from '@core/errors/network/ErrorType';
import ITicketsState from './types';
import {
    ADD_ERROR,
    CLEAR_ALL,
    CLEAR_ALL_ERRORS,
    REMOVE_ERROR,
    SET_ARE_TICKETS_LOADING,
    SET_TICKET,
    SET_TICKET_NEGOTIATION,
    SET_TICKETS,
    SET_TICKET_SUBMIT_LOADING,
    SET_TICKET_PRINTED,
} from './mutation_types';

const fetchTickets = async (context: ActionContext<ITicketsState, IState>, options: any) => {
    context.commit(SET_ARE_TICKETS_LOADING, true);
    const session = provideSession(context) as Session;
    const tickets = await TicketService.getInstance().getTickets(session, options);
    context.commit(SET_TICKETS, tickets);
    context.commit(SET_ARE_TICKETS_LOADING, false);
    return tickets as Ticket[];
};

const provideSession = ({ rootGetters }: { rootGetters: any }) => {
    const { sessionId, userId } = rootGetters['data/authentication/user'];
    return {
        sessionId,
        userId,
    };
};

let subscription: Subscription | null = null;
const subscribers = [] as Function[];

const subscribeToTicketChanges = (context: ActionContext<ITicketsState, IState>) => {
    if (subscription) {
        return;
    }
    const session = provideSession(context) as Session;
    TicketService.getInstance().startStreaming();

    subscription = TicketService.getInstance().subscribeToTicketsChanges(session.sessionId, (ticket: Ticket) => {
        const previousTicketState = context.state.tickets[ticket.id];
        context.commit(SET_TICKET, ticket);
        subscribers.forEach((subscriber) => subscriber(ticket, previousTicketState));
    });
};

export default {
    fetchTicket: StoreUtil.asyncErrorGuard(async (context: any, ticketId: string) => {
        const tickeIdUppercase = ticketId.toUpperCase();
        const ticket = await TicketService.getInstance().getTicket(tickeIdUppercase);
        return ticket;
    }),
    submitTicket: StoreUtil.asyncErrorGuard(
        async ({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, payload: any) => {
            commit(SET_TICKET_SUBMIT_LOADING, true);
            commit(CLEAR_ALL_ERRORS);
            let ticket: Ticket | TicketNegotiation;

            try {
                ticket = await TicketService.getInstance().submitSportTicket(payload.betSlip);
                if ((ticket as TicketNegotiation).isNegotiation) {
                    commit(SET_TICKET_NEGOTIATION, ticket);
                    return;
                }
            } catch (e) {
                dispatch('ui/modal/closeSpecificModal', 'overlayModal', { root: true });
                commit(SET_TICKET_SUBMIT_LOADING, false);
                await onTicketSubmitError(e, commit, dispatch);
                return;
            }
            ticket = ticket as Ticket;
            await PlatformService.getInstance().sendTicketPrint(ticket);
            return ticket;
        },
    ),
    clearAll({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
        commit(CLEAR_ALL);
        dispatch('_closeSubscriptions');
    },
    setTicketSubmitLoading: StoreUtil.createSimpleMutatorAction(SET_TICKET_SUBMIT_LOADING),
    setTicketPrinted: StoreUtil.createSimpleMutatorAction(SET_TICKET_PRINTED),
    removeError: StoreUtil.createSimpleMutatorAction(REMOVE_ERROR),
    clearAllErrors: StoreUtil.createSimpleMutatorAction(CLEAR_ALL_ERRORS),
    loadLastModifiedTickets: StoreUtil.asyncErrorGuard((context: ActionContext<any, any>) => {
        subscribeToTicketChanges(context);
        return context.dispatch('_loadTickets', { sort: SortAttribute.dateLastModified });
    }),
    acceptTicketNegotiation: StoreUtil.asyncErrorGuard(
        async ({ commit, state, dispatch }: { commit: Commit; state: ITicketsState; dispatch: Dispatch }) => {
            commit(SET_TICKET_SUBMIT_LOADING, true);

            try {
                const ticket = TicketService.getInstance().respondToTicketNegotiation(state.ticketNegotiation!, true);
                commit(SET_TICKET_NEGOTIATION, null);
                commit(SET_TICKET_SUBMIT_LOADING, true);
                const resolvedTicket = await ticket;
                PlatformService.getInstance().sendTicketPrint(resolvedTicket);
            } catch (e) {
                commit(SET_TICKET_NEGOTIATION, null);
                await onTicketSubmitError(e, commit, dispatch);
                throw e;
            }
        },
    ),
    refuseTicketNegotiation: StoreUtil.asyncErrorGuard(
        async ({ commit, dispatch, state }: { commit: Commit; dispatch: Dispatch; state: ITicketsState }) => {
            try {
                await TicketService.getInstance().respondToTicketNegotiation(state.ticketNegotiation!, false);
                commit(SET_TICKET_NEGOTIATION, null);
                commit(SET_TICKET_SUBMIT_LOADING, false);
            } catch (e) {
                commit(SET_TICKET_NEGOTIATION, null);
                await onTicketSubmitError(e, commit, dispatch);
                throw e;
            }
            commit(SET_TICKET_SUBMIT_LOADING, false);
        },
    ),
    ticketNegotiationError(context: any) {
        context.commit(SET_TICKET_NEGOTIATION, null);
        context.dispatch(
            'ui/modal/setModal',
            {
                code: Modals.error.code,
                data: {
                    button: {
                        label: 'ok',
                    },
                    text: {
                        content: 'genericTicketSubmitError',
                        className: 'instruction',
                    },
                },
            },
            { root: true },
        );
    },
    subscribeToTicketChanges(context: any, callback: Function) {
        subscribers.push(callback);
        // returns an unsubscribe function
        return () => {
            const index = subscribers.findIndex((f) => f === callback);
            subscribers.splice(index, 1);
        };
    },
    async _loadTickets(context: ActionContext<ITicketsState, IState>, options: GetTicketOptions) {
        context.commit(SET_ARE_TICKETS_LOADING, true);
        let tickets;
        try {
            tickets = await fetchTickets(context, options);
        } catch (e) {
            context.commit(SET_ARE_TICKETS_LOADING, false);
            throw e;
        }
        context.commit(SET_ARE_TICKETS_LOADING, false);
        return tickets;
    },
    _closeSubscriptions() {
        if (subscription) {
            TicketService.getInstance().unsubscribeFromTicketsChanges(subscription);
            TicketService.getInstance().stopStreaming();
            subscribers.length = 0; // cannot replace with new array because of the closure in subscribeToTicketChanges
            subscription = null;
        }
    },
};

async function onTicketSubmitError(e: Error, commit: Commit, dispatch: Dispatch) {
    commit(SET_TICKET_SUBMIT_LOADING, false);
    if (e instanceof TicketSubmitError && e.notice) {
        if (e.notice === 'Nepermis') {
            dispatch('data/authentication/sessionExpired', null, { root: true });
            return;
        }
        commit(ADD_ERROR, e.notice);
    } else if (e instanceof OddChangeError) {
        await dispatch('ui/sportOffer/betSlip/updateStaleMatchOdds', null, { root: true });
        commit(ADD_ERROR, e.notice);
    } else if (e instanceof RequestError) {
        if (e.type === ErrorType.authenticationNeeded) {
            PlatformService.getInstance().refreshAuth();
        }
    } else {
        throw e;
    }
}
