<template>
    <div>
        <Selection
            :key="getId(selection)"
            :selection="selection"
            :error="error"
            :checkShowSbv="checkShowSbv"
            :oddValueDynamics="oddValueDynamics"
            @removeClick="onRemoveClick"
        >
            <input
                :id="getId(selection).toString()"
                :value="isFixed"
                :checked="isFixed"
                type="radio"
                class="selection-row__fix-switch-checkbox--terminal"
                @click="setFixed"
            />
            <label :for="getId(selection).toString()" class="selection-row__fix-switch-label--terminal">
                <span>{{ $t('fixLabel') }}</span>
            </label>
        </Selection>
    </div>
</template>

<script>
import Selection from './Selection.vue';

export default {
    name: 'SystemBetSlipItem',
    components: {
        Selection,
    },
    props: {
        selection: {
            type: Object,
            required: true,
        },
        error: {
            type: Object,
            required: false,
        },
        oddValueDynamics: {
            type: Object,
            required: false,
        },
        checkShowSbv: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            isFixed: false,
        };
    },
    watch: {
        'selection.isFixed': {
            handler(value) {
                this.isFixed = value;
            },
            immediate: true,
        },
    },
    methods: {
        getId(selection) {
            return selection.getOddUuid() || selection.getEventId();
        },
        onRemoveClick(selection) {
            this.$emit('removeClick', selection);
        },
        setFixed() {
            this.$emit('fixClick', this.selection);
        },
    },
};
</script>
