<template>
    <div
        class="selection-row--terminal"
        :class="{
            'selection-row--has-error--terminal': error,
            'selection-row--to-remove--terminal': error && error.shouldRemove,
            'selection-row--has-info--terminal': error && error.isInfo,
            'selection-row--superbet': isSuperbet,
        }"
    >
        <div class="selection-row__fix--terminal">
            <span class="selection-row__fix-switch--terminal">
                <slot />
            </span>
        </div>
        <div class="selection-row__wrapper--terminal" @click="openEvent">
            <div v-if="error" class="selection-row-terminal__error">
                <i class="icon icon-warning_triangle_filled" />
                <span class="selection-row-terminal__error-message">
                    {{ getErrorMessage(error) }}
                </span>
            </div>
            <EventSummary
                :isLive="event.isOngoing()"
                :startDate="event.date"
                :team1Name="getTeamName()"
                :team2Name="event.team2Name || ''"
                :viewingOptions="{
                    liveBetting: event.isGoingToBeInLiveBetting || false,
                    liveSound: event.isGoingToHaveLiveAudio || false,
                }"
                :liveData="event.liveData"
                :sportId="event.sportId"
            />
            <div class="selection-row-terminal__controls">
                <i v-if="isRemovable" class="icon icon-delete" @click.stop.prevent="onRemoveClick" />
            </div>
            <div class="selection-row__bet--terminal" :class="statusClass">
                <div class="selection-row__market-box--terminal">
                    <span v-if="showEventNo && selection.event.eventNo" class="selection-row__system-info">
                        {{ selection.event.eventNo }}
                    </span>
                    <span v-if="!isSuperbet" class="selection-row__market-name" :title="selection.name">
                        {{ selection.name }}
                    </span>
                </div>
                <Pick
                    v-if="selection.odd"
                    :id="selection.getOddId()"
                    :eventId="selection.getEventId()"
                    :name="selection.odd.name"
                    :marketName="selection.marketName"
                    :isOutright="isOutright"
                    :value="selection.getOddValue()"
                    :newValue="newOddValue"
                    :symbol="selection.getOddSymbol()"
                    :isLocked="selection.isLocked()"
                    :specialBetValue="selection.getOddSpecialBetValue()"
                    :showSpecialBetValue="checkShowSbv(selection.getOdd().marketId, false)"
                    :valueChangeDynamics="valueChangeDynamics"
                    :isSuperbet="isSuperbet"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { isSuperbet } from '@models/struct/Market';
import Pick from '@src/terminal/app/modules/shared/event/Pick.vue';
import BetSlipHelper from '@store/modules/ui/shared/betSlip/Helpers';
import EventSummary from '@src/terminal/app/modules/shared/event/EventSummarySportBetslip.vue';
import { EventStatus } from '@core/models/tickets/enums';
import { EventCategory } from '@core/models/offer/enums';
import { mapActions } from 'vuex';
import slugify from '@utils/url/slugify';
import { OFFER_PREFIX } from '@app/router/constants';
import { allTimeFilterSlug, liveTimeFilterSlug, todayTimeFilterSlug } from '@store/modules/ui/shared/timeFilter/utils';
import { getTimeFilterForWeekDay } from '@src/terminal/app/modules/shared/timeFilter/helpers';

export default {
    name: 'Selection',
    props: {
        selection: {
            type: Object,
            required: true,
        },
        error: {
            type: Object,
            required: false,
        },
        isRemovable: {
            type: Boolean,
            required: false,
            default: true,
        },
        showEventNo: {
            type: Boolean,
            required: false,
            default: false,
        },
        oddValueDynamics: {
            type: Object,
            required: false,
        },
        checkShowSbv: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            lastOdd: this.selection.odd,
        };
    },
    watch: {
        'selection.odd': function (after, before) {
            if (before) {
                this.lastOdd = before;
            }
        },
    },
    methods: {
        ...mapActions('ui/sportOffer', ['toggleExpandedEvent']),
        async openEvent() {
            const eventId = this.selection.event.id;

            console.log('SELECTION:::', this.selection);
            const typeId = this.selection.event.tournamentId;
            const sportName = slugify(this.selection.event.sportName);
            const categoryName = slugify(this.selection.event.categoryName);
            const tournamentName = slugify(this.selection.event.tournamentName);
            const datePath = this.getDateString(this.selection.event);
            let slug = `${OFFER_PREFIX}/${sportName}/`;
            if (this.selection?.event?.categoryName) {
                slug += `${categoryName}/`;
            }
            if (this.selection?.event?.tournamentName) {
                slug += `${tournamentName}/`;
            }
            if (datePath) {
                slug += `${datePath}/`;
            }
            await this.$router.push({
                path: slug,
            });
            this.toggleExpandedEvent({ eventId, typeId });
        },
        getDateString(event) {
            const currentDate = new Date();
            const eventDate = new Date(event.date);
            currentDate.setHours(0, 0, 0, 0);
            eventDate.setHours(0, 0, 0, 0);
            const diffTime = eventDate - currentDate;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            console.log('DANI', diffDays);
            if (event.hasLive && event.liveData !== null) {
                return this.$t(liveTimeFilterSlug);
            }
            if (diffDays === 0) {
                return todayTimeFilterSlug;
            }
            if (diffDays > 0 && diffDays <= 5) {
                return slugify(this.$t(getTimeFilterForWeekDay(eventDate.getDay())).toString());
            }
            return allTimeFilterSlug;
        },
        getErrorMessage(error) {
            return this.$t(BetSlipHelper.getBetSlipErrorMessageTemplate(error), error.params);
        },
        onRemoveClick() {
            this.$emit('removeClick', this.selection);
        },
        getTeamName() {
            if (this.isOutright) {
                return this.event.fullName;
            }
            return this.event.team1Name || this.selection.displayName;
        },
    },
    computed: {
        valueChangeDynamics() {
            if (this.oddValueDynamics[this.selection.getEventId()] && this.lastOdd) {
                return this.oddValueDynamics[this.selection.getEventId()][this.lastOdd.uniqueId];
            }
            return null;
        },
        isOutright() {
            return this.event.eventCategory === EventCategory.OUTRIGHT;
        },
        newOddValue() {
            let eventOdd;
            const { id, specialBetValue, value } = this.selection.odd;
            if (!id) {
                return null;
            }
            if (specialBetValue) {
                eventOdd = this.selection.event.getOddById(id, specialBetValue);
            } else {
                eventOdd = this.selection.event.getOddById(id);
            }
            if (!eventOdd) {
                return null;
            }
            const isOddOld = value !== eventOdd.value;
            return isOddOld ? eventOdd.value : null;
        },
        event() {
            return this.selection.event;
        },
        statusClass() {
            switch (this.selection.event.status) {
                case EventStatus.win:
                    return 'selection-row__bet--won';
                case EventStatus.lost:
                    return 'selection-row__bet--lost';
                case EventStatus.refund:
                    return 'selection-row__bet--refund';
                default:
                    return '';
            }
        },
        isSuperbet() {
            return this.selection.odd && isSuperbet(this.selection.odd.marketId, this.selection.odd.marketName);
        },
    },
    components: {
        Pick,
        EventSummary,
    },
};
</script>

<style>
.error {
    font-size: 10px;
}
</style>
