<template>
    <div class="betslip-negotiation">
        <div class="betslip-negotiation__title">
            {{ $t('bookie changed the bet, do you accept?') }}
        </div>
        <div v-if="shouldShowStake" class="betslip-negotiation__stake">
            <span class="betslip-negotiation__stake__label"> {{ $t('stake') }}: </span>
            <span class="betslip-negotiation__stake__amounts">
                <span class="betslip-negotiation__stake__amounts__old">
                    <s>{{ ticketNegotiation.betSlipStake | twoDecimals }}</s>
                    <span class="betslip-negotiation__stake__amounts__old__currency">
                        {{ $t('currencyAbbreviation') }}
                    </span>
                </span>
                <img
                    src="/static/img/illustrations/v2/betslip-negotiation-arrow.svg"
                    class="betslip-negotiation__stake__amounts__arrow"
                />
                <span class="betslip-negotiation__stake__amounts__new">
                    {{ ticketNegotiation.bookieStake | twoDecimals }}
                    <span class="betslip-negotiation__stake__amounts__new__currency">
                        {{ $t('currencyAbbreviation') }}
                    </span>
                </span>
            </span>
        </div>
        <div class="betslip-negotiation__win">
            <span class="betslip-negotiation__win__label"> {{ $t('new potential win') }}: </span>
            <span class="betslip-negotiation__win__amount">
                {{ ticketNegotiation.potentialWin | twoDecimals }}
                <span class="betslip-negotiation__win__amount__currency">
                    {{ $t('currencyAbbreviation') }}
                </span>
            </span>
        </div>
        <div class="betslip-negotiation__actions">
            <div class="betslip-negotiation__actions__decline" @click="$emit('decline')">
                {{ $t('decline') }}
            </div>
            <div class="betslip-negotiation__actions__accept" @click="$emit('accept')">
                {{ $t('accept') }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import arrowsIcon from '@superbet-group/web.lib.ui-tokens/output/assets/icons-multi-color/gamification-arrows-up-superbet-dark.svg';
import { twoDecimals } from 'src/app/modules/shared/filters';
import { TicketNegotiation } from '@models/tickets/TicketNegotiation';

export default defineComponent({
    emits: ['accept', 'decline'],
    name: 'TicketNegotiationV2',
    props: {
        ticketNegotiation: {
            type: Object as PropType<TicketNegotiation>,
            required: true,
        },
    },
    setup(props) {
        const shouldShowStake = computed(() => {
            return (
                twoDecimals(props.ticketNegotiation.betSlipStake) !== twoDecimals(props.ticketNegotiation.bookieStake)
            );
        });

        return {
            shouldShowStake,
            arrowsIcon,
        };
    },
});
</script>
