import i18n from '@src/app/localization/i18n';
import { IModal } from './modalIndex';

export default {
    error: {
        code: 'error',
        title: 'error',
    },
    overlayModal: {
        code: 'overlayModal',
        title: 'overlayModal',
        isIndependent: true,
        containerClass: 'overlay_modal',
        overlayClass: 'overlay_modal',
    },
    login: {
        code: 'login',
        title: 'login',
    },
    forgotPassword: {
        code: 'forgotPassword',
        title: 'forgot password',
        containerClass: 'forgot_password_modal',
    },
    singleTicket: {
        code: 'singleTicket',
        isIndependent: true,
    },
    betSlipPreview: {
        code: 'betSlipPreview',
        isIndependent: true,
    },
    welcomeBonusIntro: {
        code: 'welcomeBonusIntro',
        title: 'welcome bonus',
        containerClass: 'welcome_bonus_modal',
    },
    message: {
        code: 'message',
        title: '',
        containerClass: 'message_modal',
    },
    sessionDuration: {
        code: 'sessionDuration',
        title: 'session duration',
    },
    gameUnavailable: {
        code: 'gameUnavailable',
        title: 'game currently unavailable',
    },
    ticketSubmitRetry: {
        code: 'ticketSubmitRetry',
        title: 'submit failed',
    },
    maintenance: {
        code: 'maintenance',
        title: 'currently under maintenance',
    },
    smsLimitReached: {
        code: 'smsLimitReached',
        title: 'sms limit reached',
    },
    numberInput: {
        code: 'numberInput',
        title: i18n.t('enterNumber'),
    },
    cancelTicket: {
        code: 'cancelTicket',
        title: i18n.t('cancel ticket'),
    },
    payoutTicket: {
        code: 'payoutTicket',
        title: i18n.t('payout ticket'),
    },
    sportSystems: {
        code: 'sportSystems',
        title: i18n.t('systems'),
    },
    dateTimeInput: {
        code: 'dateTimeInput',
        title: 'Selectați data',
    },
    boostedOddConflict: {
        code: 'boostedOddConflict',
        title: i18n.t('Conflict'),
        isIndependent: true,
    },
    superbonusPromo: {
        code: 'superbonusPromo',
        isIndependent: true,
    },
} as Record<string, IModal>;
