<template>
    <div class="betslip__systems">
        <div class="betslip__systems__label">{{ $t('system') }}</div>
        <div class="betslip__systems__combinations">
            <div
                v-for="(system, key) in systems"
                :key="key"
                :class="['betslip__systems__combinations__system', { selected: system.isSelected }]"
                @click="onToggleSystem(system)"
            >
                {{ computeSystem(system) }}
            </div>
        </div>
        <div class="betslip__systems__confirm" @click="$emit('systemToggle')">{{ $t('ok') }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useVuexStore } from '@src/v2/app/utils/vuexStore';
import betSlipSportModule from '@store/modules/ui/sportOffer/betSlip/betSlipIndex';
import System from '@models/betSlip/System';

export default defineComponent({
    name: 'SystemsSelect',
    props: {
        systems: {
            type: Array as PropType<System[]>,
            required: true,
        },
        fixedSelectionsCount: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const betSlipSportStore = useVuexStore<typeof betSlipSportModule>('ui/sportOffer/betSlip');
        const toggleSystemSelected = betSlipSportStore.action('toggleSystemSelected');

        const onToggleSystem = (system: System) => {
            toggleSystemSelected(system);
        };

        const computeSystem = (system: System) => {
            if (props.fixedSelectionsCount < 1) {
                return system;
            }
            return `${props.fixedSelectionsCount}F ${system}`;
        };

        return {
            computeSystem,
            onToggleSystem,
        };
    },
});
</script>
