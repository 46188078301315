import { determineWidthBreakpointMin, ScreenWidth } from '@app/styles/scripts/uiHelpers';
import { NAVBAR_HEIGHT, NAVBAR_MARGIN, TIME_FILTER_HEIGHT, TIME_FILTER_MARGIN } from '@app/styles/elementConstants';

export const FIRST_ROW_OFFSET = NAVBAR_HEIGHT + NAVBAR_MARGIN + TIME_FILTER_HEIGHT + TIME_FILTER_MARGIN;

export interface ElementHeights {
    categoryHeader: number; // class section-header
    sectionHeader: number; // class group-header
    sectionPadding: number;
    sectionBottomMargin: number;
    eventCollectionHeader: number; // class details-header
    event: number;
    extendedEvent: number;
    sectionFooter: number;
    eventFooter: number;
}

export interface BannersHeights {
    promotionBanner: number;
    quickLinkBanner: number;
}

export interface HeightConfig {
    normal: ElementHeights;
    withSidebar: ElementHeights;
    banners: BannersHeights;
}

const defaultBannersHeight = {
    quickLinkBanner: 56 + 8, // height + margin
    promotionBanner: 128 + 8, // height + margin
};

const defaultHeightConfig = {
    categoryHeader: 46 + 8,
    sectionHeader: 28,
    sectionPadding: 4,
    sectionBottomMargin: 12,
    eventCollectionHeader: 26,
    event: 98,
    sectionFooter: 28,
    eventFooter: 24,
};

const defaultHeightConfigV2 = {
    categoryHeader: 28 + 16,
    sectionHeader: 26 + 20 + 8,
    sectionPadding: 4,
    sectionBottomMargin: 12,
    eventCollectionHeader: 26,
    event: 138,
    sectionFooter: 28,
    eventFooter: 24,
};

const heightConfigMap = {
    [ScreenWidth.dXl]: {
        normal: defaultHeightConfig,
        withSidebar: defaultHeightConfig,
        banners: defaultBannersHeight,
    },
    [ScreenWidth.dLg]: {
        normal: defaultHeightConfig,
        withSidebar: defaultHeightConfig, // event actually 112 below 1824
        banners: defaultBannersHeight,
    },
    [ScreenWidth.dMd]: {
        normal: defaultHeightConfig,
        withSidebar: {
            ...defaultHeightConfig,
            event: 112,
        },
        banners: defaultBannersHeight,
    },
    [ScreenWidth.dSm]: {
        normal: {
            ...defaultHeightConfig,
            event: 112, // @marko notion says 52 here but css 112
        },
        withSidebar: {
            ...defaultHeightConfig,
            event: 112,
        },
        banners: defaultBannersHeight,
    },
    [ScreenWidth.dXs]: {
        normal: {
            ...defaultHeightConfig,
            event: 112,
        },
        withSidebar: {
            ...defaultHeightConfig,
            event: 112,
        },
        banners: defaultBannersHeight,
    },
    [ScreenWidth.tLg]: {
        normal: {
            ...defaultHeightConfig,
            event: 112,
        },
        withSidebar: {
            ...defaultHeightConfig,
            event: 112,
        },
        banners: defaultBannersHeight,
    },
    [ScreenWidth.tXl]: {
        normal: {
            ...defaultHeightConfig,
            event: 112,
        },
        withSidebar: {
            ...defaultHeightConfig,
            event: 112,
        },
        banners: defaultBannersHeight,
    },
    [ScreenWidth.tMd]: {
        normal: {
            ...defaultHeightConfig,
            event: 112,
        },
        withSidebar: {
            ...defaultHeightConfig,
            event: 112,
        },
        banners: defaultBannersHeight,
    },
    [ScreenWidth.tSm]: {
        normal: {
            ...defaultHeightConfig,
            event: 112,
        },
        withSidebar: {
            ...defaultHeightConfig,
            event: 112,
        },
        banners: defaultBannersHeight,
    },
    [ScreenWidth.mMd]: {
        normal: {
            ...defaultHeightConfig,
            event: 112,
        },
        withSidebar: {
            ...defaultHeightConfig,
            event: 112,
        },
        banners: defaultBannersHeight,
    },
} as Record<ScreenWidth, HeightConfig>;

export function getElementHeights() {
    const widthBreakpoint = determineWidthBreakpointMin();
    return (
        heightConfigMap[widthBreakpoint] || {
            normal: defaultHeightConfig,
            withSidebar: defaultHeightConfig,
        }
    );
}

export function getElementHeightsV2() {
    return {
        normal: defaultHeightConfigV2,
        withSidebar: defaultHeightConfigV2,
        banners: defaultBannersHeight,
    } as HeightConfig;
}
