/* eslint-disable import/no-named-default */
import { ITask } from '@src/terminal/core/services/gravity/_private/Task';
import { EventStatus } from '@models/tickets/enums';
import store from '@app/store';
import { RecreateTicketData, RecreateTicketEventData } from '@models/tickets/Ticket';
import TicketService from '@core/services/tickets/TicketService';
import i18n from '@src/app/localization/i18n';
import { get as _get } from '@lodash';
import FeatureFlagService from '@src/terminal/core/services/flags/FeatureFlagService';
import GetTicketDto, { EventDto } from '@core/mappers/tickets/dtos/GetTicketDto';
import PlatformService from '../../platform/PlatformService';

class TicketsRebetTask implements ITask {
    private retryTimeout: any = null;
    private retryCount = 1;

    execute = async (message?: any): Promise<void> => {
        try {
            await this.rebetTicket(message);
            clearTimeout(this.retryTimeout);
        } catch (err) {
            if (this.retryCount <= 3) {
                this.retryCount += 1;
                console.log('SSBT::Rebet error, retrying', this.retryCount, '/', 3);
                this.retryTimeout = setTimeout(this.execute, 2000, message);
            } else this.errorNotificationOutOfRetries();
        }
    };

    rebetTicket = async (message?: any) => {
        const ticketId: string = _get(message, 'data.ticket.slip.codes[0].id', null);
        const data = await TicketService.getInstance().getTicketById(ticketId);
        const mappedTicketData = this.mapTicketForRecreate(data);
        await store.dispatch('ui/sportOffer/betSlip/recreateTicket', mappedTicketData, { root: true });
    };

    mapTicketForRecreate = (ticket: GetTicketDto): RecreateTicketData => ({
        stake: ticket.ticketData.sumTotal,
        ticketUuid: ticket.id,
        ticketCode: ticket.ticketId,
        channel: ticket.ticketData.config.channel,
        system:
            ticket.ticketData.system?.groupOptionsList?.length > 0
                ? {
                      count: ticket.ticketData.system.groupCount,
                      fixed: ticket.ticketData.events.filter((event: EventDto) => event.fix).length,
                      selected: ticket.ticketData.system.groupOptionsList,
                  }
                : null,
        events: this.filteredEvents(
            ticket.ticketData.events.map((event: EventDto) => ({
                date: new Date(event.dateEvent),
                status: event.status as EventStatus,
                eventId: event.matchId,
                oddId: String(event.oddTypeId),
                oddUuid: event.oddUuid,
                specialBetValue: event.sbValue,
                isFix: event.fix,
                oddSourceType: event.oddSourceType,
            })),
        ),
    });

    filteredEvents = (events: RecreateTicketEventData[]) => {
        if (FeatureFlagService.getInstance().isInPlayAvailable()) {
            return events;
        }
        const isStartedEvent = (event: RecreateTicketEventData) => event.date! < new Date();
        return events.filter((event: RecreateTicketEventData) => !isStartedEvent(event));
    };

    errorNotificationOutOfRetries = () => {
        const notificationTitle = i18n.t('ticket rebet failed, please try again later').toString();
        PlatformService.getInstance().sendNotification({
            title: notificationTitle,
            text: notificationTitle,
            type: 'error',
        });
    };
}

export default TicketsRebetTask;
