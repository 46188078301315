import config from '@src/config';
import i18n from '@src/app/localization/i18n';

/**
 * Formats date according to locale.
 * Parameter format is a strings with following possible values:
 *
 *     ddmmyyyy
 *     HHMM
 *     Wddmmyy
 *     wHHMM
 *     ddmm
 *     ddmmyyyyHHMM
 *     monthddHHMM
 *     ddmmyyHHMM
 *     wddm
 *     wddmm
 *
 * The format represents a mapping of following formatted date-time components
 *      w - short weekday representation (e.g. Mon)
 *      W - long weekday representation (e.g. Monday)
 *      m - narrow month representation (e.g. 7, J)
 *      mm - 2-digit month representation (e.g. 07)
 *      month - short month representation (e.g. Jul)
 *      MONTH - long month representation (e.g. July)
 *      dd - day of the month
 *      HH - hour in the day
 *      MM - minutes
 *      SS - seconds
 *
 * @example
 * // returns '07/02/2019, 13:54'
 * formatDateTime(new Date(), 'ddmmyyyyHHMM')
 * @returns {string} Returns formatted string representation of date.
 */
export const formatDateTime = (date: Date, format: string) => i18n.d(date, format, config.locale);

export const timeOnly = (date: Date) => i18n.d(date, 'HHMM', config.locale);

export const dayOnly = (date: Date) => i18n.d(date, 'dd', config.locale);

export const dayOfWeek = (date: Date) => i18n.d(date, 'w', config.locale);

export enum TimeInterval {
    h = 'h',
    m = 'm',
    s = 's',
}

interface AbsoluteTimeOptions {
    inputTimeInterval?: TimeInterval;
    outputTimeFormat?:
        | [TimeInterval.h]
        | [TimeInterval.h, TimeInterval.m]
        | [TimeInterval.h, TimeInterval.m, TimeInterval.s]
        | [TimeInterval.m]
        | [TimeInterval.m, TimeInterval.s]
        | [TimeInterval.s];
}

/**
 * Displays a time period of units in options.inputTimeInterval in a specific format
 * specified in options.outputTimeFormat
 * @param value {number} the amount of time units to display
 * in minutes
 * @param options {Object} see types
 * Currently only displays an amount of minutes in hh:mm format
 */
export const absoluteTime = (
    value: number,
    options: AbsoluteTimeOptions = {
        inputTimeInterval: TimeInterval.m,
        outputTimeFormat: [TimeInterval.h, TimeInterval.m],
    },
) => {
    const amount = convertToSeconds(value, options.inputTimeInterval);
    // other options not implemented
    // not sure they will be required
    // assumes input is in minutes and output is hh:mm
    const timestamp = new Date(amount * 1000);
    return formatTime(
        {
            [TimeInterval.h]: timestamp.getHours() - 1,
            [TimeInterval.m]: timestamp.getMinutes(),
            [TimeInterval.s]: timestamp.getSeconds(),
        },
        options.outputTimeFormat,
    );
};

function convertToSeconds(value: number, type?: TimeInterval) {
    switch (type) {
        case TimeInterval.h:
            return value * 3600;
        case TimeInterval.m:
            return value * 60;
        case TimeInterval.s:
            return value;
        default:
            // accepts minutes by default
            return value * 60;
    }
}

function formatTime(timestamp: Record<TimeInterval, number>, outputTimeFormat = [TimeInterval.h, TimeInterval.m]) {
    outputTimeFormat.map((unit) => `${timestamp[unit].toString().padStart(2, '0')}`).join(':');
}
