/* eslint-disable class-methods-use-this */
import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import ISelection from '@core/models/betSlip/ISelection';
import SportSelection from '@core/models/betSlip/SportSelection';
import { ISpecialTicketItemDto, ITicketItemDto } from './dtos/ISubmittingTicketDto';
import SportSelectionMapper from './SportSelectionMapper';

const sportBetSlipSelectionMapper: SportSelectionMapper = new SportSelectionMapper();

export default class SelectionToTicketItemMapper extends AbstractMapper<
    ISelection,
    ITicketItemDto | ISpecialTicketItemDto
> {
    createTargetObject(item: ISelection, propagatedAttributes: any): ITicketItemDto | ISpecialTicketItemDto {
        if (item instanceof SportSelection) {
            return sportBetSlipSelectionMapper.createTargetObject(item, propagatedAttributes);
        }

        throw new Error('Invalid selection type.');
    }
}
