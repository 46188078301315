import 'normalize.css';
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import * as VueScrollTo from 'vue-scrollto';
// import * as localForage from 'localforage';
import VueContentPlaceholders from 'vue-content-placeholders';

import '@app/styles/main.scss';
import * as filters from '@shared/filters';

import i18n from '@app/localization/i18n';
import config from '@config';
import PortalVue from 'portal-vue';
import router from '@app/router';
import reportErrors from '@app/errors/reportErrors';
import store, { initializeSubscriptions } from './app/store';
import App from './App.vue';
import VirtualKeyboard from '../src/app/plugins/virtualKeyboard';
import virtualKeyboard from './terminal/app/modules/shared/peripherals/VirtualKeyboard.vue';

// ignore custom check ticket html component registration
Vue.config.ignoredElements = ['seven-widget-superbet-prematch-ticket-details'];

Vue.component('VirtualKeyboard', virtualKeyboard);

// @ts-ignore
Vue.use(VirtualKeyboard);

reportErrors(Vue);

Vue.config.errorHandler = function (err, vm, info) {
    // Log the error locally
    console.error(err, vm, info);
};

if (!config.environment.isTest) {
    (window as any).toEvent = {};
    (window as any).denormalizeEvent = {};
    (window as any).containsFullOddList = {};
    (window as any).getAllChanges = {};
    (window as any).removeEventsInMaintenance = {};
    (window as any).in = {};
    (window as any).subscribeToEventChanges = {};
    (window as any).setEvents = {};
    (window as any).setEventsMutationsInStore = {};
    (window as any).setEventsMutationsNotInStore = {};
    // (window as any).bobo = store.state.data.sportOffer.events;
}
Vue.prototype.$config = config;

Vue.config.productionTip = false;
Object.keys(filters).forEach((key) => {
    Vue.filter(key, (filters as Record<string, any>)[key]);
});

sync(store, router);

// @ts-ignore
Vue.use(VueScrollTo);
Vue.use(VueContentPlaceholders as any);

Vue.use(PortalVue);

// eslint-disable-next-line
new Vue({
    i18n,
    store,
    router,
    el: '#app',
    components: { App },
    template: '<App/>',
});

initializeSubscriptions();
