<template>
    <div class="search-box--terminal">
        <form class="search__form--terminal" autocomplete="off" @submit.prevent="handleSubmit">
            <AutoCompleteInput
                v-model="query"
                :placeholder="$t('search events') | capitalize"
                :areSuggestionsShown="areSuggestionsShown"
                :results="suggestions"
                :minCharacters="minCharacters"
                :isAsync="true"
                type="text"
                data-lpignore="true"
                @click="showSuggestions"
                @itemClick="handleSubmit"
                @suggestionsStateChange="onSuggestionsStateChange"
            />
        </form>
    </div>
</template>

<script>
import AutoCompleteInput from '@src/terminal/app/modules/shared/components/AutoCompleteInput.vue';
import { mapGetters } from 'vuex';

const minCharacters = 1;

export default {
    name: 'SearchInput',
    components: {
        AutoCompleteInput,
    },
    props: {
        suggestionsFunction: {
            type: Function,
            required: true,
        },
        predefinedSuggestions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            query: '',
            suggestions: [],
            areSuggestionsShown: false,
            minCharacters,
        };
    },
    watch: {
        query(newValue) {
            if (newValue.trim().length >= minCharacters) {
                this.suggestions = this.suggestionsFunction(this.query);
            } else {
                this.suggestions = this.predefinedSuggestions;
            }
            if (!newValue.length) {
                this.areSuggestionsShown = false;
            }
            if (this.useDesignV2) {
                this.suggestionsPadding();
                this.suggestionsMargin(newValue);
            }
        },
    },
    methods: {
        handleSubmit() {
            if (this.query.trim().length >= this.minCharacters) {
                this.$emit('submit', this.query);
            }
        },
        showSuggestions() {
            this.areSuggestionsShown = true;
            this.suggestions = this.predefinedSuggestions;
        },
        onSuggestionsStateChange(value) {
            this.areSuggestionsShown = value;
        },
        suggestionsPadding() {
            this.$el.style.paddingBottom = `${this.suggestions.length * 40}px`;
        },
        suggestionsMargin(inputVal) {
            if (inputVal && this.suggestions.length === 0) {
                this.$el.style.marginBottom = '40px';
            } else {
                this.$el.style.marginBottom = '0px';
            }
        },
    },
    computed: {
        ...mapGetters('data/flags', ['useDesignV2']),
    },
};
</script>

<style scoped></style>
