<template>
    <div class="betslip__body__details__stake">
        <StakePickerV2 :stake="stake" @click="onStakePick" />
        <div :class="['betslip__body__details__stake__input', { expanded: isInputExpanded }]" @click="onInputClick">
            <span class="betslip__body__details__stake__input__action">
                <i v-if="!isInputExpanded" class="sds-icon-technology-keyboard" />
                <i
                    v-else
                    class="sds-icon-navigation-close-alt-filled betslip__body__details__stake__input__action__clear"
                    @click.stop.prevent="clearStake"
                />
            </span>
            <StakeInputV2
                :stake="stake"
                :inputStake="inputStake"
                :decimalSeparator="decimalSeparator"
                :isInputExpanded="isInputExpanded"
            />
        </div>
        <NumberKeypadV2
            v-if="isInputExpanded"
            :inputStake="inputStake"
            :initialStake="initialStake"
            :decimalSeparator="decimalSeparator"
            @onChange="onStakeInput"
            @onClose="onStakePick"
            @onInputClick="$emit('onInputClick')"
        />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import StakeInputV2 from '@src/v2/app/modules/sportOffer/betSlip/stake/StakeInputV2.vue';
import StakePickerV2 from '@src/v2/app/modules/sportOffer/betSlip/stake/StakePickerV2.vue';
import NumberKeypadV2 from '@src/v2/app/modules/sportOffer/betSlip/NumberKeypadV2.vue';
import config from '@config';

export default defineComponent({
    components: { StakeInputV2, StakePickerV2, NumberKeypadV2 },
    emits: ['onInputClick', 'change', 'setInitialStake'],
    name: 'StakeV2',
    props: {
        initialStake: {
            type: Number,
            required: true,
        },
        stake: {
            type: Number,
            required: true,
        },
        isSimple: {
            type: Boolean,
            required: true,
        },
        totalCoefficient: {
            type: Number,
            default: 0,
        },
        totalNumberOfSelectedCombinations: {
            type: Number,
            default: 0,
        },
        isInputExpanded: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit }) {
        const inputStake = ref(`${props.stake}`);
        const decimalSeparator = computed(() => (1.1).toLocaleString(config.numberLocale)[1]);

        const onInputClick = () => {
            inputStake.value = props.stake.toString();
            emit('setInitialStake', props.stake);
            emit('onInputClick');
        };

        const onStakePick = (stake: number) => {
            inputStake.value = stake.toString();
            emit('change', stake);
        };

        const clearStake = () => {
            inputStake.value = '';
            emit('change', 0);
        };

        const onStakeInput = (stake: string) => {
            inputStake.value = stake;
            emit('change', parseFloat(stake) || 0);
        };

        return {
            decimalSeparator,
            inputStake,
            onStakeInput,
            onInputClick,
            onStakePick,
            clearStake,
        };
    },
});
</script>
