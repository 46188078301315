import { MatchForTree } from '@app/utils/sidebar';
import { VirtualTournament, TournamentId } from '../types';

// Replaces a list of tournaments in the sidebar with one virtual tournament
export function replaceTournamentsWithVirtualTournaments(
    events: MatchForTree[],
    tournamentToVirtualTournament: Record<TournamentId, VirtualTournament>,
): MatchForTree[] {
    return events.map((event) => {
        const virtualTournament = tournamentToVirtualTournament[event.tournamentId];
        if (virtualTournament) {
            return {
                ...event,
                tournamentId: virtualTournament.tournamentId,
                tournamentName: virtualTournament.name,
                tournamentName2: virtualTournament.name,
                sportId: virtualTournament.sportId,
                categoryId: virtualTournament.categoryId,
            };
        }
        return event;
    });
}
