import { round as _round } from '@lodash';
import ServerConfig, { HandlingFeeMethod, TaxLimit } from '@core/models/country/ServerConfig';
import { BetSlipPurchaseType } from '@models/shared/betSlip/betSlipEnums';
import { instanceGuard } from '@core/utils/services';
import { Gain } from '@core/models/betSlip/Payout';
import Decimal from 'decimal.js-light';
import Singleton from '../common/Singleton';
import SingletonError from '../common/errors/SingletonError';

// tslint:disable-next-line: variable-name
const DECIMAL_PRECISiON = 8;

export class TaxService {
    private static instance: TaxService;
    public readonly offlineTax: number;
    public readonly onlineTax: number;
    private winTax: ServerConfig['betSlip']['winTaxBounds'];
    private readonly stakeCalculationMethod: HandlingFeeMethod;

    private constructor(config: ServerConfig) {
        if (TaxService.instance) {
            throw new SingletonError(this.constructor.name);
        }
        this.offlineTax = config.betSlip.offlineTax;
        this.onlineTax = config.betSlip.onlineTax;
        this.winTax = config.betSlip.winTaxBounds;
        this.stakeCalculationMethod = config.betSlip.handlingFeeMethod;
    }

    public static getInstance(): TaxService {
        return instanceGuard(TaxService.instance);
    }

    public static createInstance(config: ServerConfig) {
        if (!TaxService.instance) {
            TaxService.instance = new TaxService(config);
        }
        return TaxService.instance;
    }

    public getStakeTaxByPurchaseType(purchaseType: BetSlipPurchaseType): number {
        switch (purchaseType) {
            case BetSlipPurchaseType.online:
                return this.onlineTax;
            case BetSlipPurchaseType.offline:
                return this.offlineTax;
        }
    }

    public getStakeAfterDeductions(stake: number, taxMethod: BetSlipPurchaseType | number): number {
        let taxPercentage = 0;
        // REFACTOR use only BetSlipPurchaseType
        if (typeof taxMethod === 'number') {
            taxPercentage = taxMethod;
        } else {
            taxPercentage = this.getStakeTaxByPurchaseType(taxMethod);
        }
        if (taxPercentage <= 0) {
            return stake;
        }

        const taxValue = taxPercentage / 100;

        let stakeWithoutRounding;
        if (this.stakeCalculationMethod === HandlingFeeMethod.TaxOnNet) {
            stakeWithoutRounding = stake / (1 + taxValue);
        } else {
            stakeWithoutRounding = stake * (1 - taxValue);
        }

        return _round(stakeWithoutRounding, 8);
    }

    private applyTaxToSpecificAmount(amount: number) {
        if (!this.winTax) {
            return { amount };
        }
        let taxClass = -1;
        for (let i = 0; i < this.winTax.length; i += 1) {
            if (amount >= this.winTax[i].lowerBound) {
                taxClass = i;
            }
        }
        if (taxClass === -1) {
            return { amount };
        }
        const taxPercentage = this.winTax[taxClass].percentage;
        return {
            taxPercentage,
            amount: _round(amount * (1 - taxPercentage / 100), DECIMAL_PRECISiON),
        };
    }

    public applyWinTax({ amount, bonus = 0 }: Gain) {
        if (amount === 0) {
            return {
                amount: 0,
                afterTaxBonus: 0,
                afterTaxAmount: 0,
                final: 0,
                tax: 0,
            };
        }

        const base = amount + bonus;
        const { amount: final, taxPercentage } = this.applyTaxToSpecificAmount(base);
        const tax = base - final;
        const amountShare = amount / base;
        const afterTaxBonus = _round(final * (1 - amountShare), DECIMAL_PRECISiON);
        const afterTaxAmount = _round(final * amountShare, DECIMAL_PRECISiON);

        return {
            amount,
            afterTaxBonus,
            afterTaxAmount,
            final,
            tax,
            taxPercentage,
        };
    }

    private getTaxClass(amount: number) {
        let taxClass: TaxLimit | null = null;
        for (let i = 0; i < this.winTax.length; i += 1) {
            if (amount >= this.winTax[i].lowerBound) {
                taxClass = this.winTax[i];
            }
        }
        return taxClass;
    }

    public getWinTax(amount: Decimal) {
        const taxClass = this.getTaxClass(amount.toNumber());
        const result = {
            percentage: 0,
            value: new Decimal(0),
        };
        if (taxClass) {
            const tax = amount.mul(taxClass.percentage).div(100);
            result.value = tax;
            result.percentage = taxClass.percentage;
        }
        return result;
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete TaxService.instance;
    }
}

export default TaxService as Singleton<TaxService>;
