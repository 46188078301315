<template>
    <div class="betslip--superbonus">
        <div class="betslip--superbonus__header">
            <img :src="superBonusIcon" width="210" height="210" />
            <i class="betslip--superbonus__header__close sds-icon-navigation-close" @click="$emit('onClose')" />
        </div>
        <div class="betslip--superbonus__body">
            <div class="betslip--superbonus__body__title">{{ $t('superbonusPromoModal.title') }}</div>
            <ul v-for="(message, index) in superBonusInfo" :key="index" class="betslip--superbonus__body__list">
                <li>{{ $t(message) }}</li>
            </ul>
        </div>
        <div class="betslip--superbonus__close" @click="$emit('onClose')">
            {{ $t('superbonusPromoModal.buttonLabel') }}
        </div>
    </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import superBonusIcon from '@superbet-group/web.lib.ui-tokens/output/assets/brand/generosity-super-bonus.png';

export default defineComponent({
    name: 'BetslipSuperBonusOverlay',
    setup() {
        const superBonusInfo = computed(() => {
            return [
                'superbonusPromoModal.content.numberOfLegs',
                'superbonusPromoModal.content.competitions',
                'superbonusPromoModal.content.validSelectionTypes',
                'superbonusPromoModal.content.minOddValue',
            ];
        });

        return {
            superBonusIcon,
            superBonusInfo,
        };
    },
});
</script>
