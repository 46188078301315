<template>
    <div class="modal__body">
        <div class="modal__body__input">
            <div :class="['modal__body__input__ticket', { error: hasError }]">
                <div class="modal__body__input__ticket__code">
                    <span class="modal__body__input__ticket__code__label">{{ $t('ticket code') }}</span>
                    <span class="modal__body__input__ticket__code__value">{{ internalValue }}</span>
                </div>
                <i v-if="hasError" class="modal__body__input__ticket--error sds-icon-status-danger-filled" />
                <span>{{ $t('code') }}</span>
                <i
                    class="modal__body__input__ticket__clear sds-icon-navigation-close-alt-filled"
                    @click="onKeypadClick({ value: 'C' })"
                />
            </div>
            <p v-for="msg in errors" :key="msg" class="modal__body__input--error">
                {{ msg }}
            </p>
        </div>
        <div class="modal__body__numpad">
            <template v-for="numberKey in fetchNumberKeys">
                <div :key="numberKey.value" class="modal__body__numpad__key" @click="onKeypadClick(numberKey)">
                    {{ numberKey.value }}
                </div>
            </template>
            <template v-for="numberKey in fetchAdditionalKeys">
                <div :key="numberKey.value" class="modal__body__numpad__key" @click="onKeypadClick(numberKey)">
                    {{ numberKey.value }}
                </div>
            </template>
            <div class="modal__body__numpad__key" @click="onKeypadClick(delKey)">
                <img src="/static/img/illustrations/v2/delete-button.svg" />
            </div>
        </div>
        <div class="modal__body__button" @click="closeModal">
            {{ $t('close') }}
        </div>
        <div
            :class="['modal__body__button', 'modal__body__button--submit', { disabled: isButtonDisabled }]"
            @click="submit"
        >
            <div v-if="isLoading" class="btn-loader" />
            <span v-else>{{ $t(buttonLabel) }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { get as _get, range as _range } from '@lodash';
import TicketService from '@core/services/tickets/TicketService';
import PlatformService from '@src/terminal/core/services/platform/PlatformService';
import commonConfig from '@src/config/common';
import { useVuexStore } from '@src/v2/app/utils/vuexStore';
import modalModule from '@store/modules/ui/shared/modal/modalIndex';
import i18n from '@src/app/localization/i18n';

const DEFAULT_VALUE = '';

export default defineComponent({
    name: 'CancelTicketModalV2',
    setup() {
        const delKey = ref({ value: 'Del' });
        const internalValue = ref(DEFAULT_VALUE);
        const errors = ref<string[]>([]);
        const isLoading = ref(false);

        const modalStore = useVuexStore<typeof modalModule>('ui/modal');
        const modalData = modalStore.getter('modalData');
        const closeModal = modalStore.action('closeModal');

        const hasError = computed(() => {
            return !!errors.value.length;
        });

        const buttonLabel = computed(() => {
            return _get(modalData.value, 'button.label') || 'ok';
        });

        const fetchNumberKeys = computed(() => {
            return _range(9).map((i) => ({ value: `${i + 1}` }));
        });

        const fetchAdditionalKeys = computed(() => {
            return [{ value: 'C' }, { value: '0' }];
        });

        const ticketId = computed(() => {
            return _get(modalData.value, 'ticketId', 'INVALID_TICKET_ID');
        });

        const btnDisabled = computed(() => {
            return internalValue.value.toString().length < 4;
        });

        const isButtonDisabled = computed(() => {
            return btnDisabled.value || isLoading.value;
        });

        const onKeypadClick = (numberKey: { value: string }) => {
            if (hasError.value) clearErrors();

            switch (numberKey.value) {
                case 'C':
                    internalValue.value = DEFAULT_VALUE;
                    break;
                case 'Del':
                    removeValueFromInternalValue();
                    break;
                default:
                    appendValueToInternalValue(numberKey.value);
                    break;
            }
        };

        const appendValueToInternalValue = (value: string) => {
            const newValue = internalValue.value + validateValue(value);
            internalValue.value = newValue;
        };

        const removeValueFromInternalValue = () => {
            const currentValue = internalValue.value;
            internalValue.value = currentValue.slice(0, -1);
        };

        const validateValue = (value: string) => {
            if (hasFourDigits()) {
                return DEFAULT_VALUE;
            }
            return value;
        };

        const hasFourDigits = () => {
            return internalValue.value.toString().length >= 4;
        };

        const clearErrors = () => {
            errors.value = [];
        };

        const pushError = (error: string) => {
            errors.value.push(error);
        };

        const submit = async () => {
            if (btnDisabled && !ticketId.value) {
                return;
            }
            clearErrors();
            const id = ticketId.value.toLowerCase();
            const controlCode = internalValue.value;
            isLoading.value = true;
            const response = await TicketService.getInstance().cancelTicket(id, controlCode);
            isLoading.value = false;
            const { error, errorMessage } = response;
            if (!error) {
                const msg = i18n.t('ticket canceled successfully').toString().toLocaleUpperCase();
                PlatformService.getInstance().sendNotification({
                    title: msg,
                    text: msg,
                    type: 'success',
                });
                if (commonConfig.environment.isInHouse) {
                    PlatformService.getInstance().requestBalanceStatusRequest();
                }
                closeModal();
            } else if (error && _get(response, 'data.ticketStatusCanceled', false)) {
                const msg = i18n.t('ticket already canceled').toString().toLocaleUpperCase();
                PlatformService.getInstance().sendNotification({
                    title: msg,
                    text: msg,
                    type: 'error',
                });
                closeModal();
            } else if (error && _get(response, 'data.isWrongTicketControlCode', false)) {
                pushError(i18n.t('wrong control code', { controlCode, ticketId: id }).toString());
            } else {
                const msg = errorMessage;
                PlatformService.getInstance().sendNotification({
                    title: msg,
                    text: msg,
                    type: 'error',
                });
                closeModal();
            }
        };

        return {
            hasError,
            internalValue,
            onKeypadClick,
            errors,
            fetchNumberKeys,
            fetchAdditionalKeys,
            delKey,
            closeModal,
            isButtonDisabled,
            submit,
            buttonLabel,
            isLoading,
        };
    },
});
</script>
