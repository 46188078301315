export enum PredefinedItems {
    all = 'all',
    promotions = 'promotions',
}

export enum ItemType {
    sportId = 'sportId',
    tournamentId = 'tournamentId',
    categoryId = 'categoryId',
}

export enum MarketFilter {
    popular = 'popular',
    allMarkets = 'allMarkets',
}
export enum EventTab {
    popular = 'popular',
    allMarkets = 'allMarkets',
    chat = 'chat',
}
