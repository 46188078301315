<template>
    <div :class="className" :to="link" :title="title" tag="div" @click="onClick(id)">
        <span class="nav-sidebar-promo__item-image" :style="{ backgroundImage: `url(${resizedImage})` }" />
    </div>
</template>

<script>
export default {
    name: 'SidebarPromoItems',
    props: {
        id: {
            type: Number,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        selectedItemId: {
            type: Number,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },
    computed: {
        className() {
            return {
                'nav-sidebar-promo__item': true,
                'nav-sidebar-promo__item--selected': this.id === this.selectedItemId,
            };
        },
        resizedImage() {
            return this.image.replace('_960.', '_800.');
        },
    },
    methods: {
        onClick(id) {
            this.$emit('click', id);
        },
    },
};
</script>
