import { EventStatus } from '@core/models/tickets/enums';
import { RecreateTicketData, RecreateTicketEventData } from '@core/models/tickets/Ticket';
import TicketService from '@core/services/tickets/TicketService';
import router from '@src/app/router';
import { routeNames } from '@src/app/router/constants';
import store from '@store/index';
import GetTicketDto, { EventDto } from '@core/mappers/tickets/dtos/GetTicketDto';
import FeatureFlagService from '../../flags/FeatureFlagService';

export default async (ticketId: string) => {
    const filterEvents = (events: RecreateTicketEventData[]) => {
        if (FeatureFlagService.getInstance().isInPlayAvailable()) {
            return events;
        }

        const isStartedEvent = (event: RecreateTicketEventData) => event.date! < new Date();
        return events.filter((event: RecreateTicketEventData) => !isStartedEvent(event));
    };

    const mapDataForRecreate = (ticket: GetTicketDto) => {
        return {
            stake: ticket.ticketData.sumTotal,
            ticketUuid: ticket.id,
            ticketCode: ticket.ticketId,
            channel: ticket.ticketData.config.channel,
            system:
                ticket.ticketData.system?.groupOptionsList?.length > 0
                    ? {
                          count: ticket.ticketData.system.groupCount,
                          fixed: ticket.ticketData.events.filter((event: EventDto) => event.fix).length,
                          selected: ticket.ticketData.system.groupOptionsList,
                      }
                    : null,
            events: filterEvents(
                ticket.ticketData.events.map((event: EventDto) => ({
                    date: new Date(event.dateEvent),
                    status: event.status as EventStatus,
                    eventId: event.matchId,
                    oddId: String(event.oddTypeId),
                    oddUuid: event.oddUuid,
                    specialBetValue: event.sbValue,
                    isFix: event.fix,
                    oddSourceType: event.oddSourceType,
                })),
            ),
        } as RecreateTicketData;
    };

    const fetchedTicketData = await TicketService.getInstance().getTicketById(ticketId);
    const recreateTicketData = mapDataForRecreate(fetchedTicketData);
    store.dispatch('ui/sportOffer/betSlip/recreateTicket', recreateTicketData, { root: true });
    router.push({ name: routeNames.landing });
};
