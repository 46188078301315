export const ADD_SELECTION = 'ADD_SELECTION';
export const UPDATE_SELECTION = 'UPDATE_SELECTION';
export const REMOVE_SELECTION = 'REMOVE_SELECTION';
export const CLEAR_SELECTIONS = 'CLEAR_SELECTIONS';
export const TOGGLE_SELECTION_IS_FIXED = 'TOGGLE_SELECTION_IS_FIXED';
export const SELECTION_FIX = 'SELECTION_FIX';
export const TOGGLE_SELECTION_ODD = 'TOGGLE_SELECTION_ODD';
export const SET_UNDO_SELECTIONS = 'SET_UNDO_SELECTIONS';
export const SET_UNDO_STAKE = 'SET_UNDO_STAKE';
export const SET_UNDO_SYSTEMS = 'SET_UNDO_SYSTEMS';
export const SET_UNDO_SELECTIONS_TIMER_ID = 'SET_UNDO_SELECTIONS_TIMER_ID';

export const SET_STAKE = 'SET_STAKE';
export const SET_PURCHASE_TYPE = 'SET_PURCHASE_TYPE';
export const SET_TYPE = 'SET_TYPE';
export const SET_IS_LOADING = 'SET_IS_LOADING';

export const SET_CHANGE = 'SET_CHANGE';
export const DELETE_CHANGE = 'DELETE_CHANGE';
export const CLEAR_CHANGES = 'CLEAR_CHANGES';
export const TOGGLE_AUTO_UPDATE_CHANGES = 'TOGGLE_AUTO_UPDATE_CHANGES';
export const SET_AUTO_UPDATE_CHANGES = 'SET_AUTO_UPDATE_CHANGES';
export const UPDATE_SELECTION_EVENT = 'UPDATE_SELECTION_EVENT';

export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_SELECTION_ERRORS = 'SET_SELECTION_ERRORS';
export const CLEAR_SELECTION_ERRORS = 'CLEAR_SELECTION_ERRORS';
export const SET_SELECTION_ADD_ERROR = 'SET_SELECTION_ADD_ERROR';

export const TOGGLE_SYSTEM_SELECTED = 'TOGGLE_SYSTEM_SELECTED';

export const TOGGLE_SELECTED_SYSTEM_MIN_NUMBER = 'TOGGLE_SELECTED_SYSTEM_MIN_NUMBER';
export const SET_SELECTED_SYSTEM_MIN_NUMBER = 'SET_SELECTED_SYSTEM_MIN_NUMBER';
export const DELETE_SELECTED_SYSTEM_MIN_NUMBER = 'DELETE_SELECTED_SYSTEM_MIN_NUMBER';
export const CLEAR_SELECTED_SYSTEMS_MIN_NUMBER = 'CLEAR_SELECTED_SYSTEMS_MIN_NUMBER';

export const SET_PAYOUT = 'SET_PAYOUT';
// we need this one only when we deserialize betSlip because we don't wanna
// bunch of calculation, only one at the end
export const SET_IS_PAYOUT_LOCKED = 'SET_IS_PAYOUT_LOCKED';
export const UPDATE_SELECTION_ODD = 'UPDATE_SELECTION_ODD';

export const ADD_SELECTION_CHANGE = 'ADD_SELECTION_CHANGE';
export const REMOVE_SELECTION_CHANGE = 'REMOVE_SELECTION_CHANGE';
export const CLEAR_SELECTION_CHANGES = 'CLEAR_SELECTION_CHANGES';
export const SET_CHANGES = 'SET_CHANGES';

export const SET_REFERRAL = 'SET_REFERRAL';
export const SET_UNDO_REFERRAL = 'SET_UNDO_REFERRAL';
