import Match from '@core/models/offer/Match';
import { OddMapper } from '@core/mappers/offer/OddMapper';
import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import RuleMapper from '@core/mappers/offer/RuleMapper';
import { MetaDataMapper } from '@core/mappers/offer/MetaDataMapper';
import { MatchResultMapper } from '@core/mappers/offer/MatchResultMapper';
import { EventCategory, OfferStatus, OfferType } from '@core/models/offer/enums';
import { EventDto } from '@superbet-group/offer.clients.lib';
import { RuleType } from '@core/models/shared/betSlip/betSlipEnums';
import Rule from '@core/models/shared/betSlip/Rule';

const metaDataMapper: MetaDataMapper = new MetaDataMapper();
const ruleMapper: RuleMapper = new RuleMapper();
const oddMapper: OddMapper = new OddMapper();
const matchResultMapper: MatchResultMapper = new MatchResultMapper();

const SAME_MATCH_PROHIBITION_RULE: Rule = {
    modifier: '',
    name: RuleType.prohibitionOfSameMatch,
    param1: 0,
    param2: 0,
};

export class EventMapper extends AbstractMapper<EventDto, Match> {
    createTargetObject(dto: EventDto) {
        const e: Match = new Match();
        const isLiveEvent = dto.offerStateStatus[OfferType.live] === OfferStatus.active;

        e.id = dto.eventId;
        e.uuid = dto.uuid;
        e.offerId = dto.offerId;
        e.specialOfferId = dto.specialOfferId ? parseInt(dto.specialOfferId.replace(/[^0-9\.]+/g, ''), 10) : null;
        e.fullName = dto.matchName;
        e.sportId = dto.sportId;
        e.tournamentId = dto.tournamentId;
        e.date = this.toDate(dto.utcDate) as Date;
        e.utcDate = dto.utcDate; // forwarded when saving ticket, to save on string -> Date -> string conversion
        e.matchTimestamp = dto.matchTimestamp;

        e.oddCount = isLiveEvent ? dto.counts.odds[OfferType.live] : dto.counts.odds[OfferType.preEvent] || 0;
        e.marketCount = dto.marketCount;
        e.team1Id = parseInt(dto.homeTeamId, 10);
        e.team2Id = parseInt(dto.awayTeamId, 10);
        e.incrementId = dto.incrementId;
        e.betRadarId = dto.betradarId;
        e.categoryId = dto.categoryId;
        e.offerStatus = dto.offerStateStatus;
        e.isGoingToBeInLiveBetting = dto.hasLive;
        e.rules = dto.resolvingRules ? ruleMapper.map(dto.resolvingRules, true) : [];
        e.enteringCodes = dto.enteringCodes;
        e.eventCategory = dto.eventCategory || null;

        e.code = e.eventCategory === EventCategory.OUTRIGHT ? 0 : dto.matchCode;

        // Same match prohibition rule should be hardcoded by instructions from offer team
        e.rules.push(SAME_MATCH_PROHIBITION_RULE);

        e.metaData = dto.metadata ? metaDataMapper.map(dto.metadata, false) : undefined;

        if (dto.matchFooter) {
            e.matchFooter = dto.matchFooter;
        }

        const odds = isLiveEvent
            ? (dto.odds || []).filter((o) => o.offerStateId === OfferType.live)
            : (dto.odds || []).filter((o) => o.offerStateId === OfferType.preEvent);

        const isSpecialFromSportOffer = e.eventCategory === EventCategory.OUTRIGHT || e.code === 0;

        e.setOdds(oddMapper.map(odds, isSpecialFromSportOffer));

        e.setOddResults(dto.oddsResults ? oddMapper.map(dto.oddsResults!, isSpecialFromSportOffer) : []);

        e.matchResults = matchResultMapper.createTargetObject({
            matchResults: dto.matchResults,
            metaData: dto.metadata,
        });

        return e;
    }
}
