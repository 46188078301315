export enum TicketType {
    sport = 'sport',
    luckySix = 'LuckySix',
    greyhound = 'GreyhoundRaces',
}

export enum TicketStatus {
    active = 'active',
    canceled = 'canceled',
    lost = 'lost',
    obsolete = 'obsolete',
    payed = 'payed',
    refund = 'refund',
    win = 'win',
}

export enum EventType {
    static = 'static',
    live = 'live',
}

export enum EventStatus {
    active = 'active',
    lost = 'lost',
    win = 'win',
    refund = 'refund',
}

export enum MarketType {
    UNSPECIFIED = 'UNSPECIFIED',
    IN_PLAY = 'IN_PLAY',
    PREGAME = 'PREGAME',
}

export const OddSourceType = {
    BET_BUILDER: 201,
    REGULAR: 101,
    SUPERBETS: 202,
} as const;

export type OddSourceTypeValues = (typeof OddSourceType)[keyof typeof OddSourceType];
