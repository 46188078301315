import commonConfig from '@src/config/common';

export const localeNumberFormat = (number: number) =>
    number.toLocaleString(commonConfig.numberLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const fact = (num: number): number => {
    if (num < 0) return -1;
    if (num === 0) return 1;
    return num * fact(num - 1);
};

export const calcCombs = (n: number, r: number) => fact(n) / (fact(r) * fact(n - r));
