import DateUtils from '@utils/date/DateUtils';
import i18n from '@app/localization/i18n';
import { slugify } from '@app/utils/slugify';
import { liveTimeFilterSlug, todayTimeFilterSlug, allTimeFilterSlug } from '@store/modules/ui/shared/timeFilter/utils';
import { formatDateTime } from '@shared/filters';
import Match from '@models/offer/Match';
import BookieDate from '@utils/date/BookieDate';
import { OfferInterval, OfferIntervalOptions } from '@superbet-group/offer.clients.lib';
import VueI18n from 'vue-i18n';
import store from '@store/index';
import { TimeFilter } from './enums';

const dayTimeFilters = [
    TimeFilter.sunday,
    TimeFilter.monday,
    TimeFilter.tuesday,
    TimeFilter.wednesday,
    TimeFilter.thursday,
    TimeFilter.friday,
    TimeFilter.saturday,
];

export function getTimeFilterFromSlug(timeFilterSlug: string) {
    switch (timeFilterSlug) {
        case todayTimeFilterSlug:
            return TimeFilter.today;
        case slugify('allToday'):
            return TimeFilter.allToday;
        case slugify('prematch'):
            return TimeFilter.prematch;
        case slugify('tomorrow'):
            return TimeFilter.tomorrow;
        case slugify('monday').toString():
            return TimeFilter.monday;
        case slugify('tuesday').toString():
            return TimeFilter.tuesday;
        case slugify('wednesday').toString():
            return TimeFilter.wednesday;
        case slugify('thursday').toString():
            return TimeFilter.thursday;
        case slugify('friday').toString():
            return TimeFilter.friday;
        case slugify('saturday').toString():
            return TimeFilter.saturday;
        case slugify('sunday').toString():
            return TimeFilter.sunday;
        case liveTimeFilterSlug:
            return TimeFilter.live;
        case slugify('upcoming'):
            return TimeFilter.upcoming;
        case slugify('next3H'):
            return TimeFilter.next3H;
        case slugify('next6H'):
            return TimeFilter.next6H;
        case slugify('next12H'):
            return TimeFilter.next12H;
        case allTimeFilterSlug:
            return TimeFilter.all;
        default:
            return TimeFilter.all;
    }
}

export function getDateFromDateSlug(date: string) {
    return new Date(date);
}

// opposite of getTimeFilterFromSlug
export function getSlugFromTimeFilter(timeFilter: TimeFilter) {
    return slugify(timeFilter.toString());
}

export function getLabelFromTimeFilter(timeFilter: TimeFilter) {
    if (isDate(timeFilter)) {
        return formatDateTime(getDateForSpecificWeekDay(getDayIndexForTimeFilter(timeFilter)), 'w');
    }
    return getTimeFilterLabel(timeFilter);
}

export function getFormattedDateFromTimeFilter(timeFilter: TimeFilter) {
    if (isDate(timeFilter)) {
        return formatDateTime(getDateForSpecificWeekDay(getDayIndexForTimeFilter(timeFilter)), 'ddmm');
    }
    return '';
}
export function getFormattedDateFromTimeFilterV2(timeFilter: TimeFilter) {
    if (isDate(timeFilter)) {
        return formatDateTime(getDateForSpecificWeekDay(getDayIndexForTimeFilter(timeFilter)), 'wddmonth');
    }
    return '';
}

export function getTimeFilterForWeekDay(dayIndex: number) {
    return dayTimeFilters[dayIndex];
}

function getTimeFilterLabel(timeFilter: TimeFilter) {
    const labelMap = {
        [TimeFilter.today]: i18n.t('today'),
        [TimeFilter.allToday]: i18n.t('all'),
        [TimeFilter.prematch]: i18n.t('prematch'),
        [TimeFilter.tomorrow]: i18n.t('tomorrow'),
        [TimeFilter.upcoming]: store.getters['data/flags/useDesignV2'] ? i18n.t('upcoming') : i18n.t('next3H'),
        [TimeFilter.next3H]: i18n.t('next3H'),
        [TimeFilter.next6H]: i18n.t('next6H'),
        [TimeFilter.next12H]: i18n.t('next12H'),
        [TimeFilter.live]: i18n.t('live'),
        [TimeFilter.all]: i18n.t('all'),
    } as Record<Partial<TimeFilter>, VueI18n.TranslateResult>;

    return labelMap[timeFilter];
}

function isDate(timeFilter: TimeFilter) {
    return dayTimeFilters.includes(timeFilter);
}

function getDayIndexForTimeFilter(timeFilter: TimeFilter) {
    return dayTimeFilters.indexOf(timeFilter);
}

function getDateForSpecificWeekDay(weekDay: number) {
    const today = new Date();
    const desiredDay = DateUtils.addDays((weekDay - today.getDay() + 7) % 7, today);
    return desiredDay;
}

export function getTimeSpanForTimeFilter(timeFilter: TimeFilter) {
    const today = new Date();
    switch (timeFilter) {
        case TimeFilter.all:
            return {
                startDate: today,
                endDate: BookieDate.getEndDateForDate(DateUtils.addDays(7, today)),
            };
        case TimeFilter.live:
        case TimeFilter.today:
        case TimeFilter.allToday:
        case TimeFilter.prematch:
            return {
                startDate: today,
                endDate: BookieDate.getEndDateForDate(today),
            };
        case TimeFilter.tomorrow:
            const tomorrow = DateUtils.addDays(1, today);
            return {
                startDate: tomorrow,
                endDate: BookieDate.getEndDateForDate(tomorrow),
            };
        case TimeFilter.upcoming:
            const endTime = store.getters['data/flags/useDesignV2'] ? 2 : 3;

            return {
                startDate: today,
                endDate: DateUtils.addHours(endTime, today),
            };
        case TimeFilter.next3H:
            return {
                startDate: today,
                endDate: DateUtils.addHours(3, today),
            };
        case TimeFilter.next6H:
            return {
                startDate: today,
                endDate: DateUtils.addHours(6, today),
            };
        case TimeFilter.next12H:
            return {
                startDate: today,
                endDate: DateUtils.addHours(12, today),
            };
        default:
            break;
    }
    const dayIndex = getDayIndexForTimeFilter(timeFilter);
    const dayDifference = (dayIndex - today.getDay() + 7) % 7;
    const desiredDay = DateUtils.addDays(dayDifference, today);

    return {
        startDate: BookieDate.getStartDateForDate(desiredDay),
        endDate: BookieDate.getEndDateForDate(desiredDay),
    };
}

export function getIntervalForTimeFilter(timeFilter: TimeFilter): {
    interval: OfferInterval;
    options?: OfferIntervalOptions;
} {
    const today = new Date();

    switch (timeFilter) {
        case TimeFilter.all:
            return {
                interval: OfferInterval.CURRENT_YEAR,
            };
        case TimeFilter.live:
        case TimeFilter.today:
        case TimeFilter.allToday:
        case TimeFilter.prematch:
            return {
                interval: OfferInterval.TODAY,
            };
        case TimeFilter.tomorrow:
            return {
                interval: OfferInterval.TOMORROW,
            };
        case TimeFilter.upcoming:
            const hoursInterval = store.getters['data/flags/useDesignV2'] ? 2 : 3;

            return {
                interval: OfferInterval.X_HOURS_FROM_NOW,
                options: { hours: hoursInterval },
            };
        case TimeFilter.next3H:
            return {
                interval: OfferInterval.X_HOURS_FROM_NOW,
                options: { hours: 3 },
            };
        case TimeFilter.next6H:
            return {
                interval: OfferInterval.X_HOURS_FROM_NOW,
                options: { hours: 6 },
            };
        case TimeFilter.next12H:
            return {
                interval: OfferInterval.X_HOURS_FROM_NOW,
                options: { hours: 12 },
            };
        default:
            break;
    }

    const dayIndex = getDayIndexForTimeFilter(timeFilter);
    const dayDifference = (dayIndex - today.getDay() + 7) % 7;

    return {
        interval: OfferInterval.X_DAYS_FROM_TODAY,
        options: { days: dayDifference },
    };
}

function getTimeSpanForDate(date: Date) {
    const startDate = DateUtils.toStartOfDay(date);
    return {
        startDate,
        endDate: DateUtils.addDays(1, startDate),
    };
}

export function getTimeFilterFunctionForTimeFilter(timeFilter: TimeFilter) {
    const { startDate, endDate } = getTimeSpanForTimeFilter(timeFilter);

    switch (timeFilter) {
        case TimeFilter.all:
            return (event: Match) => {
                return !event.isOngoing();
            };
        case TimeFilter.live:
            // live won't be called for lotto
            return (event: Match) => {
                if (event instanceof Match) {
                    return event.isOngoing();
                }
                return false;
            };
        case TimeFilter.today:
        case TimeFilter.allToday:
            const shouldFilterLive =
                store.getters['data/flags/useDesignV2'] && store.getters['data/flags/isInPlayAvailable'];

            return (event: Match) => {
                const betTime = event.date;
                return (
                    (shouldFilterLive && event.isOngoing()) ||
                    DateUtils.isDateBetweenInclusive(startDate, endDate, betTime)
                );
            };
        default:
            return (event: Match) => {
                const betTime = event.date;
                return DateUtils.isDateBetweenInclusive(startDate, endDate, betTime);
            };
    }
}

export function getTimeFilterFunctionForDate(date: Date) {
    const { startDate, endDate } = getTimeSpanForDate(date);
    return (event: Match) => {
        const betTime = event.date;
        return DateUtils.isDateBetweenInclusive(startDate, endDate, betTime);
    };
}
