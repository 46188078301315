<template>
    <i
        :class="{
            icon: true,
            'icon--xxs': size === 'xxs',
            'icon--xs': size === 'xs',
            'icon--sm': size === 'sm',
            'icon--md': size === 'md',
            'icon--xl': size === 'xl',
        }"
        @click="$emit('click', $event)"
    />
</template>

<script lang="ts">
export default {
    name: 'MarketIcon',
    props: {
        size: {
            type: String,
            default: '',
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@mixin icon-size($size) {
    font-size: $size;
    width: $size;
    height: $size;
}

.icon {
    display: inline-block;
    line-height: 1;
    text-align: center;
    @include icon-size(24px);

    &:before {
        font-family: 'superbet-icons' !important;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    &--xxs {
        @include icon-size(10px);
    }

    &--xs {
        @include icon-size(12px);
    }

    &--sm {
        @include icon-size(16px);
    }

    &--md {
        @include icon-size(20px);
    }

    &--xl {
        @include icon-size(32px);
    }
}
</style>
