import Vue from 'vue';
import { forEach as _forEach } from '@lodash';
import { isMobileSafari, isSafari, isIOSChrome } from '@core/utils/userAgent/UAUtils';
import { breakpoints, ScreenWidth } from './constants';
import { _respondToBelow } from './_uiHelpers';

function getDeviceWidthBasedType(width: number) {
    if (width >= breakpoints.dXs) {
        return 'desktop';
    }
    if (width >= breakpoints.tSm) {
        return 'tablet';
    }
    return 'mobile';
}

export function detectEffectiveDeviceType() {
    return getDeviceWidthBasedType(document.documentElement!.clientWidth);
}

export const screenInfo = Vue.observable({
    width: window.innerWidth,
});

window.addEventListener('resize', () => {
    screenInfo.width = window.innerWidth;
});

// used width media query min-width
export function determineWidthBreakpointMin() {
    let breakpoint = ScreenWidth.dXl;
    _forEach(breakpoints, (width, key) => {
        if (width > screenInfo.width) {
            return breakpoint;
        }
        breakpoint = key as ScreenWidth;
    });
    return breakpoint;
}

export function setWindowScrollTop(scrollTop: number) {
    if (isSafari() || isMobileSafari() || isIOSChrome()) {
        document.scrollingElement!.scrollTop = scrollTop;
    } else {
        document.documentElement!.scrollTop = scrollTop;
    }
}

export function respondToBelow(screenWidth: ScreenWidth) {
    return _respondToBelow(screenInfo.width, screenWidth);
}

export { ScreenWidth };
