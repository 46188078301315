import SportOffer, { ISportOfferState } from './sportOffer/sportOfferIndex';
import Modal, { IModalState } from './shared/modal/modalIndex';
import DeviceInfo, { IDeviceInfoState } from './deviceInfo/deviceInfoIndex';
import TicketsStack, { ITicketsStackState } from './shared/ticketsStack/ticketsStackIndex';

export interface IUiState {
    sportOffer: ISportOfferState;
    modal: IModalState;
    ticketsStack: ITicketsStackState;
    deviceInfo: IDeviceInfoState;
}

export default {
    namespaced: true,
    modules: {
        sportOffer: SportOffer,
        modal: Modal,
        ticketsStack: TicketsStack,
        deviceInfo: DeviceInfo,
    },
};
