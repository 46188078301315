import AuthenticationError from '@errors/authentication/AuthenticationError';
import { RequestError } from '@errors/network/RequestError';
import config from '@config';

export async function processResponse(responsePromise: Promise<any>) {
    const response = await responsePromise;
    if (response.error) {
        if (response.errorCode === 'PlayerSessionCheckFailed') {
            throw new AuthenticationError();
        }

        throw getRequestError(response);
    }

    return responsePromise;
}

export function getRequestError(response: any) {
    return new RequestError(new Error(JSON.stringify(response)));
}

export function logSocketRawEvent(target: any, event: any) {
    if (config.environment.isAxCluster && !config.environment.isTest) {
        if ((window as any)[target][event.mi]) {
            (window as any)[target][event.mi].push(event);
        } else {
            (window as any)[target][event.mi] = [event];
        }
    }
}

export function logSocketEvent(target: any, event: any) {
    if (config.environment.isAxCluster && !config.environment.isTest) {
        if ((window as any)[target][event.id]) {
            (window as any)[target][event.id].push(event);
        } else {
            (window as any)[target][event.id] = [event];
        }
    }
}
export const createQueryParamsString = (params: object): string =>
    Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
