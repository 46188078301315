export const SET_BET_BUILDER_ODD = 'SET_BET_BUILDER_ODD';
export const SET_STRUCT = 'SET_STRUCT';
export const UPDATE_ONE_STRUCT_CATEGORY = 'UPDATE_ONE_STRUCT_CATEGORY';
export const UPDATE_ONE_STRUCT_SPORT = 'UPDATE_ONE_STRUCT_SPORT';
export const UPDATE_ONE_STRUCT_TOURNAMENT = 'UPDATE_ONE_STRUCT_TOURNAMENT';
export const SET_TOP_TEN_EVENTS = 'SET_TOP_TEN_EVENTS';
export const SET_SUPER_EVENTS = 'SET_SUPER_EVENTS';
export const SET_SPORTS_TREES = 'SET_SPORTS_TREES';
export const SET_EVENTS = 'SET_EVENTS';
export const SET_EVENT = 'SET_EVENT';
// when event is finished
export const REMOVE_EVENTS = 'REMOVE_EVENTS';
export const REMOVE_EVENT = 'REMOVE_EVENT';
export const SET_TOP_TEN_EVENT = 'SET_TOP_TEN_EVENT';
export const SET_PROMOTIONAL_EVENTS = 'SET_PROMOTIONAL_EVENTS';
export const SET_EVENT_SUBSCRIPTION = 'SET_LIVE_EVENT_SUBSCRIPTION';
export const REMOVE_EVENT_SUBSCRIPTION = 'REMOVE_EVENT_SUBSCRIPTION';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const SET_IS_OFFER_STARTING = 'SET_IS_OFFER_STARTING';
export const SET_IS_OFFER_READY = 'SET_IS_OFFER_READY';
export const SET_SHOULD_FETCH_OFFER = 'SET_SHOULD_FETCH_OFFER';
export const SET_SPECIAL_TOURNAMENTS = 'SET_SPECIAL_TOURNAMENTS';

export const SET_LIVE_EVENTS_COUNT = 'SET_LIVE_EVENTS_COUNT';
export const INCREMENT_ALL_CHANGES_ID = 'INCREMENT_ALL_CHANGES_ID';
export const SET_ARE_SUBSCRIPTIONS_SET = 'SET_ARE_SUBSCRIPTIONS_SET';

export const SET_IS_OFFER_FETCHED = 'SET_IS_OFFER_FETCHED';
export const SET_IS_SUPER_OFFER_FETCHED = 'SET_IS_SUPER_OFFER_FETCHED';
export const SET_ARE_TOP_TEN_EVENTS_FETCHED = 'SET_ARE_TOP_TEN_EVENTS_FETCHED';
export const SET_ODD_VALUE_DYNAMICS = 'SET_ODD_VALUE_DYNAMICS';
export const SET_EXPANDED_MARKET_IDS = 'SET_EXPANDED_MARKET_IDS';
export const TOGGLE_EXPANDED_MARKET_ID = 'TOGGLE_EXPANDED_MARKET_ID';
export const SET_MARKET_GROUP_INFOS = 'SET_MARKET_GROUP_INFOS';
