<template>
    <TypeSwitcher :types="stakes" :selected="stake" class="betslip-stake__picker--terminal" @click="onClick" />
</template>

<script>
import config from '@config';
import TypeSwitcher from '@shared/components/TypeSwitcher.vue';

export default {
    name: 'StakePicker',
    components: {
        TypeSwitcher,
    },
    props: {
        stake: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            stakes: config.app.betSlip.stakes.map((stake) => ({
                type: stake,
                displayName: stake,
            })),
        };
    },
    methods: {
        onClick(stake) {
            this.$emit('click', stake);
        },
    },
};
</script>
