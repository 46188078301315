<template>
    <div :class="{ selected: isSelected }" class="time-filter__tab--terminal" @click="$emit('click', timeFilterId)">
        <span class="time-filter__day--terminal">
            <template v-if="isUppercase">
                {{ important | upperCase }}
            </template>
            <template v-else>
                {{ important }}
            </template>
        </span>
        <span class="time-filter__date--terminal">{{ secondary }}</span>
    </div>
</template>

<script>
import { timeFilterMixing } from '../mixins/timeFilter';

export default {
    name: 'TimeFilterItem',
    mixins: [timeFilterMixing],
    props: {
        timeFilterId: {
            type: String,
            required: true,
        },
        isUppercase: {
            type: Boolean,
            default: false,
        },
        isSelected: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
