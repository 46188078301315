import { pick as _pick } from '@lodash';
import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import ITicket, { EventCondensed } from '@models/tickets/Ticket';
import { TicketType } from '@core/models/tickets/enums';
import IPlayedTicketDto from './dtos/IPlayedTicketDto';

/**
 * Maps tickets from the tickets cache to ITickets
 */
export default class PlayedTicketMapper extends AbstractMapper<IPlayedTicketDto, ITicket> {
    createTargetObject(origin: IPlayedTicketDto) {
        const ticket = _pick(origin, [
            'type',
            'status',
            'system',
            'payment',
            'bonus',
            'win',
            'coefficient',
        ]) as Partial<ITicket>;

        ticket.id = origin.ticketId;

        if (origin.type === 'sports') {
            ticket.type = TicketType.sport;
        }
        if (!ticket.coefficient) {
            // maybe unneeded
            if (ticket.stake && ticket.win) {
                ticket.coefficient = ticket.win!.estimated / ticket.stake!;
            } else {
                ticket.coefficient = 1;
            }
        }
        ticket.dateReceived = this.toDate(origin.dateReceived, { assumeUtc: true });
        ticket.datePayoff = this.toDate(origin.datePayoff, { assumeUtc: true });
        ticket.dateLastModified = this.toDate(origin.dateLastModified, { assumeUtc: true });

        ticket.events = origin.events.map(({ date, name, ...event }, index) => {
            const eventObj = {
                ...event,
                date: this.toDate(date, { assumeUtc: true }) as Date,
            } as Partial<EventCondensed>;

            if (ticket.system) {
                eventObj.eventNo = `${index + 1} ${event.odd.systemGroupName}`;
            } else {
                eventObj.eventNo = '';
            }

            eventObj.name = name.join(' - ');
            eventObj.teamNameOne = name[0];
            eventObj.teamNameTwo = name[1];

            return eventObj as EventCondensed;
        });
        return ticket as ITicket;
    }
}
