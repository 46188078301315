<template>
    <div class="betslip__body__details__stake__input__amount" @click="$emit('onInputClick')">
        <span class="betslip__body__details__stake__input__amount__stake">
            {{ $t('stake') }}
        </span>
        <span> {{ fromattedStake }} {{ $t('currencyAbbreviation') }} </span>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { twoDecimals } from '@shared/filters';

export default defineComponent({
    emits: ['onInputClick'],
    name: 'StakeInputV2',
    props: {
        stake: {
            type: Number,
            required: true,
        },
        inputStake: {
            type: String,
            required: true,
        },
        isInputExpanded: {
            type: Boolean,
            required: true,
        },
        decimalSeparator: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const fromattedStake = computed(() => {
            return props.isInputExpanded
                ? props.inputStake.replace('.', props.decimalSeparator)
                : twoDecimals(props.stake);
        });

        return {
            fromattedStake,
        };
    },
});
</script>
