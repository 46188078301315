<template>
    <div>
        <div class="number-display__wrapper">
            <div class="number-display">
                <span v-if="internalValue">
                    <span>{{ internalValue }}</span> {{ $t('currencyAbbreviation') }}</span
                >
                <span v-else-if="oldValue">
                    <span>{{ oldValue }}</span> {{ $t('currencyAbbreviation') }}</span
                >
                <span v-else>{{ $t('enterNumber') }}</span>
            </div>
        </div>
        <div class="betslip__system-box--terminal">
            <div v-for="numberKey in fetchNumberKeys" :key="numberKey.value" class="betslip-system__wrap--terminal">
                <div class="betslip-input__modal--terminal" @click="onKeypadClick(numberKey)">
                    <div>
                        <span class="form-check-label__text">
                            {{ numberKey.value }}
                        </span>
                    </div>
                </div>
            </div>
            <div v-for="numberKey in fetchAdditionalKeys" :key="numberKey.value" class="betslip-system__wrap--terminal">
                <div class="betslip-input__modal--terminal" @click="onKeypadClick(numberKey)">
                    <div>
                        <span class="form-check-label__text">
                            {{ numberKey.value }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="betslip-system__wrap--terminal">
                <div class="betslip-input__modal--terminal" @click="onKeypadClick(delKey)">
                    <div>
                        <span class="form-check-label__text">
                            {{ delKey.value }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="betslip-system__wrap--terminal w-full">
                <button v-if="modalData.button.enabled" :class="buttonClass" type="button" @click="applyChanges">
                    {{ $t(buttonLabel) | capitalize }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get as _get, range as _range } from '@lodash';
import config from '@config';

const DEFAULT_VALUE = '';

export default {
    name: 'NumberInputModal',
    data() {
        return {
            delKey: { value: 'Del' },
            oldValue: DEFAULT_VALUE,
            internalValue: DEFAULT_VALUE,
            locale: '',
        };
    },
    created() {
        this.locale = config.numberLocale;
        this.oldValue = this.valueAsString(this.getRawStake());
        this.internalValue = DEFAULT_VALUE;
    },
    computed: {
        ...mapGetters('ui/modal', ['modalData']),
        buttonClass() {
            return _get(this, 'modalData.button.className') || 'btn btn__primary btn--block';
        },
        buttonLabel() {
            return _get(this, 'modalData.button.label') || 'ok';
        },
        namespace() {
            return _get(this, 'modalData.betSlipNamespace');
        },
        fetchNumberKeys() {
            return _range(9).map((i) => ({ value: `${i + 1}` }));
        },
        fetchAdditionalKeys() {
            return [{ value: '.' }, { value: '0' }, { value: 'C' }];
        },
    },
    methods: {
        ...mapActions('ui/modal', ['closeModal']),
        setStake(value) {
            this.$store.dispatch(`${this.namespace}/setStake`, value);
        },
        // this function should set the stake to min configured, the action dispatched does't do that
        setDefaultStake() {
            this.$store.dispatch(`${this.namespace}/setDefaultStake`);
        },
        getRawStake() {
            return this.$store.getters[`${this.namespace}/rawStake`];
        },
        onKeypadClick(numberKey) {
            switch (numberKey.value) {
                case 'C':
                    this.internalValue = DEFAULT_VALUE;
                    this.oldValue = DEFAULT_VALUE;
                    this.setStake(parseFloat(DEFAULT_VALUE));
                    break;
                case 'Del':
                    this.removeValueFromInternalValue();
                    break;
                default:
                    this.appendValueToInternalValue(numberKey.value);
                    break;
            }
        },
        appendValueToInternalValue(value) {
            if ((value === '.' || value === ',') && this.internalValue === DEFAULT_VALUE) {
                return DEFAULT_VALUE; // Do nothing if the first character is a separator
            }
            const newValue = this.internalValue + this.validateValue(value);
            this.internalValue = this.toFixed(newValue, 2).toLocaleString(this.locale, { minimumFractionDigits: 2 });
        },
        removeValueFromInternalValue() {
            if (this.internalValue === DEFAULT_VALUE) {
                this.internalValue = this.oldValue;
            }
            const currentValue = this.internalValue;
            this.internalValue = currentValue.slice(0, -1);
        },
        valueAsString(value) {
            if (!value) {
                return DEFAULT_VALUE;
            }
            return this.toFixed(value, 2).toLocaleString(this.locale, { minimumFractionDigits: 2 });
        },
        validateValue(value) {
            if ([',', '.'].includes(value) && this.alreadyContainsDecimalSeparator()) {
                return DEFAULT_VALUE;
            }
            return value;
        },
        alreadyContainsDecimalSeparator() {
            const decimalSeparator = (1.1).toLocaleString(this.locale).includes('.') ? '.' : ',';
            return this.internalValue.toString().includes(decimalSeparator);
        },
        toFixed(num, fixed) {
            // eslint-disable-next-line no-useless-escape
            const regex = new RegExp(`^-?\\d+(?:\.\\d{0,${fixed || -1}})?`);
            return num.toString().match(regex)[0];
        },
        internalValueAsNumber() {
            if (!this.internalValue) {
                return this.internalValue;
            }
            const value = this.internalValue.replace(',', '.');
            return parseFloat(value);
        },
        applyChanges() {
            this.setStake(this.validateValue(this.internalValue));
            this.closeModal();
        },
    },
};
</script>
