/**
 * This module provides non-reactive getter-like functions which are then called
 * from the real getters. This was done to optimize the sport offer event grid.
 * Vuex reactivity added to all objects ended up being too big of a performance
 * hit.
 */
import { last as _last, sortBy as _sortBy, sumBy as _sumBy } from '@lodash';
import {
    createTreeNode,
    buildTreeFromMatches,
    INode,
    orderTree,
    extractBreadcrumbsFromRoute,
} from '@app/utils/sidebar';
import { IState } from '@app/store';
import StoreUtil from '@store/StoreUtil';
import Match from '@core/models/offer/Match';
import i18n from '@app/localization/i18n';
import { RelativeRootGetters } from '@store/types';
import Sport from '@core/models/struct/Sport';
import { capitalize } from '@shared/filters';
import { map, flow, uniqBy, orderBy } from '@lodash/fp';
import {
    getTimeFilterFromSlug,
    getTimeFilterFunctionForTimeFilter,
} from '@src/terminal/app/modules/shared/timeFilter/helpers';
import { TimeFilter } from '@src/terminal/app/modules/shared/timeFilter/enums';
import { replaceTournamentsWithVirtualTournaments } from '@src/app/competitions/utilities/sidebar';
import { isActiveEvent } from '../utils';
import { PROMOTIONS_ID, PROMOTIONS_TITLE } from '../constants';
import { ISportsState } from './sportsIndex';
import { SelectedTreeItem } from './types';
import { PredefinedItems, ItemType } from './enums';

const ALL_ID = -1000;

export const selectedTreeItems = StoreUtil.createSimpleGetter('selectedTreeItems');

export const selectedFeaturedItem = StoreUtil.createSimpleGetter('selectedFeaturedItem');

export function getTimeFilter(rootState: IState, rootGetters: RelativeRootGetters) {
    const timeFilterSlug = rootState.route.params.timeFilter;
    const defaultFilter = TimeFilter.today;

    return timeFilterSlug ? getTimeFilterFromSlug(timeFilterSlug) : defaultFilter;
}

// filter = sport, category, tournament
// suggest better name
/**
 * Maps URL to breadcrumb of ids.
 *
 * This getter takes slugs and maps slugs to ids (from tree for some date, not for struct!).
 * If someone comes from outside with offer/football/anglia method first finds id of football
 * and id of anglia.
 *
 * Example: if path is offer/football/anglia breadcrumb is
 * football > anglia and getter returns [5, 42]
 */
export function selectedFilter(state: ISportsState, rootState: IState, rootGetters: RelativeRootGetters) {
    const currentTree = rootGetters['ui/sportOffer/sports/tree']; // tree(state, rootState, rootGetters);
    const currentRoute = rootState.route;
    return extractBreadcrumbsFromRoute(currentRoute, currentTree);
}

function isFilterNotToday(filter: TimeFilter) {
    return filter !== TimeFilter.today && filter !== TimeFilter.allToday && filter !== TimeFilter.prematch;
}

export function filteredPromotionalEvents(state: ISportsState, rootState: IState, rootGetters: RelativeRootGetters) {
    const timeFilter = getTimeFilter(rootState, rootGetters);

    if (
        isFilterNotToday(timeFilter) ||
        rootGetters['navigation/isOnSportLivePage'] ||
        rootGetters['data/country/isSportInMaintenance'] ||
        !!selectedFeaturedItem(state)
    ) {
        return [];
    }

    const filters = [(event: Match) => !event.isFinished() && !event.isStopped() && !event.isOngoing()];

    filters.push(getTimeFilterFunctionForTimeFilter(timeFilter));

    const promotionalEventGroups = rootGetters['data/sportOffer/promotionalEvents'];
    const filterFunc = (event: Match) => filters.every((func: Function) => func(event));
    const filteredPromotionGroups = promotionalEventGroups
        .map((promotion) => ({
            ...promotion,
            events: promotion.events.filter(filterFunc),
        }))
        .filter((p) => p.events.length);

    return _sortBy(filteredPromotionGroups, 'order');
}

export function topTenEvents(state: ISportsState, rootState: IState, rootGetters: any) {
    const timeFilter = getTimeFilter(rootState, rootGetters);

    if (
        isFilterNotToday(timeFilter) ||
        rootGetters['navigation/isOnSportLivePage'] ||
        rootGetters['data/country/isSportInMaintenance']
    ) {
        return [];
    }

    const filters = [(event: Match) => !event.isFinished() && !event.isStopped() && !event.isOngoing()];

    return rootGetters['data/sportOffer/topTenEvents'].filter((event: Match) =>
        filters.every((func: Function) => func(event)),
    );
}

/**
 * This getter creates and updates sports sidebar tree from events filtered by some date
 * + previously selected breadcrumbs if date doesn't contain items in breadcrumbs or only some items
 */
export function tree(state: ISportsState, rootState: IState, rootGetters: RelativeRootGetters) {
    // costly operation not cached but called by multiple getters
    const events = rootGetters['ui/sportOffer/sports/eventsFilteredByDate']; // eventsFilteredByDate(state, rootState, rootGetters);
    const updatedEvents = replaceTournamentsWithVirtualTournaments(
        events,
        rootGetters['data/competitions/tournamentToVirtualTournament'],
    );
    const currentTree = buildTreeFromMatches(updatedEvents);
    /*
     * insert selected items if they were filtered-out by date
     * Be aware that selectedTreeItems are persisted in local storage
     * so when user refreshes page we still have selected tree items in state
     * if there is one in local storage.
     */
    let branch = currentTree;
    let parent: INode | undefined;

    selectedTreeItems(state).forEach((item: SelectedTreeItem) => {
        if (![ALL_ID, PROMOTIONS_ID].includes(item.id)) {
            branch[item.id] =
                branch[item.id] ||
                createTreeNode(
                    { ...item, children: {}, eventCount: 0 } as any, // TODO remove any
                    branch,
                    parent && parent.id,
                );
            parent = branch[item.id];
            branch = parent.children;
        }
    });

    const orderedTree = orderTree(Object.values(currentTree));
    const promotionGroups = filteredPromotionalEvents(state, rootState, rootGetters);
    const promotionalsCount =
        _sumBy(promotionGroups, 'events.length') + topTenEvents(state, rootState, rootGetters).length;
    const useDesignV2 = rootGetters['data/flags/useDesignV2'] as boolean;

    const result = [
        {
            id: ALL_ID,
            icon: useDesignV2 ? 'sds-icon-navigation-home' : 'icon-sports_a-z',
            name: capitalize(i18n.t('all').toString()),
            children: [] as INode[],
            eventCount: events.length,
            order: -2,
        } as INode,
    ];

    if (promotionalsCount) {
        result.push({
            id: PROMOTIONS_ID,
            icon: 'icon-promotii',
            name: PROMOTIONS_TITLE,
            children: [],
            eventCount: promotionalsCount,
            order: -1,
        });
    }

    result.push(...orderedTree);
    return result;
}

/**
 * Uses date from dateTimeFilter and returns all events with that date.
 */
export function eventsFilteredByDate(
    state: ISportsState,
    rootState: IState,
    rootGetters: RelativeRootGetters,
): Match[] {
    const filters = [isActiveEvent];

    const isLiveInMaintenance = rootGetters['data/country/isLiveInMaintenance'];
    const isSportInMaintenance = rootGetters['data/country/isSportInMaintenance'];

    if (isLiveInMaintenance || isSportInMaintenance) {
        filters.push((e: Match) => {
            const liveEventInMaintenance = e.isOngoing() && isLiveInMaintenance;
            const sportEventInMaintenance = !e.isOngoing() && isSportInMaintenance;

            return !liveEventInMaintenance && !sportEventInMaintenance;
        });
    }
    const timeFilter = getTimeFilter(rootState, rootGetters);

    filters.push(getTimeFilterFunctionForTimeFilter(timeFilter));

    const events = [] as Match[];
    for (const id in rootState.data.sportOffer.activeEvents) {
        const event = rootState.data.sportOffer.activeEvents[id];
        if (!filters.every((func) => func(event))) {
            continue;
        }
        events.push(event);
    }
    return events;
}

/**
 * This getter takes treeBreadcrumbsIds and returns SelectedTreeItem object
 * with type (sport, tournament, category) and id so we can.  Events are going
 * to be filtered only by one type, the rightmost in breadcrumbs
 */
export function selectedTreeItem(state: ISportsState, rootState: IState, rootGetters: RelativeRootGetters) {
    const breadcrumbIds = selectedFilter(state, rootState, rootGetters);

    const selectedItem = {
        type: PredefinedItems.all,
        id: ALL_ID,
    } as SelectedTreeItem;

    if (breadcrumbIds[0] === PROMOTIONS_ID) {
        selectedItem.type = PredefinedItems.promotions;
    } else if (breadcrumbIds[0] !== ALL_ID) {
        switch (breadcrumbIds.length) {
            case 3:
                selectedItem.type = ItemType.tournamentId;
                break;
            case 2:
                selectedItem.type = ItemType.categoryId;
                break;
            case 1:
                selectedItem.type = ItemType.sportId;
                break;
        }
        selectedItem.id = _last(breadcrumbIds)!;
    }

    return selectedItem;
}

/**
 * This getter returns events for rendering, events are filtered by date from dateTimeFilter
 * and all the other filters (sport, competition, etc.)
 */
export function filteredEvents(state: ISportsState, rootState: IState, rootGetters: RelativeRootGetters): Match[] {
    const currentTreeItem: SelectedTreeItem = rootGetters['ui/sportOffer/sports/selectedTreeItem'];
    if (rootState.route.params.sportSlug === PredefinedItems.promotions) {
        return [];
    }

    const events = rootGetters['ui/sportOffer/sports/eventsFilteredByDate']; // eventsFilteredByDate(state, rootState, rootGetters);
    const currentVirtualTournament = rootGetters['data/competitions/currentVirtualTournament'];
    if (currentVirtualTournament) {
        return events.filter((event: Match) => currentVirtualTournament.childrenIds.includes(event.tournamentId));
    }
    if (currentTreeItem.type !== PredefinedItems.all) {
        return events.filter((event: Match) => (event as any)[currentTreeItem.type] === currentTreeItem.id);
    }
    return events;
}

// mobile only

export function sportsFilter(
    state: ISportsState,
    rootState: IState,
    rootGetters: RelativeRootGetters,
): {
    id: Sport['id'];
    label: Sport['name'];
}[] {
    const allNavItem = {
        label: i18n.t(PredefinedItems.all),
        id: ALL_ID,
    };
    const events = rootGetters['ui/sportOffer/sports/eventsFilteredByDate']; // eventsFilteredByDate(state, rootState, rootGetters);
    const sports = flow(
        uniqBy('sportId'),
        orderBy('sportOrder')('asc'),
        map(({ sportName, sportId }: any) => ({ label: sportName, id: sportId })),
    )(events as any[]);
    sports.unshift(allNavItem);
    return sports;
}
