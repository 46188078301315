import { Commit, Dispatch } from 'vuex';
import StoreUtil from '@app/store/StoreUtil';
import ServerConfig from '@core/models/country/ServerConfig';
import ScheduledUtil from '@core/utils/scheduled/ScheduledUtil';
import configService from '@core/services/configs/ConfigService';
import config from '@config';
import { errorGuard } from '@app/utils';
import ServerConfigProvider from '@core/providers/configuration/ServerConfigProvider';
import Modals from '@store/modules/ui/shared/modal/modals';
// import { MaintenanceType } from '@core/models/country/enums';
import {
    isBettingAvailable,
    isBillAcceptorWorking,
    isPrinterWorking,
    isScannerWorking,
} from '@src/terminal/core/services/status/StatusCheckerService';
import { IHostStatus } from '@src/terminal/core/services/status/models/StatusInterface';

export const SET_DISABLED = 'SET_DISABLED';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_IS_APP_READY = 'SET_IS_APP_READY';
export const SET_CHECKED_TICKET = 'SET_CHECKED_TICKET';
export const SET_IS_BETTING_AVAILABLE = 'SET_IS_BETTING_AVAILABLE';
export const SET_IS_PRINTER_AVAILABLE = 'SET_IS_PRINTER_AVAILABLE';
export const SET_IS_SCANNER_AVAILABLE = 'SET_IS_SCANNER_AVAILABLE';
export const SET_IS_BILL_ACCEPTOR_WORKING = 'SET_IS_BILL_ACCEPTOR_WORKING';
export const SET_USER_CARD_SERIAL_NUMBER = 'SET_USER_CARD_SERIAL_NUMBER';

export interface ICheckedTicket {
    id?: string;
    timeChecked: number;
}

export interface ICountryState {
    disabled: boolean;
    config: null | ServerConfig;
    isAppReady: boolean;
    // check ticket prototype
    checkedTicket: ICheckedTicket | null;
    isBettingAvailable: boolean;
    isPrinterAvailable: boolean;
    isScannerAvailable: boolean;
    isBillAcceptorWorking: boolean;
    userCardSerialNumber: string;
}

function defaultState(): ICountryState {
    return {
        disabled: false,
        config: null,
        isAppReady: false,
        checkedTicket: null,
        isBettingAvailable: false,
        isPrinterAvailable: false,
        isScannerAvailable: false,
        isBillAcceptorWorking: false,
        userCardSerialNumber: '',
    };
}

export default {
    namespaced: true,

    state: defaultState(),

    mutations: {
        [SET_DISABLED]: StoreUtil.createSimpleMutator('disabled'),
        [SET_CONFIG]: StoreUtil.createSimpleMutator('config'),
        [SET_IS_APP_READY]: StoreUtil.createSimpleMutator('isAppReady'),
        [SET_CHECKED_TICKET]: StoreUtil.createSimpleMutator('checkedTicket'),
        [SET_IS_BETTING_AVAILABLE]: StoreUtil.createSimpleMutator('isBettingAvailable'),
        [SET_IS_PRINTER_AVAILABLE]: StoreUtil.createSimpleMutator('isPrinterAvailable'),
        [SET_IS_SCANNER_AVAILABLE]: StoreUtil.createSimpleMutator('isScannerAvailable'),
        [SET_IS_BILL_ACCEPTOR_WORKING]: StoreUtil.createSimpleMutator('isBillAcceptorWorking'),
        [SET_USER_CARD_SERIAL_NUMBER]: StoreUtil.createSimpleMutator('userCardSerialNumber'),
    },

    getters: {
        disabled: StoreUtil.createSimpleGetter('disabled'),
        config: StoreUtil.createSimpleGetter('config'),
        isAppReady: StoreUtil.createSimpleGetter('isAppReady'),
        isLiveInMaintenance: () =>
            // state: ICountryState
            // if (state.config) {
            //     return [
            //         state.config.applicationStatus.maintainedParts[MaintenanceType.global],
            //         state.config.applicationStatus.maintainedParts[MaintenanceType.live]
            //     ].some(Boolean);
            // }

            false,
        isSportInMaintenance: () =>
            // state: ICountryState
            // if (state.config) {
            //     return [
            //         state.config.applicationStatus.maintainedParts[MaintenanceType.global],
            //         state.config.applicationStatus.maintainedParts[MaintenanceType.sport]
            //     ].some(Boolean);
            // }

            false,

        checkedTicket: StoreUtil.createSimpleGetter('checkedTicket'),
        isBettingAvailable: StoreUtil.createSimpleGetter('isBettingAvailable'),
        userCardSerialNumber: StoreUtil.createSimpleGetter('userCardSerialNumber'),
    },
    actions: {
        disableTerminal: StoreUtil.createSimpleMutatorAction(SET_DISABLED),
        initializeConfig: StoreUtil.asyncErrorGuard(async ({ dispatch }: { dispatch: Dispatch }) => {
            dispatch('setIsAppReady', false);
            await errorGuard({
                action: async () => {
                    await dispatch('fetchConfig');
                    dispatch('setIsAppReady', true);
                },
                showGlobalError: false,
                onError: () => {
                    dispatch(
                        'ui/modal/setModal',
                        {
                            code: Modals.error.code,
                            data: {
                                button: {
                                    label: 'ok',
                                },
                                text: {
                                    content: 'Please reload the page',
                                },
                            },
                        },
                        { root: true },
                    );
                },
            });
        }),
        startScheduledConfigCheck: ({ dispatch }: { dispatch: Dispatch }) => {
            ScheduledUtil.scheduleTask(() => {
                dispatch('fetchConfig');
            }, config.app.country.checkIsMaintenanceModePeriod);
        },
        startScheduledBannersCheck: ({ dispatch }: { dispatch: Dispatch }) => {
            dispatch('fetchBanners');
            ScheduledUtil.scheduleTask(() => {
                dispatch('fetchBanners');
            }, config.app.country.incomingBannersCheckPeriod);
        },
        fetchConfig: StoreUtil.asyncErrorGuard(async ({ commit }: { commit: Commit }) => {
            const config = await configService.getConfig();
            commit(SET_CONFIG, config);
            ServerConfigProvider.setConfig(config);
        }),
        setIsAppReady: StoreUtil.createSimpleMutatorAction(SET_IS_APP_READY),
        setCheckedTicket: StoreUtil.createSimpleMutatorAction(SET_CHECKED_TICKET),
        setStatuses: ({ commit }: { commit: Commit }, { payload }: { payload: IHostStatus }) => {
            const printerStatus = isPrinterWorking(payload.printerStatus);
            const billAcceptorStatus = isBillAcceptorWorking(payload.billAcceptorStatus);
            const scannerStatus = isScannerWorking(payload.scannerStatus);
            const bettingAvailable = isBettingAvailable(printerStatus);
            commit('SET_IS_PRINTER_AVAILABLE', printerStatus);
            commit('SET_IS_BILL_ACCEPTOR_WORKING', billAcceptorStatus);
            commit('SET_IS_SCANNER_AVAILABLE', scannerStatus);
            commit('SET_IS_BETTING_AVAILABLE', bettingAvailable);
        },
        setUserCardSerialNumber: StoreUtil.createSimpleMutatorAction(SET_USER_CARD_SERIAL_NUMBER),
    },
};
