import { instanceGuard } from '@core/utils/services';
import ServerConfig from '@core/models/country/ServerConfig';
import router, { links, routeNames } from '@src/app/router';
import Modals from 'src/app/store/modules/ui/shared/modal/modals';
import store from '@app/store';
import Ticket from '@core/models/tickets/Ticket';
import * as Sentry from '@sentry/vue';
import i18n from '@src/app/localization/i18n';
import common from '@src/config/common';
import RetailService from '../retail/RetailService';
import BettingAvailableService from '../betting/BettingAvailableService';
import { NotReadySource } from '../betting/_private/enums';
import { INotification, IPlatform } from '../platform/IPlatform';
import FeatureFlagService from '../flags/FeatureFlagService';
import { prepareTicketForPrintV1 } from '../gravity/helpers/ticket';
import { Config } from './types/Config';
import { BalanceStatus } from './types/Balance';
import { Navigate } from './types/Navigate';
import { ScanOutcome } from './types/Scan';
import { TicketPrintOutcome } from './types/Ticket';
import RecreateTicket from './_private/RecreateTicket';
import { isConfigError, openBettingServiceErrorModal } from './utils';
import { ErrorData } from './models/Payload';

class ProductService implements IPlatform {
    private static instance: ProductService;

    private actions = window.PIL.Actions;
    private messenger = new window.PIL.Messenger();
    private removeMessageListener: () => void;
    private config: Config | null = null;
    private balance: number;
    private slaveId = '';

    private constructor(private serverConfig: ServerConfig) {
        BettingAvailableService.getInstance().set(NotReadySource.Printer, {
            reason: 'default printer off',
            available: false,
        });
        BettingAvailableService.getInstance().set(NotReadySource.RetailConn, {
            reason: 'default connection to retail',
            available: false,
        });

        if (!window.name) {
            if (!common.environment.isDevelopment) {
                Sentry.captureMessage('ProductService: slaveId empty', Sentry.Severity.Error);

                openBettingServiceErrorModal(NotReadySource.RetailConn, 'Product is not set');
            } else {
                // TODO: improve BettingAvailableService get
                // On dev, unset printer source to show RetailConn err on ticket submit
                BettingAvailableService.getInstance().unset(NotReadySource.Printer);
            }

            return;
        }

        this.setSlaveId(window.name);

        if (this.removeMessageListener) {
            this.removeMessageListener();
        }

        this.removeMessageListener = this.messenger.addMessageListener(this.handleMessage.bind(this));

        this.messenger.emitConfigRequest();
        this.messenger.emitBalanceRequest();

        BettingAvailableService.getInstance().unset(NotReadySource.Printer);
    }

    private handleMessage(action: string, data: any) {
        switch (action) {
            case this.actions.Config:
                this.handleConfig(data);
                break;
            case this.actions.Balance:
                this.handleBalanceChange(data);
                break;
            case this.actions.ScanOutcome:
                this.handleScanOutcome(data);
                break;
            case this.actions.TicketPrintOutcome:
                this.handleTicketPrintOutcome(data);
                break;
            // Deprecated, will be removed in a future version of PIL (Product Integration Library)
            // Recreate/Rebet will be handled by the product internally.
            case this.actions.TicketRecreateOutcome:
                this.handleTicketRecreateOutcome(data);
                break;
            // Deprecated, will be removed in a future version of PIL (Product Integration Library)
            // In favor of using client ID and ref value.
            case this.actions.SerialNumberScanOutcome:
                this.handleSerialNumberScanOutcome(data);
                break;
            case this.actions.Navigate:
                this.handleNavigate(data);
                break;
            default:
                console.log('SSBT::Unknown message received', action, data);
                break;
        }
    }

    private async handleConfig(config: Config | ErrorData) {
        if (!config || isConfigError(config)) {
            if (!common.environment.isDevelopment) {
                const message = config.data
                    ? `ProductService: ${(config as ErrorData).error}`
                    : 'ProductService: Received config is empty';

                Sentry.captureMessage(message, {
                    extra: {
                        config,
                    },
                    level: Sentry.Severity.Error,
                });
            }

            openBettingServiceErrorModal(NotReadySource.RetailConn, 'Config is not valid');
            return;
        }

        this.config = config;
        console.log('SSBT::Config received', config);

        try {
            await FeatureFlagService.getInstance().setInHouseCtx(
                this.config.retailDetails.terminal,
                this.config.retailDetails.shop,
            );
            store.dispatch('data/flags/initFlags', null, { root: true });
        } catch (error) {
            console.error('SSBT::Error setting in-house context', error);
        }

        const { token } = this.config;
        const {
            shop: { code: storeId },
            terminal: { code: terminalId },
        } = config.retailDetails;

        localStorage.setItem('authToken', token);
        Sentry.setTag('storeId', storeId);
        Sentry.setTag('terminalId', terminalId);

        try {
            RetailService.createInstance(this.serverConfig, this.getSlaveId());
        } catch (error) {
            console.error('RetailService failed to initialize', error);
        }
        BettingAvailableService.getInstance().unset(NotReadySource.RetailConn);
    }

    private handleBalanceChange(balanceStatus: BalanceStatus) {
        this.balance = balanceStatus.balance;
    }

    private handleScanOutcome(data: ScanOutcome) {
        const { value } = data;
        this.checkTicket(value);
    }

    private handleNavigate(payload: Navigate) {
        const product = payload?.value?.product?.id;
        const ticketId = payload?.value?.ticket?.ticketId;

        if (ticketId) {
            router.push({ path: links.checkTicket }, () => {});
        } else if (product && product === 'SuperbetInPlay' && window.name === 'SuperbetPrematch') {
            router.push({ path: links.live }, () => {});
        } else if (product && product === 'SuperbetPrematch' && window.name === 'SuperbetPrematch') {
            router.push({ path: links.landing }, () => {});
        } else if (product && product === 'HomeScreen') {
            this.checkTicket('CLEAR');
        }
    }

    private handleSerialNumberScanOutcome(data: ScanOutcome) {
        store.dispatch('data/country/setUserCardSerialNumber', data.value, { root: true });
    }

    private handleTicketPrintOutcome(data: TicketPrintOutcome) {
        const { status } = data;

        if (status >= 200 && status < 300) {
            const title = i18n.t('print success').toString().toUpperCase();
            const text = i18n.t('ticket print success').toString().toUpperCase();
            this.sendNotification({ title, text, type: 'success' });
            store.dispatch('data/tickets/setTicketPrinted', true);
        } else {
            const title = i18n.t('ticket error').toString().toUpperCase();
            const text = i18n.t('ticket failed to print').toString().toUpperCase();
            this.sendNotification({ title, text, type: 'error' });
        }

        store.dispatch('data/tickets/setTicketSubmitLoading', false);
        setTimeout(() => store.dispatch('data/tickets/setTicketPrinted', false), 1500);
    }

    private handleTicketRecreateOutcome(data: ScanOutcome) {
        RecreateTicket(data.value);
    }

    public refreshAuth(): void {
        this.messenger.emitConfigRequest();
    }

    public async resetBetSlip() {
        await store.dispatch('ui/sportOffer/betSlip/setAutoUpdateChanges', true, { root: true });
        await store.dispatch('ui/sportOffer/betSlip/clear', null, { root: true });
    }

    getGravityAuth(): string {
        throw new Error('Method not implemented.');
    }
    postMessage(message: any, source?: any): void {
        throw new Error('Method not implemented.');
    }

    public static getInstance(): ProductService {
        return instanceGuard(ProductService.instance);
    }

    public static createInstance(config: ServerConfig) {
        if (!ProductService.instance) {
            ProductService.instance = new ProductService(config);
        }
        return ProductService.instance;
    }

    public requestBalanceStatusRequest() {
        this.messenger.emitBalanceRequest();
    }

    private checkTicket(id: string) {
        store.dispatch('data/country/setCheckedTicket', { id, timeChecked: Date.now() }, { root: true });
    }

    public getTerminalConfiguration(): any {
        return this.config;
    }

    public getAuthToken(): string {
        return this.config?.token || '';
    }

    public getDeviceUUID() {
        return this.config?.retailDetails?.terminal?.externalId || '';
    }

    public setDeviceUUID(uuid: string) {}

    public getSlaveId(): string {
        return this.slaveId;
    }
    public setSlaveId(value: string) {
        this.slaveId = value;
    }

    public areFoundsAvailable(amount: number): boolean {
        console.log('SSBT::Current Terminal Balance', this.getTerminalBalance());
        return this.getTerminalBalance() >= amount;
    }

    public getTerminalBalance(): number {
        return this.balance;
    }

    public setTerminalBalance(value: number) {
        this.balance = value;
    }

    public checkTicketExit() {
        const name = routeNames.landing;
        router.push({ name });
    }

    public sendNotification({ title, text, type }: INotification) {
        const notification = {
            title,
            text,
            type: type || 'info',
        };
        this.messenger.emitNotification(notification);
    }

    public checkTicketCancel = (ticketId?: string) => {
        const root = { root: true };
        const config = {
            code: Modals.cancelTicket.code,
            containerClass: 'cancle-ticket-modal--terminal',
            data: {
                ticketId,
                button: {
                    label: 'confirm',
                    enabled: true,
                    className: 'btn modal-btn--terminal btn--block btn--cancel-ticket',
                },
            },
        };
        store.dispatch('ui/modal/setModal', config, root);
    };

    public checkPayoutTicket(ticketId?: string) {
        const root = { root: true };
        const config = {
            code: Modals.payoutTicket.code,
            containerClass: 'payout-ticket-modal--terminal',
            data: {
                ticketId,
                button: {
                    label: 'confirm',
                    enabled: true,
                    className: 'btn modal-btn--terminal btn--block btn--payout-ticket',
                },
            },
        };
        store.dispatch('ui/modal/setModal', config, root);
    }

    // Deprecated, product will handle Recreate/Rebet internally.
    public checkTicketRecreateRequest(ticketId: string) {
        this.messenger.emitTicketRecreateRequest(ticketId);
    }

    public async sendTicketPrint(ticket: Ticket | any): Promise<any> {
        const preparedTicket = await prepareTicketForPrintV1(ticket, this.serverConfig);
        console.log('SSBT::Prepared Ticket for Print >>', preparedTicket);
        this.messenger.emitTicketPrint(preparedTicket);
        return preparedTicket;
    }

    public scanTicket(value: string) {
        const payload = { data: { value: value.toUpperCase() } };
        console.log('SSBT::Manual Input As Scan ~>', payload);
        this.messenger.emitTicketScanRequest(payload);
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
    }
}

export default ProductService;
