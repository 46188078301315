import { BetSlipPurchaseType, BetSlipType } from '@models/shared/betSlip/betSlipEnums';
import { mutations, actionsFactory, getters } from '@store/modules/ui/shared/betSlip';
import BetSlipToTicketMapper from '@core/mappers/tickets/BetSlipToTicketMapper';
import IBetSlipState from '@store/modules/ui/shared/betSlip/types';
import { EMPTY_PAYOUT } from '@models/betSlip/Payout';
import { TicketType } from '@models/tickets/enums';
import SportValidationService from '@core/services/betSlip/validation/sport/SportValidationService';

const betSlipToTicketMapper = new BetSlipToTicketMapper();

export default {
    namespaced: true,

    state: {
        selections: [],
        undoSelectionTimerId: null,
        undoSelections: null,
        undoStake: null,
        undoSelectedSystemsMinNumbers: null,
        purchaseType: BetSlipPurchaseType.offline,
        type: BetSlipType.simple,
        systems: [],
        stake: null,
        isLoading: false,
        errors: [],
        selectionErrors: {},
        selectionAddError: null,
        autoUpdateChanges: true,
        changes: {},
        serializedBetSlip: undefined,
        selectedSystemsMinNumbers: {},
        payout: EMPTY_PAYOUT,
        isPayoutLocked: false,
    } as IBetSlipState,

    mutations: {
        ...mutations,
    },

    getters: {
        ...getters,
        ticket: (state: IBetSlipState, getters: any) =>
            betSlipToTicketMapper.createTargetObject(
                {
                    selections: getters.selections,
                    stake: getters.stake,
                    purchaseType: getters.purchaseType,
                    type: getters.type,
                    autoUpdateChanges: getters.autoUpdateChanges,
                    selectedSystems: getters.selectedSystems,
                    payout: getters.payout,
                    stakeAfterTax: getters.stakeAfterTax,
                },
                {
                    type: TicketType.sport,
                },
            ),
    },

    actions: {
        ...actionsFactory(SportValidationService.getInstance),
    },
};
