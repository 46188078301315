import config from '@config';

export default (data: string | string[], lang = config.language) => {
    let translations;

    if (!data) {
        return '';
    }

    if (typeof data === 'string') {
        translations = data.split('|');
    } else {
        translations = data;
    }

    const [
        englishTranslation = '',
        romanianTranslation = '',
        // eslint-disable-next-line
        _placeholder1,
        // eslint-disable-next-line
        _placeholder2,
        polishTranslations = '',
    ] = translations;

    switch (lang) {
        case 'en':
            return englishTranslation || romanianTranslation;
        case 'ro':
            return romanianTranslation || englishTranslation;
        case 'pl':
            return polishTranslations || englishTranslation;
        default:
            return englishTranslation;
    }
};
