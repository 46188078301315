import StoreUtil from '@store/StoreUtil';
import SelectionChange from '@models/betSlip/SelectionChange';
import ISelection from '@models/betSlip/ISelection';
import System from '@models/betSlip/System';
import Vue from 'vue';
import Odd from '@core/models/offer/Odd';
import IBetSlipError from '@models/betSlip/validation/IBetSlipError';
import Referral from '@core/models/betSlip/Referral';
import {
    ADD_SELECTION,
    CLEAR_CHANGES,
    CLEAR_ERRORS,
    DELETE_CHANGE,
    REMOVE_SELECTION,
    SET_CHANGE,
    SET_IS_LOADING,
    SET_PURCHASE_TYPE,
    SET_STAKE,
    SET_TYPE,
    UPDATE_SELECTION,
    CLEAR_SELECTIONS,
    ADD_ERROR,
    TOGGLE_SELECTION_IS_FIXED,
    TOGGLE_SYSTEM_SELECTED,
    TOGGLE_SELECTION_ODD,
    TOGGLE_SELECTED_SYSTEM_MIN_NUMBER,
    SET_SELECTED_SYSTEM_MIN_NUMBER,
    DELETE_SELECTED_SYSTEM_MIN_NUMBER,
    CLEAR_SELECTED_SYSTEMS_MIN_NUMBER,
    SET_PAYOUT,
    SET_IS_PAYOUT_LOCKED,
    SET_SELECTION_ERRORS,
    CLEAR_SELECTION_ERRORS,
    SET_SELECTION_ADD_ERROR,
    TOGGLE_AUTO_UPDATE_CHANGES,
    SET_AUTO_UPDATE_CHANGES,
    UPDATE_SELECTION_EVENT,
    REMOVE_ERROR,
    SET_UNDO_SELECTIONS,
    SET_UNDO_STAKE,
    SET_UNDO_SELECTIONS_TIMER_ID,
    SET_UNDO_SYSTEMS,
    SELECTION_FIX,
    UPDATE_SELECTION_ODD,
    SET_REFERRAL,
    SET_UNDO_REFERRAL,
} from './mutationTypes';
import SelectionsHelper from './Helpers';
import IBetSlipState from './types';

export default {
    [ADD_SELECTION]: StoreUtil.createPushOrInsertToListMutator('selections', 'selection'),
    [UPDATE_SELECTION_EVENT](state: IBetSlipState, payload: any) {
        payload.selection.event = payload.newSelection.event;
    },
    [UPDATE_SELECTION](state: IBetSlipState, payload: any) {
        payload.newSelection.isFixed = payload.selection.isFixed;
        const index = SelectionsHelper.getSelectionIndex(state.selections, payload.selection);
        Vue.set(state.selections, index, payload.newSelection);
    },
    [REMOVE_SELECTION]: StoreUtil.createRemoveFromListMutator('selections'),
    [CLEAR_SELECTIONS]: StoreUtil.createClearListMutator('selections'),
    [TOGGLE_SELECTION_IS_FIXED](state: IBetSlipState, selection: ISelection) {
        selection.toggleFixState();
    },
    [SELECTION_FIX](state: IBetSlipState, selection: ISelection) {
        selection.isFixed = true;
    },
    [TOGGLE_SELECTION_ODD](state: IBetSlipState, selection: ISelection) {
        const stateSelection = state.selections.find((s) => selection.event.id === s.event.id);

        if (stateSelection) {
            stateSelection.odd = selection.odd;
        }
    },
    [SET_UNDO_SELECTIONS]: StoreUtil.createSimpleMutator('undoSelections'),
    [SET_UNDO_STAKE]: StoreUtil.createSimpleMutator('undoStake'),
    [SET_UNDO_SYSTEMS]: StoreUtil.createSimpleMutator('undoSelectedSystemsMinNumbers'),
    [SET_UNDO_SELECTIONS_TIMER_ID]: StoreUtil.createSimpleMutator('undoSelectionTimerId'),

    [SET_PURCHASE_TYPE]: StoreUtil.createSimpleMutator('purchaseType'),
    [SET_TYPE]: StoreUtil.createSimpleMutator('type'),
    [SET_STAKE]: StoreUtil.createSimpleMutator('stake'),
    [SET_IS_LOADING]: StoreUtil.createSimpleMutator('isLoading'),

    [TOGGLE_AUTO_UPDATE_CHANGES](state: IBetSlipState) {
        state.autoUpdateChanges = !state.autoUpdateChanges;
    },
    [SET_AUTO_UPDATE_CHANGES](state: IBetSlipState, change: boolean) {
        state.autoUpdateChanges = change;
    },
    [SET_CHANGE](state: IBetSlipState, change: SelectionChange) {
        Vue.set(state.changes, change.selection.getEventId().toString(), change);
    },
    [DELETE_CHANGE](state: IBetSlipState, eventId: number) {
        Vue.delete(state.changes, eventId.toString());
    },
    [CLEAR_CHANGES](state: IBetSlipState) {
        state.changes = {};
    },

    [ADD_ERROR]: StoreUtil.createPushToListMutator('errors'),
    [REMOVE_ERROR](state: IBetSlipState, error: IBetSlipError) {
        const index = state.errors.findIndex((e) => e.type === error.type);

        if (index === -1) {
            return;
        }

        state.errors.splice(index, 1);
    },
    [CLEAR_ERRORS]: StoreUtil.createClearListMutator('errors'),
    [SET_SELECTION_ERRORS]: StoreUtil.createSimpleMutator('selectionErrors'),
    [CLEAR_SELECTION_ERRORS](state: IBetSlipState) {
        state.selectionErrors = {};
    },
    [SET_SELECTION_ADD_ERROR]: StoreUtil.createSimpleMutator('selectionAddError'),

    [TOGGLE_SYSTEM_SELECTED](state: IBetSlipState, system: System) {
        system.isSelected = !system.isSelected;
    },
    // registry of selected system (1/2, 2/3, 4/4 => 1, 2, 4)
    [TOGGLE_SELECTED_SYSTEM_MIN_NUMBER](state: IBetSlipState, minNumber: number) {
        if (state.selectedSystemsMinNumbers.hasOwnProperty(minNumber)) {
            Vue.delete(state.selectedSystemsMinNumbers, minNumber.toString());
        } else {
            Vue.set(state.selectedSystemsMinNumbers, minNumber.toString(), true);
        }
    },

    [SET_SELECTED_SYSTEM_MIN_NUMBER]: StoreUtil.createSimpleMutator('selectedSystemsMinNumbers'),
    [DELETE_SELECTED_SYSTEM_MIN_NUMBER](state: IBetSlipState, minNumber: number) {
        if (state.selectedSystemsMinNumbers.hasOwnProperty(minNumber)) {
            Vue.delete(state.selectedSystemsMinNumbers, minNumber.toString());
        }
    },
    [CLEAR_SELECTED_SYSTEMS_MIN_NUMBER](state: IBetSlipState) {
        state.selectedSystemsMinNumbers = {};
    },

    [SET_PAYOUT]: StoreUtil.createSimpleMutator('payout'),
    [SET_IS_PAYOUT_LOCKED]: StoreUtil.createSimpleMutator('isPayoutLocked'),
    [UPDATE_SELECTION_ODD]: (state: IBetSlipState, data: { odd: Odd; selectionIndex: number }) => {
        const { odd, selectionIndex } = data;

        const oldOdd = state.selections[selectionIndex].odd;

        if (oldOdd && oldOdd.value !== odd.value) {
            // state.selections[selectionIndex].savedOddData =
            //     SavedOddData.createFromOdd(oldOdd);
            state.selections[selectionIndex].odd = odd;
        }
    },
    [SET_REFERRAL]: (state: IBetSlipState, payload: Referral | null) => {
        Vue.set(state, 'referral', payload);
    },
    [SET_UNDO_REFERRAL]: (state: IBetSlipState, payload: Referral | null) => {
        Vue.set(state, 'undoReferral', payload);
    },
};
