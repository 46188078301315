<template>
    <div class="selections-list">
        <!-- Inverting betslip selections so the new selections are more visible, possible filter -->
        <SystemBetSlipItemV2
            v-for="selection in selections.slice().reverse()"
            :key="getId(selection)"
            :selection="selection"
            :error="selectionErrors[selection.getEventId()]"
            :oddValueDynamics="oddValueDynamics"
            :checkShowSbv="checkShowSbv"
            @removeClick="onRemoveClick"
            @fixClick="onFixClick"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import SportSelection from '@core/models/betSlip/SportSelection';
import { shouldShowSpecialBetValue } from '@core/models/offer/Odd';
import IBetSlipError from '@core/models/betSlip/validation/IBetSlipError';
import dataSportModule from '@app/store/modules/data/sportOffer/index';
import { useVuexStore } from '@src/v2/app/utils/vuexStore';
import System from '@models/betSlip/System';
import { OddDynamics } from '@store/modules/data/sportOffer/types';
import SystemBetSlipItemV2 from '@src/v2/app/modules/sportOffer/betSlip/SystemBetslipItemV2.vue';

export default defineComponent({
    components: { SystemBetSlipItemV2 },
    emits: ['systemToggle', 'removeClick', 'fixClick'],
    name: 'SystemBetSlipV2',
    props: {
        selections: {
            type: Array as PropType<SportSelection[]>,
            required: true,
        },
        selectionErrors: {
            type: Object as PropType<Record<number, IBetSlipError>>,
            required: false,
            default: () => {},
        },
        systems: {
            type: Array as PropType<System[]>,
            required: true,
        },
        fixedSelectionsCount: {
            type: Number,
            required: true,
        },
        oddValueDynamics: {
            type: Object as PropType<Record<number, Record<string, OddDynamics>>>,
            required: false,
        },
    },
    setup(_, { emit }) {
        const dataSportStore = useVuexStore<typeof dataSportModule>('data/sportOffer');
        const markets = dataSportStore.getter('markets');

        const getId = (selection: SportSelection) => {
            return selection.getOddUuid() || selection.getEventId();
        };

        const checkShowSbv = (marketId: number, isSpecial: boolean) => {
            return shouldShowSpecialBetValue(marketId, markets.value, isSpecial);
        };

        const onFixClick = (item: SportSelection) => {
            emit('fixClick', item);
        };

        const onRemoveClick = (item: SportSelection) => {
            emit('removeClick', item);
        };

        return {
            checkShowSbv,
            getId,
            markets,
            onFixClick,
            onRemoveClick,
        };
    },
});
</script>
