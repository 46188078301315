<template>
    <TimeFilterContainer v-if="isOfferReady" :filters="filters" :shouldFetchDataOnCreated="false" />
</template>

<script>
import { mapGetters } from 'vuex';
import TimeFilterContainer from '@src/terminal/app/modules/shared/timeFilter/TimeFilterContainer.vue';
import { TimeFilter } from '@src/terminal/app/modules/shared/timeFilter/enums';
import { getTimeFilterForWeekDay } from '@src/terminal/app/modules/shared/timeFilter/helpers';

export default {
    name: 'SportsTimeFilterContainer',
    data() {
        return {
            filters: [],
        };
    },
    watch: {
        isInPlayAvailable(enabled) {
            if (enabled) {
                this.filters.unshift({
                    id: TimeFilter.live,
                    isUppercase: true,
                });
            } else {
                this.filters.shift();
            }
        },
    },
    created() {
        if (this.isInPlayAvailable) {
            this.filters.push({
                id: TimeFilter.live,
                isUppercase: true,
            });
        }
        this.filters.push({
            id: TimeFilter.upcoming,
            isUppercase: true,
        });
        this.filters.push({
            id: TimeFilter.today,
            isUppercase: true,
        });

        const todayDayIndex = new Date().getDay();
        for (let i = todayDayIndex + 1; i < todayDayIndex + 7; i += 1) {
            this.filters.push({
                id: getTimeFilterForWeekDay((i + 7) % 7),
            });
        }
        this.filters.push({
            id: TimeFilter.all,
            isUppercase: true,
        });
    },
    computed: {
        ...mapGetters('data/sportOffer', ['isOfferReady']),
        ...mapGetters('data/flags', ['isInPlayAvailable']),
    },

    components: {
        TimeFilterContainer,
    },
};
</script>
