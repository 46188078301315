enum ErrorType {
    badRequest = 'badRequest',
    authenticationNeeded = 'authenticationNeeded',
    forbidden = 'forbidden',
    resourceNotFound = 'resourceNotFound',
    serverError = 'serverError',
    timeout = 'timeout',
    networkError = 'networkError',
    socketConnectionError = 'socketConnectionError',
    socketDisconnectError = 'socketDisconnectError',
    generic = 'generic',
}

export default ErrorType;
