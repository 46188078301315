import Singleton from '@core/services/common/Singleton';
import { instanceGuard } from '@utils/services';
import ServerConfig from '@models/country/ServerConfig';
import { BetSlipData } from '@core/models/betSlip/IBetSlip';
import { Payout } from '@core/models/betSlip/Payout';
import SingletonError from '@core/services/common/errors/SingletonError';

import {
    CalculatedPayout,
    calculateSportPayout,
    calculateSuperBonus,
    isLuckyLoserEligible,
    isSuperBonusEligible,
    LuckyLoserConfig,
    MarketType,
    OfferType,
    PayoutConfig,
    SelectionType,
    SuperBonusConfig,
    SuperBonusValidBetTypes,
    SuperOffer,
    TicketType,
} from '@superbet-group/betting.lib.payments';
import ISelection from '@models/betSlip/ISelection';
import { BetSlipPurchaseType, BetSlipType } from '@models/shared/betSlip/betSlipEnums';

export interface ISimplePayout {
    selections: ISelection[];
    stakeAfterTax: number;
    purchaseType: BetSlipPurchaseType;
}

function mapToClassicPayout(payout: CalculatedPayout, isPariulSansa: boolean, minLuckyLoserCount: number): Payout {
    return {
        isPariulSansa,
        minLuckyLoserCount,
        base: payout.maxWin.gross,
        bonus: payout.maxWin.bonus,
        tax: payout.maxWin.taxes.totalTax,
        final: payout.maxWin.net,
        minFinal: payout.minWin.net,
        hasBeenLimited: payout.hasBeenLimited,
        taxPerTranche: payout.maxWin.taxes.brackets.map((bracket) => ({
            value: bracket.amount,
            percentage: bracket.rate,
        })),
    };
}

function getSuperOffer(tags: string, superOffers: SuperOffer[]): SuperOffer | null {
    for (const superOffer of superOffers) {
        if (tags.includes(superOffer)) {
            return superOffer;
        }
    }
    return null;
}

function getSelectedSystems(betSlip: BetSlipData): number[] {
    if (betSlip.type === BetSlipType.simple) {
        return [];
    }

    return betSlip.selectedSystems.map((system) => system.getMinNumber());
}

export class PayoutService {
    private static instance: PayoutService;
    private readonly payoutConfig: PayoutConfig;
    private readonly areSuperBonusSpecialsValidOnline: boolean;
    private readonly ignoredOfferTypes: SuperOffer[];
    private readonly minOddPariulSansa: number;
    private readonly minSelectionPariulSansa: number;
    public readonly isSuperBonusEnabled: boolean;
    public readonly superBonusMinCoefficient: number;
    public readonly superBonusNumberOfSelectionsMinimum: number;
    public static getInstance(): PayoutService {
        return instanceGuard(PayoutService.instance);
    }

    public static createInstance(config: ServerConfig): PayoutService {
        if (!PayoutService.instance) {
            PayoutService.instance = new PayoutService(config);
        }
        return PayoutService.instance;
    }

    private constructor(config: ServerConfig) {
        if (PayoutService.instance) {
            throw new SingletonError(this.constructor.name);
        }
        this.isSuperBonusEnabled = config.betSlip.bonusConfig.superBonusEnabled;
        this.ignoredOfferTypes = config.betSlip.bonusConfig.ignoredOfferTypes;
        this.superBonusMinCoefficient = config.betSlip.bonusConfig.minOddValue;
        this.superBonusNumberOfSelectionsMinimum = config.betSlip.bonusConfig.bonusBreakpoints[0].numberOfSelections;
        this.areSuperBonusSpecialsValidOnline = config.betSlip.areSuperBonusSpecialsValidOnline;
        this.minOddPariulSansa = config.betSlip.minOddPariulSansa || 1.3;
        this.minSelectionPariulSansa = config.betSlip.minSelectionPariulSansa || 11;
        this.payoutConfig = {
            handlingFeeInverse: config.betSlip.handlingFeeInverse,
            taxPerSystem: config.betSlip.taxPerSystem,
            maxWin: config.betSlip.maxWin,
            taxMode: config.betSlip.taxMode,
            taxBrackets: config.betSlip.taxBrackets,
            bonusConfig: config.betSlip.bonusConfig,
            taxBase: config.betSlip.taxBase,
            rounding: {
                amounts: 2,
                preFinal: {
                    totalTax: 3,
                    amounts: 3,
                },
                stakeCalculations: {
                    simple: 2,
                    system: null,
                    perCombination: 8,
                },
                tax: {
                    brackets: 2,
                    total: 2,
                },
                roundToRealMoneyAmounts: true,
            },
            handlingFeeRate: config.betSlip.offlineTax,
        };
    }

    private getMarketType(selection: ISelection): MarketType {
        const ignoredOfferTypes = (this.payoutConfig.bonusConfig as SuperBonusConfig).ignoredOfferTypes;
        const superOfferType = getSuperOffer(selection.getTags(), ignoredOfferTypes);
        if (superOfferType) {
            return {
                kind: OfferType.SuperOffer,
                offerType: superOfferType,
            };
        }
        return {
            kind: OfferType.Base,
            selectionType: selection.isOngoing() ? SelectionType.live : SelectionType.prematch,
        };
    }

    private getPayoutConfig(betSlip: BetSlipData): PayoutConfig {
        let bonusConfig = this.payoutConfig.bonusConfig as SuperBonusConfig | null;
        const superBonusEnabled = this.isBonusAvailable(betSlip, bonusConfig);
        bonusConfig = bonusConfig !== null ? { ...bonusConfig, superBonusEnabled } : null;
        return {
            ...this.payoutConfig,
            bonusConfig,
        };
    }

    private isBonusAvailable(betSlip: BetSlipData, bonusConfig: SuperBonusConfig | null): boolean {
        const { purchaseType, stake, selections } = betSlip;
        if (!bonusConfig) {
            return false;
        }

        const betType =
            betSlip.type === BetSlipType.system ? SuperBonusValidBetTypes.System : SuperBonusValidBetTypes.Simple;

        return this.getSuperBonusEligibility({
            purchaseType,
            selections,
            stake: stake || 0,
            betType,
        }).isUnlocked;
    }

    public getPayout(betSlip: BetSlipData): Payout {
        const systems = getSelectedSystems(betSlip);
        const selections = betSlip.selections.map((s) => ({
            odd: s.odd?.value || 0,
            fixed: s.isFixed,
            bonusable: s.odd?.isBonusable || false,
            marketType: this.getMarketType(s),
            tags: s.getTags(),
        }));
        const isSimple = betSlip.type === BetSlipType.simple;
        // pariul sansa progresiv
        const luckyLoserProgressiveConfig: Record<string, LuckyLoserConfig> = {
            1: {
                minOddCount: 9,
                minOddValue: 1.3,
                minTicketCoefficient: 10,
                ignoredOfferTypes: [SuperOffer.superKvota, SuperOffer.superExtra],
                excludedMarketsByTag: ['No Pariul'],
            },
        };
        const minLuckyLoserCount = luckyLoserProgressiveConfig[1].minOddCount;
        const isLuckyLoser = isLuckyLoserEligible(
            luckyLoserProgressiveConfig,
            TicketType.retail,
            selections,
            true,
            isSimple,
        );

        const payout = calculateSportPayout(selections, systems, betSlip.stake, this.getPayoutConfig(betSlip));

        return mapToClassicPayout(payout, isLuckyLoser, minLuckyLoserCount);
    }

    public getSuperBonus(selections: ISelection[], betType: SuperBonusValidBetTypes) {
        const mappedSelections = selections.map((s) => {
            const odd = s.odd?.value || 0;
            const isBonusable = s.odd?.isBonusable || false;
            const bonusConfig = this.payoutConfig.bonusConfig;

            return {
                odd,
                bonusable: isBonusable,
                fixed: s.isFixed,
                marketType: this.getMarketType(s),
                tolerable:
                    isBonusable && !!bonusConfig?.areSelectionsBelowMinOddTolerated && odd < bonusConfig!.minOddValue,
            };
        });

        return calculateSuperBonus(mappedSelections, this.payoutConfig.bonusConfig as SuperBonusConfig, betType);
    }

    public getSuperBonusEligibility({
        stake,
        purchaseType,
        selections,
        betType,
    }: {
        stake: number;
        purchaseType: BetSlipPurchaseType;
        selections: ISelection[];
        betType?: SuperBonusValidBetTypes;
    }) {
        const mappedSelections = selections.map((s) => {
            const odd = s.odd?.value || 0;
            const isBonusable = s.odd?.isBonusable || false;
            const bonusConfig = this.payoutConfig.bonusConfig;

            return {
                odd,
                bonusable: isBonusable,
                fixed: s.isFixed,
                marketType: this.getMarketType(s),
                tolerable:
                    isBonusable && !!bonusConfig?.areSelectionsBelowMinOddTolerated && odd < bonusConfig!.minOddValue,
            };
        });

        return isSuperBonusEligible(
            stake,
            TicketType.retail,
            {
                userBalance: 0,
                sportBonusBalance: 0,
            }, // TODO: remove monetary info data
            mappedSelections,
            this.payoutConfig.bonusConfig as SuperBonusConfig,
            betType,
        );
    }

    public isOddValidForSuperBonus(tags: string | undefined): boolean {
        return !tags || !getSuperOffer(tags, this.ignoredOfferTypes);
    }

    public static clearInstance(): void {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete PayoutService.instance;
    }
}

export default PayoutService as Singleton<PayoutService>;
