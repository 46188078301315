<template>
    <div>
        <div :class="['filters__scroll', 'left', { show: showScrollLeft }]" @click="scrollTo('left')">
            <i class="sds-icon-navigation-chevron-left" />
        </div>
        <TimeFilterV2
            ref="filtersContainer"
            :filters="filters"
            :selectedFilter="timeFilter"
            :isTodaySelected="isTodaySelected"
            class="filters__container"
            @click="onClick"
            @scroll.native="onScroll"
        />
        <div :class="['filters__scroll', 'right', { show: showScrollRight }]" @click="scrollTo('right')">
            <i class="sds-icon-navigation-chevron-right" />
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { omit as _omit } from '@lodash';
import TimeFilterV2 from '@src/v2/app/modules/sportOffer/sports/timeFilter/TimeFilterV2.vue';
import { TimeFilter } from '@src/terminal/app/modules/shared/timeFilter/enums';
import { routeNames } from '@src/app/router';
import { ExpandedEventInUrl } from 'src/app/enums';
import { getTimeFilterFromSlug, getSlugFromTimeFilter } from '@src/terminal/app/modules/shared/timeFilter/helpers';
import { TIME_FILTER_SCROLL } from '@src/app/styles/elementConstants';
import { useRoute, useRouter } from 'vue-router/composables';
import { DateTimeFilter } from '@src/v2/core/models/DateTimeFilter';

export default defineComponent({
    components: { TimeFilterV2 },
    name: 'TimeFilterContainerV2',
    props: {
        filters: {
            type: Array as PropType<DateTimeFilter>,
            required: true,
        },
        isTodaySelected: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        const { scrollWidth, clientWidth } = this.filtersContainer.$el;
        this.showScrollRight = scrollWidth > clientWidth;
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const showScrollLeft = ref(false);
        const showScrollRight = ref(false);
        const filtersContainer = ref();

        const timeFilter = computed(() => {
            const slug = route.params.timeFilter;
            const currentFilter = slug ? getTimeFilterFromSlug(slug) : TimeFilter.today;

            return currentFilter === TimeFilter.today ? TimeFilter.allToday : currentFilter;
        });

        const onScroll = () => {
            const { scrollLeft, scrollWidth, offsetWidth } = filtersContainer.value.$el;
            showScrollLeft.value = scrollLeft > 0;
            showScrollRight.value = Math.ceil(scrollLeft + offsetWidth) < scrollWidth;
        };

        const scrollTo = (direction: 'left' | 'right') => {
            const timeFilterElement = filtersContainer.value.$el;
            const scrollAmount = direction === 'left' ? -TIME_FILTER_SCROLL : TIME_FILTER_SCROLL;

            timeFilterElement.scrollTo({
                left: timeFilterElement.scrollLeft + scrollAmount,
            });
        };

        const onClick = (filter: TimeFilter) => {
            const timeFilterSlug = getSlugFromTimeFilter(filter);
            // warning: Too specific code incoming. Refactor when we have time
            const routeName = route.name === routeNames.landing ? routeNames.sportOffer : route.name;
            // specific code end
            router.push({
                name: routeName || undefined,
                params: {
                    ...route.params,
                    timeFilter: timeFilterSlug,
                },
                query: _omit(route.query, [
                    ExpandedEventInUrl.event,
                    ExpandedEventInUrl.type,
                    ExpandedEventInUrl.odd,
                    ExpandedEventInUrl.sbValue,
                ]),
            });
        };

        return {
            filtersContainer,
            showScrollLeft,
            showScrollRight,
            timeFilter,
            onScroll,
            scrollTo,
            onClick,
        };
    },
    onClick(filter: TimeFilter) {
        const timeFilterSlug = getSlugFromTimeFilter(filter);
        // warning: Too specific code incoming. Refactor when we have time
        const routeName = this.$route.name === routeNames.landing ? routeNames.sportOffer : this.$route.name;
        // specific code end
        this.$router.push({
            name: routeName,
            params: {
                ...this.$route.params,
                timeFilter: timeFilterSlug,
            },
            query: _omit(this.$route.query, [
                ExpandedEventInUrl.event,
                ExpandedEventInUrl.type,
                ExpandedEventInUrl.odd,
                ExpandedEventInUrl.sbValue,
            ]),
        });
    },
});
</script>
