<template>
    <router-link :to="to" :tag="tag" v-bind="$attrs" class="static-link">
        <slot />
        {{ text }}
    </router-link>
</template>

<script>
import { links } from '@app/router';
import { capitalize } from '../filters';

export default {
    name: 'StaticLink',
    props: {
        path: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        shouldCapitalize: {
            type: Boolean,
            default: true,
        },
        tag: {
            type: String,
            default: 'a',
        },
    },
    computed: {
        to() {
            return `${links.wiki}/${this.$t(this.path)}`;
        },
        text() {
            const t = this.$t(this.label);
            if (this.shouldCapitalize) {
                return capitalize(t);
            }
            return t;
        },
    },
};
</script>
