/* eslint-disable @typescript-eslint/no-explicit-any */
/* We explicitly need `any` for the resolution to work, unknown would not help */
import { computed, getCurrentInstance, ComputedRef } from 'vue';

/**
 * Wraps a Vuex module in a type-safe definition for accessing state, getters,
 * and actions. To be used correctly, you need to pass it the module index (as
 * well as the correct key).
 *
 * You will typically do so by importing the store index and passing it as typeof
 *
 * ```typescript
 * import myStoreIndex from '@store/modules/my/store/storeIndex';
 * const myStore = useVuexStore<typeof myStoreIndex>('my/store');
 * const myField = myStore.getter('myField');
 * ```
 *
 * When dealing with a module factory, you can use the `ReturnType` helper instead:
 * `useVuexStore<ReturnType<typeof myStoreIndex>>('my/store')`
 *
 * If some field or action resolves to any, check the type definitions in your
 * module. They are most likely to blame.
 *
 * If the return value from the function resolves to `never`, contact @luka.skukan,
 * we could have a bug in the helper.
 *
 * @param module Path to the module inside of global Vuex, separated by slashes, as in `mapGetters` or `mapActions`
 */
export function useVuexStore<SubStore>(
    module: string,
): SubStore extends StoreDef<infer StoreState, infer StoreGetters, infer StoreActions>
    ? StoreUtil<StoreState, StoreGetters, StoreActions>
    : never;

export function useVuexStore(module: string): unknown {
    const currentInstance = getCurrentInstance();

    if (currentInstance === null) {
        throw new Error('useVuexStore cannot be used outside of Vue component setup or lifecycle hooks');
    }

    const rootStore = currentInstance.proxy.$store;

    function state(key: string): ComputedRef<unknown> {
        return computed(() => {
            const pathParts = `${module}/${key}`.split('/');

            return pathParts.reduce((currState, path) => currState?.[path], rootStore.state);
        });
    }

    function getter(key: string): ComputedRef<unknown> {
        return computed(() => rootStore.getters[`${module}/${key}`]);
    }

    function action(key: string): (...args: unknown[]) => Promise<unknown> {
        return (payload?: unknown): Promise<unknown> => rootStore.dispatch(`${module}/${key}`, payload);
    }

    return {
        action,
        getter,
        state,
    };
}

type AnyFunction = (...args: any[]) => any;

type ActionObject = {
    root?: boolean;
    handler: AnyFunction;
};

type StoreAction = AnyFunction | ActionObject;

type WrappedAction<Action extends StoreAction> = Action extends (_ctx: any, payload?: infer Arg) => infer R
    ? (payload?: Arg) => Promise<R>
    : Action extends (_ctx: any) => infer R
    ? () => Promise<R>
    : Action extends (_ctx: any, arg: infer Arg, ...args: any[]) => infer R
    ? (payload: Arg) => Promise<R>
    : Action extends ActionObject
    ? WrappedAction<Action['handler']>
    : never;

type StoreDef<
    State,
    Getters extends Record<string, AnyFunction> | undefined,
    Actions extends Record<string, StoreAction> | undefined,
> = {
    state?: State | (() => State);
    getters?: Getters;
    actions?: Actions;
};

type StoreUtil<State, Getters, Actions> = {
    action<K extends keyof Actions>(name: K): Actions[K] extends StoreAction ? WrappedAction<Actions[K]> : undefined;
    state<K extends keyof State>(name: K): ComputedRef<State[K]>;
    getter<K extends keyof Getters>(
        name: K,
    ): Getters[K] extends AnyFunction ? ComputedRef<ReturnType<Getters[K]>> : never;
};
