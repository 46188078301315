<template>
    <div :class="overlayClasses" @mousedown.self="onClickOutside">
        <component :is="modalComponent" v-if="isIndependent" @close="close" />
        <div v-else :class="containerClasses">
            <div class="modal__header">
                <div class="modal__title">
                    {{ $t(title) }}
                </div>
                <i class="icon icon-close" @click="close" />
            </div>
            <component :is="modalComponent" @close="close" />
        </div>
    </div>
</template>

<script>
import { Keys, getKey } from '@app/utils/keyListener';

export default {
    props: {
        code: {
            type: String,
            required: true,
        },
        modalComponent: {
            type: Object,
            required: true,
        },
        setModal: {
            type: Function,
            required: true,
        },
        containerClass: {
            type: String,
            required: false,
            default: '',
        },
        overlayClass: {
            type: String,
            required: false,
            default: '',
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        goBack: {
            type: Boolean,
            required: false,
        },
        // Used when the component itself will be the modal. No header or container around it
        isIndependent: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        containerClasses() {
            const classes = ['modal__container'];
            if (this.containerClass) {
                classes.push(this.containerClass);
            }
            return classes;
        },
        overlayClasses() {
            const classes = ['modal__overlay'];
            if (this.overlayClass) {
                classes.push(this.overlayClass);
            }
            return classes;
        },
    },
    created() {
        this.modalOverLayClass = 'modal__overlay';
    },
    mounted() {
        document.addEventListener('keyup', this.onKeyup);
    },
    destroyed() {
        document.removeEventListener('keyup', this.onKeyup);
    },
    methods: {
        close() {
            this.$emit('close');
        },
        onClickOutside(event) {
            if (event.target.className === this.modalOverLayClass) {
                this.close();
            }
        },
        onKeyup(event) {
            const key = getKey(event);
            if (key === Keys.ESC) {
                this.close();
            }
        },
    },
};
</script>
