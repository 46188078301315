import Logger from '@loggers/Logger';

const DELETED_COOKIE_PLACEHOLDER = '##cookieKeyIsDeleted##';

/**
 * Cookie storage is always fallback when local storage does not work or it is full.
 * Because of that we always have additional check
 */

export class ClientStorage {
    set(key: string, value: any, expiresDays = 365): void {
        const stringValue = JSON.stringify(value);

        try {
            localStorage.setItem(key, stringValue);
        } catch (e) {
            try {
                this.setToCookie(key, stringValue, expiresDays);
            } catch (e) {
                Logger.error(e);
            }
        }
    }

    get(key: string): any | null {
        try {
            const value = localStorage.getItem(key);

            if (value !== null) {
                return JSON.parse(value);
            }
        } catch (e) {
            Logger.error(e);
        }

        return this.getFromCookie(key);
    }

    remove(key: string): void {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            try {
                this.deleteFromCookie(key);
            } catch (e) {
                Logger.error(e);
            }
        }
    }

    private setToCookie(key: string, value: string, expiresDays: number): void {
        const date = new Date();
        date.setTime(date.getTime() + expiresDays * 24 * 60 * 60 * 1000);
        document.cookie = `${key}=${value};expires=${date.toUTCString()}; path=/;`;
    }

    private getFromCookie(key: string): string | null {
        try {
            const decodedCookie = decodeURIComponent(document.cookie);
            const items = decodedCookie.split(';');

            for (let i = 0; i < items.length; i += 1) {
                const trimmedItem = items[i].trim();
                const [itemKey, value] = trimmedItem.split('=');

                if (itemKey === key) {
                    if (value === DELETED_COOKIE_PLACEHOLDER) {
                        return null;
                    }

                    return value;
                }
            }
        } catch (e) {
            Logger.error(e);
        }

        return null;
    }

    private deleteFromCookie(key: string): void {
        const date = new Date();
        date.setFullYear(date.getFullYear() - 1);
        document.cookie = `${key}=${DELETED_COOKIE_PLACEHOLDER};expires=${date.toUTCString()}; path=/;`;
    }
}

export default new ClientStorage();
