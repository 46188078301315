<template>
    <div>
        <TimeFilter :filters="filters" :selectedFilter="timeFilter" class="time-filter--terminal" @click="onClick" />
        <transition name="animation__scale-up-ver-top-">
            <div v-if="isReady" class="spinner-container--terminal">
                <SpinnerBars />
            </div>
        </transition>
    </div>
</template>

<script>
import { omit as _omit } from '@lodash';
import { mapGetters } from 'vuex';
import TimeFilter from '@src/terminal/app/modules/shared/timeFilter/components/TimeFilter.vue';
import { TimeFilter as TimeFiltersEnum } from '@src/terminal/app/modules/shared/timeFilter/enums';
import { routeNames } from '@src/app/router';
import { ExpandedEventInUrl } from 'src/app/enums';
import SpinnerBars from '@shared/components/SpinnerBars.vue';
import { getTimeFilterFromSlug, getSlugFromTimeFilter } from './helpers';

export default {
    name: 'TimeFilterContainer',
    props: {
        filters: {
            type: Array,
            required: true,
        },
        shouldFetchDataOnCreated: {
            type: Boolean,
            required: false,
            default: true,
        },
        isReady: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapGetters('navigation', ['route']),
        timeFilter() {
            const slug = this.$route.params.timeFilter;
            return slug ? getTimeFilterFromSlug(slug) : TimeFiltersEnum.today;
        },
    },
    methods: {
        onClick(filter) {
            const timeFilterSlug = getSlugFromTimeFilter(filter);
            // warning: Too specific code incoming. Refactor when we have time
            const routeName = this.$route.name === routeNames.landing ? routeNames.sportOffer : this.$route.name;
            // specific code end
            this.$router.push({
                name: routeName,
                params: {
                    ...this.$route.params,
                    timeFilter: timeFilterSlug,
                },
                query: _omit(this.$route.query, [
                    ExpandedEventInUrl.event,
                    ExpandedEventInUrl.type,
                    ExpandedEventInUrl.odd,
                    ExpandedEventInUrl.sbValue,
                ]),
            });
        },
    },
    components: {
        TimeFilter,
        SpinnerBars,
    },
};
</script>
