import Singleton from '@core/services/common/Singleton';
import { instanceGuard } from '@core/utils/services';
import ServerConfig from '@core/models/country/ServerConfig';
import { Subscription } from 'rxjs/internal/Subscription';
import i18n from '@src/app/localization/i18n';
import store from '@app/store/';
import common from '@src/config/common';
import { RequestError } from '@core/errors/network/RequestError';
import ErrorType from '@core/errors/network/ErrorType';
import PlatformService from '../platform/PlatformService';
import SocketManager from './_private/SocketManager';
import { NotReadySource } from '../betting/_private/enums';
import BettingAvailableService from '../betting/BettingAvailableService';
import { TicketStatus } from './_private/enums';

class RetailService {
    private static instance: RetailService;
    private socketManager: SocketManager;
    private bettingUnavailable = { reason: 'connection to retail.', available: false };

    private constructor(private config: ServerConfig, private productId: string) {
        this.initialize();
    }

    public initialize() {
        if (this.socketManager) {
            this.stopStreaming();
        }
        const { paymentHostSocket, socketPath } = this.config.tickets;
        const baseUrl = paymentHostSocket;
        const path = socketPath;
        if (common.environment.isNSoft) {
            const deviceUuid = localStorage.getItem('deviceUuid');
            this.socketManager = new SocketManager(
                `${baseUrl}?terminalUuid=${deviceUuid}&product=${this.productId}`,
                path,
            );
        } else {
            const authToken = localStorage.getItem('authToken');
            const storeId = localStorage.getItem('storeId');
            const terminalId = localStorage.getItem('terminalId');
            this.socketManager = new SocketManager(
                `${baseUrl}?product=${this.productId}&terminalId=${terminalId}&storeId=${storeId}&token=${authToken}`,
                path,
            );
        }
        this.startStreaming();
    }

    /**
     *
     * @throws {RequestError}
     * @throws {Error}
     */
    public stopStreaming(): void {
        this.socketManager.stopStreaming();
    }

    /**
     * @throws {RequestError}
     * @throws {Error}
     */
    public startStreaming(): void {
        this.socketManager.startStreaming();
        this.initializeEvents();
    }

    public static getInstance(): RetailService {
        return instanceGuard(RetailService.instance);
    }

    public static createInstance(config: ServerConfig, productId: string) {
        if (!RetailService.instance) {
            RetailService.instance = new RetailService(config, productId);
        }
        return RetailService.instance;
    }

    public initializeEvents(): void {
        this.subscribeToConnectedStream((data: any) => {
            console.log('SSBT::Retail Service Connected >>', data);
            this.updateIsTerminalDisabled(data.disabled);
            BettingAvailableService.getInstance().unset(NotReadySource.RetailConn);
            console.log('SSBT::Application Ready (999)');
            this.setAppReady(true);
        });
        this.subscribeToTerminalStatus((data: any) => {
            console.log('SSBT::Terminal Status Update -> Retail Service >>', data);
            this.updateIsTerminalDisabled(data.disable);
        });
        this.subscribeToConnectErrorStream((error: any) => {
            console.error('SSBT::Retail Service Connect Error >>', error);
            BettingAvailableService.getInstance().set(NotReadySource.RetailConn, this.bettingUnavailable);
        });
        this.subscribeToReconnectedStream(() => {
            console.log('SSBT::Retail Service Reconnected');
            BettingAvailableService.getInstance().unset(NotReadySource.RetailConn);
        });
        this.subscribeToDisconnectedStream(() => {
            console.error('SSBT::Retail Service Disconnected');
            BettingAvailableService.getInstance().set(NotReadySource.RetailConn, this.bettingUnavailable);
        });
        this.subscribeToErrorStream((error: any) => {
            console.error('SSBT::Retail Service Error Ocurred >>', error);
            BettingAvailableService.getInstance().set(NotReadySource.RetailConn, this.bettingUnavailable);
        });
    }

    public setAppReady(isReady: boolean) {
        store.dispatch('data/country/setIsAppReady', isReady, { root: true });
    }

    /**
     * Resolves disjointed submit ticket request via interrupted socket connection
     * @param outcomeTimeout timeout period before the process is canceled
     * @param requestIdPromise promise which should resolve to submit ticket request id
     */
    public obtainTicketOutcome(
        outcomeTimeout: number,
        ticketCheckInterval: number,
        requestIdPromise: Promise<any>,
    ): Promise<any> {
        return new Promise((resolve) => {
            let timeout: NodeJS.Timer | undefined;
            let outcomeSub: Subscription | undefined;
            let statusSub: Subscription | undefined;
            let hangingInterval: NodeJS.Timer | undefined;
            let hangingSub: Subscription | undefined;

            const closeClosable = () => {
                if (timeout) clearTimeout(timeout);
                if (outcomeSub) outcomeSub.unsubscribe();
                if (statusSub) statusSub.unsubscribe();
                if (hangingInterval) clearInterval(hangingInterval);
                if (hangingSub) hangingSub.unsubscribe();
            };

            const evalPayloadStatus = (payload: any, resolve: any) => {
                switch (payload.status) {
                    case TicketStatus.Negotiation:
                        this.updateNegotiationLoading(true);
                        break;
                    case TicketStatus.InProgress:
                        break;
                    case TicketStatus.Success:
                        closeClosable();
                        this.updateNegotiationLoading(false);
                        resolve(payload);
                        break;
                    case TicketStatus.Error:
                        closeClosable();
                        this.updateNegotiationLoading(false);
                        resolve({ data: null, error: true, message: i18n.t('genericTicketSubmitError') });
                        break;
                    default:
                        break;
                }
            };

            try {
                timeout = setTimeout(() => {
                    closeClosable();
                    resolve({ data: null, error: true, message: i18n.t('genericTicketSubmitError') });
                }, outcomeTimeout);

                requestIdPromise
                    .then((response: any) => {
                        console.log('SSBT::RequestId -> ObtainTicketOutcome (Nested) >>', response);
                        const { requestId } = response;
                        if (!requestId) {
                            console.log('SSBT::Err RequestId not received');
                            throw new Error(`${i18n.t('genericTicketSubmitError')}`);
                        }

                        hangingSub = this.subscribeToTicketCheck((payload: any) => {
                            console.log('SSBT::Hanging ticket status update >>', payload);
                            if (payload) {
                                evalPayloadStatus(payload, resolve);
                            }
                        });
                        hangingInterval = setInterval(() => {
                            console.log('SSBT::Requesting Scheduled Ticket Check with ReqId >>', requestId);
                            this.requestTicketCheck(requestId);
                        }, ticketCheckInterval);
                    })
                    .catch((e: any) => {
                        console.error('SSBT::RequestId ERR -> ObtainTicketOutcome (Nested) >>', e.message);
                        if (e instanceof RequestError) {
                            if (e.type === ErrorType.authenticationNeeded && common.environment.isInHouse) {
                                PlatformService.getInstance().refreshAuth();
                            }
                        }
                        closeClosable();
                        resolve({ data: null, error: true, message: `${i18n.t('genericTicketSubmitError')}` });
                    });

                statusSub = this.subscribeToTicketSubmitStatus((payload: any) => {
                    console.log('SSBT::Periodic status update (server initiated) >>', payload);
                    if (payload) {
                        evalPayloadStatus(payload, resolve);
                    }
                });
                outcomeSub = this.subscribeToTicketOutcome((payload: any) => {
                    closeClosable();
                    this.updateNegotiationLoading(false);
                    console.log('SSBT::Ticket Resolved with Outcome Payload >>', payload);
                    resolve(payload);
                });
            } catch (error: any) {
                closeClosable();
                resolve({ data: null, error: true, message: error.message });
            }
        });
    }

    public updateIsTerminalDisabled(isDisabled: boolean) {
        store.dispatch('data/country/disableTerminal', isDisabled, { root: true });
    }

    public updateNegotiationLoading(isLoading: boolean) {
        store.commit('data/tickets/SET_TICKET_NEGOTIATION_LOADING', isLoading, { root: true });
    }

    /**
     *  When stream connects the caller will be notified
     *
     * @return Subscription object so you can obj.unsubscribe()
     */
    public subscribeToTicketOutcome(callback: (payload: any) => void): Subscription {
        return this.socketManager.subscribeToTicketOutcome(callback);
    }

    /**
     *  When stream connects the caller will be notified
     *
     * @return Subscription object so you can obj.unsubscribe()
     */
    public subscribeToTicketSubmitStatus(callback: (payload: any) => void): Subscription {
        return this.socketManager.subscribeToTicketSubmitStatus(callback);
    }

    /**
     *  When stream connects the caller will be notified
     *
     * @return Subscription object so you can obj.unsubscribe()
     */
    public subscribeToTicketCheck(callback: (payload: any) => void): Subscription {
        return this.socketManager.subscribeToTerminalTicketCheck(callback);
    }

    /**
     *  When terminal status changes event is received
     *
     * @return Subscription object so you can obj.unsubscribe()
     */
    public subscribeToTerminalStatus(callback: (payload: any) => void): Subscription {
        return this.socketManager.subscribeToTerminalStatus(callback);
    }

    /**
     * When stream can't connect the caller will be notified
     *
     * @return Subscription object so you can obj.unsubscribe()
     */
    public subscribeToConnectErrorStream(callback: (error: Error) => void): Subscription {
        return this.socketManager.subscribeToConnectErrorStream(callback);
    }

    /**
     *  When stream disconnects the caller will be notified
     *
     * @return Subscription object so you can obj.unsubscribe()
     */
    public subscribeToDisconnectedStream(callback: () => void): Subscription {
        return this.socketManager.subscribeToDisconnectedStream(callback);
    }

    /**
     *  When stream reconnects the caller will be notified
     *
     * @return Subscription object so you can obj.unsubscribe()
     */
    public subscribeToReconnectedStream(callback: () => void): Subscription {
        return this.socketManager.subscribeToReconnectedStream(callback);
    }

    /**
     *  When stream connects the caller will be notified
     *
     * @return Subscription object so you can obj.unsubscribe()
     */
    public subscribeToConnectedStream(callback: (value: any) => void): Subscription {
        return this.socketManager.subscribeToConnectedStream(callback);
    }

    public requestTicketCheck(requestId: string): void {
        this.socketManager.requestTerminalTicketCheck(requestId);
    }

    /**
     *  When stream connects the caller will be notified
     *
     * @return Subscription object so you can obj.unsubscribe()
     */
    public subscribeToErrorStream(callback: (error: any) => void): Subscription {
        return this.socketManager.subscribeToErrorStream(callback);
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete (RetailService as any).instance;
    }
}

export default RetailService as Singleton<RetailService>;
