import Vue from 'vue';
import VueI18n from 'vue-i18n';
import config from '@config';
import messages from '@app/localization/lang/messages';

Vue.use(VueI18n);

const mappings: { [k: string]: Intl.DateTimeFormatOptions } = {
    w: { weekday: 'short' },
    W: { weekday: 'long' },

    dd: { day: 'numeric' },
    m: { month: 'narrow' },
    mm: { month: 'numeric' },
    month: { month: 'short' },
    MONTH: { month: 'long' },
    yy: { year: '2-digit' },
    yyyy: { year: 'numeric' },

    HH: { hour: 'numeric' },
    MM: { minute: 'numeric' },
    SS: { second: 'numeric' },
};

const buildMapping = (format: string[]) => format.reduce((acc: any, f) => ({ ...acc, ...(<any>mappings)[f] }), {});

const dateTimeFormats = {
    ddmmyyyy: buildMapping(['dd', 'mm', 'yyyy']),
    dd: buildMapping(['dd']),
    HHMM: buildMapping(['HH', 'MM']),
    Wddmmyy: buildMapping(['W', 'dd', 'mm', 'yy']),
    wHHMM: buildMapping(['w', 'HH', 'MM']),
    wddHHMM: buildMapping(['w', 'dd', 'HH', 'MM']),
    ddmm: buildMapping(['dd', 'mm']),
    ddmmyyyyHHMM: buildMapping(['dd', 'mm', 'yyyy', 'HH', 'MM']),
    monthddHHMM: buildMapping(['month', 'dd', 'HH', 'MM']),
    monthddHHMMyyyy: buildMapping(['month', 'dd', 'HH', 'MM', 'yyyy']),
    ddmmyyHHMM: buildMapping(['dd', 'mm', 'yy', 'HH', 'MM']),
    wddm: buildMapping(['w', 'dd', 'm']),
    wddmm: buildMapping(['w', 'dd', 'mm']),
    wddmonth: buildMapping(['w', 'dd', 'month']),
    ddmonth: buildMapping(['dd', 'month']),
    ddmonthyyyy: buildMapping(['dd', 'month', 'yyyy']),
    w: buildMapping(['w']),
    wdd: buildMapping(['w', 'dd']),
    time: buildMapping(config.app.format.time),
    monthddhHHMM: buildMapping(['month', 'dd', 'HH', 'MM']),
};

export default new VueI18n({
    messages,
    numberFormats: {
        'en-GB': {
            decimal: { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 },
            currency: {
                style: 'currency',
                currency: 'GBP',
            },
        },
        'ro-RO': {
            decimal: { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 },
            currency: {
                style: 'currency',
                currency: 'RON',
            },
        },
        'pl-PL': {
            decimal: { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 },
            currency: {
                style: 'currency',
                currency: 'PLN',
            },
        },
    },
    dateTimeFormats: {
        'en-GB': dateTimeFormats,
        'ro-RO': dateTimeFormats,
        'pl-PL': dateTimeFormats,
    },
    locale: config.locale,
    fallbackLocale: 'en-GB',
});
